import React, { FC, memo } from 'react'
import { ItemCell } from '../../components/Cell'
import Text from '../../components/Text'
import { ColumnView } from '../../components/QCFlex'
import { isZero } from '../../utils/lodash'
import { IGoodsMenuItem } from './interface'
interface GoodsItemProps {
  name?: string
  count?: number
  price?: number
}
interface GoodsCardProps {
  list?: IGoodsMenuItem[]
  shopName?: string
}
const GoodsItem: FC<GoodsItemProps> = memo(({ name, count, price }) => (
  <ItemCell
    label={
      <>
        {name}
        {!isZero(count) && (
          <Text color="#999999FF" size={28}>
            x{count}
          </Text>
        )}
      </>
    }
    value={`￥${price}`}
    arrow={false}
  />
))
GoodsItem.displayName = 'MGoodsItem'
const GoodsCard: FC<GoodsCardProps> = ({ list = [], shopName }) => {
  // 总数量
  let count = 0
  // 总费用
  let cost = 0
  list.map((item) => {
    count += item.count || 0
    // 配送费、打包费、餐盒费count是0
    cost += item.count ? Number(item.price) * item.count : Number(item.price)
  })
  return (
    <ColumnView className="qc-section-card">
      <ItemCell
        label={
          <Text size={34} color="#999999FF">
            {shopName}
          </Text>
        }
        arrow={false}
      />
      {list.map((item: any) => (
        <GoodsItem
          key={item.menu_name}
          name={item.menu_name}
          count={item.count}
          price={item.price}
        />
      ))}
      <ItemCell
        label={
          <Text color="#999999FF" size={30}>
            合计x{count}
          </Text>
        }
        value={
          <Text size={30} color="#FF9847FF">
            ¥{cost.toFixed(2)}
          </Text>
        }
        arrow={false}
      />
      <ItemCell
        value="因不可预测的餐厅变动，此金额仅供参考，具体支付金额请以收货后发票为准，如金额差异较大请及时联系客服"
        arrow={false}
      />
    </ColumnView>
  )
}

export default GoodsCard
