import React, { FC, PropsWithChildren } from 'react'
import QCIcon, { IconProps as QCIconProps } from '../Icon'
import Text from '../Text'

import './index.global.less'
export type TCellInner = FC<PropsWithChildren<{}>>
export type TCellIcon = FC<QCIconProps>
export interface ICellLayout {
  Left: TCellInner
  Right: TCellInner
  Icon: TCellIcon
}
export type TCellLayoutAlign = 'between' | 'start'
export interface CellLayoutProps {
  className?: string
  align?: TCellLayoutAlign
}
export interface IconItemCellProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  label?: React.ReactNode
  value?: React.ReactNode
  icon?: QCIconProps
  arrow?: boolean
  align?: TCellLayoutAlign
}
const Left: TCellInner = ({ children }) => {
  const mergedClassName = `qc-cellbox-left`
  return <div className={mergedClassName}>{children}</div>
}
const Right: TCellInner = ({ children }) => {
  const mergedClassName = `qc-cellbox-right`
  return <div className={mergedClassName}>{children}</div>
}
const Icon: TCellIcon = ({ className = '', ...props }) => {
  const mergedClassName = `qc-cellbox-icon ${className}`
  return <QCIcon className={mergedClassName} {...props} />
}
const LayoutAlignMap = {
  start: 'qc-cellbox-start',
  between: 'qc-cellbox-between'
}
const CellLayout: FC<PropsWithChildren<CellLayoutProps>> & ICellLayout = ({
  className = '',
  align = 'between',
  ...otherProps
}) => {
  const mergedClassName = `qc-cellbox ${className} ${LayoutAlignMap[align]}`
  return <div className={mergedClassName} {...otherProps} />
}
CellLayout.Icon = Icon
CellLayout.Left = Left
CellLayout.Right = Right

// 固定组件样式
// 如有定制需求请自行封装
// align=between    | icon label ------------ value > |
// align=start      | icon label value ------------ > |
export const ItemCell: FC<IconItemCellProps> = ({
  icon,
  label,
  value,
  arrow = true,
  align,
  ...otherProps
}) => (
  <CellLayout align={align} {...otherProps}>
    {icon && (
      <CellLayout.Icon
        color={icon?.color}
        size={icon?.size || 30}
        name={icon?.name}
      />
    )}
    {label && (
      <CellLayout.Left>
        <Text size={30} color="#181818FF">
          {label}
        </Text>
      </CellLayout.Left>
    )}
    {(value || arrow) && (
      <CellLayout.Right>
        {value && (
          <Text size={30} color="#888888ff">
            {value}
          </Text>
        )}
        {arrow && <QCIcon name="iconyoujiantou" size={16} color="#888888ff" />}
      </CellLayout.Right>
    )}
  </CellLayout>
)
export default CellLayout
