import React, { FC, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Modal } from 'antd-mobile'
import dayjs from 'dayjs'
import {
  useOrderLockCheck as useuseOrderLockCheckApi,
  useMonthLimit
} from '../api/Order'
import Button from '../components/Button'
import Text from '../components/Text'
import { XCenterView } from '../components/QCFlex'
export interface OrderLockCheckProps {
  visible?: boolean
  manual?: boolean
  reserveTime?: string
  onOk?: () => void
}
const OrderLockCheckContent: FC<{ texts?: string[]; onOk?: () => void }> = ({
  texts,
  onOk
}) => (
  <>
    {texts?.map((text, idx) => (
      <Text color="#999999FF" size={30} key={idx}>
        {text}
      </Text>
    ))}
    <XCenterView margin={[50, 0, 0, 0]}>
      <Button onClick={onOk}>知道了</Button>
    </XCenterView>
  </>
)
export const useOrderLockCheck = ({
  reserveTime,
  visible = true,
  manual,
  onOk
}: OrderLockCheckProps): [boolean, () => void] => {
  const modalRef = useRef<any>(null)
  const handleOk = () => {
    // 关闭
    modalRef.current.close()
    modalRef.current = null
    onOk && onOk()
  }
  // 月消费额限制
  const [monthLimitRes, fetchMonthLimit] = useMonthLimit({
    // 锁住 不校验消费限额
    manual: true,
    useCache: false,
    data: {
      reserveTime: dayjs(reserveTime || Date.now()).format('YYYY-MM-DD HH:mm')
    }
  })
  // 是否锁单
  const [orderLockCheckRes, orderLockCheck] = useuseOrderLockCheckApi({
    useCache: false,
    manual: manual
  })

  useEffect(() => {
    const texts: string[] = []
    // 预算文案
    if (monthLimitRes.data?.data?.check === true) {
      texts.push('您本月预算已用完，无法下单')
    }
    // 锁单文案
    if (orderLockCheckRes.data?.data?.total) {
      texts.push(
        `您有${orderLockCheckRes.data?.data?.total}笔订单未完成，去订单页完成～`
      )
    }

    // texts 有文案才去弹框，没有就认为是通过了
    visible &&
      !modalRef.current &&
      texts?.length &&
      (modalRef.current = Modal.alert(
        '',
        <OrderLockCheckContent texts={texts} onOk={handleOk} />,
        []
      ))
  }, [orderLockCheckRes, monthLimitRes])
  const orderCheck = () => {
    orderLockCheck()
    // fetchMonthLimit()
  }
  // 目前只检查锁单所以只限定一个条件
  const success = orderLockCheckRes.data?.data?.total === 0
  return [success, orderCheck]
}
