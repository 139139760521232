import React, { FC, useState } from 'react'
import { InputItem } from 'antd-mobile'
import { useLocalStore, observer } from 'mobx-react-lite'
import { useRestaurantSearch } from '../../api/EatinRestaurant'

const DemoRestaurantList: FC = () => {
  const searchParams = useLocalStore<Definitions.Europe.搜索商户参数>(() => ({
    needUnsigned: true,
    radius: '10000',
    p: 0,
    sort: '4',
    localLat: '31.18544',
    localLng: '121.3872',
    city: '上海',
    type: '1',
    lat: '31.185442',
    lng: '121.387197'
  }))

  const [{ data }, research] = useRestaurantSearch({
    useCache: true,
    data: searchParams
  })

  return (
    <div>
      <InputItem onChange={(city) => (searchParams.city = city)} />
      {data?.data?.list.map((item) => {
        return (
          <div key={item.rid}>
            <h1>{item.title}</h1>
          </div>
        )
      })}
      <button
        onClick={() => {
          searchParams.p! += 1
        }}
      >
        +
      </button>
    </div>
  )
}

export default observer(DemoRestaurantList)
