import React, { FC } from 'react'
import Text from '../../components/Text'
import { FormTextareaItem, FormRateItem } from '../../components/FormItems'
import { XCenterView } from '../../components/QCFlex'
const EvaluationCard: FC = () => {
  return (
    <>
      <XCenterView>
        <Text color="#999999FF" size={26}>
          请对本次服务进行评价
        </Text>
      </XCenterView>
      <FormRateItem label="餐厅环境" field="restaurantScore" />
      <FormRateItem label="菜品口味" field="dishScore" />
      <FormRateItem label="商宴通服务" field="sytServiceScore" />
      <FormTextareaItem
        placeholder="请输入本次服务的详细体验"
        field="replyContent"
      />
    </>
  )
}

export default EvaluationCard
