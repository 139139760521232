import { AxiosRequestConfig } from 'axios'
import makeUseApi from '../utils/makeUseApi'

export const GetCheckoutCounterUrl: AxiosRequestConfig = {
  url: '/hades/gat/checkoutCounterUrl',
  method: 'POST'
}

export const useGetCheckoutCounterUrl = makeUseApi<
  Definitions.Hades.CheckoutCounterUrlRequest,
  Definitions.Hades.ResponseOfstring
>(GetCheckoutCounterUrl)

export const PaymentSearch: AxiosRequestConfig = {
  url: '/hades/payment/api/search',
  method: 'GET'
}

export const usePaymentSearch = makeUseApi<
  { paymentId?: string },
  Definitions.Hades.SearchPaymentRecordResponse
>(PaymentSearch)

export const SetPaymentPending: AxiosRequestConfig = {
  url: '/aimee/Qcpay/pending',
  method: 'POST'
}

export const useSetPaymentPending = makeUseApi<
  Definitions.Aimee.PayRequest,
  Definitions.Aimee.RestResponseOfboolean
>(SetPaymentPending)
