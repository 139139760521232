import { AxiosPromise, AxiosRequestConfig } from 'axios'
import useAxios, { Options, RefetchOptions, ResponseValues } from 'axios-hooks'
import { useEffect, useState } from 'react'
import { useGlobalStores } from '../hooks/store'

export type ApiOptions<T> = Options & {
  data?: T
  params?: T
  noAuth?: boolean
}

const makeUseApi = <T = Record<string, unknown>, V = Record<string, unknown>>(
  requestConfig: AxiosRequestConfig
) => {
  return (
    options?: ApiOptions<T>
  ): [
    ResponseValues<MaybeNull<Partial<V>>>,
    (
      config?: Omit<AxiosRequestConfig, 'data' | 'params'> & {
        data?: T
        params?: T
      },
      options?: RefetchOptions
    ) => AxiosPromise<MaybeNull<Partial<V>>>
  ] => {
    const [resData, updateResData] = useState<MaybeNull<Partial<V>>>(null)
    const { userStore } = useGlobalStores()
    const { data, params, ...others } = options || {}
    const [apiResult, exec] = useAxios<MaybeNull<Partial<V>>>(
      {
        ...requestConfig,
        ...{
          data,
          params,
          headers: {
            ...requestConfig.headers,
            ...(!options?.noAuth && { token: userStore.token })
          }
        }
      },
      others
    )
    useEffect(() => {
      if (apiResult.loading) {
        updateResData(null)
      } else {
        updateResData(apiResult.data)
      }
    }, [apiResult.loading, apiResult.data])

    return [{ ...apiResult, data: resData }, exec]
  }
}

export default makeUseApi
