import dayjs from 'dayjs'
import { IFormValues, IGoodsMenuItem } from './interface'
interface FormatSubmitDataProps {
  formData: IFormValues
  goodList: IGoodsMenuItem[]
  city: string
  rid: string
}
export const formatSubmitData = ({
  formData,
  goodList,
  city,
  rid
}: FormatSubmitDataProps): Definitions.Aimee.WaimaiOrderFromApp => {
  const pureGoodList = goodList
    ?.map((item) => {
      // 过滤掉餐盒费、打包费等
      // menu_id为空的就是餐盒费、打包费
      // 预留if-else 万一提交的时候需要单独的餐盒费、打包费字段
      if (item.menu_id) {
        return item
      }
    })
    .filter((item) => !!item)
  return {
    date: dayjs(formData.time).format('YYYY-MM-DD'),
    menuList: pureGoodList ? JSON.stringify(pureGoodList) : '',
    address: formData.address?.address,
    deliveryTime: '' as any,
    lng: formData.address?.landmark?.location?.lng || '',
    city,
    costCenterId: formData.costCenterId,
    approvalCode: formData.approvalCode,
    consumptionType: formData.consumptionType,
    linkmanName: formData.linkmanName,
    rid,
    message: formData.message,
    peopleNum: formData.peopleNum?.toString() || '',
    taxIdentificationNumber: formData.tax?.taxIdentificationNumber || '-',
    addressId: formData.address?.address_id?.toString() || '',
    isDelivery: '0',
    deliveryFee: '' as any,
    confirmOrder: '0',
    time: dayjs(formData.time).format('HH:mm'),
    linkmanPhone: formData.linkmanPhone,
    invoiceTitle: formData.tax?.invoiceTitle || '',
    buyReason: formData.buyReason,
    lat: formData.address?.landmark?.location?.lat || ''
  }
}
export const validate = () => {}
export const formatDeliveryDates = (
  deliveryDates: Definitions.Aimee.DateModel[]
) => {}
