// 用户
import Login from '../../views/Login'
import Register from '../../views/Register'
import ResetPassword from '../../views/ResetPassword'
import CompleteUserInfo from '../../views/CompleteUserInfo'
import Home from '../../views/Home'
import ConsumStatistics from '../../views/ConsumStatistics'
import EnterpriseInvoiceInfo from '../../views/EnterpriseInvoiceInfo'
import ShippingAddressList from '../../views/ShippingAddressList'
import ShippingAddressEdit from '../../views/ShippingAddressEdit'
import Feedback from '../../views/Feedback'
import CityLocation from '../../views/CityLocation'
import RegisterSuccess from '../../views/RegisterSuccess'
import UserManual from '../../views/UserManual'
import BindEntyCode from '../../views/BindEntyCode'
import CheckAuthor from '../../views/checkAuthor'
const Routers = [
  {
    name: 'Home',
    path: '/',
    component: Home,
    cached: true,
    extraProps: {
      exact: true
    }
  },
  {
    name: 'Login',
    path: '/login',
    component: Login,
    public: true
  },
  {
    name: 'Register',
    path: '/register',
    component: Register,
    public: true
  },
  {
    name: 'RegisterSuccess',
    path: '/registerSuccess',
    component: RegisterSuccess
  },
  {
    name: 'ResetPassword',
    path: '/resetPassword',
    component: ResetPassword,
    public: true
  },
  {
    name: 'CompleteUserInfo',
    path: '/completeUserInfo',
    component: CompleteUserInfo
  },
  {
    name: 'ConsumStatistics',
    path: '/consumStatistics',
    component: ConsumStatistics
  },
  {
    name: 'EnterpriseInvoiceInfo',
    path: '/enterpriseInvoiceInfo',
    component: EnterpriseInvoiceInfo
  },
  {
    name: 'ShippingAddressList',
    path: '/shippingAddressList',
    component: ShippingAddressList
  },
  {
    name: 'ShippingAddressEdit',
    path: '/shippingAddressEdit',
    component: ShippingAddressEdit
  },
  {
    name: 'Feedback',
    path: '/feedback',
    component: Feedback
  },
  {
    name: 'CityLocation',
    path: '/cityLocation',
    component: CityLocation
  },
  {
    name: 'UserManual',
    path: '/userManual',
    component: UserManual
  },
  {
    name: 'BindEntyCode',
    path: '/BindEntyCode',
    component: BindEntyCode
  },
  {
    name: 'CheckAuthor',
    path: '/CheckAuthor',
    component: CheckAuthor
    
  }
]
export default Routers
