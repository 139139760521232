import React, { FC } from 'react'
import { ItemCell } from '../../components/Cell'
import { ColumnView } from '../../components/QCFlex'
import { ImagePicker } from '../../components/FormItems/ImagePicker'
import Text from '../../components/Text'
import { useHistory } from 'react-router-dom'

const ReviewTextMap: { [key: string]: string } = {
  CONFIRMED: '审核通过',
  UNCONFIRMED: '审核中',
  NOT_SUBMITTED: '待上传',
  REFUSED: '审核拒绝'
}
const InvoiceCard: FC<QCOrderInfo> = ({
  order_state,
  receipt_images,
  receipt_consistency,
  invoice_consistency,
  thumb_postfix,
  ticket_info_confirmed,
  invoice_images,
  res_invoice_title,
  res_tax_identification_number,
  order_id,
  store_name
}) => {
  const router = useHistory()
  // 已完成（支付成功）才有发票

  // 上传发票小票
  // base {TICKET_CHECK_STATUS} ../../constants/other
  // "ticket_info_confirmed": "CONSISTENCY" ，CONSISTENCY("已提交，一致") 无上传票据、无编辑按钮
  // "ticket_info_confirmed": "UNCONFIRMED", UNCONFIRMED("已提交，未确认") 无上传票据、无编辑按钮
  // "ticket_info_confirmed": "REFUSED", REFUSED("已提交，被拒绝") 展示审核拒绝状态，无上传票据，有编辑按钮
  // "ticket_info_confirmed": "CONFIRMED"，CONFIRMED("已提交，已确认")无上传票据、无编辑按钮
  // "ticket_info_confirmed": "NOT_SUBMITTED"，NOT_SUBMITTED("未提交") 有上传票据按钮，无编辑按钮
  const showInvoiceCard =
    [5].includes(order_state) && !['NONE'].includes(ticket_info_confirmed)

  // 编辑按钮展示
  const showEditBtn = ['REFUSED'].includes(ticket_info_confirmed)
  const showStatusTag = [
    'REFUSED',
    'CONFIRMED',
    'UNCONFIRMED',
    'NOT_SUBMITTED'
  ].includes(ticket_info_confirmed)

  // 整个缩略图
  const invoiceImages = invoice_images?.map(
    (url: string) => `${url}${thumb_postfix}`
  )
  const receiptImages = receipt_images?.map(
    (url: string) => `${url}${thumb_postfix}`
  )
  const goEdit = () => {
    router.push('/uploadTicket', {
      detail: {
        receipt_images,
        receipt_consistency,
        invoice_consistency,
        thumb_postfix,
        invoice_images,
        res_invoice_title,
        store_name,
        res_tax_identification_number,
        order_id
      },
      orderType: 'takeaway'
    })
  }
  return showInvoiceCard ? (
    <ColumnView className="qc-section-card">
      <ItemCell
        label={
          <>
            <Text color="#999999FF" size={34} margin={[0, 10, 0, 0]}>
              票据信息
            </Text>
            {showStatusTag && (
              <Text color="#FF9847FF" size={28}>
                {ReviewTextMap[ticket_info_confirmed]}
              </Text>
            )}
          </>
        }
        value={
          showEditBtn && (
            <Text onClick={goEdit} size={28}>
              编辑
            </Text>
          )
        }
        arrow={false}
      />
      <ImagePicker label="发票" value={invoiceImages} disabled />
      <ImagePicker label="小票/水单" value={receiptImages} disabled />
    </ColumnView>
  ) : (
    <></>
  )
}

export default InvoiceCard
