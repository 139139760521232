import React, { FC } from 'react'
import Picker, { PickerProps } from '../Picker'
import { useQueryClientOpenArea } from '../../api/City'
import { useFormattedProps } from '../../hooks/pickerHelper'

const CitySelector: FC<Partial<PickerProps>> = ({
  value,
  onChange,
  ...props
}) => {
  const [{ data, loading }] = useQueryClientOpenArea({
    data: {}
  })
  const formattedProps = useFormattedProps({
    value,
    onChange
  })
  return (
    <Picker
      {...props}
      value={formattedProps.value}
      onPickerChange={console.log}
      onChange={formattedProps.onChange}
      cols={1}
      label="城市"
      data={
        data?.data?.clientRegions?.map((region) => ({
          label: region.regionName!,
          value: region.regionName!
        })) || []
      }
      extra="请选择您所在的城市"
    ></Picker>
  )
}

export default CitySelector
