import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type BaseResponse = {
  __typename?: 'BaseResponse';
  errorCode?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type BaseOptions = {
  __typename?: 'BaseOptions';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type PagerResult = {
  __typename?: 'PagerResult';
  currentPage: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
  hasMore: Scalars['Boolean'];
};

export type BaseResult = {
  __typename?: 'BaseResult';
  success?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type HospitalInfo = {
  __typename?: 'HospitalInfo';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  lng?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
};

export type ContentActionBaseInfo = {
  __typename?: 'ContentActionBaseInfo';
  id: Scalars['String'];
  contentId: Scalars['String'];
  doctor?: Maybe<AccountInfo>;
  actionTime: Scalars['String'];
  doctorAccountId: Scalars['String'];
  salesAccountId: Scalars['String'];
  companyId: Scalars['String'];
  type: ContentActionType;
  value?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  gmtCreated: Scalars['String'];
  gmtModified: Scalars['String'];
};

export enum ContentActionType {
  Share = 'SHARE',
  Read = 'READ',
  Reshare = 'RESHARE',
  ReadTime = 'READ_TIME',
  Up = 'UP',
  Comment = 'COMMENT'
}

export type ContentActionStatInfo = {
  __typename?: 'ContentActionStatInfo';
  type: ContentActionType;
  value?: Maybe<Scalars['Int']>;
  period?: Maybe<StatPeriod>;
  quota: Scalars['Float'];
};

export enum StatPeriod {
  All = 'ALL',
  Day = 'DAY',
  CurrentWeek = 'CURRENT_WEEK',
  CurrentMonth = 'CURRENT_MONTH',
  RecentWeek = 'RECENT_WEEK',
  RecentMonth = 'RECENT_MONTH'
}

export type ContentInfo = {
  __typename?: 'ContentInfo';
  id?: Maybe<Scalars['String']>;
  type: ContentType;
  title: Scalars['String'];
  quizId: Scalars['String'];
  creatorAccountId: Scalars['String'];
  releaseTime: Scalars['String'];
  gmtCreated: Scalars['String'];
  gmtModified: Scalars['String'];
  companyId?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  contentActions?: Maybe<Scalars['JSONObject']>;
  contentStats?: Maybe<Array<ContentActionStatInfo>>;
  hasLike: Scalars['Boolean'];
  comments: Array<ContentActionBaseInfo>;
};


export type ContentInfoHasLikeArgs = {
  doctorId?: Maybe<Scalars['String']>;
  openId?: Maybe<Scalars['String']>;
};

export enum ContentType {
  Article = 'ARTICLE',
  Video = 'VIDEO'
}


export type ContentListQueryResult = {
  __typename?: 'ContentListQueryResult';
  items: Array<ContentInfo>;
  pager: PagerResult;
};

export type ContentActionInfo = {
  __typename?: 'ContentActionInfo';
  id: Scalars['String'];
  contentId: Scalars['String'];
  doctor?: Maybe<AccountInfo>;
  actionTime: Scalars['String'];
  doctorAccountId: Scalars['String'];
  salesAccountId: Scalars['String'];
  companyId: Scalars['String'];
  type: ContentActionType;
  value?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  gmtCreated: Scalars['String'];
  gmtModified: Scalars['String'];
  content?: Maybe<ContentInfo>;
  hasDoctorRead: Scalars['Boolean'];
};

export type SalesPerformance = {
  __typename?: 'SalesPerformance';
  share: ContentActionStatInfo;
  unshare: ContentActionStatInfo;
  up: ContentActionStatInfo;
  read: ContentActionStatInfo;
};

export type SalesPerformanceData = {
  __typename?: 'SalesPerformanceData';
  period: StatPeriod;
  data: Scalars['JSONObject'];
};

export type AccountCertInfo = {
  __typename?: 'AccountCertInfo';
  accountCertId?: Maybe<Scalars['String']>;
  type?: Maybe<AccountCertType>;
  certNo?: Maybe<Scalars['String']>;
  rawCertNo?: Maybe<Scalars['String']>;
  certImages?: Maybe<Array<Scalars['String']>>;
  custom1?: Maybe<Scalars['String']>;
  cnFirstPinyin?: Maybe<Scalars['String']>;
  cnLastPinyin?: Maybe<Scalars['String']>;
  enFirstName?: Maybe<Scalars['String']>;
  enLastName?: Maybe<Scalars['String']>;
  enMiddleName?: Maybe<Scalars['String']>;
  cnName?: Maybe<Scalars['String']>;
  qkkBankName?: Maybe<Scalars['String']>;
  ssqBankName?: Maybe<Scalars['String']>;
};

export enum AccountCertType {
  Idcard = 'IDCARD',
  Passport = 'PASSPORT',
  TwowayPermit = 'TWOWAY_PERMIT',
  Mtp = 'MTP',
  TaiwanPass = 'TAIWAN_PASS',
  QualificationCert = 'QUALIFICATION_CERT',
  StaffCert = 'STAFF_CERT',
  Face = 'FACE',
  Other = 'OTHER',
  Bankcard = 'BANKCARD'
}

export type AccountGroupInfo = {
  __typename?: 'AccountGroupInfo';
  groupId: Scalars['String'];
  name: Scalars['String'];
  pid?: Maybe<Scalars['String']>;
  parent?: Maybe<AccountGroupInfo>;
  children?: Maybe<Array<AccountGroupInfo>>;
  custom4?: Maybe<Scalars['String']>;
};

export type DepartmentInfo = {
  __typename?: 'DepartmentInfo';
  departmentId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  status?: Maybe<GroupStatus>;
};

/** 组织状态 */
export enum GroupStatus {
  GroupActivated = 'GROUP_ACTIVATED',
  GroupDisabled = 'GROUP_DISABLED'
}

export type CompanyInfo = {
  __typename?: 'CompanyInfo';
  companyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  cnName?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  group: AccountGroupInfo;
};


export type CompanyInfoGroupArgs = {
  type: Scalars['String'];
};

export type AccountBaseInfo = {
  __typename?: 'AccountBaseInfo';
  accountId?: Maybe<Scalars['String']>;
  cnName?: Maybe<Scalars['String']>;
  cnFirstName?: Maybe<Scalars['String']>;
  cnLastName?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['String']>;
  status?: Maybe<AccountStatus>;
  gender?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  companyMobile?: Maybe<Scalars['String']>;
  companyEmail?: Maybe<Scalars['String']>;
  companyPhone?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['String']>;
  reportAccountId?: Maybe<Scalars['String']>;
  accountCerts?: Maybe<Array<AccountCertInfo>>;
  ouId?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  defaultChannel: Scalars['String'];
  custom1?: Maybe<Scalars['String']>;
  custom2?: Maybe<Scalars['String']>;
  custom4?: Maybe<Scalars['String']>;
};

/** 员工状态 */
export enum AccountStatus {
  AccountActivated = 'ACCOUNT_ACTIVATED',
  AccountDisabled = 'ACCOUNT_DISABLED',
  AccountInactive = 'ACCOUNT_INACTIVE',
  AccountReviewPending = 'ACCOUNT_REVIEW_PENDING',
  AccountReviewRefused = 'ACCOUNT_REVIEW_REFUSED'
}

export type AccountInfo = {
  __typename?: 'AccountInfo';
  accountId?: Maybe<Scalars['String']>;
  cnName?: Maybe<Scalars['String']>;
  cnFirstName?: Maybe<Scalars['String']>;
  cnLastName?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['String']>;
  status?: Maybe<AccountStatus>;
  gender?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  companyMobile?: Maybe<Scalars['String']>;
  companyEmail?: Maybe<Scalars['String']>;
  companyPhone?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['String']>;
  reportAccountId?: Maybe<Scalars['String']>;
  accountCerts?: Maybe<Array<AccountCertInfo>>;
  ouId?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  defaultChannel: Scalars['String'];
  custom1?: Maybe<Scalars['String']>;
  custom2?: Maybe<Scalars['String']>;
  custom4?: Maybe<Scalars['String']>;
  company?: Maybe<CompanyInfo>;
  reportAccount?: Maybe<AccountInfo>;
  department?: Maybe<DepartmentInfo>;
  group?: Maybe<AccountGroupInfo>;
  hospital?: Maybe<HospitalInfo>;
  authenticated: Scalars['Boolean'];
  contentActionStats: Array<ContentActionStatInfo>;
  roles: Array<RoleInfo>;
  bankInfo?: Maybe<AccountCertInfo>;
  idCardInfo?: Maybe<AccountCertInfo>;
  appMenus?: Maybe<Array<AppMenu>>;
  roleAuthorities?: Maybe<Array<RoleAuthority>>;
  /** 作为参会人信息 */
  infoAsMeetingAttender?: Maybe<StandardMeetingBaseAttender>;
  /** 是否完善参会人信息 */
  hasCompeleteHRImgDoctorCert?: Maybe<Scalars['Boolean']>;
  /** 是否已注册商税宝 */
  shangshuibaoRegistered?: Maybe<Scalars['Boolean']>;
  /** 是否已注册财税云 */
  caishuiyunRegistered?: Maybe<Scalars['Boolean']>;
  defaultChannelOnPreview: Scalars['String'];
  qkkUser?: Maybe<Scalars['JSONObject']>;
  ou?: Maybe<CompanyInfo>;
  userCertificationImages?: Maybe<AccountCertInfo>;
  hrProduction?: Maybe<Scalars['String']>;
  qkkStudioOpenStatus?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  ptxOpenStatus?: Maybe<Scalars['String']>;
  ptxOpenStatusOriginal?: Maybe<Scalars['Int']>;
  ownedProductions: Array<ProjectProduction>;
  budgetInfo?: Maybe<BudgetUnit>;
  isJunziqianSigned?: Maybe<Scalars['Boolean']>;
};


export type AccountInfoCnNameArgs = {
  encrypted?: Maybe<Scalars['Boolean']>;
};


export type AccountInfoCompanyMobileArgs = {
  encrypted?: Maybe<Scalars['Boolean']>;
};


export type AccountInfoGroupArgs = {
  groupType: Scalars['String'];
};


export type AccountInfoContentActionStatsArgs = {
  contentId?: Maybe<Scalars['String']>;
};


export type AccountInfoAppMenusArgs = {
  appCode?: Maybe<Scalars['String']>;
};


export type AccountInfoRoleAuthoritiesArgs = {
  appCode?: Maybe<Scalars['String']>;
};


export type AccountInfoInfoAsMeetingAttenderArgs = {
  meetingId: Scalars['String'];
};


export type AccountInfoHasCompeleteHrImgDoctorCertArgs = {
  channel?: Maybe<Scalars['String']>;
  meetingId?: Maybe<Scalars['String']>;
};


export type AccountInfoIsJunziqianSignedArgs = {
  channel?: Maybe<MeetingSignChannel>;
};

export enum MeetingSignChannel {
  Ptx = 'PTX',
  Fg = 'FG',
  Hnjc = 'HNJC',
  Axb = 'AXB'
}

export type TargetRegionNodeBaseInfo = {
  __typename?: 'TargetRegionNodeBaseInfo';
  groupId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type TargetRegionNodeInfo = {
  __typename?: 'TargetRegionNodeInfo';
  groupId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  children: Array<TargetRegionNodeInfo>;
};

export type MemberPaginatedResponse = {
  __typename?: 'MemberPaginatedResponse';
  items: Array<AccountInfo>;
  pager: PagerResult;
};

export type GroupPaginationRes = {
  __typename?: 'GroupPaginationRes';
  items: Array<AccountGroupInfo>;
  pager: PagerResult;
};

export type HospitalPaginationRes = {
  __typename?: 'HospitalPaginationRes';
  items: Array<HospitalInfo>;
  pager: PagerResult;
};

export type IesLocation = {
  __typename?: 'IESLocation';
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
};

export type RestaurantInfo = {
  __typename?: 'RestaurantInfo';
  indexId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  restaurantId: Scalars['String'];
  companys: Array<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  restaurantName: Scalars['String'];
  district?: Maybe<Scalars['String']>;
  areaName?: Maybe<Scalars['String']>;
  areaCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cityName?: Maybe<Scalars['String']>;
  cityCode?: Maybe<Scalars['String']>;
  businessAreaName?: Maybe<Scalars['String']>;
  businessAreaCode?: Maybe<Scalars['String']>;
  cityNameTag?: Maybe<Scalars['String']>;
  areaNameTag?: Maybe<Scalars['String']>;
  businessAreaNameTag?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  location?: Maybe<IesLocation>;
  cookStyle?: Maybe<Array<Scalars['String']>>;
  category?: Maybe<Array<Scalars['String']>>;
  cookStyleTag?: Maybe<Array<Scalars['String']>>;
  avg?: Maybe<Scalars['Float']>;
  consume?: Maybe<Scalars['Float']>;
  indoor?: Maybe<Array<Scalars['String']>>;
  indoorUrls?: Maybe<Array<Scalars['String']>>;
  outdoor?: Maybe<Array<Scalars['String']>>;
  outdoorUrl?: Maybe<Array<Scalars['String']>>;
  createTime?: Maybe<Scalars['Timestamp']>;
  needAdvance?: Maybe<Scalars['Boolean']>;
  advanceHour?: Maybe<Scalars['Float']>;
  hitNum?: Maybe<Scalars['Float']>;
  onLineTime: Scalars['Timestamp'];
  settlementType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Float']>;
  gaodeId?: Maybe<Scalars['String']>;
  txType?: Maybe<Scalars['String']>;
  telPhone?: Maybe<Scalars['String']>;
  supportWaimai: Scalars['Boolean'];
  supportYuding: Scalars['Boolean'];
  shippingDis?: Maybe<Scalars['Float']>;
  advanceTime?: Maybe<Scalars['Float']>;
  deliveryDesc?: Maybe<Scalars['String']>;
  shippingFeeMin?: Maybe<Scalars['Float']>;
  cancelRule?: Maybe<Scalars['String']>;
  maxItems?: Maybe<Scalars['Float']>;
  maxType?: Maybe<Scalars['String']>;
  openingHours?: Maybe<Scalars['String']>;
  introduce?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
  frozenAmount?: Maybe<Scalars['Float']>;
  availableAmount?: Maybe<Scalars['Float']>;
  companyName?: Maybe<Scalars['String']>;
  balanceAcountId?: Maybe<Scalars['String']>;
  businessId?: Maybe<Scalars['String']>;
  clientBlacklists: Array<Scalars['String']>;
  onlineStatus?: Maybe<Scalars['String']>;
  settlementOwner?: Maybe<Scalars['String']>;
  rebateId?: Maybe<Scalars['String']>;
  rebateType?: Maybe<Scalars['String']>;
  rebateRule?: Maybe<Scalars['String']>;
  rebateSettlementType?: Maybe<Scalars['String']>;
  effectiveTime: Scalars['Timestamp'];
  rebateStatus?: Maybe<Scalars['String']>;
  rebatePercent?: Maybe<Scalars['Float']>;
  rebateBizType?: Maybe<Scalars['String']>;
  companyNameTag?: Maybe<Scalars['String']>;
  einvoice?: Maybe<Scalars['Boolean']>;
  shippingFee?: Maybe<Scalars['Float']>;
  packingBoxFee?: Maybe<Scalars['Float']>;
  polygon?: Maybe<Scalars['String']>;
  isPolygon?: Maybe<Scalars['Float']>;
  haveBox?: Maybe<Scalars['Boolean']>;
  boxNum?: Maybe<Scalars['Float']>;
  boxMinConsume?: Maybe<Scalars['String']>;
  boxMinimum?: Maybe<Scalars['String']>;
};


export type RestaurantSearchResult = {
  __typename?: 'RestaurantSearchResult';
  items: Array<RestaurantInfo>;
  pager: PagerResult;
};

export type LocationInfo = {
  __typename?: 'LocationInfo';
  lat?: Maybe<Scalars['String']>;
  lng?: Maybe<Scalars['String']>;
  landMark?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};

export type CarOrderScheduleInfo = {
  __typename?: 'CarOrderScheduleInfo';
  scheduleId?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  origin?: Maybe<LocationInfo>;
  departureTime?: Maybe<Scalars['String']>;
  destination?: Maybe<LocationInfo>;
  contactPeople?: Maybe<Scalars['String']>;
  contactPeoplePhone?: Maybe<Scalars['String']>;
  predictCost?: Maybe<Scalars['Float']>;
  passenger?: Maybe<Scalars['String']>;
  passengerPhone?: Maybe<Scalars['String']>;
  model?: Maybe<BaeyCarModel>;
  peopleNum?: Maybe<Scalars['Int']>;
  totalCost?: Maybe<Scalars['Float']>;
  liquidatedDamages?: Maybe<Scalars['Float']>;
  rentType?: Maybe<BaeyCarRentType>;
  flightDate?: Maybe<Scalars['String']>;
  flightNumber?: Maybe<Scalars['String']>;
  flightDepartDelayTime?: Maybe<Scalars['Timestamp']>;
};

export enum BaeyCarModel {
  Economic = 'economic',
  Comfortable = 'comfortable',
  Business = 'business',
  Luxurious = 'luxurious',
  Minibus = 'minibus',
  Midibus = 'midibus',
  Bigbus = 'bigbus'
}

export enum BaeyCarRentType {
  Btbc = 'BTBC',
  Qtbc = 'QTBC',
  Jjz = 'JJZ',
  Sjz = 'SJZ',
  Snjs = 'SNJS',
  Ksjs = 'KSJS'
}

export type CarOrderInfo = {
  __typename?: 'CarOrderInfo';
  orderId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  serviceType?: Maybe<Scalars['String']>;
  rentType?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  predictCost?: Maybe<Scalars['Float']>;
  actualCost?: Maybe<Scalars['Float']>;
  peopleNum?: Maybe<Scalars['Float']>;
  departureTime?: Maybe<Scalars['Timestamp']>;
  destination?: Maybe<LocationInfo>;
  origin?: Maybe<LocationInfo>;
  status?: Maybe<Scalars['String']>;
  schedule?: Maybe<Array<CarOrderScheduleInfo>>;
};

export type CarServiceInfo = {
  __typename?: 'CarServiceInfo';
  id: Scalars['String'];
  serviceType?: Maybe<Scalars['String']>;
  servicePrice?: Maybe<Scalars['String']>;
  serviceDesc: Scalars['String'];
  timeOutPrice?: Maybe<Scalars['String']>;
  timeOutDesc?: Maybe<Scalars['String']>;
  overDistancePrice?: Maybe<Scalars['String']>;
  overDistanceDesc?: Maybe<Scalars['String']>;
};

export type CarInfo = {
  __typename?: 'CarInfo';
  carType?: Maybe<Scalars['String']>;
  carTypeDesc?: Maybe<Scalars['String']>;
  carService?: Maybe<Array<CarServiceInfo>>;
};

export type CarOfferPrice = {
  __typename?: 'CarOfferPrice';
  id?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  carType?: Maybe<Scalars['String']>;
  serviceType?: Maybe<Scalars['String']>;
};

export type PaginatedCarOrderResponse = {
  __typename?: 'PaginatedCarOrderResponse';
  items: Array<CarOrderInfo>;
  pager: PagerResult;
};

export type MeetingUplatformHotelTempDemo = {
  __typename?: 'MeetingUplatformHotelTempDemo';
  id: Scalars['String'];
  name: Scalars['String'];
  gmtCreated?: Maybe<Scalars['Timestamp']>;
  gmtModified?: Maybe<Scalars['Timestamp']>;
  bizExt?: Maybe<Scalars['String']>;
};

export type MeetingUplatformOrder = {
  __typename?: 'MeetingUplatformOrder';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  meetingId?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['JSONObject']>;
  num?: Maybe<Scalars['Float']>;
  day?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  budgetId?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['String']>;
  vendorType?: Maybe<VendorType>;
};

export enum VendorType {
  Restaurant = 'RESTAURANT',
  Hotel = 'HOTEL',
  Vehicle = 'VEHICLE'
}

export type BizStatusInfo = {
  __typename?: 'BizStatusInfo';
  text: Scalars['String'];
  color: Scalars['String'];
};

export type BizBtnInfo = {
  __typename?: 'BizBtnInfo';
  title: Scalars['String'];
  action: Scalars['String'];
  btnProps?: Maybe<Scalars['JSONObject']>;
};

export type LogData = {
  __typename?: 'LogData';
  logType?: Maybe<Scalars['String']>;
  assginer?: Maybe<Scalars['String']>;
  assginerInfo?: Maybe<AccountInfo>;
  reason?: Maybe<Scalars['String']>;
};

export type TaskInfo = {
  __typename?: 'TaskInfo';
  taskId?: Maybe<Scalars['String']>;
  approver?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  nodeSort?: Maybe<Scalars['Int']>;
  approverInfo?: Maybe<AccountInfo>;
  logDatas?: Maybe<Array<LogData>>;
};

export type ApproveInfo = {
  __typename?: 'ApproveInfo';
  instanceId?: Maybe<Scalars['String']>;
  processId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  tasks?: Maybe<Array<TaskInfo>>;
};

export type MeetingUplatformBudget = {
  __typename?: 'MeetingUplatformBudget';
  id: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  typeName?: Maybe<Scalars['String']>;
  meetingId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  attributes?: Maybe<Scalars['JSONObject']>;
  note?: Maybe<Scalars['String']>;
  gmtCreated?: Maybe<Scalars['Timestamp']>;
  gmtModified?: Maybe<Scalars['Timestamp']>;
  orders: Array<MeetingOrderInfo>;
};

export type MeetingUplatformQuotation = {
  __typename?: 'MeetingUplatformQuotation';
  id?: Maybe<Scalars['Int']>;
  orderId: Scalars['String'];
  num?: Maybe<Scalars['Float']>;
  day?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['JSONObject']>;
  vendorId?: Maybe<Scalars['String']>;
  vendorType?: Maybe<VendorType>;
  status?: Maybe<Scalars['String']>;
  gmtCreated?: Maybe<Scalars['Timestamp']>;
  gmtModified?: Maybe<Scalars['Timestamp']>;
};

export type MeetingUplatformQuotationHistory = {
  __typename?: 'MeetingUplatformQuotationHistory';
  id?: Maybe<Scalars['ID']>;
  originalId?: Maybe<Scalars['Int']>;
  orderId: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['String']>;
  vendorType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  gmtCreated?: Maybe<Scalars['Timestamp']>;
  gmtModified?: Maybe<Scalars['Timestamp']>;
};

export type MeetingQuotationInfo = {
  __typename?: 'MeetingQuotationInfo';
  id?: Maybe<Scalars['Int']>;
  orderId: Scalars['String'];
  num?: Maybe<Scalars['Float']>;
  day?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['JSONObject']>;
  vendorId?: Maybe<Scalars['String']>;
  vendorType?: Maybe<VendorType>;
  status?: Maybe<Scalars['String']>;
  gmtCreated?: Maybe<Scalars['Timestamp']>;
  gmtModified?: Maybe<Scalars['Timestamp']>;
  vendor?: Maybe<VendorUnion>;
  total?: Maybe<Scalars['Float']>;
};

export type VendorUnion = RestaurantInfo | MeetingUplatformHotelTempDemo | CarServiceInfo;

export type MeetingUplatformSettlement = {
  __typename?: 'MeetingUplatformSettlement';
  id?: Maybe<Scalars['Int']>;
  orderId: Scalars['String'];
  externalOrderId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  day?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  tickets?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['JSONObject']>;
  vendorId?: Maybe<Scalars['String']>;
  vendorType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  gmtCreated?: Maybe<Scalars['Timestamp']>;
  gmtModified?: Maybe<Scalars['Timestamp']>;
};

export type MeetingSettlementInfo = {
  __typename?: 'MeetingSettlementInfo';
  id?: Maybe<Scalars['Int']>;
  orderId: Scalars['String'];
  externalOrderId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  day?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  tickets?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['JSONObject']>;
  vendorId?: Maybe<Scalars['String']>;
  vendorType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  gmtCreated?: Maybe<Scalars['Timestamp']>;
  gmtModified?: Maybe<Scalars['Timestamp']>;
  vendor?: Maybe<VendorUnion>;
  total?: Maybe<Scalars['Float']>;
};

export type MeetingOrderInfo = {
  __typename?: 'MeetingOrderInfo';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  meetingId?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['JSONObject']>;
  num?: Maybe<Scalars['Float']>;
  day?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  budgetId?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['String']>;
  vendorType?: Maybe<VendorType>;
  typeName?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
  quotations: Array<MeetingQuotationInfo>;
  settlements: Array<MeetingSettlementInfo>;
  vendor?: Maybe<VendorUnion>;
  budget?: Maybe<MeetingUplatformBudget>;
  subType?: Maybe<Scalars['String']>;
  relatedBizOrder?: Maybe<Scalars['JSON']>;
  relatedBizOrders?: Maybe<Scalars['JSON']>;
};


export type MeetingOrderInfoQuotationsArgs = {
  type?: Maybe<QuotationQueryType>;
};

export enum QuotationQueryType {
  History = 'HISTORY',
  Current = 'CURRENT'
}


export type MeetingUplatformAttender = {
  __typename?: 'MeetingUplatformAttender';
  id: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  meetingId?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['JSONObject']>;
  accountInfo?: Maybe<AccountInfo>;
  gmtCreated?: Maybe<Scalars['Timestamp']>;
  gmtModified?: Maybe<Scalars['Timestamp']>;
};

export type MeetingUplatformSchedule = {
  __typename?: 'MeetingUplatformSchedule';
  id: Scalars['String'];
  meetingId?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['JSONObject']>;
  startTime: Scalars['Timestamp'];
  endTime: Scalars['Timestamp'];
  topic: Scalars['String'];
  place?: Maybe<Scalars['String']>;
  gmtCreated?: Maybe<Scalars['Timestamp']>;
  gmtModified?: Maybe<Scalars['Timestamp']>;
  livePhotos?: Maybe<Scalars['JSON']>;
};

export type CityInfo = {
  __typename?: 'CityInfo';
  id: Scalars['String'];
  name: Scalars['String'];
  nameEn: Scalars['String'];
  initialEn: Scalars['String'];
  pid: Scalars['String'];
  pCity?: Maybe<CityInfo>;
};

export type MeetingBudgetsEntry = {
  __typename?: 'MeetingBudgetsEntry';
  items: Array<MeetingUplatformBudget>;
  total: Scalars['Float'];
};

export type MeetingInfo = {
  __typename?: 'MeetingInfo';
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  owner?: Maybe<AccountInfo>;
  applierId?: Maybe<Scalars['String']>;
  applier?: Maybe<AccountInfo>;
  startTime?: Maybe<Scalars['Timestamp']>;
  endTime?: Maybe<Scalars['Timestamp']>;
  type?: Maybe<UpMeetingType>;
  bmsType?: Maybe<Scalars['String']>;
  status?: Maybe<UpMeetingStatus>;
  subStatus?: Maybe<Scalars['String']>;
  statusText?: Maybe<Scalars['String']>;
  /** @deprecated use new field bizStatus */
  approveStatus?: Maybe<BizStatusInfo>;
  bizStatus?: Maybe<BizStatusInfo>;
  approve?: Maybe<ApproveInfo>;
  department?: Maybe<Scalars['String']>;
  hotel?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['String']>;
  city?: Maybe<CityInfo>;
  cityName?: Maybe<Scalars['String']>;
  hospitals?: Maybe<Array<HospitalInfo>>;
  hospitalIds?: Maybe<Array<Scalars['String']>>;
  insideAttenderNum?: Maybe<Scalars['Int']>;
  outsideAttenderNum?: Maybe<Scalars['Int']>;
  peopleNum?: Maybe<Scalars['Int']>;
  period?: Maybe<UpMeetingPeriod>;
  budgets: MeetingBudgetsEntry;
  schedules: Array<MeetingUplatformSchedule>;
  attenders: Array<MeetingUplatformAttender>;
  hasSpeaker?: Maybe<Scalars['Boolean']>;
  /** @deprecated use new field actionBtns */
  approveBtns: Array<BizBtnInfo>;
  actionBtns: Array<BizBtnInfo>;
  hintMessage?: Maybe<Scalars['String']>;
  bmsPOBill?: Maybe<Scalars['String']>;
  bmsMarkedPaper?: Maybe<Scalars['String']>;
  bmsMeetingHotel?: Maybe<BmsMeetingHotel>;
  bmsMeetingAbnormalCount?: Maybe<Scalars['Int']>;
  approveReason?: Maybe<Scalars['String']>;
  carOrders?: Maybe<Scalars['JSON']>;
  bmsBizStatus?: Maybe<BizStatusInfo>;
};


export type MeetingInfoAttendersArgs = {
  type?: Maybe<AttenderType>;
};


export type MeetingInfoApproveBtnsArgs = {
  in?: Maybe<Scalars['String']>;
};


export type MeetingInfoActionBtnsArgs = {
  in?: Maybe<Scalars['String']>;
};


export type MeetingInfoCarOrdersArgs = {
  orderId?: Maybe<Scalars['String']>;
};

/** u平台会议类型 */
export enum UpMeetingType {
  UDepartment = 'U_DEPARTMENT',
  UHospital = 'U_HOSPITAL',
  UCity = 'U_CITY',
  UArea = 'U_AREA',
  UResearch = 'U_RESEARCH'
}

/** u平台会议状态 */
export enum UpMeetingStatus {
  UMeetingDraft = 'U_MEETING_DRAFT',
  UMeetingWithdraw = 'U_MEETING_WITHDRAW',
  UMeetingApproving = 'U_MEETING_APPROVING',
  UMeetingQuotation = 'U_MEETING_QUOTATION',
  UMeetingInProgress = 'U_MEETING_IN_PROGRESS',
  UMeetingInSettlement = 'U_MEETING_IN_SETTLEMENT',
  UMeetingFinished = 'U_MEETING_FINISHED',
  UMeetingForbidden = 'U_MEETING_FORBIDDEN',
  UMeetingCanceled = 'U_MEETING_CANCELED'
}

/** u平台会议时段 */
export enum UpMeetingPeriod {
  Morning = 'MORNING',
  Evening = 'EVENING',
  Beforenoon = 'BEFORENOON',
  Afternoon = 'AFTERNOON',
  Allday = 'ALLDAY'
}

/** 参会人员类型 */
export enum AttenderType {
  Inside = 'INSIDE',
  Outside = 'OUTSIDE'
}

export type MeetingSearchResult = {
  __typename?: 'MeetingSearchResult';
  items: Array<MeetingInfo>;
  pager: PagerResult;
};

export type UpMeetingStatusOption = {
  __typename?: 'UPMeetingStatusOption';
  value: UpMeetingStatus;
  label: Scalars['String'];
};

export type UpMeetingQuotationStatusOption = {
  __typename?: 'UPMeetingQuotationStatusOption';
  value: UpMeetingQuotationStatus;
  label: Scalars['String'];
};

/** u平台报价阶段状态 */
export enum UpMeetingQuotationStatus {
  UAwaitOrder = 'U_AWAIT_ORDER',
  UAwaitQuotation = 'U_AWAIT_QUOTATION',
  UAwaitConfirm = 'U_AWAIT_CONFIRM',
  UAwaitApproval = 'U_AWAIT_APPROVAL'
}

export type UpMeetingSignInInfo = {
  __typename?: 'UPMeetingSignInInfo';
  accountId: Scalars['String'];
  meetingId: Scalars['String'];
  address: Scalars['String'];
  time: Scalars['String'];
  userInfo?: Maybe<Scalars['JSONObject']>;
};

export type CarTravelCreat = {
  __typename?: 'CarTravelCreat';
  num: Scalars['String'];
  carType: Scalars['String'];
  type: Scalars['String'];
  unit: Scalars['String'];
  note: Scalars['String'];
};

export type BmsLoginResponse = {
  __typename?: 'BmsLoginResponse';
  token: Scalars['String'];
  account: AccountInfo;
};

export type BmsMeetingHotel = {
  __typename?: 'BmsMeetingHotel';
  name: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  position: Scalars['String'];
  amapId: Scalars['String'];
  city: Scalars['String'];
};

export type BmsMeetingStatusOption = {
  __typename?: 'BmsMeetingStatusOption';
  value: BmsMeetingStatus;
  label: Scalars['String'];
};

export enum BmsMeetingStatus {
  WaitTravelUpload = 'WAIT_TRAVEL_UPLOAD',
  WaitQuotation = 'WAIT_QUOTATION',
  WaitQuotationToConfirm = 'WAIT_QUOTATION_TO_CONFIRM',
  InProgress = 'IN_PROGRESS',
  WaitConfirm = 'WAIT_CONFIRM',
  Finished = 'FINISHED',
  Canceled = 'CANCELED'
}

export type BmsCarOrderDetail = {
  __typename?: 'BmsCarOrderDetail';
  order?: Maybe<Scalars['JSON']>;
  meeting?: Maybe<MeetingInfo>;
};

export type RoleInfo = {
  __typename?: 'RoleInfo';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  enable?: Maybe<Scalars['Boolean']>;
};

export type AppMenu = {
  __typename?: 'AppMenu';
  id?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  access?: Maybe<Scalars['String']>;
  orderNum?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  childs?: Maybe<Array<AppMenu>>;
};

export type RoleAuthority = {
  __typename?: 'RoleAuthority';
  appId?: Maybe<Scalars['String']>;
  appName?: Maybe<Scalars['String']>;
};

export type HrMeetingStatusOption = {
  __typename?: 'HRMeetingStatusOption';
  value: StandardMeetingServiceBizStatus;
  label: Scalars['String'];
};

export enum StandardMeetingServiceBizStatus {
  MeetingInProgress = 'MEETING_IN_PROGRESS',
  MeetingInApproving = 'MEETING_IN_APPROVING',
  MeetingRetreated = 'MEETING_RETREATED',
  MeetingSettlementConfirming = 'MEETING_SETTLEMENT_CONFIRMING',
  MeetingInSettlement = 'MEETING_IN_SETTLEMENT',
  MeetingFinished = 'MEETING_FINISHED',
  MeetingSettlementWithoutTax = 'MEETING_SETTLEMENT_WITHOUT_TAX',
  MeetingSettlementNotPass = 'MEETING_SETTLEMENT_NOT_PASS'
}

export type StandardMeetingBaseAttender = {
  __typename?: 'StandardMeetingBaseAttender';
  id?: Maybe<Scalars['String']>;
  meetingId?: Maybe<Scalars['String']>;
  attenderData?: Maybe<Scalars['JSON']>;
  attenderId?: Maybe<Scalars['String']>;
  attenderName?: Maybe<Scalars['String']>;
  attenderPhone?: Maybe<Scalars['String']>;
  attenderType?: Maybe<StandardMeetingTypeAttenderType>;
  /** 费用 */
  attenderFee?: Maybe<Scalars['String']>;
  /** 服务费用 */
  serviceFee?: Maybe<Scalars['String']>;
  /** 总费用 */
  totalFee?: Maybe<Scalars['String']>;
  attenderStatus?: Maybe<Scalars['String']>;
  billStatus?: Maybe<AttenderBillStatus>;
  billTime?: Maybe<Scalars['String']>;
  attenderInfo?: Maybe<AccountInfo>;
  /** origin签约渠道 */
  channel?: Maybe<Scalars['String']>;
  /** 签约渠道 */
  attenderSignChannel?: Maybe<Scalars['String']>;
  attenderSettlementTime?: Maybe<Scalars['Timestamp']>;
  meetingAttending?: Maybe<StandardMeetingInfo>;
  qkkStudioOpenStatus?: Maybe<Scalars['String']>;
  invitationButtonStatus: InvitationButtonStatus;
  ptxUserRegistered: Scalars['Boolean'];
  settlementRecord?: Maybe<Scalars['JSON']>;
};

export enum StandardMeetingTypeAttenderType {
  None = 'NONE',
  Creator = 'CREATOR',
  Speaker = 'SPEAKER',
  Attender = 'ATTENDER',
  Guest = 'GUEST',
  Subscriber = 'SUBSCRIBER'
}

export enum AttenderBillStatus {
  BillStatusNone = 'BILL_STATUS_NONE',
  BillStatusInvoiced = 'BILL_STATUS_INVOICED'
}

export enum InvitationButtonStatus {
  NeedPreregistered = 'NEED_PREREGISTERED',
  Preregistered = 'PREREGISTERED',
  CanInvited = 'CAN_INVITED'
}

export type HengruiMeetingSpeaker = {
  __typename?: 'HengruiMeetingSpeaker';
  name: Scalars['String'];
  accountId: Scalars['String'];
  mobile: Scalars['String'];
  topic?: Maybe<Scalars['String']>;
  fee: Scalars['String'];
  amount?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  materials?: Maybe<Array<Scalars['String']>>;
  data?: Maybe<Scalars['JSON']>;
};

export type StandardMeetingCost = {
  __typename?: 'StandardMeetingCost';
  amount: Scalars['String'];
  type: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};

export type HrCostCenter = {
  __typename?: 'HRCostCenter';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type InterventionProjectParticipantBase = {
  __typename?: 'InterventionProjectParticipantBase';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  attenderId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['String']>>;
  startTime?: Maybe<Scalars['Timestamp']>;
  endTime?: Maybe<Scalars['Timestamp']>;
  status?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  regionOwnerId?: Maybe<Scalars['String']>;
};

export type InterventionProjectParticipantInfo = {
  __typename?: 'InterventionProjectParticipantInfo';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  attenderId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['String']>>;
  startTime?: Maybe<Scalars['Timestamp']>;
  endTime?: Maybe<Scalars['Timestamp']>;
  status?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  regionOwnerId?: Maybe<Scalars['String']>;
  attender?: Maybe<AccountInfo>;
  regionOwner?: Maybe<AccountInfo>;
  reviews: Array<ProjectReviewInfo>;
  project?: Maybe<InterventionalProjectInfo>;
};

export type PaginatedInterventionalProjectParticipantsInfo = {
  __typename?: 'PaginatedInterventionalProjectParticipantsInfo';
  items: Array<InterventionProjectParticipantInfo>;
  pager: PagerResult;
};

export type InterventionalProjectInfoBase = {
  __typename?: 'InterventionalProjectInfoBase';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  productIds?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['Timestamp']>;
  endTime?: Maybe<Scalars['Timestamp']>;
  regionApplicationEnabled?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  /** 参与人数 */
  participantsAllowed?: Maybe<Scalars['Int']>;
  purpose?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Timestamp']>;
  fee?: Maybe<Scalars['JSONObject']>;
  totalFee?: Maybe<Scalars['String']>;
  isLiveSupport?: Maybe<Scalars['Int']>;
  meetingType?: Maybe<Scalars['Int']>;
  meetingData?: Maybe<Scalars['JSONObject']>;
};

export type InterventionalProjectInfo = {
  __typename?: 'InterventionalProjectInfo';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  productIds?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['Timestamp']>;
  endTime?: Maybe<Scalars['Timestamp']>;
  regionApplicationEnabled?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  /** 参与人数 */
  participantsAllowed?: Maybe<Scalars['Int']>;
  purpose?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Timestamp']>;
  fee?: Maybe<Scalars['JSONObject']>;
  totalFee?: Maybe<Scalars['String']>;
  isLiveSupport?: Maybe<Scalars['Int']>;
  meetingType?: Maybe<Scalars['Int']>;
  meetingData?: Maybe<Scalars['JSONObject']>;
  participants: Array<InterventionProjectParticipantInfo>;
  owner?: Maybe<AccountInfo>;
  schedulePics?: Maybe<Array<Scalars['String']>>;
  city?: Maybe<CityInfo>;
  statusText: Scalars['String'];
  isCurrentApprover: Scalars['Boolean'];
  products: Array<ProjectProduction>;
};

export type PaginatedInterventionalProjectInfo = {
  __typename?: 'PaginatedInterventionalProjectInfo';
  items: Array<InterventionalProjectInfo>;
  pager: PagerResult;
};

export type ProjectProduction = {
  __typename?: 'ProjectProduction';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  children?: Maybe<Array<ProjectProduction>>;
};

export type ProjectCategory = {
  __typename?: 'ProjectCategory';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  describe?: Maybe<Scalars['String']>;
};

export type ProjectReviewBase = {
  __typename?: 'ProjectReviewBase';
  id?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  nextPlan?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  conceptChange?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
  projectScheduleId: Scalars['String'];
};

export type ProjectReviewInfo = {
  __typename?: 'ProjectReviewInfo';
  id?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  nextPlan?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  conceptChange?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
  projectScheduleId: Scalars['String'];
  canAddReview: Array<ProjectReviewInfo>;
};

export type BudgetUnit = {
  __typename?: 'BudgetUnit';
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
  /** 预算金额 */
  monthBudget?: Maybe<Scalars['String']>;
  /** 预算金额 */
  budgetAmount?: Maybe<Scalars['String']>;
  /** 扣减金额 */
  deductionAmount?: Maybe<Scalars['String']>;
  /** 已使用金额 */
  usedAmount?: Maybe<Scalars['String']>;
  /** 结语金额 */
  balanceAmount?: Maybe<Scalars['String']>;
  /** 释放增加 */
  increaseAmount?: Maybe<Scalars['String']>;
};

export type HansohBudget = {
  __typename?: 'HansohBudget';
  accountId?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  business?: Maybe<Scalars['String']>;
  financeNo?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  budget?: Maybe<Array<BudgetUnit>>;
};

export type ProcessBudget = {
  __typename?: 'ProcessBudget';
  id: Scalars['String'];
  budgetNumber: Scalars['String'];
  budgetAmount: Scalars['String'];
  usedFee: Scalars['String'];
};

export type PaginatedHansohBudgets = {
  __typename?: 'PaginatedHansohBudgets';
  items: Array<HansohBudget>;
  pager: PagerResult;
};

export type PaginatedProcessBudget = {
  __typename?: 'PaginatedProcessBudget';
  items: Array<ProcessBudget>;
  pager: PagerResult;
};

export type UpProduct = {
  __typename?: 'UpProduct';
  id: Scalars['String'];
  companyId: Scalars['String'];
  productName?: Maybe<Scalars['String']>;
  isEnable?: Maybe<Scalars['Boolean']>;
  productCategory?: Maybe<Scalars['String']>;
  productCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
};

export type ArchiveTag = {
  __typename?: 'ArchiveTag';
  typeName?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type ArchiveInfo = {
  __typename?: 'ArchiveInfo';
  id?: Maybe<Scalars['String']>;
  archiveType?: Maybe<Scalars['String']>;
  creator?: Maybe<AccountInfo>;
  archiveUrl?: Maybe<Scalars['String']>;
  archiveTitle?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  describe?: Maybe<Scalars['String']>;
  createTimestamp?: Maybe<Scalars['Timestamp']>;
  isEnable?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<ArchiveTag>>;
  signedUrl?: Maybe<Scalars['String']>;
  archiveTags?: Maybe<Array<ArchiveTagInfo>>;
};


export type ArchiveInfoSignedUrlArgs = {
  expiredAfter?: Maybe<Scalars['Int']>;
};

export type PageQueryArchivesResult = {
  __typename?: 'PageQueryArchivesResult';
  items: Array<ArchiveInfo>;
  pager: PagerResult;
};

export type ArchiveTagInfo = {
  __typename?: 'ArchiveTagInfo';
  id?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
};

export type MeetingAttenderTask = {
  __typename?: 'MeetingAttenderTask';
  id: Scalars['String'];
  templateCode?: Maybe<Scalars['String']>;
  taskAttribute?: Maybe<Scalars['String']>;
  taskCategory?: Maybe<Scalars['String']>;
  taskName?: Maybe<Scalars['String']>;
  taskPrice?: Maybe<Scalars['String']>;
  objectId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  statusText?: Maybe<Scalars['String']>;
  isEnable?: Maybe<Scalars['Boolean']>;
  remark?: Maybe<Scalars['String']>;
  attenderId: Scalars['String'];
  accountId: Scalars['String'];
  accountInfo?: Maybe<AccountInfo>;
  meetingId: Scalars['String'];
  companyId: Scalars['String'];
  templateInfo?: Maybe<MeetingActivityTemplate>;
  documentInfo?: Maybe<DocumentInfo>;
  meetingInfo?: Maybe<StandardMeetingInfo>;
};

export type PaginatedAttenderTasks = {
  __typename?: 'PaginatedAttenderTasks';
  items: Array<MeetingAttenderTask>;
  pager: PagerResult;
};

export type DocumentArchiveCategory = {
  __typename?: 'DocumentArchiveCategory';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  describe?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  remark?: Maybe<Scalars['String']>;
};

export type DocumentArchiveProperty = {
  __typename?: 'DocumentArchiveProperty';
  id?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  remark?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};

export type Document = {
  __typename?: 'Document';
  id?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  owner?: Maybe<AccountInfo>;
  title?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  describe?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  createdTimestamp?: Maybe<Scalars['String']>;
  releaseTimestamp?: Maybe<Scalars['String']>;
  category?: Maybe<DocumentArchiveCategory>;
  language?: Maybe<Scalars['String']>;
  productCode?: Maybe<Scalars['String']>;
  productInfo?: Maybe<UpProduct>;
  company?: Maybe<CompanyInfo>;
  hasChoosenByAttender?: Maybe<Scalars['Boolean']>;
  documentReview?: Maybe<DocumentReviewInfo>;
};


export type DocumentAuthorArgs = {
  encrypted?: Maybe<Scalars['Boolean']>;
};


export type DocumentHasChoosenByAttenderArgs = {
  attenderId: Scalars['String'];
};

export type DocumentInfo = {
  __typename?: 'DocumentInfo';
  document?: Maybe<Document>;
  archive?: Maybe<Array<ArchiveInfo>>;
  properties?: Maybe<Array<DocumentArchiveProperty>>;
};

export type DocumentArchivePagerSearchResult = {
  __typename?: 'DocumentArchivePagerSearchResult';
  items: Array<DocumentInfo>;
  pager: PagerResult;
};

export type DocumentReviewInfo = {
  __typename?: 'DocumentReviewInfo';
  id?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  account?: Maybe<AccountInfo>;
  companyId?: Maybe<Scalars['String']>;
  createdTimestamp?: Maybe<Scalars['String']>;
  inviterId?: Maybe<Scalars['String']>;
  inviter?: Maybe<AccountInfo>;
  inviterName?: Maybe<Scalars['String']>;
  inviterRegionId?: Maybe<Scalars['String']>;
  documentId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['Int']>;
  stateText?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  rejectReason?: Maybe<Scalars['String']>;
  editable: Scalars['Boolean'];
  reviewed: Scalars['Boolean'];
  isOpen?: Maybe<Scalars['Boolean']>;
  documentInfo?: Maybe<DocumentInfo>;
};


export type DocumentReviewInfoInviterNameArgs = {
  encrypted?: Maybe<Scalars['Boolean']>;
};


export type DocumentReviewInfoNameArgs = {
  encrypted?: Maybe<Scalars['Boolean']>;
};


export type DocumentReviewInfoPhoneArgs = {
  encrypted?: Maybe<Scalars['Boolean']>;
};

export type DocumentReviewPagerSearchResult = {
  __typename?: 'DocumentReviewPagerSearchResult';
  items: Array<DocumentReviewInfo>;
  pager: PagerResult;
};

export type DocumentReviewContentInfo = {
  __typename?: 'DocumentReviewContentInfo';
  id?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  documentId?: Maybe<Scalars['String']>;
  documentReviewId?: Maybe<Scalars['String']>;
  createdTimestamp?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
};

export type ReviewContentTemplate = {
  __typename?: 'ReviewContentTemplate';
  key?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Int']>;
  placeholder?: Maybe<Scalars['String']>;
  min?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
};

export type MeetingCategoryProps = {
  __typename?: 'MeetingCategoryProps';
  code?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Int']>;
};

export type MeetingConfigBase = {
  __typename?: 'MeetingConfigBase';
  id: Scalars['String'];
  code: Scalars['String'];
  enable: Scalars['Boolean'];
  sort: Scalars['Int'];
  icon?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  desc?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  properties?: Maybe<Array<Scalars['JSON']>>;
};

export type MeetingCategory = {
  __typename?: 'MeetingCategory';
  id: Scalars['String'];
  enable: Scalars['Boolean'];
  config?: Maybe<MeetingConfigBase>;
  companyId: Scalars['String'];
  explain?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Int']>;
  properties?: Maybe<Array<Scalars['JSON']>>;
  activitiyTypes: Array<MeetingActivity>;
  children: Array<MeetingCategory>;
  myApplied: Scalars['Int'];
  mySettled: Scalars['Int'];
};

export type MeetingActivity = {
  __typename?: 'MeetingActivity';
  id: Scalars['String'];
  enable: Scalars['Boolean'];
  config?: Maybe<MeetingConfigBase>;
  companyId: Scalars['String'];
  explain?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Int']>;
  properties?: Maybe<Array<Scalars['JSON']>>;
  activitiyTypes: Array<MeetingActivity>;
  children: Array<MeetingCategory>;
  myApplied: Scalars['Int'];
  mySettled: Scalars['Int'];
  categoryCode?: Maybe<Scalars['String']>;
  processId?: Maybe<Scalars['String']>;
  templateCode?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  instanceMin?: Maybe<Scalars['Int']>;
  instanceMax?: Maybe<Scalars['Int']>;
  templateInfo?: Maybe<MeetingActivityTemplate>;
};

export type MeetingActivityTemplate = {
  __typename?: 'MeetingActivityTemplate';
  id?: Maybe<Scalars['String']>;
  companyId: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
  templateCode?: Maybe<Scalars['String']>;
  templateCategory?: Maybe<Scalars['String']>;
  documentCategory?: Maybe<Scalars['String']>;
  templateAttribute?: Maybe<Scalars['String']>;
  isEnable?: Maybe<Scalars['Boolean']>;
  instanceMax?: Maybe<Scalars['Int']>;
  instanceMin?: Maybe<Scalars['Int']>;
  documentCategoryInfo?: Maybe<DocumentArchiveCategory>;
};

export type ResourceInfo = {
  __typename?: 'ResourceInfo';
  resourceId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  type?: Maybe<ResourceType>;
};

export enum ResourceType {
  Ppt = 'PPT',
  Pdf = 'PDF',
  Video = 'VIDEO',
  Image = 'IMAGE'
}

export type StandardMeetingBudgetInfo = {
  __typename?: 'StandardMeetingBudgetInfo';
  LectureFee?: Maybe<Scalars['Float']>;
  otherFee?: Maybe<Scalars['Float']>;
};

export type ProductInfo = {
  __typename?: 'ProductInfo';
  id: Scalars['String'];
  name: Scalars['String'];
  isOtc?: Maybe<Scalars['Boolean']>;
};

export type WatchUrlInfo = {
  __typename?: 'WatchUrlInfo';
  pc?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
};

export type WebLiveToolInfo = {
  __typename?: 'WebLiveToolInfo';
  hostLoginAddr?: Maybe<Scalars['String']>;
  hostLoginToken?: Maybe<Scalars['String']>;
  guestLoginAddr?: Maybe<Scalars['String']>;
  guestLoginToken?: Maybe<Scalars['String']>;
};

export type StandardMeetingBaseData = {
  __typename?: 'StandardMeetingBaseData';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  applyTime?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  company?: Maybe<CompanyInfo>;
  ownerId?: Maybe<Scalars['String']>;
  owner?: Maybe<AccountInfo>;
  topic?: Maybe<Scalars['String']>;
  desc?: Maybe<Scalars['String']>;
  status?: Maybe<StandardMeetingStatus>;
  type?: Maybe<Scalars['String']>;
  peopleNum?: Maybe<Scalars['Int']>;
  channel?: Maybe<StandardMeetingChannel>;
  needBudget?: Maybe<Scalars['Boolean']>;
  /** 0: 默认，1：个人，2：流程 */
  budgetChannel?: Maybe<Scalars['Int']>;
  budgetNumber?: Maybe<Scalars['String']>;
  activityType?: Maybe<Scalars['String']>;
  serviceBusinessStatus?: Maybe<Scalars['String']>;
  belongProduct?: Maybe<UpProduct>;
  productType?: Maybe<Scalars['String']>;
  activityConfig?: Maybe<Scalars['JSON']>;
  activityConfigModified?: Maybe<MeetingActivity>;
};

export enum StandardMeetingStatus {
  NotStart = 'NOT_START',
  Ongoing = 'ONGOING',
  Finish = 'FINISH',
  Exception = 'EXCEPTION',
  Deleted = 'DELETED'
}

export enum StandardMeetingChannel {
  Default = 'DEFAULT',
  HrVideo = 'HR_VIDEO',
  PingGuang = 'PING_GUANG',
  HrNarcosis = 'HR_NARCOSIS',
  All = 'ALL',
  Hansoh = 'HANSOH',
  Cttq = 'CTTQ',
  Qc = 'QC',
  Qilu = 'QILU',
  Zmhd = 'ZMHD',
  HrncsMarket = 'HRNCS_MARKET'
}

export type StandardMeetingProperties = {
  __typename?: 'StandardMeetingProperties';
  topicPictureUrls: Array<Scalars['String']>;
  speaker?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  pptUrl: Array<Scalars['String']>;
  liveUrl?: Maybe<WatchUrlInfo>;
  needQuiz?: Maybe<Scalars['Boolean']>;
  needAssistance?: Maybe<Scalars['Boolean']>;
  budget?: Maybe<StandardMeetingBudgetInfo>;
  approvalStatus?: Maybe<StandardMeetingApprovalStatus>;
  products: Array<ProductInfo>;
  webLiveTool?: Maybe<WebLiveToolInfo>;
};

export enum StandardMeetingApprovalStatus {
  Approving = 'APPROVING',
  NotApproval = 'NOT_APPROVAL',
  Success = 'SUCCESS',
  Fail = 'FAIL'
}

export type StandardMeetingOtherProperties = {
  __typename?: 'StandardMeetingOtherProperties';
  type: StandardMeetingPropType;
  value?: Maybe<Scalars['String']>;
  diyType?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
};

export enum StandardMeetingPropType {
  Diy = 'DIY',
  QuizUrl = 'QUIZ_URL',
  LiveUrl = 'LIVE_URL',
  MeetingProduct = 'MEETING_PRODUCT',
  MeetingSpeaker = 'MEETING_SPEAKER',
  MeetingChairman = 'MEETING_CHAIRMAN',
  MeetingRedenvelopeTotalmoney = 'MEETING_REDENVELOPE_TOTALMONEY',
  LiveId = 'LIVE_ID',
  NeedQuiz = 'NEED_QUIZ',
  PlayerId = 'PLAYER_ID',
  ThumbUrl = 'THUMB_URL',
  TopicPicUrl = 'TOPIC_PIC_URL',
  SourceMeetingInfo = 'SOURCE_MEETING_INFO',
  QuizStartTime = 'QUIZ_START_TIME',
  LiveAuthoKey = 'LIVE_AUTHO_KEY',
  Hospital = 'HOSPITAL',
  OpenRegistration = 'OPEN_REGISTRATION',
  Location = 'LOCATION',
  Area = 'AREA',
  BudgetCycle = 'BUDGET_CYCLE',
  PptUrl = 'PPT_URL',
  ApprovalStatus = 'APPROVAL_STATUS',
  Budget = 'BUDGET',
  NeedAssistance = 'NEED_ASSISTANCE',
  WebLiveTool = 'WEB_LIVE_TOOL',
  MuduLiveExcelBill = 'MUDU_LIVE_EXCEL_BILL',
  MuduLiveExcelDetail = 'MUDU_LIVE_EXCEL_DETAIL',
  ProjectType = 'PROJECT_TYPE',
  ContractTemplateId = 'CONTRACT_TEMPLATE_ID',
  MeetingLiveId = 'MEETING_LIVE_ID',
  ServiceBusinessStatus = 'SERVICE_BUSINESS_STATUS',
  City = 'CITY',
  Province = 'PROVINCE',
  AdvertisingActivitiesType = 'ADVERTISING_ACTIVITIES_TYPE',
  CostCenterForMeeting = 'COST_CENTER_FOR_MEETING',
  HrMeetingOwnerCostCenter = 'HR_MEETING_OWNER_COST_CENTER',
  SettlementVendor = 'SETTLEMENT_VENDOR'
}

export type StandardMeetingInfo = {
  __typename?: 'StandardMeetingInfo';
  meetingBaseData?: Maybe<StandardMeetingBaseData>;
  properties?: Maybe<StandardMeetingProperties>;
  city?: Maybe<AccountGroupInfo>;
  ppts: Array<ResourceInfo>;
  speakerInfo?: Maybe<AccountInfo>;
  authLiveUrl?: Maybe<Scalars['String']>;
  isOwner: Scalars['Boolean'];
  isCurrentApprover: Scalars['Boolean'];
  otherProperties?: Maybe<Array<StandardMeetingOtherProperties>>;
  hengruiImage_approveStatus?: Maybe<Scalars['String']>;
  hengruiImage_approveStatusText?: Maybe<Scalars['String']>;
  hengruiImage_approveReason?: Maybe<Scalars['String']>;
  hasUnfinishedTasks?: Maybe<Scalars['Boolean']>;
  hengruiSpearks: Array<HengruiMeetingSpeaker>;
  meetingTotalFee: Scalars['Float'];
  hengruiImage_costs: Array<StandardMeetingCost>;
  hengruiImage_allAttenders?: Maybe<Array<StandardMeetingBaseAttender>>;
  hengruiImage_spearkers?: Maybe<Array<StandardMeetingBaseAttender>>;
  hengruiImage_attendDoctors?: Maybe<Array<StandardMeetingBaseAttender>>;
  hengruiImage_projectType?: Maybe<Scalars['String']>;
  hengruiImage_activityType?: Maybe<Scalars['String']>;
  hengruiImage_categoryName?: Maybe<Scalars['String']>;
  hengruiImage_costcenter?: Maybe<HrCostCenter>;
  hengruiImage_meetingRegion?: Maybe<AccountGroupInfo>;
  hengruiImage_vendor?: Maybe<CompanyInfo>;
  hengruiImage_address?: Maybe<Scalars['String']>;
  hengruiImage_hospital?: Maybe<Scalars['String']>;
  hengruiImage_materials?: Maybe<Array<Scalars['String']>>;
  hengruiImage_schedulePics?: Maybe<Array<Scalars['String']>>;
  hengruiImage_checkReports?: Maybe<Array<Scalars['String']>>;
  hengruiImage_status?: Maybe<Scalars['String']>;
  hengruiImage_statusExtra?: Maybe<Scalars['String']>;
  hengruiImage_statusText?: Maybe<Scalars['String']>;
  hengruiImage_city?: Maybe<CityInfo>;
};


export type StandardMeetingInfoHengruiImage_AllAttendersArgs = {
  attenderType?: Maybe<StandardMeetingTypeAttenderType>;
};

export type PageQueryStandardMeetingResult = {
  __typename?: 'PageQueryStandardMeetingResult';
  items: Array<StandardMeetingInfo>;
  pager: PagerResult;
};

export type BalancePoolDetail = {
  __typename?: 'BalancePoolDetail';
  /** 大区ID */
  regionId?: Maybe<Scalars['String']>;
  /** 总预付 */
  totalPrepaid?: Maybe<Scalars['Float']>;
  /** 冻结 */
  frozenFee?: Maybe<Scalars['Float']>;
  /** 已使用 */
  usedFee?: Maybe<Scalars['Float']>;
  /** 余额 */
  balance?: Maybe<Scalars['Float']>;
  region?: Maybe<AccountGroupInfo>;
  records?: Maybe<Array<BalancePoolRecord>>;
};


export type BalancePoolDetailRecordsArgs = {
  companyId?: Maybe<Scalars['String']>;
};

export type BalancePoolRecord = {
  __typename?: 'BalancePoolRecord';
  /** 预付时间内 */
  paymentTime?: Maybe<Scalars['String']>;
  /** 预付金额 */
  amount?: Maybe<Scalars['Float']>;
};

export type MeetingUplatformProcess = {
  __typename?: 'MeetingUplatformProcess';
  id: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['String']>;
  meetingId?: Maybe<Scalars['String']>;
  actionerId?: Maybe<Scalars['String']>;
  actionId?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  actioner?: Maybe<AccountInfo>;
  reason?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['JSONObject']>;
  done?: Maybe<Scalars['Boolean']>;
  gmtCreated?: Maybe<Scalars['Timestamp']>;
  gmtModified?: Maybe<Scalars['Timestamp']>;
};

export type LimitQueryResult = {
  __typename?: 'LimitQueryResult';
  limitReached: Scalars['Boolean'];
  limitEnabled: Scalars['Boolean'];
  message: Scalars['String'];
};

export type MeetingBudgetTypeOption = {
  __typename?: 'MeetingBudgetTypeOption';
  value: UpMeetingBudgetType;
  label: Scalars['String'];
};

/** u平台会议预算类型 */
export enum UpMeetingBudgetType {
  MeetingPlace = 'MEETING_PLACE',
  Accommodation = 'ACCOMMODATION',
  Dining = 'DINING',
  LongDistanceTransportationFee = 'LONG_DISTANCE_TRANSPORTATION_FEE',
  ShortDistanceTransportationFee = 'SHORT_DISTANCE_TRANSPORTATION_FEE',
  LectureFee = 'LECTURE_FEE',
  MeetingServiceLive = 'MEETING_SERVICE_LIVE',
  Others = 'OTHERS'
}

export type BankCardInfo = {
  __typename?: 'BankCardInfo';
  cardBin?: Maybe<Scalars['String']>;
  cardDesc?: Maybe<Scalars['String']>;
  cardName?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankId?: Maybe<Scalars['String']>;
};

export type BankCodeInfo = {
  __typename?: 'BankCodeInfo';
  bankName?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
};

export type PageResultMeetingAttenders = {
  __typename?: 'PageResultMeetingAttenders';
  items: Array<StandardMeetingBaseAttender>;
  pager: PagerResult;
};

export type MeetingSettlement = {
  __typename?: 'MeetingSettlement';
  settlementId?: Maybe<Scalars['String']>;
  attenders?: Maybe<StandardMeetingBaseAttender>;
  state?: Maybe<Scalars['Int']>;
  settlementStatus?: Maybe<Scalars['String']>;
};

export type PaginatedMeetingSettlementResult = {
  __typename?: 'PaginatedMeetingSettlementResult';
  items: Array<MeetingSettlement>;
  pager: PagerResult;
};

export type BasicInfoNode = {
  __typename?: 'BasicInfoNode';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  previewUrl?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  thumb?: Maybe<Scalars['Int']>;
  reviewed?: Maybe<Scalars['Int']>;
  signedUrl?: Maybe<Scalars['String']>;
};

export type PaginatedBasicInfoNode = {
  __typename?: 'PaginatedBasicInfoNode';
  items: Array<BasicInfoNode>;
  pager: PagerResult;
};

export type BizExt = {
  __typename?: 'BizExt';
  rating?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['String']>;
  hotelOrdering?: Maybe<Scalars['String']>;
  mealOrdering?: Maybe<Scalars['String']>;
  seatOrdering?: Maybe<Scalars['String']>;
  ticketOrdering?: Maybe<Scalars['String']>;
};

export type IPhoto = {
  __typename?: 'IPhoto';
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type HotelInfo = {
  __typename?: 'HotelInfo';
  id: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
  bizExt?: Maybe<BizExt>;
  photos?: Maybe<Array<IPhoto>>;
};

export type HotelSearchResult = {
  __typename?: 'HotelSearchResult';
  items: Array<HotelInfo>;
  pager: PagerResult;
};

export type HospitalPaginationResult = {
  __typename?: 'HospitalPaginationResult';
  items: Array<HospitalInfo>;
  pager: PagerResult;
};

export type PagerInput = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type ContentQueryInput = {
  sortField?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<SortType>;
  status?: Maybe<ContentQueryStatus>;
  pager?: Maybe<PagerInput>;
  targetDoctorId?: Maybe<Scalars['String']>;
};

export enum SortType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum ContentQueryStatus {
  All = 'ALL',
  Unshare = 'UNSHARE',
  Unread = 'UNREAD',
  Shared = 'SHARED'
}

export type AccountCertInfoInput = {
  accountCertId?: Maybe<Scalars['String']>;
  type?: Maybe<AccountCertType>;
  certNo?: Maybe<Scalars['String']>;
  rawCertNo?: Maybe<Scalars['String']>;
  certImages?: Maybe<Array<Scalars['String']>>;
  custom1?: Maybe<Scalars['String']>;
  cnFirstPinyin?: Maybe<Scalars['String']>;
  cnLastPinyin?: Maybe<Scalars['String']>;
  enFirstName?: Maybe<Scalars['String']>;
  enLastName?: Maybe<Scalars['String']>;
  enMiddleName?: Maybe<Scalars['String']>;
  cnName?: Maybe<Scalars['String']>;
  qkkBankName?: Maybe<Scalars['String']>;
  ssqBankName?: Maybe<Scalars['String']>;
};

export type AccountBaseInfoInput = {
  accountId?: Maybe<Scalars['String']>;
  cnName?: Maybe<Scalars['String']>;
  cnFirstName?: Maybe<Scalars['String']>;
  cnLastName?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['String']>;
  status?: Maybe<AccountStatus>;
  gender?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  companyMobile?: Maybe<Scalars['String']>;
  companyEmail?: Maybe<Scalars['String']>;
  companyPhone?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['String']>;
  reportAccountId?: Maybe<Scalars['String']>;
  accountCerts?: Maybe<Array<AccountCertInfoInput>>;
  ouId?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  defaultChannel?: Maybe<Scalars['String']>;
  custom1?: Maybe<Scalars['String']>;
  custom2?: Maybe<Scalars['String']>;
  custom4?: Maybe<Scalars['String']>;
};

export type QcsfRegisterInput = {
  mobile: Scalars['String'];
  cnName: Scalars['String'];
  cityId?: Maybe<Scalars['String']>;
  provinceId?: Maybe<Scalars['String']>;
  hospitalId?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  certPictures?: Maybe<Array<Scalars['String']>>;
  targetProvinceIds?: Maybe<Array<Scalars['String']>>;
  targetCityIds?: Maybe<Array<Scalars['String']>>;
};

export type MemberQueryInput = {
  search?: Maybe<Scalars['String']>;
  pager?: Maybe<PagerInput>;
};

export type RestaurantSearchQuery = {
  areaName?: Maybe<Scalars['String']>;
  cityName?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lng?: Maybe<Scalars['String']>;
  sortType?: Maybe<SortType>;
  orderBy?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  radius?: Maybe<Scalars['String']>;
};

export type RestaurantSearchInput = {
  query?: Maybe<RestaurantSearchQuery>;
  pager?: Maybe<PagerInput>;
};

export type HotelCreateInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  gmtCreated?: Maybe<Scalars['Timestamp']>;
  gmtModified?: Maybe<Scalars['Timestamp']>;
  bizExt?: Maybe<Scalars['String']>;
};

export type MeetingOrderInput = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  meetingId?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['JSONObject']>;
  num?: Maybe<Scalars['Float']>;
  day?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  budgetId?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['String']>;
  vendorType?: Maybe<VendorType>;
};

export type MeetingQuotationInput = {
  id?: Maybe<Scalars['Int']>;
  orderId: Scalars['String'];
  num?: Maybe<Scalars['Float']>;
  day?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['JSONObject']>;
  vendorId?: Maybe<Scalars['String']>;
  vendorType?: Maybe<VendorType>;
  status?: Maybe<Scalars['String']>;
  gmtCreated?: Maybe<Scalars['Timestamp']>;
  gmtModified?: Maybe<Scalars['Timestamp']>;
};

export type MeetingSettlementInput = {
  id?: Maybe<Scalars['Int']>;
  orderId: Scalars['String'];
  externalOrderId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  day?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  tickets?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['JSONObject']>;
  vendorId?: Maybe<Scalars['String']>;
  vendorType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  gmtCreated?: Maybe<Scalars['Timestamp']>;
  gmtModified?: Maybe<Scalars['Timestamp']>;
};

export type ConfirmBizOrderContentInput = {
  amount?: Maybe<Scalars['String']>;
  receipts?: Maybe<Array<Scalars['String']>>;
  invoices?: Maybe<Array<Scalars['String']>>;
  peopleNum?: Maybe<Scalars['String']>;
  drinkAmount?: Maybe<Scalars['String']>;
};

export type MeetingCreateOrUpdateInput = {
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  startTime: Scalars['Timestamp'];
  endTime: Scalars['Timestamp'];
  type: UpMeetingType;
  insideAttenderNum: Scalars['Int'];
  outsideAttenderNum: Scalars['Int'];
  cityId: Scalars['String'];
  hospitalIds?: Maybe<Array<Scalars['String']>>;
  address?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  period: UpMeetingPeriod;
  ownerId: Scalars['ID'];
  hasSpeaker?: Maybe<Scalars['Boolean']>;
};

export type MeetingQueryInput = {
  name?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Timestamp']>;
  endTime?: Maybe<Scalars['Timestamp']>;
  status?: Maybe<UpMeetingStatus>;
  subStatus?: Maybe<Scalars['String']>;
  pager?: Maybe<PagerInput>;
};

export type StandardMeetingBillMarkStatus = {
  meetingId?: Maybe<Scalars['String']>;
  billStatus?: Maybe<AttenderBillStatus>;
  attenderId?: Maybe<Scalars['String']>;
  billTime?: Maybe<Scalars['String']>;
};

export type BaseBmsMeetingSearchInput = {
  cityId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Timestamp']>;
  endTime?: Maybe<Scalars['Timestamp']>;
};

export type BmsMeetingSearchInput = {
  cityId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Timestamp']>;
  endTime?: Maybe<Scalars['Timestamp']>;
  status?: Maybe<BmsMeetingStatus>;
};

export type BmsMeetingSearchH5Input = {
  cityId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Timestamp']>;
  endTime?: Maybe<Scalars['Timestamp']>;
  status?: Maybe<BmsMeetingStatus>;
};

export type PaginatedBmsMeetingSearchInput = {
  query?: Maybe<BmsMeetingSearchInput>;
  pager?: Maybe<PagerInput>;
};

export type PaginatedBmsMeetingSearchH5Input = {
  query?: Maybe<BmsMeetingSearchH5Input>;
  pager?: Maybe<PagerInput>;
};

export type CarTravelCreatInput = {
  num: Scalars['String'];
  carType: Scalars['String'];
  type: Scalars['String'];
  unit: Scalars['String'];
  note: Scalars['String'];
};

export type BmsMeetingHotelInput = {
  name: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  position: Scalars['String'];
  amapId: Scalars['String'];
  city: Scalars['String'];
};

export type BmsMeetingCreateOrUpdateInput = {
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  startTime: Scalars['Timestamp'];
  endTime: Scalars['Timestamp'];
  type: Scalars['String'];
  insideAttenderNum: Scalars['Int'];
  outsideAttenderNum: Scalars['Int'];
  city: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  period: UpMeetingPeriod;
  ownerName: Scalars['String'];
  hotel: BmsMeetingHotelInput;
  travels: Array<CarTravelCreatInput>;
};

export type AddAttendersInput = {
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  hospital?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  inviterName?: Maybe<Scalars['String']>;
  inviterMobile?: Maybe<Scalars['String']>;
};

export type DoctorCompleteCertInfo = {
  certNo: Scalars['String'];
  cardNo?: Maybe<Scalars['String']>;
  certImages?: Maybe<Array<Scalars['String']>>;
  bankName?: Maybe<Scalars['String']>;
};

export type HengruiMeetingSpeakerInput = {
  name: Scalars['String'];
  accountId: Scalars['String'];
  mobile: Scalars['String'];
  topic?: Maybe<Scalars['String']>;
  fee: Scalars['String'];
  amount?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  materials?: Maybe<Array<Scalars['String']>>;
  data?: Maybe<Scalars['JSON']>;
};

export type AddAttenderInput = {
  name: Scalars['String'];
  mobile: Scalars['String'];
  accountId?: Maybe<Scalars['String']>;
  hospital?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['String']>;
  titleId?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
};

export type SaveStandardMeetingCostInput = {
  amount: Scalars['String'];
  type: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};

export type CreateHengruiMeeting = {
  type: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  cityId: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  peopleNum: Scalars['Float'];
  desc?: Maybe<Scalars['String']>;
  projectType?: Maybe<Scalars['String']>;
  activityType?: Maybe<Scalars['String']>;
  costcenter?: Maybe<Scalars['String']>;
  speakers?: Maybe<Array<HengruiMeetingSpeakerInput>>;
  hospital?: Maybe<Scalars['String']>;
  vendor?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  needBudget?: Maybe<Scalars['Boolean']>;
  /** 0: 默认，1：个人，2：流程 */
  budgetChannel?: Maybe<Scalars['Int']>;
  budgetNumber?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
};

export type UpdateHengruiMeeting = {
  type: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  cityId: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  peopleNum: Scalars['Float'];
  desc?: Maybe<Scalars['String']>;
  projectType?: Maybe<Scalars['String']>;
  activityType?: Maybe<Scalars['String']>;
  costcenter?: Maybe<Scalars['String']>;
  speakers?: Maybe<Array<HengruiMeetingSpeakerInput>>;
  hospital?: Maybe<Scalars['String']>;
  vendor?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  needBudget?: Maybe<Scalars['Boolean']>;
  /** 0: 默认，1：个人，2：流程 */
  budgetChannel?: Maybe<Scalars['Int']>;
  budgetNumber?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  meetingId: Scalars['String'];
};

export type InterventionProjectParticipantInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  attenderId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['String']>>;
  startTime?: Maybe<Scalars['Timestamp']>;
  endTime?: Maybe<Scalars['Timestamp']>;
  status?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  regionOwnerId?: Maybe<Scalars['String']>;
};

export type InterventionalProjectInfoInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  productIds?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['Timestamp']>;
  endTime?: Maybe<Scalars['Timestamp']>;
  regionApplicationEnabled?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  /** 参与人数 */
  participantsAllowed?: Maybe<Scalars['Int']>;
  purpose?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Timestamp']>;
  fee?: Maybe<Scalars['JSONObject']>;
  totalFee?: Maybe<Scalars['String']>;
  isLiveSupport?: Maybe<Scalars['Int']>;
  meetingType?: Maybe<Scalars['Int']>;
  meetingData?: Maybe<Scalars['JSONObject']>;
};

export type InterventionalProjectQueryParams = {
  keyword?: Maybe<Scalars['String']>;
  searchFields?: Maybe<Array<Scalars['String']>>;
  startTime?: Maybe<Scalars['Timestamp']>;
  endTime?: Maybe<Scalars['Timestamp']>;
  departmentId?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  productIds?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Array<Scalars['Int']>>;
  bizStatus?: Maybe<ProjectBizStatus>;
  regionOwnerIds?: Maybe<Array<Scalars['String']>>;
  approveStatus?: Maybe<ProjectApprovalStatus>;
};

export enum ProjectBizStatus {
  Completed = 'COMPLETED',
  Incompleted = 'INCOMPLETED'
}

export enum ProjectApprovalStatus {
  WaitForMyApproving = 'WAIT_FOR_MY_APPROVING',
  MyApproved = 'MY_APPROVED'
}

export type InterventionalProjectParticipantsQueryParams = {
  nameKeyword?: Maybe<Scalars['String']>;
  clientKeyword?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Timestamp']>;
  endTime?: Maybe<Scalars['Timestamp']>;
  productIds?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Array<Scalars['Int']>>;
};

export type InterventionalProjectQueryParamsWithPager = {
  query?: Maybe<InterventionalProjectQueryParams>;
  pager?: Maybe<PagerInput>;
};

export type InterventionalProjectParticipantsQueryParamsWithPager = {
  query?: Maybe<InterventionalProjectParticipantsQueryParams>;
  pager?: Maybe<PagerInput>;
};

export type ProjectReviewInput = {
  id?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  nextPlan?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  conceptChange?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
  projectScheduleId: Scalars['String'];
};

export type BudgetImportUnit = {
  month: Scalars['String'];
  budget: Scalars['String'];
};

export type BudgetImportData = {
  name: Scalars['String'];
  bu: Scalars['String'];
  region: Scalars['String'];
  area: Scalars['String'];
  monthBudget: Array<BudgetImportUnit>;
  year: Scalars['String'];
  financeNo: Scalars['String'];
};

export type ProcessBudgetImportData = {
  number: Scalars['String'];
  amount: Scalars['String'];
};

export type BudgetSearch = {
  businessIds?: Maybe<Array<Scalars['String']>>;
  areaIds?: Maybe<Array<Scalars['String']>>;
  regionIds?: Maybe<Array<Scalars['String']>>;
  keyword?: Maybe<Scalars['String']>;
  isProcess?: Maybe<Scalars['Boolean']>;
};

export type BudgetSearchWithPager = {
  query?: Maybe<BudgetSearch>;
  pager?: Maybe<PagerInput>;
};

export type ProcessBudgetSearch = {
  number?: Maybe<Scalars['String']>;
};

export type ProcessBudgetSearchWithPager = {
  query?: Maybe<ProcessBudgetSearch>;
  pager?: Maybe<PagerInput>;
};

export type ArchiveSearchParams = {
  bizTypes?: Maybe<Array<Scalars['String']>>;
  keyword?: Maybe<Scalars['String']>;
  archiveTypes?: Maybe<Array<Scalars['String']>>;
  meetingId?: Maybe<Scalars['String']>;
  productIds?: Maybe<Array<Scalars['String']>>;
  needDisabled?: Maybe<Scalars['Boolean']>;
  creatorIds?: Maybe<Array<Scalars['String']>>;
  tagIds?: Maybe<Array<Scalars['String']>>;
};

export type ArchiveCreateParams = {
  archiveUrl: Scalars['String'];
  title: Scalars['String'];
  describe: Scalars['String'];
  companyId: Scalars['String'];
  products?: Maybe<Array<Scalars['String']>>;
  isExternal?: Maybe<Scalars['Boolean']>;
};

export type PageQueryArchivesRequest = {
  query?: Maybe<ArchiveSearchParams>;
  pager?: Maybe<PagerInput>;
};

export type DoctorTaskSearchInput = {
  id?: Maybe<Array<Scalars['String']>>;
  taskCategory?: Maybe<Array<Scalars['String']>>;
  attenderId?: Maybe<Array<Scalars['String']>>;
  accountId?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Scalars['String']>;
  meetingId?: Maybe<Scalars['String']>;
  templateCode?: Maybe<Scalars['String']>;
};

export type DoctorTaskSearchInputWithPager = {
  query?: Maybe<DoctorTaskSearchInput>;
  pager?: Maybe<PagerInput>;
};

export type DocumentArchiveSearchParams = {
  tagIds?: Maybe<Array<Scalars['String']>>;
  documentCategoryIds?: Maybe<Array<Scalars['String']>>;
  documentReviewStatus?: Maybe<Array<Scalars['Int']>>;
  keywords?: Maybe<Scalars['String']>;
  productCodes?: Maybe<Array<Scalars['String']>>;
  companyId?: Maybe<Scalars['String']>;
  templateCode?: Maybe<Scalars['String']>;
  activityCode?: Maybe<Scalars['String']>;
};

export type DocumentArchiveReviewsSearchParams = {
  tagIds?: Maybe<Array<Scalars['String']>>;
  documentCategoryIds?: Maybe<Array<Scalars['String']>>;
  documentReviewStatus?: Maybe<Array<Scalars['Int']>>;
  keywords?: Maybe<Scalars['String']>;
};

export type DocumentArchiveSearchParamsWithPager = {
  query?: Maybe<DocumentArchiveSearchParams>;
  pager?: Maybe<PagerInput>;
};

export type DocumentArchiveReviewsSearchParamsWithPager = {
  query?: Maybe<DocumentArchiveReviewsSearchParams>;
  pager?: Maybe<PagerInput>;
};

export type AddDocumentInput = {
  title: Scalars['String'];
  author: Scalars['String'];
  source: Scalars['String'];
  publishDate: Scalars['Timestamp'];
  describe?: Maybe<Scalars['String']>;
  tagIds?: Maybe<Array<Scalars['String']>>;
  url: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  productCode?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
};

export type SubmitReviewContentInput = {
  id?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  value: Scalars['String'];
};

export type SubmitReviewInput = {
  reviewId?: Maybe<Scalars['String']>;
  contents: Array<SubmitReviewContentInput>;
  canOpen?: Maybe<Scalars['Boolean']>;
};

export type MeetingCategoryPropsInput = {
  code?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Int']>;
};

export type MeetingConfigAddOrUpdateInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  code: Scalars['String'];
  icon: Scalars['String'];
  enable: Scalars['Boolean'];
  sort?: Maybe<Scalars['Int']>;
  props: Array<MeetingCategoryPropsInput>;
};

export type MeetingACtivityCreateOrUpdateInput = {
  id?: Maybe<Scalars['String']>;
  companyId: Scalars['String'];
  categoryCode: Scalars['String'];
  processId: Scalars['String'];
  activityCode: Scalars['String'];
  enable: Scalars['Boolean'];
  templateCode?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  instanceMin?: Maybe<Scalars['Int']>;
  instanceMax?: Maybe<Scalars['Int']>;
};

export type TemplateInput = {
  id?: Maybe<Scalars['String']>;
  companyId: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
  templateCode?: Maybe<Scalars['String']>;
  templateCategory?: Maybe<Scalars['String']>;
  documentCategory?: Maybe<Scalars['String']>;
  templateAttribute?: Maybe<Scalars['String']>;
  isEnable?: Maybe<Scalars['Boolean']>;
  instanceMax?: Maybe<Scalars['Int']>;
  instanceMin?: Maybe<Scalars['Int']>;
};

export type ProductInfoInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  isOtc?: Maybe<Scalars['Boolean']>;
};

export type CreateStandardMeetingInput = {
  id?: Maybe<Scalars['String']>;
  cityId: Scalars['String'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  desc: Scalars['String'];
  topic: Scalars['String'];
  products: Array<ProductInfoInput>;
  speaker: Scalars['String'];
  pptUrl: Array<Scalars['String']>;
  topicPictureUrls: Array<Scalars['String']>;
  needAssistance: Scalars['Boolean'];
  needQuiz: Scalars['Boolean'];
  lectureFee: Scalars['Float'];
  otherFee: Scalars['Float'];
};

export type StandardMeetingSearchQuery = {
  keyword?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  meetingStatus?: Maybe<Array<StandardMeetingStatus>>;
  productIds?: Maybe<Array<Scalars['String']>>;
  meetingType?: Maybe<Scalars['String']>;
  serviceBizStatus?: Maybe<Array<StandardMeetingServiceBizStatus>>;
  startTime?: Maybe<Scalars['Timestamp']>;
  endTime?: Maybe<Scalars['Timestamp']>;
};

export type StandardMeetingSearchInput = {
  region?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  projectType?: Maybe<Scalars['String']>;
  activityType?: Maybe<Scalars['String']>;
  projectTypes?: Maybe<Array<Scalars['String']>>;
  activityTypes?: Maybe<Array<Scalars['String']>>;
  startTime?: Maybe<Scalars['Timestamp']>;
  endTime?: Maybe<Scalars['Timestamp']>;
  meetingType?: Maybe<Scalars['String']>;
  meetingTypes?: Maybe<Array<Scalars['String']>>;
  productIds?: Maybe<Array<Scalars['String']>>;
  serviceBizStatus?: Maybe<Array<StandardMeetingServiceBizStatus>>;
  vendor?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};

export type PageQueryStandardMeetingInput = {
  query?: Maybe<StandardMeetingSearchQuery>;
  pager?: Maybe<PagerInput>;
};

export type PageSearchStandardMeetingInput = {
  query?: Maybe<StandardMeetingSearchInput>;
  pager?: Maybe<PagerInput>;
};

export type SaleAccountInput = {
  cid: Scalars['String'];
  uid: Scalars['String'];
};

export type DocotorAccountInput = {
  cnName: Scalars['String'];
  mobile: Scalars['String'];
  hospitalId: Scalars['String'];
  departmentId: Scalars['String'];
  titleId: Scalars['String'];
  staffId: Scalars['String'];
};

export type BatchInfo = {
  sales: SaleAccountInput;
  doctor: DocotorAccountInput;
};

export type MeetingBudgetCreateInput = {
  id?: Maybe<Scalars['ID']>;
  meetingId: Scalars['ID'];
  type: UpMeetingBudgetType;
  value: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
  day?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Int']>;
};

export type MeetingScheduleInput = {
  id?: Maybe<Scalars['String']>;
  meetingId: Scalars['String'];
  startTime: Scalars['Timestamp'];
  endTime: Scalars['Timestamp'];
  topic: Scalars['String'];
  place?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

export type EditDoctorInfo = {
  accountId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  mobile: Scalars['String'];
  hospital?: Maybe<Scalars['String']>;
  /** 卡号 */
  cardNo?: Maybe<Scalars['String']>;
  companyId: Scalars['String'];
  departmentId?: Maybe<Scalars['String']>;
  titleId?: Maybe<Scalars['String']>;
  /** 银行 */
  bankName?: Maybe<Scalars['String']>;
  /** 开户行 */
  bankBranchName?: Maybe<Scalars['String']>;
  /** 联行号 */
  bankCode?: Maybe<Scalars['String']>;
  /** 身份证号 */
  certNo?: Maybe<Scalars['String']>;
  /** 默认渠道 */
  channel?: Maybe<Scalars['String']>;
};

export type QueryMeetingAttenders = {
  companyId?: Maybe<Scalars['String']>;
  meetingType?: Maybe<Scalars['String']>;
  activityType?: Maybe<Array<Scalars['String']>>;
  projectType?: Maybe<Array<Scalars['String']>>;
  regionId?: Maybe<Scalars['String']>;
  meetingStatus?: Maybe<Array<Scalars['String']>>;
  meetingStartTime?: Maybe<Scalars['Timestamp']>;
  meetingEndTime?: Maybe<Scalars['Timestamp']>;
  providerId?: Maybe<Scalars['String']>;
  settlementStatus?: Maybe<Scalars['String']>;
  billStatus?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
};

export type PageQueryMeetingAttenders = {
  query?: Maybe<QueryMeetingAttenders>;
  pager?: Maybe<PagerInput>;
};

export type MeetingSettlementSearch = {
  keyword?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  meetingStartTimestamp?: Maybe<Scalars['String']>;
  meetingEndTimestamp?: Maybe<Scalars['String']>;
  settlementStatus?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
};

export type PaginatedMeetingSettlementRequest = {
  query?: Maybe<MeetingSettlementSearch>;
  pager?: Maybe<PagerInput>;
};

export type HotelSearchQuery = {
  keyword?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

export type HotelSearchInput = {
  query?: Maybe<HotelSearchQuery>;
  pager?: Maybe<PagerInput>;
};

export type HospitalSearchQuery = {
  cityId?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
};

export type HospitalPaginationRequest = {
  query?: Maybe<HospitalSearchQuery>;
  pager?: Maybe<PagerInput>;
};

export type Query = {
  __typename?: 'Query';
  meetingAttenderList: Array<MeetingUplatformAttender>;
  insideAttenderList: MemberPaginatedResponse;
  outsideAttenderList: MemberPaginatedResponse;
  currentUser?: Maybe<AccountInfo>;
  queryUser?: Maybe<AccountInfo>;
  queryTargetRegionTree: Array<TargetRegionNodeInfo>;
  queryTargetRegions: Array<TargetRegionNodeBaseInfo>;
  queryDoctorAccountAttrConfigs: Array<AccountGroupInfo>;
  queryCompanyGroup: Array<AccountGroupInfo>;
  queryArchiveList: PageQueryArchivesResult;
  queryArchiveDetail?: Maybe<ArchiveInfo>;
  getArchiveProductsList?: Maybe<Array<ArchiveTagInfo>>;
  getArchiveSTSInfo?: Maybe<Scalars['JSON']>;
  getArchiveSignedUrl?: Maybe<Scalars['String']>;
  getUploadAdminPageUrl?: Maybe<Scalars['String']>;
  hengrui_queryBalancePoolList: Array<BalancePoolDetail>;
  bmsMeetingsH5: MeetingSearchResult;
  bmsMeetings: MeetingSearchResult;
  bmsMeetingStatus: Array<BmsMeetingStatusOption>;
  bmsAttenders: Array<MeetingUplatformAttender>;
  bmsQueryCarOfferPrice: Array<CarOfferPrice>;
  bmsQueryCarOrder?: Maybe<BmsCarOrderDetail>;
  bmsCarScheduleComplianceRisks?: Maybe<Scalars['JSON']>;
  pageSearchHansohBudget?: Maybe<PaginatedHansohBudgets>;
  pageSearchProcessBudget?: Maybe<PaginatedProcessBudget>;
  checkPersonalBudget?: Maybe<LimitQueryResult>;
  carOrder?: Maybe<CarOrderInfo>;
  cities: Array<CityInfo>;
  contentList?: Maybe<ContentListQueryResult>;
  contentDetail?: Maybe<ContentInfo>;
  contentActionList: Array<ContentActionInfo>;
  queryUpDoctorMobileByAttenderId?: Maybe<Scalars['String']>;
  doctorTasks?: Maybe<PaginatedAttenderTasks>;
  doctorTask?: Maybe<MeetingAttenderTask>;
  hasUnfinishedTasks?: Maybe<Scalars['Boolean']>;
  queryTaskDocumentReviewContent: Array<DocumentReviewContentInfo>;
  hengruiImage_doctorHasCompeleteCert: Scalars['Boolean'];
  hengruiImage_meetingList: PageQueryStandardMeetingResult;
  hengruiImage_interProjectType: Array<Scalars['String']>;
  hengruiImage_searchMeeting: PageQueryStandardMeetingResult;
  hengruiImage_meetingDetail?: Maybe<StandardMeetingInfo>;
  hengruiImage_doctors: MemberPaginatedResponse;
  hengruiImage_myApprovals: PageQueryStandardMeetingResult;
  hengruiImage_departments: GroupPaginationRes;
  hengruiImage_titles: GroupPaginationRes;
  hengruiImage_isUserJunziqianSigned?: Maybe<Scalars['Boolean']>;
  hengruiImage_meetingCount: Scalars['JSON'];
  hengruiImage_meetingTypes: Scalars['JSON'];
  hengruiImage_approvalCount: Scalars['JSON'];
  hengruiImage_statusMap: Array<HrMeetingStatusOption>;
  hengruiImage_regions: Array<AccountGroupInfo>;
  hengruiImage_attenderContract?: Maybe<Scalars['String']>;
  hengruiImage_userCostCenter?: Maybe<Array<HrCostCenter>>;
  hengruiImage_attenderPaymentDetail?: Maybe<Scalars['JSON']>;
  hengruiImage_vendors?: Maybe<Array<CompanyInfo>>;
  hengruiImage_checkOwnerLimit?: Maybe<LimitQueryResult>;
  hengruiImage_checkAddDoctorLimit?: Maybe<LimitQueryResult>;
  queryAttenderTasks?: Maybe<PaginatedAttenderTasks>;
  hospitalList: Array<HospitalInfo>;
  queryInterventionalProject?: Maybe<InterventionalProjectInfo>;
  pageQueryInterventionalProject?: Maybe<PaginatedInterventionalProjectInfo>;
  queryProductionTree?: Maybe<Array<ProjectProduction>>;
  queryMyProductions?: Maybe<Array<ProjectProduction>>;
  queryParticipantRoleList?: Maybe<Array<Scalars['String']>>;
  /** 获取会议大类型 */
  queryProjectCatergories?: Maybe<Array<ProjectCategory>>;
  checkProjectCanCreate?: Maybe<InterventionalProjectInfo>;
  interventionalProjectDoctors?: Maybe<MemberPaginatedResponse>;
  interventionalRegionOwners?: Maybe<MemberPaginatedResponse>;
  queryInterventionalProjectDoctorGroups?: Maybe<Array<AccountGroupInfo>>;
  pageQueryProjectParticipants?: Maybe<PaginatedInterventionalProjectParticipantsInfo>;
  queryProjectParticipants?: Maybe<Array<InterventionProjectParticipantInfo>>;
  queryProjectParticipantsById?: Maybe<InterventionProjectParticipantInfo>;
  queryReview?: Maybe<Array<ProjectReviewInfo>>;
  queryArchiveTags: Array<ArchiveTagInfo>;
  queryMedicalLiteratureTags: Array<ArchiveTagInfo>;
  queryDocuments: DocumentArchivePagerSearchResult;
  queryDocument: DocumentInfo;
  queryDocumentCategories: Array<DocumentArchiveCategory>;
  queryMedicalLiteratureAppUrl?: Maybe<Scalars['String']>;
  queryMedicalLiteratureAdminUrl?: Maybe<Scalars['String']>;
  translateDocumentContent?: Maybe<Scalars['String']>;
  queryDocumentReviews: DocumentReviewPagerSearchResult;
  financeQueryDocumentReviews: DocumentReviewPagerSearchResult;
  queryDocumentReview?: Maybe<DocumentReviewInfo>;
  queryDocumentReviewContent: Array<DocumentReviewContentInfo>;
  queryDocumentReviewContentTemplate: Array<ReviewContentTemplate>;
  meetingDetail?: Maybe<MeetingInfo>;
  meetingList?: Maybe<MeetingSearchResult>;
  meetingListWithinQuotation?: Maybe<MeetingSearchResult>;
  meetingStatusList: Array<UpMeetingStatusOption>;
  meetingQuotationStatusList: Array<UpMeetingQuotationStatusOption>;
  queryMeetingFiles: Scalars['JSON'];
  queryMeetingCounts: Array<Scalars['Int']>;
  meetingWaitForMyApproval?: Maybe<Array<MeetingInfo>>;
  meetingApprovalFlow?: Maybe<ApproveInfo>;
  meetingApprovalProcess: Array<MeetingUplatformProcess>;
  meetingBudgetTypes: Array<MeetingBudgetTypeOption>;
  companyApprovelProcess?: Maybe<Array<BaseOptions>>;
  companyUpProducts?: Maybe<Array<UpProduct>>;
  queryMeetingActivityTemplates?: Maybe<Array<MeetingActivityTemplate>>;
  meetingOrderList: Array<MeetingOrderInfo>;
  relatedBizOrderList?: Maybe<Scalars['JSON']>;
  quotationQueryByOrderId: Array<MeetingQuotationInfo>;
  quotationQueryByBudgetId: Array<MeetingQuotationInfo>;
  settlementQueryByOrderId: Array<MeetingSettlementInfo>;
  queryMeetingLivePhotos: Scalars['JSON'];
  signInList: Scalars['Boolean'];
  signInLogs: Array<UpMeetingSignInInfo>;
  queryBankCardInfo?: Maybe<BankCardInfo>;
  pingguang_userInfo: AccountInfo;
  pingguang_authLiveUrl?: Maybe<Scalars['String']>;
  pingguang_meetingList: PageQueryStandardMeetingResult;
  pingguang_meetingDetail: StandardMeetingInfo;
  pingguang_lecturers: MemberPaginatedResponse;
  pingguang_doctors: MemberPaginatedResponse;
  pingguang_hospitals: HospitalPaginationRes;
  pingguang_departments: GroupPaginationRes;
  pingguang_titles: GroupPaginationRes;
  pingguang_products: GroupPaginationRes;
  pingguang_regions: GroupPaginationRes;
  pingguang_pptList: Array<ResourceInfo>;
  pingguang_approveList: Array<StandardMeetingInfo>;
  salesPerformance: Array<SalesPerformanceData>;
  salesPerformanceAll: Scalars['JSONObject'];
  latestContents: Array<ContentInfo>;
  relatedDoctors: MemberPaginatedResponse;
  upadminCompanies: Array<CompanyInfo>;
  upadminDoctorCompanies: Array<CompanyInfo>;
  upadminRegions: Array<AccountGroupInfo>;
  upadminFetchCNAPSCode: Array<BankCodeInfo>;
  upadminSearchDoctors: MemberPaginatedResponse;
  upadminSearchAttenders: PageResultMeetingAttenders;
  upadminMyApprovals: PageQueryStandardMeetingResult;
  availableChannels: Array<Scalars['JSONObject']>;
  queryInvitationUrl?: Maybe<Scalars['String']>;
  upadminFinancePageQuerySettlements: PaginatedMeetingSettlementResult;
  appBanner: Array<BasicInfoNode>;
  appInfos: Array<BasicInfoNode>;
  appInfosWithPager: PaginatedBasicInfoNode;
  appMeetingCategories: Array<MeetingCategory>;
  appMeetingCategoryProps: Array<Scalars['JSON']>;
  appMeetingActivityProps: Array<Scalars['JSON']>;
  appMeetingActivityTypes?: Maybe<Array<MeetingActivity>>;
  queryMeetingActivityById?: Maybe<MeetingActivity>;
  fetchInvitationDataBySignature?: Maybe<Scalars['JSON']>;
  upappDocumentList?: Maybe<DocumentArchivePagerSearchResult>;
  meetingCategories?: Maybe<Array<MeetingCategory>>;
  fetchAllBaseMeetingTypes?: Maybe<Array<MeetingConfigBase>>;
  fetchAllBaseMeetingActivities?: Maybe<Array<MeetingConfigBase>>;
  meetingActivitiyTypes?: Maybe<Array<MeetingCategory>>;
  restaurantSearch: RestaurantSearchResult;
  hotelSearch: HotelSearchResult;
  carTypeSearch: Array<CarInfo>;
  carServiceSearch: Array<CarServiceInfo>;
  queryCarSupportedCities: Array<Scalars['String']>;
  queryHospitals: HospitalPaginationResult;
};


export type QueryMeetingAttenderListArgs = {
  type?: Maybe<AttenderType>;
  meetingId: Scalars['String'];
};


export type QueryInsideAttenderListArgs = {
  query?: Maybe<MemberQueryInput>;
};


export type QueryOutsideAttenderListArgs = {
  query?: Maybe<MemberQueryInput>;
};


export type QueryQueryUserArgs = {
  accountId: Scalars['String'];
};


export type QueryQueryTargetRegionsArgs = {
  userType?: Maybe<QcsfUserType>;
  pid?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
};


export type QueryQueryDoctorAccountAttrConfigsArgs = {
  search?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};


export type QueryQueryCompanyGroupArgs = {
  level?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};


export type QueryQueryArchiveListArgs = {
  params?: Maybe<PageQueryArchivesRequest>;
};


export type QueryQueryArchiveDetailArgs = {
  archiveId: Scalars['String'];
};


export type QueryGetArchiveProductsListArgs = {
  refId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['String']>;
};


export type QueryGetArchiveSignedUrlArgs = {
  archiveId: Scalars['String'];
};


export type QueryHengrui_QueryBalancePoolListArgs = {
  companyId?: Maybe<Scalars['String']>;
  regionIds?: Maybe<Array<Scalars['String']>>;
};


export type QueryBmsMeetingsH5Args = {
  search?: Maybe<PaginatedBmsMeetingSearchH5Input>;
};


export type QueryBmsMeetingsArgs = {
  search?: Maybe<PaginatedBmsMeetingSearchInput>;
};


export type QueryBmsAttendersArgs = {
  name?: Maybe<Scalars['String']>;
  meetingId: Scalars['String'];
  type: Scalars['String'];
};


export type QueryBmsQueryCarOfferPriceArgs = {
  supplier: Scalars['String'];
  cityName: Scalars['String'];
  carType: Scalars['String'];
  serviceName: Scalars['String'];
};


export type QueryBmsQueryCarOrderArgs = {
  orderId: Scalars['String'];
};


export type QueryBmsCarScheduleComplianceRisksArgs = {
  scheduleId: Scalars['String'];
};


export type QueryPageSearchHansohBudgetArgs = {
  param?: Maybe<BudgetSearchWithPager>;
};


export type QueryPageSearchProcessBudgetArgs = {
  param?: Maybe<ProcessBudgetSearchWithPager>;
};


export type QueryCheckPersonalBudgetArgs = {
  meetingId: Scalars['String'];
  amountToAdd?: Maybe<Scalars['Float']>;
};


export type QueryCarOrderArgs = {
  orderId: Scalars['String'];
};


export type QueryCitiesArgs = {
  level?: Maybe<Scalars['String']>;
};


export type QueryContentListArgs = {
  query?: Maybe<ContentQueryInput>;
};


export type QueryContentDetailArgs = {
  contentId: Scalars['String'];
};


export type QueryContentActionListArgs = {
  contentId?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Scalars['String']>>;
};


export type QueryQueryUpDoctorMobileByAttenderIdArgs = {
  attenderId: Scalars['String'];
};


export type QueryDoctorTasksArgs = {
  search?: Maybe<DoctorTaskSearchInputWithPager>;
};


export type QueryDoctorTaskArgs = {
  id: Scalars['String'];
};


export type QueryHasUnfinishedTasksArgs = {
  category: Scalars['String'];
};


export type QueryQueryTaskDocumentReviewContentArgs = {
  taskId: Scalars['String'];
};


export type QueryHengruiImage_DoctorHasCompeleteCertArgs = {
  channel?: Maybe<Scalars['String']>;
  meetingId?: Maybe<Scalars['String']>;
  mobile: Scalars['String'];
};


export type QueryHengruiImage_MeetingListArgs = {
  filter?: Maybe<Scalars['String']>;
  pageQuery?: Maybe<PageQueryStandardMeetingInput>;
};


export type QueryHengruiImage_SearchMeetingArgs = {
  isFinanceAdmin?: Maybe<Scalars['Boolean']>;
  pageQuery?: Maybe<PageSearchStandardMeetingInput>;
};


export type QueryHengruiImage_MeetingDetailArgs = {
  meetingId?: Maybe<Scalars['String']>;
};


export type QueryHengruiImage_DoctorsArgs = {
  query?: Maybe<MemberQueryInput>;
};


export type QueryHengruiImage_MyApprovalsArgs = {
  approveStatus?: Maybe<Scalars['String']>;
  pageQuery?: Maybe<PageQueryStandardMeetingInput>;
};


export type QueryHengruiImage_DepartmentsArgs = {
  companyId?: Maybe<Scalars['String']>;
  query?: Maybe<MemberQueryInput>;
};


export type QueryHengruiImage_TitlesArgs = {
  companyId?: Maybe<Scalars['String']>;
  query?: Maybe<MemberQueryInput>;
};


export type QueryHengruiImage_IsUserJunziqianSignedArgs = {
  channel?: Maybe<MeetingSignChannel>;
  accountId: Scalars['String'];
};


export type QueryHengruiImage_AttenderContractArgs = {
  attenderId: Scalars['String'];
  meetingId: Scalars['String'];
};


export type QueryHengruiImage_AttenderPaymentDetailArgs = {
  type?: Maybe<HadesReceiptType>;
  channel?: Maybe<HadesPaymentChannel>;
  paymentId?: Maybe<Scalars['String']>;
};


export type QueryHengruiImage_CheckOwnerLimitArgs = {
  meetingId?: Maybe<Scalars['String']>;
  meetingDate: Scalars['Timestamp'];
};


export type QueryHengruiImage_CheckAddDoctorLimitArgs = {
  budget?: Maybe<Scalars['Float']>;
  meetingId: Scalars['String'];
};


export type QueryQueryAttenderTasksArgs = {
  search?: Maybe<DoctorTaskSearchInputWithPager>;
};


export type QueryHospitalListArgs = {
  city: Scalars['String'];
};


export type QueryQueryInterventionalProjectArgs = {
  id: Scalars['String'];
};


export type QueryPageQueryInterventionalProjectArgs = {
  query?: Maybe<InterventionalProjectQueryParamsWithPager>;
};


export type QueryQueryProductionTreeArgs = {
  categoryId?: Maybe<Scalars['String']>;
};


export type QueryInterventionalProjectDoctorsArgs = {
  query?: Maybe<MemberQueryInput>;
};


export type QueryInterventionalRegionOwnersArgs = {
  query?: Maybe<MemberQueryInput>;
};


export type QueryQueryInterventionalProjectDoctorGroupsArgs = {
  search: Scalars['String'];
  type: Scalars['String'];
};


export type QueryPageQueryProjectParticipantsArgs = {
  search?: Maybe<InterventionalProjectParticipantsQueryParamsWithPager>;
};


export type QueryQueryProjectParticipantsArgs = {
  projectId: Scalars['String'];
};


export type QueryQueryProjectParticipantsByIdArgs = {
  participantId: Scalars['String'];
};


export type QueryQueryReviewArgs = {
  participantId: Scalars['String'];
};


export type QueryQueryArchiveTagsArgs = {
  companyId?: Maybe<Scalars['String']>;
};


export type QueryQueryMedicalLiteratureTagsArgs = {
  companyId?: Maybe<Scalars['String']>;
};


export type QueryQueryDocumentsArgs = {
  isAdmin?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Scalars['String']>;
  search?: Maybe<DocumentArchiveSearchParamsWithPager>;
};


export type QueryQueryDocumentArgs = {
  documentId: Scalars['String'];
};


export type QueryQueryDocumentCategoriesArgs = {
  companyId?: Maybe<Scalars['String']>;
};


export type QueryQueryMedicalLiteratureAppUrlArgs = {
  path: Scalars['String'];
};


export type QueryQueryMedicalLiteratureAdminUrlArgs = {
  path: Scalars['String'];
};


export type QueryTranslateDocumentContentArgs = {
  text: Scalars['String'];
};


export type QueryQueryDocumentReviewsArgs = {
  isAdmin?: Maybe<Scalars['Boolean']>;
  search?: Maybe<DocumentArchiveReviewsSearchParamsWithPager>;
};


export type QueryFinanceQueryDocumentReviewsArgs = {
  search?: Maybe<DocumentArchiveReviewsSearchParamsWithPager>;
};


export type QueryQueryDocumentReviewArgs = {
  reviewId: Scalars['String'];
};


export type QueryQueryDocumentReviewContentArgs = {
  reviewId: Scalars['String'];
};


export type QueryQueryDocumentReviewContentTemplateArgs = {
  documentId: Scalars['String'];
};


export type QueryMeetingDetailArgs = {
  meetingId: Scalars['String'];
};


export type QueryMeetingListArgs = {
  query?: Maybe<MeetingQueryInput>;
  homeStatus?: Maybe<UpMeetingHomeStatus>;
};


export type QueryMeetingListWithinQuotationArgs = {
  query?: Maybe<MeetingQueryInput>;
  homeStatus?: Maybe<UpMeetingHomeStatus>;
};


export type QueryQueryMeetingFilesArgs = {
  meetingId: Scalars['String'];
};


export type QueryMeetingApprovalFlowArgs = {
  meetingId: Scalars['String'];
};


export type QueryMeetingApprovalProcessArgs = {
  meetingId: Scalars['String'];
};


export type QueryCompanyApprovelProcessArgs = {
  companyId: Scalars['String'];
};


export type QueryCompanyUpProductsArgs = {
  currentUser?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['String']>;
};


export type QueryQueryMeetingActivityTemplatesArgs = {
  documentCategory?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};


export type QueryMeetingOrderListArgs = {
  budgetId: Scalars['String'];
};


export type QueryRelatedBizOrderListArgs = {
  orderType: Scalars['String'];
  orderId: Scalars['String'];
};


export type QueryQuotationQueryByOrderIdArgs = {
  orderId: Scalars['String'];
};


export type QueryQuotationQueryByBudgetIdArgs = {
  budgetId: Scalars['String'];
};


export type QuerySettlementQueryByOrderIdArgs = {
  orderId: Scalars['String'];
};


export type QueryQueryMeetingLivePhotosArgs = {
  scheduleId: Scalars['String'];
  meetingId: Scalars['String'];
};


export type QuerySignInLogsArgs = {
  meetingId: Scalars['String'];
};


export type QueryQueryBankCardInfoArgs = {
  bankCardNo: Scalars['String'];
};


export type QueryPingguang_UserInfoArgs = {
  accountId?: Maybe<Scalars['String']>;
};


export type QueryPingguang_AuthLiveUrlArgs = {
  meetingId: Scalars['String'];
};


export type QueryPingguang_MeetingListArgs = {
  filter?: Maybe<Scalars['String']>;
  pageQuery?: Maybe<PageQueryStandardMeetingInput>;
};


export type QueryPingguang_MeetingDetailArgs = {
  meetingId: Scalars['String'];
};


export type QueryPingguang_LecturersArgs = {
  query?: Maybe<MemberQueryInput>;
};


export type QueryPingguang_DoctorsArgs = {
  query?: Maybe<MemberQueryInput>;
};


export type QueryPingguang_HospitalsArgs = {
  query?: Maybe<MemberQueryInput>;
};


export type QueryPingguang_DepartmentsArgs = {
  query?: Maybe<MemberQueryInput>;
};


export type QueryPingguang_TitlesArgs = {
  query?: Maybe<MemberQueryInput>;
};


export type QueryPingguang_ProductsArgs = {
  query?: Maybe<MemberQueryInput>;
};


export type QueryPingguang_RegionsArgs = {
  query?: Maybe<MemberQueryInput>;
};


export type QueryPingguang_ApproveListArgs = {
  submitted?: Maybe<Scalars['Boolean']>;
};


export type QueryRelatedDoctorsArgs = {
  pager?: Maybe<PagerInput>;
  keyword?: Maybe<Scalars['String']>;
};


export type QueryUpadminRegionsArgs = {
  companyId: Scalars['String'];
};


export type QueryUpadminFetchCnapsCodeArgs = {
  bankBranchName?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
};


export type QueryUpadminSearchDoctorsArgs = {
  query?: Maybe<PageQueryMeetingAttenders>;
};


export type QueryUpadminSearchAttendersArgs = {
  query?: Maybe<PageQueryMeetingAttenders>;
};


export type QueryUpadminMyApprovalsArgs = {
  approveStatus?: Maybe<Scalars['String']>;
  pageQuery?: Maybe<PageQueryStandardMeetingInput>;
};


export type QueryQueryInvitationUrlArgs = {
  issuerId?: Maybe<Scalars['String']>;
  meetingId: Scalars['String'];
  host: Scalars['String'];
};


export type QueryUpadminFinancePageQuerySettlementsArgs = {
  request?: Maybe<PaginatedMeetingSettlementRequest>;
};


export type QueryAppInfosArgs = {
  type: AppInfoType;
};


export type QueryAppInfosWithPagerArgs = {
  pager: PagerInput;
  type: AppInfoType;
};


export type QueryAppMeetingCategoryPropsArgs = {
  cid: Scalars['String'];
};


export type QueryAppMeetingActivityPropsArgs = {
  aid: Scalars['String'];
};


export type QueryAppMeetingActivityTypesArgs = {
  mine?: Maybe<Scalars['Boolean']>;
  productType?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  meetingType?: Maybe<Scalars['String']>;
};


export type QueryQueryMeetingActivityByIdArgs = {
  id: Scalars['String'];
};


export type QueryFetchInvitationDataBySignatureArgs = {
  signature: Scalars['String'];
};


export type QueryUpappDocumentListArgs = {
  query?: Maybe<DocumentArchiveSearchParamsWithPager>;
};


export type QueryMeetingCategoriesArgs = {
  companyId?: Maybe<Scalars['String']>;
};


export type QueryMeetingActivitiyTypesArgs = {
  productType?: Maybe<Scalars['String']>;
  categoryCode?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};


export type QueryRestaurantSearchArgs = {
  search?: Maybe<RestaurantSearchInput>;
};


export type QueryHotelSearchArgs = {
  search?: Maybe<HotelSearchInput>;
};


export type QueryCarTypeSearchArgs = {
  city?: Maybe<Scalars['String']>;
};


export type QueryCarServiceSearchArgs = {
  carType: Scalars['String'];
};


export type QueryQueryHospitalsArgs = {
  request?: Maybe<HospitalPaginationRequest>;
};

/** doctor: 医生, propagator: 代表 */
export enum QcsfUserType {
  Doctor = 'DOCTOR',
  Propagator = 'PROPAGATOR'
}

export enum HadesReceiptType {
  Vouchaer = 'VOUCHAER',
  Tax = 'TAX',
  Invoice = 'INVOICE'
}

export enum HadesPaymentChannel {
  Qkk = 'QKK',
  Csy = 'CSY',
  Ssq = 'SSQ'
}

/** u平台会议首页业务状态 */
export enum UpMeetingHomeStatus {
  UHomeApplication = 'U_HOME_APPLICATION',
  UHomeApproving = 'U_HOME_APPROVING',
  UHomeQuotation = 'U_HOME_QUOTATION',
  UHomeProcessing = 'U_HOME_PROCESSING',
  UHomeSettlement = 'U_HOME_SETTLEMENT',
  UHomeWaitformyapproval = 'U_HOME_WAITFORMYAPPROVAL',
  UHomeMyapproved = 'U_HOME_MYAPPROVED',
  UHomeAll = 'U_HOME_ALL'
}

export enum AppInfoType {
  News = 'NEWS',
  Doc = 'DOC',
  Grc = 'GRC',
  Video = 'VIDEO'
}

export type Mutation = {
  __typename?: 'Mutation';
  login: Scalars['String'];
  sendVerifyCode: Scalars['String'];
  loginWithVerifyCode: Scalars['String'];
  register: Scalars['String'];
  simpleRegisterDoctor: Scalars['Boolean'];
  logout: Scalars['Boolean'];
  updateAccount: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  changeAccountDefaultChannel: Scalars['Boolean'];
  unionLogin?: Maybe<Scalars['JSON']>;
  uploadUserCertificationImages?: Maybe<BaseResponse>;
  registerPtxUser?: Maybe<BaseResponse>;
  saveArchive: Scalars['Boolean'];
  bindArchivesToMeeting: BaseResponse;
  unbindArchiveFromMeeting: BaseResponse;
  disableArchive?: Maybe<BaseResponse>;
  enableArchive?: Maybe<BaseResponse>;
  hengrui_logBalanceRecord: Scalars['Boolean'];
  bmsLogin: BmsLoginResponse;
  bmsCancelMeeting: Scalars['Boolean'];
  bmsMeetingCreate: MeetingInfo;
  bmsLoginWithVerifyCode: Scalars['String'];
  bmsAddAttenderH5: Scalars['Boolean'];
  addAttenders: Scalars['Boolean'];
  bmsUploadSignedPaper: Scalars['Boolean'];
  bmsUploadPO: Scalars['Boolean'];
  bmsMeetingOrderBatchSync: Array<MeetingOrderInfo>;
  bmsConfirmComplete: Scalars['Boolean'];
  bmsQuotationBatchSave: Scalars['Boolean'];
  bmsConfirmQuotations: Scalars['Boolean'];
  preImportHansohBudget?: Maybe<Scalars['JSON']>;
  importHansohBudget?: Maybe<BaseResponse>;
  preImportProcessBudget?: Maybe<Scalars['JSON']>;
  importProcessBudget?: Maybe<BaseResponse>;
  createContent: Scalars['Boolean'];
  updateContent: Scalars['Boolean'];
  deleteContent: Scalars['Boolean'];
  shareContent: Scalars['Boolean'];
  commentContent: Scalars['Boolean'];
  readContent: Scalars['Boolean'];
  likeContent: Scalars['Boolean'];
  dislikeContent: Scalars['Boolean'];
  upDoctorSendCode: Scalars['String'];
  upDoctorSendCodeByAttenderId: Scalars['String'];
  upDoctorLogin: BmsLoginResponse;
  upDoctorLoginWithCode: Scalars['String'];
  upDoctorLoginWithCodeAndAttenderId: Scalars['String'];
  doctorCompleteTask?: Maybe<BaseResponse>;
  removeDoctorTask?: Maybe<BaseResponse>;
  saveDocumentReviewContents?: Maybe<BaseResponse>;
  hengruiImage_sendCode: Scalars['String'];
  hengruiImage_login: BmsLoginResponse;
  hengruiImage_loginWithCode: Scalars['String'];
  hengruiImage_verifyDoctorBankInfo: Scalars['Boolean'];
  hengruiImage_completeDoctorCerts: Scalars['Boolean'];
  hengruiImage_batchMarkBillStatus?: Maybe<Scalars['String']>;
  hengruiImage_exportMeeting: Scalars['String'];
  hengruiImage_addTemporaryAttender: Scalars['Boolean'];
  hengruiImage_startHRMeetingSettlement?: Maybe<Scalars['Boolean']>;
  hengruiImage_updateMeeting?: Maybe<StandardMeetingInfo>;
  hengruiImage_createMeeting?: Maybe<StandardMeetingInfo>;
  hengruiImage_submitMeeting: Scalars['Boolean'];
  hengruiImage_confirmMeeting: Scalars['Boolean'];
  hengruiImage_retreatMeeting: Scalars['Boolean'];
  hengruiImage_deleteMeeting?: Maybe<BaseResponse>;
  hengruiImage_uploadSpeakerMaterials: Scalars['Boolean'];
  hengruiImage_uploadSpeakerJSONMaterials: Scalars['Boolean'];
  hengruiImage_uploadAttenderTaxReceipts: Scalars['Boolean'];
  hengruiImage_uploadAttenderPaymentVoucher: Scalars['Boolean'];
  hengruiImage_uploadAttenderInvoices: Scalars['Boolean'];
  hengruiImage_uploadSchedulePics: Scalars['Boolean'];
  hengruiImage_uploadMaterials: Scalars['Boolean'];
  hengruiImage_addDoctors: Scalars['Boolean'];
  hengruiImage_addDoctorsWithError: BaseResponse;
  hengruiImage_removeDoctors: Scalars['Boolean'];
  hengruiImage_removeSpeakers: Scalars['Boolean'];
  hengruiImage_updateAttenderInfo?: Maybe<BaseResponse>;
  hengruiImage_addCost: Scalars['Boolean'];
  hengruiImage_addCheckReport: Scalars['Boolean'];
  hengruiImage_setDoctorBeenInvited?: Maybe<Scalars['Boolean']>;
  hengruiImage_doctorReceiveInvitation?: Maybe<Scalars['Boolean']>;
  hengruiImage_doctorReceiveInvitationWithoutMeeting?: Maybe<Scalars['Boolean']>;
  hengruiImage_generateContract?: Maybe<Scalars['String']>;
  hengruiImage_generateJunziqianUrl?: Maybe<Scalars['String']>;
  hengruiImage_editDoctor: Scalars['Boolean'];
  hengruiImage_markHRMeetingPaymentFinished: Scalars['Boolean'];
  hengruiImage_getSettlementZipUrl?: Maybe<Scalars['String']>;
  sendTaskInvitationMessage?: Maybe<Scalars['String']>;
  createInterventionalProject?: Maybe<Scalars['String']>;
  updateInterventionalProject?: Maybe<BaseResponse>;
  submitProject?: Maybe<BaseResponse>;
  interventionalProjectAddDoctor?: Maybe<Scalars['Boolean']>;
  interventionalProjectEditDoctor?: Maybe<Scalars['Boolean']>;
  interventionalProjectSendCode: Scalars['String'];
  interventionalProjectLogin: Scalars['String'];
  interventionalProjectLoginWithPwd: Scalars['String'];
  addSchedulePicsToProject?: Maybe<BaseResponse>;
  removeInterventionalProject?: Maybe<BaseResponse>;
  approveInterventionalProject?: Maybe<BaseResponse>;
  retreatInterventionalProject?: Maybe<BaseResponse>;
  addParticipantsToProject?: Maybe<Array<Maybe<BaseResponse>>>;
  removeParticipant?: Maybe<BaseResponse>;
  addReviewToProjectParticipants?: Maybe<BaseResponse>;
  submitAllReviews?: Maybe<BaseResponse>;
  batchSyncPropagatorAndDoctors: Scalars['Boolean'];
  medicalLiteratureSendCode: Scalars['String'];
  medicalLiteratureLogin: Scalars['String'];
  medicalLiteratureDoctorSendCode: Scalars['String'];
  medicalLiteratureDoctorLogin: Scalars['String'];
  addDocument: BaseResponse;
  removeDocument: BaseResponse;
  submitReview?: Maybe<BaseResponse>;
  batchInviteDoctorsToReview?: Maybe<Array<BaseResponse>>;
  ratingReview?: Maybe<BaseResponse>;
  payReview?: Maybe<BaseResponse>;
  setReviewToSettlement?: Maybe<BaseResponse>;
  meetingCreate?: Maybe<MeetingInfo>;
  meetingEdit?: Maybe<MeetingInfo>;
  attenderBatchAdd: Array<MeetingUplatformAttender>;
  attenderRemove?: Maybe<Scalars['String']>;
  submitFiles: Scalars['Boolean'];
  meetingSubmit?: Maybe<Scalars['String']>;
  meetingApprovalNotify?: Maybe<Scalars['Boolean']>;
  meetingConfirm?: Maybe<Scalars['Boolean']>;
  meetingReject?: Maybe<Scalars['Boolean']>;
  meetingRetreat?: Maybe<Scalars['Boolean']>;
  meetingWithdraw?: Maybe<Scalars['Boolean']>;
  meetingBudgetCreate: MeetingUplatformBudget;
  meetingBudgetUpdate: Scalars['String'];
  meetingBudgetBatchSync: Array<MeetingUplatformBudget>;
  updateMeetingCategoryEnabled?: Maybe<BaseResponse>;
  updateMeetingActivityEnabled?: Maybe<BaseResponse>;
  createOrupdateBaseMeetingCategory?: Maybe<BaseResponse>;
  createOrupdateBaseMeetingActivity?: Maybe<BaseResponse>;
  createOrUpdateMeetingActivity?: Maybe<BaseResponse>;
  createMeetingActivityTemplate?: Maybe<BaseResponse>;
  meetingOrderBatchSync: Array<MeetingOrderInfo>;
  meetingOrderSave: MeetingOrderInfo;
  meetingOrderDelete: Scalars['Boolean'];
  meetingOrderAcquireQuotation: Scalars['Boolean'];
  meetingOrderRetreatQuotation: Scalars['Boolean'];
  meetingOrderSubmitQuotation: Scalars['Boolean'];
  meetingOrderSubmitQuotationOLd: Scalars['Boolean'];
  dangerAddBizOrderConfirm: Scalars['Boolean'];
  bizOrderConfirm: Scalars['Boolean'];
  quotationSave: Scalars['Boolean'];
  quotationDelete: Scalars['Boolean'];
  quotationBatchSave: Scalars['Boolean'];
  confirmQuotations: Scalars['Boolean'];
  procurementerApproveQuotation: Scalars['Boolean'];
  meetingScheduleCreate: MeetingUplatformSchedule;
  meetingScheduleUpdate: MeetingUplatformSchedule;
  meetingScheduleDelete: Scalars['Boolean'];
  meetingScheduleBatchSync: Array<MeetingUplatformSchedule>;
  settlementSave: Scalars['Boolean'];
  settlementDelete: Scalars['Boolean'];
  submitSettlement: Scalars['Boolean'];
  submitLivePhotos: Scalars['Boolean'];
  settlementBatchSave: Scalars['Boolean'];
  pingguang_sendCode: Scalars['String'];
  pingguang_login: Scalars['String'];
  pingguang_lectureLogin: Scalars['String'];
  pingguang_attenderLogin: Scalars['String'];
  pingguang_updateMeeting?: Maybe<StandardMeetingInfo>;
  pingguang_createMeeting?: Maybe<StandardMeetingInfo>;
  pingguang_approveMeeting: Scalars['Boolean'];
  pingguang_rejectMeeting: Scalars['Boolean'];
  sendAuthMessage: Scalars['Boolean'];
  normalSendCode?: Maybe<Scalars['String']>;
  normalValidateCode?: Maybe<Scalars['Boolean']>;
  preregisterPTXUser: BaseResponse;
  upadminEditOrAddDoctor: BaseResponse;
  upadminFinanceApprove: Scalars['Boolean'];
  upadminFinancePayToHZBank: BaseResponse;
  generateInvitationSignature?: Maybe<Scalars['String']>;
  yinglianLogin: BmsLoginResponse;
  yinglianRegister: BmsLoginResponse;
  yinglianCompleteUserInfo?: Maybe<AccountInfo>;
  yinglianSendActivitationMail: Scalars['Boolean'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  mobile: Scalars['String'];
};


export type MutationSendVerifyCodeArgs = {
  userType: QcsfUserType;
  stage: QcsfSendVerifyCodeStage;
  mobile: Scalars['String'];
};


export type MutationLoginWithVerifyCodeArgs = {
  userType: QcsfUserType;
  sessionId: Scalars['String'];
  verifyCode: Scalars['String'];
  mobile: Scalars['String'];
};


export type MutationRegisterArgs = {
  inviterId?: Maybe<Scalars['String']>;
  userType: QcsfUserType;
  userInfo: QcsfRegisterInput;
};


export type MutationSimpleRegisterDoctorArgs = {
  userInfo: QcsfRegisterInput;
};


export type MutationUpdateAccountArgs = {
  account: AccountBaseInfoInput;
};


export type MutationResetPasswordArgs = {
  companyName?: Maybe<Scalars['String']>;
  newPassword: Scalars['String'];
  sessionId: Scalars['String'];
  code: Scalars['String'];
  mobile: Scalars['String'];
};


export type MutationChangeAccountDefaultChannelArgs = {
  channel: Scalars['String'];
  accountId: Scalars['String'];
};


export type MutationUnionLoginArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationUploadUserCertificationImagesArgs = {
  doctorId: Scalars['String'];
  images: Array<Scalars['String']>;
  type: Scalars['String'];
};


export type MutationRegisterPtxUserArgs = {
  accountId: Scalars['String'];
};


export type MutationSaveArchiveArgs = {
  archiveInfo: ArchiveCreateParams;
};


export type MutationBindArchivesToMeetingArgs = {
  meetingId: Scalars['String'];
  archiveIds: Array<Scalars['String']>;
};


export type MutationUnbindArchiveFromMeetingArgs = {
  meetingId: Scalars['String'];
  archiveId: Scalars['String'];
};


export type MutationDisableArchiveArgs = {
  archiveId: Scalars['String'];
};


export type MutationEnableArchiveArgs = {
  archiveId: Scalars['String'];
};


export type MutationHengrui_LogBalanceRecordArgs = {
  companyId?: Maybe<Scalars['String']>;
  paymentTime: Scalars['String'];
  type: Scalars['String'];
  amount: Scalars['Float'];
  regionId: Scalars['String'];
};


export type MutationBmsLoginArgs = {
  password: Scalars['String'];
  mobile: Scalars['String'];
};


export type MutationBmsCancelMeetingArgs = {
  meetingId: Scalars['String'];
};


export type MutationBmsMeetingCreateArgs = {
  meetingInfo: BmsMeetingCreateOrUpdateInput;
};


export type MutationBmsLoginWithVerifyCodeArgs = {
  sessionId: Scalars['String'];
  code: Scalars['String'];
  mobile: Scalars['String'];
};


export type MutationBmsAddAttenderH5Args = {
  attender: AddAttendersInput;
  meetingId: Scalars['String'];
};


export type MutationAddAttendersArgs = {
  attenders: Array<AddAttendersInput>;
  meetingId: Scalars['String'];
};


export type MutationBmsUploadSignedPaperArgs = {
  url: Scalars['String'];
  meetingId: Scalars['String'];
};


export type MutationBmsUploadPoArgs = {
  url: Scalars['String'];
  meetingId: Scalars['String'];
};


export type MutationBmsMeetingOrderBatchSyncArgs = {
  orders: Array<CarTravelCreatInput>;
  budgetId: Scalars['String'];
  meetingId: Scalars['String'];
};


export type MutationBmsConfirmCompleteArgs = {
  meetingId: Scalars['String'];
};


export type MutationBmsQuotationBatchSaveArgs = {
  quotations: Array<MeetingQuotationInput>;
  meetingId: Scalars['String'];
};


export type MutationBmsConfirmQuotationsArgs = {
  meetingId: Scalars['String'];
};


export type MutationPreImportHansohBudgetArgs = {
  importId?: Maybe<Scalars['String']>;
  data: Array<BudgetImportData>;
};


export type MutationImportHansohBudgetArgs = {
  importId: Scalars['String'];
  data: Array<BudgetImportData>;
};


export type MutationPreImportProcessBudgetArgs = {
  importId?: Maybe<Scalars['String']>;
  data: Array<ProcessBudgetImportData>;
};


export type MutationImportProcessBudgetArgs = {
  importId: Scalars['String'];
  data: Array<ProcessBudgetImportData>;
};


export type MutationDeleteContentArgs = {
  id: Scalars['String'];
};


export type MutationShareContentArgs = {
  doctorId: Scalars['String'];
  contentId: Scalars['String'];
};


export type MutationCommentContentArgs = {
  openId?: Maybe<Scalars['String']>;
  commentorId?: Maybe<Scalars['String']>;
  comment: Scalars['String'];
  sharerId?: Maybe<Scalars['String']>;
  contentId: Scalars['String'];
};


export type MutationReadContentArgs = {
  doctorId?: Maybe<Scalars['String']>;
  openId?: Maybe<Scalars['String']>;
  sharerId?: Maybe<Scalars['String']>;
  contentId: Scalars['String'];
};


export type MutationLikeContentArgs = {
  doctorId?: Maybe<Scalars['String']>;
  openId?: Maybe<Scalars['String']>;
  sharerId?: Maybe<Scalars['String']>;
  contentId: Scalars['String'];
};


export type MutationDislikeContentArgs = {
  doctorId?: Maybe<Scalars['String']>;
  openId?: Maybe<Scalars['String']>;
  contentId: Scalars['String'];
};


export type MutationUpDoctorSendCodeArgs = {
  mobile: Scalars['String'];
};


export type MutationUpDoctorSendCodeByAttenderIdArgs = {
  attenderId: Scalars['String'];
};


export type MutationUpDoctorLoginArgs = {
  pwd: Scalars['String'];
  mobile: Scalars['String'];
};


export type MutationUpDoctorLoginWithCodeArgs = {
  sessionId: Scalars['String'];
  verifyCode: Scalars['String'];
  mobile: Scalars['String'];
};


export type MutationUpDoctorLoginWithCodeAndAttenderIdArgs = {
  sessionId: Scalars['String'];
  verifyCode: Scalars['String'];
  attenderId: Scalars['String'];
};


export type MutationDoctorCompleteTaskArgs = {
  answers: Scalars['String'];
  taskId: Scalars['String'];
};


export type MutationRemoveDoctorTaskArgs = {
  taskId: Scalars['String'];
};


export type MutationSaveDocumentReviewContentsArgs = {
  review: SubmitReviewInput;
  documentId: Scalars['String'];
  taskId: Scalars['String'];
};


export type MutationHengruiImage_SendCodeArgs = {
  mobile: Scalars['String'];
};


export type MutationHengruiImage_LoginArgs = {
  pwd: Scalars['String'];
  mobile: Scalars['String'];
};


export type MutationHengruiImage_LoginWithCodeArgs = {
  sessionId: Scalars['String'];
  verifyCode: Scalars['String'];
  mobile: Scalars['String'];
};


export type MutationHengruiImage_VerifyDoctorBankInfoArgs = {
  bankCardNo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  idCardNo: Scalars['String'];
};


export type MutationHengruiImage_CompleteDoctorCertsArgs = {
  meetingId: Scalars['String'];
  doctorInfo: DoctorCompleteCertInfo;
  doctorAccountId: Scalars['String'];
};


export type MutationHengruiImage_BatchMarkBillStatusArgs = {
  data: Array<StandardMeetingBillMarkStatus>;
};


export type MutationHengruiImage_ExportMeetingArgs = {
  pageQuery?: Maybe<PageSearchStandardMeetingInput>;
};


export type MutationHengruiImage_AddTemporaryAttenderArgs = {
  attender: AddAttenderInput;
};


export type MutationHengruiImage_StartHrMeetingSettlementArgs = {
  meetingId: Scalars['String'];
};


export type MutationHengruiImage_UpdateMeetingArgs = {
  meetingInfo: UpdateHengruiMeeting;
};


export type MutationHengruiImage_CreateMeetingArgs = {
  meetingInfo: CreateHengruiMeeting;
};


export type MutationHengruiImage_SubmitMeetingArgs = {
  meetingId: Scalars['String'];
};


export type MutationHengruiImage_ConfirmMeetingArgs = {
  reason?: Maybe<Scalars['String']>;
  meetingId: Scalars['String'];
};


export type MutationHengruiImage_RetreatMeetingArgs = {
  reason?: Maybe<Scalars['String']>;
  meetingId: Scalars['String'];
};


export type MutationHengruiImage_DeleteMeetingArgs = {
  meetingId: Scalars['String'];
};


export type MutationHengruiImage_UploadSpeakerMaterialsArgs = {
  materials: Array<Scalars['String']>;
  attenderId: Scalars['String'];
  meetingId: Scalars['String'];
};


export type MutationHengruiImage_UploadSpeakerJsonMaterialsArgs = {
  materials: Array<Scalars['JSON']>;
  attenderId: Scalars['String'];
  meetingId: Scalars['String'];
};


export type MutationHengruiImage_UploadAttenderTaxReceiptsArgs = {
  urls: Array<Scalars['String']>;
  attenderId: Scalars['String'];
  meetingId: Scalars['String'];
};


export type MutationHengruiImage_UploadAttenderPaymentVoucherArgs = {
  urls: Array<Scalars['String']>;
  attenderId: Scalars['String'];
  meetingId: Scalars['String'];
};


export type MutationHengruiImage_UploadAttenderInvoicesArgs = {
  urls: Array<Scalars['String']>;
  attenderId: Scalars['String'];
  meetingId: Scalars['String'];
};


export type MutationHengruiImage_UploadSchedulePicsArgs = {
  schedulePicUrls: Array<Scalars['String']>;
  meetingId: Scalars['String'];
};


export type MutationHengruiImage_UploadMaterialsArgs = {
  materialUrls: Array<Scalars['String']>;
  meetingId: Scalars['String'];
};


export type MutationHengruiImage_AddDoctorsArgs = {
  attenderType?: Maybe<StandardMeetingTypeAttenderType>;
  doctorInfo: HengruiMeetingSpeakerInput;
  meetingId: Scalars['String'];
};


export type MutationHengruiImage_AddDoctorsWithErrorArgs = {
  documentIds?: Maybe<Array<Scalars['String']>>;
  attenderType?: Maybe<StandardMeetingTypeAttenderType>;
  doctorInfo: HengruiMeetingSpeakerInput;
  meetingId: Scalars['String'];
};


export type MutationHengruiImage_RemoveDoctorsArgs = {
  attenderId: Scalars['String'];
  meetingId: Scalars['String'];
};


export type MutationHengruiImage_RemoveSpeakersArgs = {
  attenderId: Scalars['String'];
  meetingId: Scalars['String'];
};


export type MutationHengruiImage_UpdateAttenderInfoArgs = {
  increasingDocumentIds?: Maybe<Array<Scalars['String']>>;
  increasingAmount?: Maybe<Scalars['Int']>;
  templateCode: Scalars['String'];
  attenderId: Scalars['String'];
  doctorAccountId: Scalars['String'];
  meetingId: Scalars['String'];
};


export type MutationHengruiImage_AddCostArgs = {
  costs: Array<SaveStandardMeetingCostInput>;
  meetingId: Scalars['String'];
};


export type MutationHengruiImage_AddCheckReportArgs = {
  urls: Array<Scalars['String']>;
  meetingId: Scalars['String'];
};


export type MutationHengruiImage_SetDoctorBeenInvitedArgs = {
  doctorType: StandardMeetingTypeAttenderType;
  meetingId: Scalars['String'];
  doctorId: Scalars['String'];
};


export type MutationHengruiImage_DoctorReceiveInvitationArgs = {
  doctorType: StandardMeetingTypeAttenderType;
  meetingId: Scalars['String'];
  doctorId: Scalars['String'];
};


export type MutationHengruiImage_DoctorReceiveInvitationWithoutMeetingArgs = {
  doctorId: Scalars['String'];
};


export type MutationHengruiImage_GenerateContractArgs = {
  inviterId: Scalars['String'];
  meetingId: Scalars['String'];
  signerId: Scalars['String'];
};


export type MutationHengruiImage_GenerateJunziqianUrlArgs = {
  channel?: Maybe<MeetingSignChannel>;
  accountId: Scalars['String'];
};


export type MutationHengruiImage_EditDoctorArgs = {
  doctor: AddAttenderInput;
};


export type MutationHengruiImage_MarkHrMeetingPaymentFinishedArgs = {
  meetingId: Scalars['String'];
};


export type MutationHengruiImage_GetSettlementZipUrlArgs = {
  meetingId: Scalars['String'];
};


export type MutationSendTaskInvitationMessageArgs = {
  taskUrl: Scalars['String'];
  activityName: Scalars['String'];
  doctorAccountId: Scalars['String'];
};


export type MutationCreateInterventionalProjectArgs = {
  info: InterventionalProjectInfoInput;
  categoryId: Scalars['String'];
};


export type MutationUpdateInterventionalProjectArgs = {
  info: InterventionalProjectInfoInput;
};


export type MutationSubmitProjectArgs = {
  projectId: Scalars['String'];
};


export type MutationInterventionalProjectAddDoctorArgs = {
  doctor: AddAttenderInput;
};


export type MutationInterventionalProjectEditDoctorArgs = {
  doctor: AddAttenderInput;
};


export type MutationInterventionalProjectSendCodeArgs = {
  mobile: Scalars['String'];
};


export type MutationInterventionalProjectLoginArgs = {
  sessionId: Scalars['String'];
  code: Scalars['String'];
  mobile: Scalars['String'];
};


export type MutationInterventionalProjectLoginWithPwdArgs = {
  password: Scalars['String'];
  mobile: Scalars['String'];
};


export type MutationAddSchedulePicsToProjectArgs = {
  pics: Array<Scalars['String']>;
  projectId: Scalars['String'];
};


export type MutationRemoveInterventionalProjectArgs = {
  projectId: Scalars['String'];
};


export type MutationApproveInterventionalProjectArgs = {
  remark?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
};


export type MutationRetreatInterventionalProjectArgs = {
  reason: Scalars['String'];
  projectId: Scalars['String'];
};


export type MutationAddParticipantsToProjectArgs = {
  participants: Array<InterventionProjectParticipantInput>;
  projectId: Scalars['String'];
};


export type MutationRemoveParticipantArgs = {
  id: Scalars['String'];
};


export type MutationAddReviewToProjectParticipantsArgs = {
  review: ProjectReviewInput;
};


export type MutationSubmitAllReviewsArgs = {
  projectId: Scalars['String'];
};


export type MutationBatchSyncPropagatorAndDoctorsArgs = {
  infos: Array<BatchInfo>;
  companyId: Scalars['String'];
};


export type MutationMedicalLiteratureSendCodeArgs = {
  isDoctor?: Maybe<Scalars['Boolean']>;
  mobile: Scalars['String'];
};


export type MutationMedicalLiteratureLoginArgs = {
  sessionId: Scalars['String'];
  code: Scalars['String'];
  mobile: Scalars['String'];
};


export type MutationMedicalLiteratureDoctorSendCodeArgs = {
  mobile: Scalars['String'];
};


export type MutationMedicalLiteratureDoctorLoginArgs = {
  sessionId: Scalars['String'];
  code: Scalars['String'];
  mobile: Scalars['String'];
};


export type MutationAddDocumentArgs = {
  info: AddDocumentInput;
};


export type MutationRemoveDocumentArgs = {
  documentId: Scalars['String'];
};


export type MutationSubmitReviewArgs = {
  review: SubmitReviewInput;
};


export type MutationBatchInviteDoctorsToReviewArgs = {
  documentIds: Array<Scalars['String']>;
  doctorIds: Array<Scalars['String']>;
};


export type MutationRatingReviewArgs = {
  remark?: Maybe<Scalars['String']>;
  level: Scalars['Int'];
  reviewId: Scalars['String'];
};


export type MutationPayReviewArgs = {
  reviewId: Scalars['String'];
};


export type MutationSetReviewToSettlementArgs = {
  reviewId: Scalars['String'];
};


export type MutationMeetingCreateArgs = {
  meetingInfo: MeetingCreateOrUpdateInput;
};


export type MutationMeetingEditArgs = {
  meetingInfo: MeetingCreateOrUpdateInput;
};


export type MutationAttenderBatchAddArgs = {
  type: AttenderType;
  accountIds: Array<Scalars['String']>;
  meetingId: Scalars['String'];
};


export type MutationAttenderRemoveArgs = {
  attenderId: Scalars['String'];
};


export type MutationSubmitFilesArgs = {
  files: Scalars['JSON'];
  pptId: Scalars['String'];
  pptName: Scalars['String'];
  meetingId: Scalars['String'];
};


export type MutationMeetingSubmitArgs = {
  meetingId: Scalars['String'];
};


export type MutationMeetingApprovalNotifyArgs = {
  reason?: Maybe<Scalars['String']>;
  meetingId: Scalars['String'];
};


export type MutationMeetingConfirmArgs = {
  reason?: Maybe<Scalars['String']>;
  meetingId: Scalars['String'];
};


export type MutationMeetingRejectArgs = {
  reason?: Maybe<Scalars['String']>;
  meetingId: Scalars['String'];
};


export type MutationMeetingRetreatArgs = {
  reason?: Maybe<Scalars['String']>;
  meetingId: Scalars['String'];
};


export type MutationMeetingWithdrawArgs = {
  reason?: Maybe<Scalars['String']>;
  meetingId: Scalars['String'];
};


export type MutationMeetingBudgetCreateArgs = {
  budget: MeetingBudgetCreateInput;
};


export type MutationMeetingBudgetBatchSyncArgs = {
  budgets: Array<MeetingBudgetCreateInput>;
  meetingId: Scalars['String'];
};


export type MutationUpdateMeetingCategoryEnabledArgs = {
  enabled: Scalars['Boolean'];
  categoryId: Scalars['String'];
};


export type MutationUpdateMeetingActivityEnabledArgs = {
  enabled: Scalars['Boolean'];
  activityId: Scalars['String'];
};


export type MutationCreateOrupdateBaseMeetingCategoryArgs = {
  config: MeetingConfigAddOrUpdateInput;
};


export type MutationCreateOrupdateBaseMeetingActivityArgs = {
  config: MeetingConfigAddOrUpdateInput;
};


export type MutationCreateOrUpdateMeetingActivityArgs = {
  input: MeetingACtivityCreateOrUpdateInput;
};


export type MutationCreateMeetingActivityTemplateArgs = {
  template: TemplateInput;
};


export type MutationMeetingOrderBatchSyncArgs = {
  orders: Array<MeetingOrderInput>;
  budgetId: Scalars['String'];
};


export type MutationMeetingOrderSaveArgs = {
  order: MeetingOrderInput;
};


export type MutationMeetingOrderDeleteArgs = {
  orderId: Scalars['String'];
};


export type MutationMeetingOrderAcquireQuotationArgs = {
  meetingId: Scalars['String'];
};


export type MutationMeetingOrderRetreatQuotationArgs = {
  reason?: Maybe<Scalars['String']>;
  meetingId: Scalars['String'];
};


export type MutationMeetingOrderSubmitQuotationArgs = {
  meetingId: Scalars['String'];
};


export type MutationMeetingOrderSubmitQuotationOLdArgs = {
  meetingId: Scalars['String'];
};


export type MutationDangerAddBizOrderConfirmArgs = {
  type: UpMeetingBudgetType;
  orderId: Scalars['String'];
};


export type MutationBizOrderConfirmArgs = {
  orderType: UpMeetingBudgetType;
  content: ConfirmBizOrderContentInput;
  orderId: Scalars['String'];
};


export type MutationQuotationSaveArgs = {
  quotation: MeetingQuotationInput;
};


export type MutationQuotationDeleteArgs = {
  quotationId: Scalars['String'];
};


export type MutationQuotationBatchSaveArgs = {
  quotations: Array<MeetingQuotationInput>;
  meetingId: Scalars['String'];
};


export type MutationConfirmQuotationsArgs = {
  meetingId: Scalars['String'];
};


export type MutationProcurementerApproveQuotationArgs = {
  reason?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
  meetingId: Scalars['String'];
};


export type MutationMeetingScheduleCreateArgs = {
  schedule: MeetingScheduleInput;
};


export type MutationMeetingScheduleUpdateArgs = {
  schedule: MeetingScheduleInput;
};


export type MutationMeetingScheduleDeleteArgs = {
  scheduleId: Scalars['String'];
};


export type MutationMeetingScheduleBatchSyncArgs = {
  schedules: Array<MeetingScheduleInput>;
  meetingId: Scalars['String'];
};


export type MutationSettlementSaveArgs = {
  settlement: MeetingSettlementInput;
};


export type MutationSettlementDeleteArgs = {
  settlementId: Scalars['String'];
};


export type MutationSubmitSettlementArgs = {
  meetingId: Scalars['String'];
};


export type MutationSubmitLivePhotosArgs = {
  photos?: Maybe<Scalars['JSON']>;
  scheduleId: Scalars['String'];
  meetingId: Scalars['String'];
};


export type MutationSettlementBatchSaveArgs = {
  settlements: Array<MeetingSettlementInput>;
  meetingId: Scalars['String'];
};


export type MutationPingguang_SendCodeArgs = {
  stage: QcsfSendVerifyCodeStage;
  mobile: Scalars['String'];
};


export type MutationPingguang_LoginArgs = {
  sessionId: Scalars['String'];
  verifyCode: Scalars['String'];
  mobile: Scalars['String'];
};


export type MutationPingguang_LectureLoginArgs = {
  sessionId: Scalars['String'];
  verifyCode: Scalars['String'];
  mobile: Scalars['String'];
  meetingId: Scalars['String'];
};


export type MutationPingguang_AttenderLoginArgs = {
  titleId: Scalars['String'];
  departmentId: Scalars['String'];
  hospitalId: Scalars['String'];
  name: Scalars['String'];
  mobile: Scalars['String'];
};


export type MutationPingguang_UpdateMeetingArgs = {
  meeting: CreateStandardMeetingInput;
};


export type MutationPingguang_CreateMeetingArgs = {
  meeting: CreateStandardMeetingInput;
};


export type MutationPingguang_ApproveMeetingArgs = {
  reason?: Maybe<Scalars['String']>;
  meetingId: Scalars['String'];
};


export type MutationPingguang_RejectMeetingArgs = {
  reason?: Maybe<Scalars['String']>;
  meetingId: Scalars['String'];
};


export type MutationSendAuthMessageArgs = {
  doctorId: Scalars['String'];
};


export type MutationNormalSendCodeArgs = {
  mobile: Scalars['String'];
};


export type MutationNormalValidateCodeArgs = {
  code: Scalars['String'];
  sessionId: Scalars['String'];
  mobile: Scalars['String'];
};


export type MutationPreregisterPtxUserArgs = {
  idNo: Scalars['String'];
  attenderId: Scalars['String'];
};


export type MutationUpadminEditOrAddDoctorArgs = {
  doctor: EditDoctorInfo;
};


export type MutationUpadminFinanceApproveArgs = {
  reason?: Maybe<Scalars['String']>;
  result: FinanceApproveResult;
  meetingId: Scalars['String'];
};


export type MutationUpadminFinancePayToHzBankArgs = {
  meetingId: Scalars['String'];
  attenderId: Scalars['String'];
};


export type MutationGenerateInvitationSignatureArgs = {
  expire?: Maybe<Scalars['Int']>;
  snapshot?: Maybe<Scalars['JSON']>;
};


export type MutationYinglianLoginArgs = {
  password: Scalars['String'];
  mobile: Scalars['String'];
};


export type MutationYinglianRegisterArgs = {
  code: Scalars['String'];
  sessionId: Scalars['String'];
  password: Scalars['String'];
  mobile: Scalars['String'];
};


export type MutationYinglianCompleteUserInfoArgs = {
  regionCode: Scalars['String'];
  suCode?: Maybe<Scalars['String']>;
  buCode: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
  staffId: Scalars['String'];
  city: Scalars['String'];
};

/** login: 登录, register: 注册 */
export enum QcsfSendVerifyCodeStage {
  Login = 'LOGIN',
  Register = 'REGISTER'
}

export enum FinanceApproveResult {
  Reject = 'REJECT',
  Approved = 'APPROVED'
}

export type AccountFieldFragment = (
  { __typename?: 'AccountInfo' }
  & Pick<AccountInfo, 'accountId' | 'companyId' | 'staffId' | 'status'>
  & { name: AccountInfo['cnName'], mobile: AccountInfo['companyMobile'], email: AccountInfo['companyEmail'], cityName: AccountInfo['custom2'] }
  & { region?: Maybe<(
    { __typename?: 'AccountGroupInfo' }
    & Pick<AccountGroupInfo, 'name' | 'groupId'>
  )>, bu?: Maybe<(
    { __typename?: 'AccountGroupInfo' }
    & Pick<AccountGroupInfo, 'name' | 'groupId'>
  )>, su?: Maybe<(
    { __typename?: 'AccountGroupInfo' }
    & Pick<AccountGroupInfo, 'name' | 'groupId'>
  )>, city?: Maybe<(
    { __typename?: 'AccountGroupInfo' }
    & Pick<AccountGroupInfo, 'name' | 'groupId'>
  )> }
);

export type MenuFieldFragment = (
  { __typename?: 'AppMenu' }
  & Pick<AppMenu, 'id' | 'name' | 'url' | 'access' | 'orderNum' | 'code'>
);

export type UserProfileFragment = (
  { __typename?: 'AccountInfo' }
  & { roles: Array<(
    { __typename?: 'RoleInfo' }
    & Pick<RoleInfo, 'id' | 'name' | 'type' | 'enable'>
  )>, appMenus?: Maybe<Array<(
    { __typename?: 'AppMenu' }
    & MenuFieldFragment
  )>>, roleAuthorities?: Maybe<Array<(
    { __typename?: 'RoleAuthority' }
    & Pick<RoleAuthority, 'appId' | 'appName'>
  )>> }
  & AccountFieldFragment
);

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'AccountInfo' }
    & UserProfileFragment
  )> }
);

export type CompanyGroupsQueryVariables = Exact<{
  type: Scalars['String'];
}>;


export type CompanyGroupsQuery = (
  { __typename?: 'Query' }
  & { groups: Array<(
    { __typename?: 'AccountGroupInfo' }
    & Pick<AccountGroupInfo, 'groupId' | 'name'>
  )> }
);

export type SendVerifyCodeMutationVariables = Exact<{
  mobile: Scalars['String'];
}>;


export type SendVerifyCodeMutation = (
  { __typename?: 'Mutation' }
  & { sessionId: Mutation['sendVerifyCode'] }
);

export type UserLoginMutationVariables = Exact<{
  mobile: Scalars['String'];
  password: Scalars['String'];
}>;


export type UserLoginMutation = (
  { __typename?: 'Mutation' }
  & { loginResult: (
    { __typename?: 'BmsLoginResponse' }
    & Pick<BmsLoginResponse, 'token'>
    & { account: (
      { __typename?: 'AccountInfo' }
      & UserProfileFragment
    ) }
  ) }
);

export type UserRegisterMutationVariables = Exact<{
  mobile: Scalars['String'];
  password: Scalars['String'];
  code: Scalars['String'];
  sessionId: Scalars['String'];
}>;


export type UserRegisterMutation = (
  { __typename?: 'Mutation' }
  & { registerResult: (
    { __typename?: 'BmsLoginResponse' }
    & Pick<BmsLoginResponse, 'token'>
    & { account: (
      { __typename?: 'AccountInfo' }
      & UserProfileFragment
    ) }
  ) }
);

export type UserResetPasswordMutationVariables = Exact<{
  mobile: Scalars['String'];
  newPassword: Scalars['String'];
  sessionId: Scalars['String'];
  code: Scalars['String'];
}>;


export type UserResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPasswordResult: Mutation['resetPassword'] }
);

export type CompleteUserInfoMutationVariables = Exact<{
  regionCode: Scalars['String'];
  buCode: Scalars['String'];
  suCode?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  name: Scalars['String'];
  city: Scalars['String'];
  staffId: Scalars['String'];
}>;


export type CompleteUserInfoMutation = (
  { __typename?: 'Mutation' }
  & { updatedUser?: Maybe<(
    { __typename?: 'AccountInfo' }
    & UserProfileFragment
  )> }
);

export type SendActivitationMailMutationVariables = Exact<{ [key: string]: never; }>;


export type SendActivitationMailMutation = (
  { __typename?: 'Mutation' }
  & { result: Mutation['yinglianSendActivitationMail'] }
);

export const AccountFieldFragmentDoc = gql`
    fragment accountField on AccountInfo {
  name: cnName
  accountId
  companyId
  mobile: companyMobile
  email: companyEmail
  staffId
  status
  region: group(groupType: "costcenter") {
    name
    groupId
  }
  bu: group(groupType: "structure") {
    name
    groupId
  }
  su: group(groupType: "project") {
    name
    groupId
  }
  city: group(groupType: "city") {
    name
    groupId
  }
  cityName: custom2
}
    `;
export const MenuFieldFragmentDoc = gql`
    fragment menuField on AppMenu {
  id
  name
  url
  access
  orderNum
  code
}
    `;
export const UserProfileFragmentDoc = gql`
    fragment userProfile on AccountInfo {
  ...accountField
  roles {
    id
    name
    type
    enable
  }
  appMenus {
    ...menuField
  }
  roleAuthorities {
    appId
    appName
  }
}
    ${AccountFieldFragmentDoc}
${MenuFieldFragmentDoc}`;
export const CurrentUserDocument = gql`
    query CurrentUser {
  user: currentUser {
    ...userProfile
  }
}
    ${UserProfileFragmentDoc}`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const CompanyGroupsDocument = gql`
    query CompanyGroups($type: String!) {
  groups: queryCompanyGroup(type: $type) {
    groupId
    name
  }
}
    `;

/**
 * __useCompanyGroupsQuery__
 *
 * To run a query within a React component, call `useCompanyGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyGroupsQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCompanyGroupsQuery(baseOptions?: Apollo.QueryHookOptions<CompanyGroupsQuery, CompanyGroupsQueryVariables>) {
        return Apollo.useQuery<CompanyGroupsQuery, CompanyGroupsQueryVariables>(CompanyGroupsDocument, baseOptions);
      }
export function useCompanyGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyGroupsQuery, CompanyGroupsQueryVariables>) {
          return Apollo.useLazyQuery<CompanyGroupsQuery, CompanyGroupsQueryVariables>(CompanyGroupsDocument, baseOptions);
        }
export type CompanyGroupsQueryHookResult = ReturnType<typeof useCompanyGroupsQuery>;
export type CompanyGroupsLazyQueryHookResult = ReturnType<typeof useCompanyGroupsLazyQuery>;
export type CompanyGroupsQueryResult = Apollo.QueryResult<CompanyGroupsQuery, CompanyGroupsQueryVariables>;
export const SendVerifyCodeDocument = gql`
    mutation SendVerifyCode($mobile: String!) {
  sessionId: sendVerifyCode(stage: REGISTER, mobile: $mobile, userType: PROPAGATOR)
}
    `;
export type SendVerifyCodeMutationFn = Apollo.MutationFunction<SendVerifyCodeMutation, SendVerifyCodeMutationVariables>;

/**
 * __useSendVerifyCodeMutation__
 *
 * To run a mutation, you first call `useSendVerifyCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendVerifyCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendVerifyCodeMutation, { data, loading, error }] = useSendVerifyCodeMutation({
 *   variables: {
 *      mobile: // value for 'mobile'
 *   },
 * });
 */
export function useSendVerifyCodeMutation(baseOptions?: Apollo.MutationHookOptions<SendVerifyCodeMutation, SendVerifyCodeMutationVariables>) {
        return Apollo.useMutation<SendVerifyCodeMutation, SendVerifyCodeMutationVariables>(SendVerifyCodeDocument, baseOptions);
      }
export type SendVerifyCodeMutationHookResult = ReturnType<typeof useSendVerifyCodeMutation>;
export type SendVerifyCodeMutationResult = Apollo.MutationResult<SendVerifyCodeMutation>;
export type SendVerifyCodeMutationOptions = Apollo.BaseMutationOptions<SendVerifyCodeMutation, SendVerifyCodeMutationVariables>;
export const UserLoginDocument = gql`
    mutation UserLogin($mobile: String!, $password: String!) {
  loginResult: yinglianLogin(mobile: $mobile, password: $password) {
    account {
      ...userProfile
    }
    token
  }
}
    ${UserProfileFragmentDoc}`;
export type UserLoginMutationFn = Apollo.MutationFunction<UserLoginMutation, UserLoginMutationVariables>;

/**
 * __useUserLoginMutation__
 *
 * To run a mutation, you first call `useUserLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userLoginMutation, { data, loading, error }] = useUserLoginMutation({
 *   variables: {
 *      mobile: // value for 'mobile'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUserLoginMutation(baseOptions?: Apollo.MutationHookOptions<UserLoginMutation, UserLoginMutationVariables>) {
        return Apollo.useMutation<UserLoginMutation, UserLoginMutationVariables>(UserLoginDocument, baseOptions);
      }
export type UserLoginMutationHookResult = ReturnType<typeof useUserLoginMutation>;
export type UserLoginMutationResult = Apollo.MutationResult<UserLoginMutation>;
export type UserLoginMutationOptions = Apollo.BaseMutationOptions<UserLoginMutation, UserLoginMutationVariables>;
export const UserRegisterDocument = gql`
    mutation UserRegister($mobile: String!, $password: String!, $code: String!, $sessionId: String!) {
  registerResult: yinglianRegister(mobile: $mobile, password: $password, code: $code, sessionId: $sessionId) {
    account {
      ...userProfile
    }
    token
  }
}
    ${UserProfileFragmentDoc}`;
export type UserRegisterMutationFn = Apollo.MutationFunction<UserRegisterMutation, UserRegisterMutationVariables>;

/**
 * __useUserRegisterMutation__
 *
 * To run a mutation, you first call `useUserRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRegisterMutation, { data, loading, error }] = useUserRegisterMutation({
 *   variables: {
 *      mobile: // value for 'mobile'
 *      password: // value for 'password'
 *      code: // value for 'code'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useUserRegisterMutation(baseOptions?: Apollo.MutationHookOptions<UserRegisterMutation, UserRegisterMutationVariables>) {
        return Apollo.useMutation<UserRegisterMutation, UserRegisterMutationVariables>(UserRegisterDocument, baseOptions);
      }
export type UserRegisterMutationHookResult = ReturnType<typeof useUserRegisterMutation>;
export type UserRegisterMutationResult = Apollo.MutationResult<UserRegisterMutation>;
export type UserRegisterMutationOptions = Apollo.BaseMutationOptions<UserRegisterMutation, UserRegisterMutationVariables>;
export const UserResetPasswordDocument = gql`
    mutation userResetPassword($mobile: String!, $newPassword: String!, $sessionId: String!, $code: String!) {
  resetPasswordResult: resetPassword(mobile: $mobile, newPassword: $newPassword, sessionId: $sessionId, code: $code)
}
    `;
export type UserResetPasswordMutationFn = Apollo.MutationFunction<UserResetPasswordMutation, UserResetPasswordMutationVariables>;

/**
 * __useUserResetPasswordMutation__
 *
 * To run a mutation, you first call `useUserResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userResetPasswordMutation, { data, loading, error }] = useUserResetPasswordMutation({
 *   variables: {
 *      mobile: // value for 'mobile'
 *      newPassword: // value for 'newPassword'
 *      sessionId: // value for 'sessionId'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useUserResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UserResetPasswordMutation, UserResetPasswordMutationVariables>) {
        return Apollo.useMutation<UserResetPasswordMutation, UserResetPasswordMutationVariables>(UserResetPasswordDocument, baseOptions);
      }
export type UserResetPasswordMutationHookResult = ReturnType<typeof useUserResetPasswordMutation>;
export type UserResetPasswordMutationResult = Apollo.MutationResult<UserResetPasswordMutation>;
export type UserResetPasswordMutationOptions = Apollo.BaseMutationOptions<UserResetPasswordMutation, UserResetPasswordMutationVariables>;
export const CompleteUserInfoDocument = gql`
    mutation CompleteUserInfo($regionCode: String!, $buCode: String!, $suCode: String, $email: String!, $name: String!, $city: String!, $staffId: String!) {
  updatedUser: yinglianCompleteUserInfo(regionCode: $regionCode, suCode: $suCode, buCode: $buCode, email: $email, city: $city, name: $name, staffId: $staffId) {
    ...userProfile
  }
}
    ${UserProfileFragmentDoc}`;
export type CompleteUserInfoMutationFn = Apollo.MutationFunction<CompleteUserInfoMutation, CompleteUserInfoMutationVariables>;

/**
 * __useCompleteUserInfoMutation__
 *
 * To run a mutation, you first call `useCompleteUserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteUserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeUserInfoMutation, { data, loading, error }] = useCompleteUserInfoMutation({
 *   variables: {
 *      regionCode: // value for 'regionCode'
 *      buCode: // value for 'buCode'
 *      suCode: // value for 'suCode'
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      city: // value for 'city'
 *      staffId: // value for 'staffId'
 *   },
 * });
 */
export function useCompleteUserInfoMutation(baseOptions?: Apollo.MutationHookOptions<CompleteUserInfoMutation, CompleteUserInfoMutationVariables>) {
        return Apollo.useMutation<CompleteUserInfoMutation, CompleteUserInfoMutationVariables>(CompleteUserInfoDocument, baseOptions);
      }
export type CompleteUserInfoMutationHookResult = ReturnType<typeof useCompleteUserInfoMutation>;
export type CompleteUserInfoMutationResult = Apollo.MutationResult<CompleteUserInfoMutation>;
export type CompleteUserInfoMutationOptions = Apollo.BaseMutationOptions<CompleteUserInfoMutation, CompleteUserInfoMutationVariables>;
export const SendActivitationMailDocument = gql`
    mutation SendActivitationMail {
  result: yinglianSendActivitationMail
}
    `;
export type SendActivitationMailMutationFn = Apollo.MutationFunction<SendActivitationMailMutation, SendActivitationMailMutationVariables>;

/**
 * __useSendActivitationMailMutation__
 *
 * To run a mutation, you first call `useSendActivitationMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendActivitationMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendActivitationMailMutation, { data, loading, error }] = useSendActivitationMailMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendActivitationMailMutation(baseOptions?: Apollo.MutationHookOptions<SendActivitationMailMutation, SendActivitationMailMutationVariables>) {
        return Apollo.useMutation<SendActivitationMailMutation, SendActivitationMailMutationVariables>(SendActivitationMailDocument, baseOptions);
      }
export type SendActivitationMailMutationHookResult = ReturnType<typeof useSendActivitationMailMutation>;
export type SendActivitationMailMutationResult = Apollo.MutationResult<SendActivitationMailMutation>;
export type SendActivitationMailMutationOptions = Apollo.BaseMutationOptions<SendActivitationMailMutation, SendActivitationMailMutationVariables>;