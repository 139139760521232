import Axios from 'axios'

export const axios = Axios.create({
  baseURL: process.env.UAPI_URI
})

axios.interceptors.request.use(
  (config) => {
    return config
  },
  (err) => {
    console.log(err)
    return Promise.reject(err)
  }
)

axios.interceptors.response.use(
  (config) => {
    if (config.status === 401) {
      return config
    } else if (config.status >= 400) {
      return config
    } else if (!config.data.success) {
      return config
    }
    return config
  },
  (err) => {
    console.log(err.code)
    return Promise.reject(err)
  }
)
