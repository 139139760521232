import { AxiosRequestConfig } from 'axios'
import makeUseApi from '../utils/makeUseApi'
// 发票列表

export const InvoiceList: AxiosRequestConfig = {
  url: '/aimee/tool/getCompanyInvoiceList',
  method: 'GET'
}
export const useInvoiceList = makeUseApi<
  null,
  Definitions.Aimee.RestResponseOfListOfClientInvoiceResult
>(InvoiceList)
