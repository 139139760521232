import React, { FC } from 'react'
import { ItemCell } from '../../components/Cell'
import Text from '../../components/Text'
import { RateItem } from '../../components/FormItems/Rate'
import { ColumnView } from '../../components/QCFlex'
const ServiceEvaluationCard: FC<QCOrderInfo> = ({ rating }) => {
  const showServiceEvaluationCard = !!rating
  return showServiceEvaluationCard ? (
    <ColumnView className="qc-section-card">
      <ItemCell
        label={
          <Text color="#999999FF" size={34}>
            服务评价
          </Text>
        }
        value={rating?.rating_time || ''}
        arrow={false}
      />
      {rating?.list?.map((item: any) => (
        <RateItem key={item.title} label={item.title} value={item.score} />
      ))}
      <Text>{rating.reply_content}</Text>
    </ColumnView>
  ) : (
    <></>
  )
}

export default ServiceEvaluationCard
