import React, {
  FC,
  useCallback,
  useState,
  memo,
  useMemo,
  useEffect
} from 'react'
import Icon from '../../components/Icon'
import Text from '../../components/Text'
import {
  filterSearchBar,
  text,
  filterItem,
  icon,
  filterActive
} from './index.less'
import { RowView, XCenterView } from '../../components/QCFlex'
import { useClassNames } from '../../hooks/classname'
import FoodTypeDrawer from './FoodTypeDrawer'
import SortTypeDrawer from './SortTypeDrawer'
import HospitalSearchDrawer from './HospitalSearchDrawer'
import { usePagePickerRouter } from '../../hooks/pagePickerRouter'
import { useCurrentAmapGeo } from '../../hooks/geolocation'
import { useGlobalStores } from '../../hooks/store'
import { useCacheState } from '../../hooks/common'
import {
  FilterItemProps,
  IFormatParams,
  IAddress,
  SearchFilterProps,
  SearchFilterParams,
  TToggleDrawerValue
} from './interface'
const FilterItem = memo(
  <TValue extends Record<string, any> | string>({
    value,
    placeholder,
    onClick,
    flod
  }: FilterItemProps<TValue>) => {
    const mergedClassName = useClassNames([
      filterItem,
      flod ? filterActive : ''
    ])
    const handleClick = useCallback(() => {
      onClick && onClick()
    }, [onClick])
    return (
      <XCenterView onClick={handleClick} className={mergedClassName}>
        <Text
          className={text}
          color={value ? '#181818FF' : '#999999FF'}
          size={28}
        >
          {value || placeholder}
        </Text>
        <Icon
          className={icon}
          name="iconxialajiantou"
          size={12}
          color="#999999FF"
        />
      </XCenterView>
    )
  }
)
FilterItem.displayName = 'MFilterItem'
const SORT_LIST = [
  {
    title: '默认排序',
    value: '1'
  },
  {
    title: '配送费最低',
    value: '2'
  },
  {
    title: '起送价最低',
    value: '3'
  }
]
const SearchFilter: FC<SearchFilterProps> = ({
  onChange,
  defaultValue = {},
  filterRef,
  cateList
}) => {
  const { formStore, takeawaySearchStore } = useGlobalStores()
  // 进图默认浏览器定位
  const regeoCode = useCurrentAmapGeo((msg) => console.error(msg))

  const [regeoCodePoiLng, regeoCodePoiLat] =
    regeoCode?.addressComponent?.streetNumber?.location?.split(',') || []
  const [params, updateParams, , cacheParams] = useCacheState<
    SearchFilterParams
  >({
    initialState: defaultValue,
    manual: true,
    cacheKey: 'takeaway-search-filter'
  })
  const [drawerName, updateDrawerName] = useState<string>('')

  // 跨页面选地址相关
  const handleAddressPickerPop = (item: Definitions.Europe.DeliveryAddress) => {
    const address = {
      address: item?.landmark?.title,
      address_id: item.address_id,
      lat: item?.landmark?.location?.lat,
      lng: item?.landmark?.location?.lng
    }
    // 选择医院的话删除store缓存的配送地址
    // 选择地址的话保存到store里面
    takeawaySearchStore.address = address
    // 选择配送地址需要把医院、菜品置空
    updateParams({
      address
    })
  }
  const pagePickerRouter = usePagePickerRouter({
    onPop: handleAddressPickerPop
  })
  const handleAddressFilterClick = () => {
    formStore.data = params
    pagePickerRouter.push('/shippingAddressList')
  }
  // 折叠选项选中change
  const handleDrawerSelectChange = (filter: keyof SearchFilterParams) => (
    value?: TToggleDrawerValue
  ) => {
    const mergedParams: SearchFilterParams = params
    if (filter === 'hospital') {
      // 选择医院的话删除store缓存的配送地址
      // 选择地址的话保存到store里面
      takeawaySearchStore.address = null
      // 选择医院的话要同时更新address
      mergedParams.address = {
        address: (value as Definitions.Europe.Hospital)?.address,
        lat: (value as Definitions.Europe.Hospital)?.location?.lat?.toString(),
        lng: (value as Definitions.Europe.Hospital)?.location?.lng?.toString()
      }
      mergedParams.hospital = value as Definitions.Europe.Hospital
    } else {
      mergedParams[filter] = value as any
    }
    if (filter === 'address' || filter === 'hospital') {
      // 医院或地址清空菜品
      mergedParams.category = undefined
    }
    value && updateParams(mergedParams)
  }
  // 展示折叠选项
  const toggleDrawer = (filter: keyof SearchFilterParams) => {
    // 如果toggle的filter和当前name一致就判断为关闭drawer
    updateDrawerName((name) => (name === filter ? '' : filter))
  }
  useEffect(() => {
    const popParams: IFormatParams = {
      address: {
        address: params.address?.address,
        lat: params.address?.lat,
        lng: params.address?.lng,
        hospitalId: params.hospital?.hospitalId?.toString()
      },
      category: params.category,
      sort: params.sort
    }
    onChange && onChange(popParams)
  }, [JSON.stringify(params)])
  useEffect(() => {
    // 根据regeoCodePoiLat是否有值来判断是否取到浏览器定位
    // 根据当前是否有lat来判断是否选择过address 或者 hospital
    if (regeoCodePoiLat && !params.address?.lat) {
      // 获取到浏览器定位且没有选择过地址
      const regeoParams: SearchFilterParams = {
        address: {
          lat: regeoCodePoiLat,
          lng: regeoCodePoiLng,
          address: regeoCode?.formatted_address
        }
      }
      updateParams((prevParams) => {
        return Object.assign({}, prevParams, regeoParams)
      })
    }
  }, [regeoCodePoiLat])
  const isFoodFlod = useMemo(() => drawerName === 'category', [drawerName])
  const isSortFlod = useMemo(() => drawerName === 'sort', [drawerName])
  const isHospitalSearchFlod = useMemo(() => drawerName === 'hospital', [
    drawerName
  ])
  const selectedSort = SORT_LIST.find((item) => item.value === params.sort)
  // 暴露出去个cache方法 方便缓存state
  useEffect(() => {
    filterRef && filterRef({ addCache: cacheParams })
  }, [])
  return (
    <>
      <RowView hAlign="between" className={filterSearchBar}>
        <FilterItem
          onClick={handleAddressFilterClick}
          placeholder="配送地址"
          value={params.address?.address || ''}
        />
        <FilterItem
          value={params.hospital?.hospitalName}
          placeholder="医院附近"
          onClick={() => toggleDrawer('hospital')}
        />
        <FilterItem
          value={params.category}
          flod={isFoodFlod}
          placeholder="全部菜系"
          onClick={() => toggleDrawer('category')}
        />
        <FilterItem
          value={selectedSort?.title}
          flod={isSortFlod}
          placeholder="默认排序"
          onClick={() => toggleDrawer('sort')}
        />
      </RowView>
      <FoodTypeDrawer
        cateList={cateList}
        open={isFoodFlod}
        onOpenChange={() => toggleDrawer('category')}
        onChange={handleDrawerSelectChange('category')}
      />
      <SortTypeDrawer
        sortList={SORT_LIST}
        open={isSortFlod}
        onOpenChange={() => toggleDrawer('sort')}
        onChange={handleDrawerSelectChange('sort')}
      />
      <HospitalSearchDrawer
        open={isHospitalSearchFlod}
        onOpenChange={() => toggleDrawer('hospital')}
        onChange={handleDrawerSelectChange('hospital')}
      />
    </>
  )
}

export default SearchFilter
