import { IRestaurantInfo } from '../../components/RestaurantInfoCard/CardB'
export const formatDetail = (
  detail: Definitions.Agalia.UnsignedRestaurantDetail
): IRestaurantInfo => ({
  storeName: detail.name,
  address: detail.address,
  rating: detail.rating,
  indoor: detail.indoorUrls,
  cookStyle: detail.category,
  area: detail.district,
  consume: detail.price ? Number(detail.price) : undefined,
  outdoor: detail.outdoorUrl,
  phones: [detail.phone || ''],
  businessHours: detail.openingTime,
  location: {
    lat: detail.latitude,
    lng: detail.longitude
  }
})
