import React, { FC } from 'react'

import { STY_CUSTOMER_PHONE } from '../../constants/other'
import { ItemCell } from '../../components/Cell'
import { ColumnView } from '../../components/QCFlex'

const ConsumerCard: FC<QCOrderInfo> = ({ order_state }) => {
  const showConsumerCard = [1, 2].includes(order_state)
  return showConsumerCard ? (
    <ColumnView className="qc-section-card">
      <a href={`tel:${STY_CUSTOMER_PHONE}`}>
        <ItemCell label="修改订单，联系客服" />
      </a>
    </ColumnView>
  ) : (
    <></>
  )
}

export default ConsumerCard
