import React, { memo, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useHistory, Link } from 'react-router-dom'
import { InputItem, Flex, Toast, WhiteSpace } from 'antd-mobile'
import {
  loginBox,
  submitButton,
  control as controlStyle,
  right as alignRight,
  resetPassBtn
} from './index.less'
import UserActionTab from '../../components/UserActionTab'
import Button from '../../components/Button'
import Page from '../../components/Layout/Page'
import { useClassNames } from '../../hooks/classname'
import { useCondition } from '../../hooks/common'
import { AccountStatus, useUserLoginMutation } from '../../generated/graphql'
import { useGlobalStores } from '../../hooks/store'
import { useToastFail } from '../../hooks/toast'
import {
  COMPANY_THEME_TXTCOLOR,
  COMPANY_THEME_COLOR
} from '../../constants/app'
import FixedCustomerBtn from '../../components/FixedCustomerBtn'
import { useQueryAuthorType } from '../../api/Author'

interface LoginParams {
  mobile: string
  password: string
}

const CustomerBtn = memo(FixedCustomerBtn)
const Login: QCPage = () => {
  const history = useHistory()

  const controlAlignRight = useClassNames([controlStyle, alignRight])
  const [login, { data: loginData, error: loginErr }] = useUserLoginMutation()
  // 下单
  const [authorRes, authorType] = useQueryAuthorType({
    data: {
      returnUrl: ''
    }
  })
  const { handleSubmit, control, errors } = useForm<LoginParams>()
  const { userStore } = useGlobalStores()

  useCondition(!!loginData?.loginResult, () => {
    userStore.login(
      loginData!.loginResult.token,
      loginData!.loginResult.account
    )
  })

  useToastFail(loginErr?.message)
  useEffect(() => {
    const sdata = authorRes?.data?.data
    if (authorRes?.data?.status == 0) {
      if (sdata?.state == 'SUCCESS') history.replace(
        loginData?.loginResult.account.status === AccountStatus.AccountInactive
          ? '/completeUserInfo'
          : '/'
      );
      else history.replace('/BindEntyCode');
    }

  }, [authorRes?.data])
  const onSubmit = (data: LoginParams) => {
    login({
      variables: data
    })
  }
  return (
    <Page>
      <UserActionTab currentActive="login" />
      <form className={loginBox} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="mobile"
          rules={{ required: { message: '必填', value: true } }}
          defaultValue=""
          render={(props) => (
            <InputItem
              className={controlAlignRight}
              placeholder="请输入手机号"
              {...props}
              error={!!errors.mobile}
              onErrorClick={() => {
                Toast.show(errors.mobile?.message)
              }}
            >
              账号
            </InputItem>
          )}
        />

        <Controller
          control={control}
          name="password"
          rules={{ required: true }}
          defaultValue=""
          render={(props) => (
            <InputItem
              className={controlAlignRight}
              placeholder="请输入密码"
              type="password"
              error={!!errors.password}
              {...props}
            >
              密码
            </InputItem>
          )}
        />

        <WhiteSpace size="lg" />
        <WhiteSpace size="lg" />

        <Flex
          alignContent="center"
          align="center"
          justify="center"
          direction="row"
        >
          <Button type="submit" className={submitButton}>
            下一步
          </Button>
        </Flex>
      </form>

      <Link to="/resetPassword" className={resetPassBtn}>
        忘记密码
      </Link>

      <CustomerBtn />
    </Page>
  )
}

Login.config = {
  title: '登录',
  noBack: true,
  titlebarBgColor: COMPANY_THEME_COLOR,
  titlebarTextColor: COMPANY_THEME_TXTCOLOR,
  pageBgColor: COMPANY_THEME_COLOR
}

export default Login
