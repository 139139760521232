import React, { FC, useMemo, useState, useCallback, memo } from 'react'
import { Drawer } from 'antd-mobile'
import { ColumnView, RowView } from '../../components/QCFlex'
import { SearchBar } from '../../components/SearchBar'
import { useHospitalSearch } from '../../api/Hospital'
import { useGlobalStores } from '../../hooks/store'
import AutoListView, {
  IAutoListViewFormatFn
} from '../../components/AutoListView'
import { hospitalDrawerContent, tip, hospital } from './index.less'
import {
  HospitalItemProps,
  HospitalSearchProps,
  HospitalSearchDrawerProps
} from './interface'
const Item: FC<HospitalItemProps> = ({ name, onClick }) => {
  const handleClick = useCallback(() => {
    onClick && onClick(name)
  }, [])
  return (
    <RowView className={hospital} onClick={handleClick}>
      {name}
    </RowView>
  )
}
const PAGE_SIZE = 20
const formatRes: IAutoListViewFormatFn<
  Definitions.Europe.Hospital,
  Definitions.Europe.搜索医院返回值
> = ({ hospitals }) => ({
  list: hospitals,
  nomore: hospitals?.length < PAGE_SIZE
})
const itemKeyExtractor = (item: Definitions.Europe.Hospital) =>
  (item.hospitalId || item.hospitalName || '').toString()
const HospitalSearch: FC<HospitalSearchProps> = ({
  onCancel,
  onChoose,
  autoFocus
}) => {
  const { cityStore } = useGlobalStores()
  const cityId = cityStore?.info?.id
  const [keyword, updateKeyword] = useState<string>('')
  const tipText = useMemo(
    () => (keyword ? '选择以下医院，搜索医院附近的酒店' : ''),
    [keyword]
  )
  const handleChange = useCallback((text) => {
    updateKeyword(text)
  }, [])
  const handleItemClick = (hospital: Definitions.Europe.Hospital) => {
    onChoose && onChoose(hospital)
  }
  const renderHospitalItem = (item: Definitions.Europe.Hospital) => {
    return (
      <Item name={item.hospitalName} onClick={() => handleItemClick(item)} />
    )
  }
  const params = {
    keywords: keyword,
    city: cityId
  }
  return (
    <ColumnView className={hospitalDrawerContent}>
      <SearchBar
        autoFocus={autoFocus}
        placeholder="请输入医院名称"
        onChange={handleChange}
        onCancel={onCancel}
      />
      {tipText && (
        <RowView className={tip}>
          {tipText}-{keyword}
        </RowView>
      )}
      {keyword && (
        <AutoListView
          params={params}
          pageSize={PAGE_SIZE}
          renderItem={renderHospitalItem}
          format={formatRes}
          useService={useHospitalSearch}
          itemKeyExtractor={itemKeyExtractor}
        />
      )}
    </ColumnView>
  )
}
const HospitalSearchDrawer: FC<HospitalSearchDrawerProps> = ({
  open,
  onChange,
  onOpenChange
}) => {
  // hack fadeIn的动画
  const fakeStyle = useMemo(
    () => ({
      zIndex: open ? 1 : -1,
      ...(!open && { transitionProperty: 'z-index', transitionDelay: '0.4s' })
    }),
    [open]
  )
  const handleChoose = (item?: Definitions.Europe.Hospital) => {
    onChange && onChange(item)
    onOpenChange && onOpenChange(false)
  }
  return (
    <Drawer
      open={open}
      // hack fadeIn的动画
      style={fakeStyle}
      position="right"
      {...(!open && { style: { zIndex: -1 } })}
      enableDragHandle
      // 简单关闭drawer不需要传选中值
      onOpenChange={onOpenChange}
      sidebar={
        <HospitalSearch
          onCancel={handleChoose}
          onChoose={handleChoose}
          autoFocus={open}
        />
      }
    >
      <></>
    </Drawer>
  )
}

export default memo(HospitalSearchDrawer)
