import { Icon } from 'antd-mobile'
import React, { FC } from 'react'
import { button, icon } from './index.less'

const Button: FC<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > & {
    size?: 'small' | 'large'
    loading?: boolean
  }
> = ({ children, className, size, loading, ...props }) => {
  let buttonClassName = [button, size]
  if (className) {
    if (Array.isArray(className)) {
      buttonClassName = buttonClassName.concat(className)
    } else {
      buttonClassName.push(className)
    }
  }
  return (
    <button className={buttonClassName.join(' ')} {...props}>
      {loading && <Icon type="loading" color="#fff" className={icon} />}
      {children}
    </button>
  )
}

export default Button
