import React, { FC, useEffect, useState } from 'react'
import {
  useForm,
  Controller,
  ControllerProps,
  FieldErrors
} from 'react-hook-form'
import {
  InputItem,
  Flex,
  Toast,
  WhiteSpace,
  Checkbox,
  WingBlank
} from 'antd-mobile'
import { useHistory } from 'react-router-dom'
import {
  loginBox,
  submitButton,
  control as controlStyle,
  right as alignRight
} from '../Login/index.less'
import Button from '../../components/Button'
import Page from '../../components/Layout/Page'
import { useClassNames } from '../../hooks/classname'
import GroupPicker from '../../components/GroupPicker'
import {
  useCompleteUserInfoMutation,
  useSendActivitationMailMutation
} from '../../generated/graphql'
import { useCondition, useMount } from '../../hooks/common'
import { useToastFail, useToastLoading } from '../../hooks/toast'
import CitySelector from '../../components/CitySelector'
import { useGlobalStores } from '../../hooks/store'
import RuleModal from './RuleModal'
import { observer } from 'mobx-react-lite'
import hoistNonReactStatics from 'hoist-non-react-statics'

interface CompleteUserInfoParams {
  buCode: string
  suCode: string
  regionCode: string
  city: string
  staffId: string
  email: string
  name: string
}

type renderProps = Parameters<NonNullable<ControllerProps<any, any>['render']>>

const fileds: {
  name: keyof CompleteUserInfoParams
  label: string
  placeholder?: string
  render?: (props: renderProps[0], error: boolean) => React.ReactElement
  rules?: ControllerProps<any, any>['rules']
}[] = [
  {
    name: 'buCode',
    label: '部门',
    rules: { required: { message: '部门必填', value: true } },
    render: (props, error) => {
      return (
        <GroupPicker
          cols={1}
          label="部门"
          error={error}
          extra={'请选择部门'}
          {...props}
        />
      )
    }
  },
  {
    name: 'regionCode',
    label: '区域',
    rules: { required: { message: '区域必填', value: true } },
    render: (props, error) => {
      return (
        <GroupPicker
          cols={1}
          error={error}
          label="区域"
          extra={'请选择区域'}
          type={'costcenter'}
          {...props}
        />
      )
    }
  },
  // {
  //   name: 'suCode',
  //   label: 'SUCode',
  //   render: (props, error) => {
  //     return (
  //       <GroupPicker
  //         cols={1}
  //         error={error}
  //         label="SUCode"
  //         extra={'请选择SUCode'}
  //         type={'project'}
  //         {...props}
  //       />
  //     )
  //   }
  // },
  {
    name: 'city',
    label: '城市',
    rules: { required: { message: '城市必填', value: true } },
    placeholder: '请选择您所在的城市',
    render: (props, error) => {
      return <CitySelector {...props} error={error} />
    }
  },
  {
    name: 'staffId',
    label: '工号',
    rules: {
      required: { message: '工号必填', value: true },
      maxLength: { message: '工号最长不可超过25个字符', value: 25 }
    },
    placeholder: '请输入您的企业工号'
  },
  {
    name: 'email',
    label: '邮箱',
    rules: {
      required: { message: '邮箱必填', value: true },
      pattern: {
        message: '请输入珠海英联企业邮箱',
        value: /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@(nuancepharma\.cn|jlylsw\.cn|quancheng-ec\.com)$/
      }
    },
    placeholder: '请输入您的企业邮箱'
  },
  {
    name: 'name',
    label: '姓名',
    rules: {
      required: { message: '姓名必填', value: true },
      maxLength: { message: '姓名最长不可超过25个字符', value: 25 }
    },
    placeholder: '请输入您的姓名'
  }
]

const CompleteUserInfo: QCPage = () => {
  const router = useHistory()
  const { userStore } = useGlobalStores()
  const { handleSubmit, control, errors } = useForm<CompleteUserInfoParams>({})
  const [isConfirmed, setConfirmed] = useState(false)
  const [showRuleModal, setShowRuleModal] = useState(false)
  const controlAlignRight = useClassNames([controlStyle, alignRight])
  const [completeMutation, { data, error }] = useCompleteUserInfoMutation()
  const [sendActivitationMail] = useSendActivitationMailMutation()

  const onSubmit = (data: CompleteUserInfoParams) => {
    completeMutation({
      variables: data
    })
  }

  const onInvalid = (err: FieldErrors<CompleteUserInfoParams> | undefined) => {
    if (!err) return
    for (const [, errObj] of Object.entries(err)) {
      if (errObj?.message) {
        Toast.fail(errObj.message, undefined, undefined, false)
        break
      }
    }
  }

  useToastFail(error?.message)

  useToastLoading(!userStore.profile)

  useCondition(!!data?.updatedUser, () => {
    userStore.profile = data?.updatedUser
    sendActivitationMail()
    router.replace('/registerSuccess')
  })

  return (
    <Page style={{ marginTop: 0 }}>
      <form className={loginBox} onSubmit={handleSubmit(onSubmit, onInvalid)}>
        {fileds.map((field) => {
          return (
            <Controller
              key={field.name}
              control={control}
              name={field.name}
              defaultValue={''}
              rules={field.rules}
              render={(props) =>
                field.render ? (
                  field.render(props, !!errors[field.name])
                ) : (
                  <InputItem
                    className={controlAlignRight}
                    placeholder={field.placeholder || `请选择${field.label}`}
                    {...props}
                    error={!!errors[field.name]}
                    onErrorClick={() => {
                      Toast.show(errors[field.name]?.message)
                    }}
                  >
                    {field.label}
                  </InputItem>
                )
              }
            />
          )
        })}

        <WhiteSpace size="lg" />
        <WhiteSpace size="lg" />
        <WingBlank>
          <Checkbox
            checked={isConfirmed}
            onChange={(e) => setConfirmed(e.target.checked)}
          >
            我已阅读并同意{' '}
            <a
              style={{ color: '#FF9847' }}
              onClick={() => {
                setShowRuleModal(true)
              }}
            >
              商宴通服务条款
            </a>
          </Checkbox>
        </WingBlank>
        <WhiteSpace size="lg" />
        <WhiteSpace size="lg" />
        <Flex
          alignContent="center"
          align="center"
          justify="center"
          direction="row"
        >
          <Button
            disabled={!isConfirmed}
            type="submit"
            className={submitButton}
          >
            提交
          </Button>
        </Flex>
      </form>
      <RuleModal
        visible={showRuleModal}
        onClose={() => setShowRuleModal(false)}
      />
    </Page>
  )
}

const observerd = observer(CompleteUserInfo)

CompleteUserInfo.config = {
  title: '完善个人信息',
  noBack: true
}

export default hoistNonReactStatics(observerd, CompleteUserInfo)
