import { Toast } from 'antd-mobile'
// TODO 表单封装
import React, { FC, PropsWithChildren, useEffect } from 'react'
import {
  useForm,
  useFormContext,
  FormProvider,
  UseFormMethods,
  Controller,
  UnpackNestedValue,
  ValidationRules,
  FieldError
} from 'react-hook-form'
// hack
type QCSubmitProps = (
  onVaild: UseFormMethods['handleSubmit']['arguments'][0],
  onInvalid?: UseFormMethods['handleSubmit']['arguments'][1]
) => Promise<void>
export interface FormRefProps<TFormValues> extends UseFormMethods<TFormValues> {
  qcSubmit: QCSubmitProps
}
export interface QCFromProps<TFormValues> {
  formRef?: (formMethods: FormRefProps<TFormValues>) => void
  defaultValues?: UnpackNestedValue<TFormValues>
}
export interface ConnectFormProps {
  placeholder?: string
  disabled?: boolean
  field?: string
  rules?: ValidationRules
  // defaultValue?: any
  // defaultValue?: any
  // value?: any
}
export interface ConnectFormChildrenProps {
  sync?: (value: any) => void
  formState?: UseFormMethods['formState']
  register?: UseFormMethods['register']
  watch?: UseFormMethods['watch']
  control?: UseFormMethods['control']
  rules?: ValidationRules
  value?: any
  onBlur?: () => void
}
export const ConnectForm: FC<PropsWithChildren<ConnectFormProps>> = ({
  field,
  rules,
  children
}) => {
  // 表单上下文
  const { register, formState, control } = useFormContext()
  // input 、select 等原生标签可以通过ref=register来进行注册
  // 所以存在field为空的状态
  console.log('ConnectForm render')
  // 非合法元素直接return
  if (!React.isValidElement(children)) {
    // 此处拿空标签包裹仅仅是为了绕过ts
    return <>{children}</>
  }
  // 有无field来判断是否是受控组件(业务组件)还是非受控组件(原生input、select、textarea)
  return field ? (
    <Controller
      name={field}
      control={control}
      rules={rules}
      render={({ onChange, value, onBlur }) =>
        React.createElement(children.type, {
          ...children.props,
          value,
          onBlur,
          sync: onChange,
          // 传个formState 方便 isDirty memo优化
          formState
        } as ConnectFormChildrenProps)
      }
    />
  ) : (
    React.createElement(children.type, {
      ...children.props,
      // 传个formState 方便 isDirty memo优化
      formState,
      register
    } as ConnectFormChildrenProps)
  )
}
const QCForm = <TFormValues extends Record<string, any> = Record<string, any>>({
  formRef,
  defaultValues,
  children
}: PropsWithChildren<QCFromProps<TFormValues>>) => {
  const methods = useForm<TFormValues>({ defaultValues })
  // 初始化模拟返回ref，即form的所有方法
  // 注入个自定义的submit方法
  const qcSubmit: QCSubmitProps = (onValid, onInvalid) =>
    methods.handleSubmit(onValid, onInvalid)()
  useEffect(() => {
    formRef && formRef({ ...methods, qcSubmit })
  }, [methods])
  // 统一处理一些rules
  useEffect(() => {
    const haveError = Object.keys(methods.errors).length
    if (haveError) {
      // Toast下第一个错误
      const firstErrorMessage = (methods.errors[
        Object.keys(methods.errors)[0]
      ] as FieldError)?.message
      firstErrorMessage && Toast.show(firstErrorMessage)
    }
  }, [methods.errors])
  return (
    <FormProvider {...methods}>
      <form>{children}</form>
    </FormProvider>
  )
}
export default QCForm
