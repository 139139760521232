import { AxiosRequestConfig } from 'axios'
import makeUseApi from '../utils/makeUseApi'

export const UserFeedback: AxiosRequestConfig = {
  method: 'POST',
  url: '/aimee/tool/app/feedback'
}

export const useUserFeedback = makeUseApi<
  Definitions.Aimee.FeedbackRequest,
  Definitions.Aimee.RestResponseOfstring
>(UserFeedback)
