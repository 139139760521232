import React, { FC } from 'react'
import dayjs from 'dayjs'
import { Link, useHistory } from 'react-router-dom'

import { ItemCell } from '../../components/Cell'
import { ColumnView } from '../../components/QCFlex'
import Text from '../../components/Text'
import useWeixin from '../../utils/weixin'
const ShopInfoCard: FC<QCOrderInfo> = ({
  store_name,
  address,
  location,
  store_phone = '',
  yuyue_date,
  yuyue_week,
  yuyue_clock,
  reserve_time,
  people_num,
  biz_type,
  predict_cost,
  restaurant_id
}) => {
  const isUnsigned = ['UNSIGNED'].includes(biz_type)
  const router = useHistory()
  const { openMap } = useWeixin()
  const gotoDetail = () => {
    isUnsigned
      ? router.push('/recommendRestaurantDetail', {
          restaurantId: restaurant_id
        })
      : router.push(`/onlineRestaurantDetail/${restaurant_id}`)
  }
  return (
    <ColumnView className="qc-section-card">
      <ItemCell
        label={
          <Text size={34} color="#999999FF">
            商户信息
          </Text>
        }
        arrow={false}
      />
      <ItemCell
        onClick={gotoDetail}
        icon={{ name: 'icondianputubiao', color: '#F4630BFF' }}
        label={store_name}
      />
      <a
        onClick={() => {
          openMap({
            longitude: parseFloat(location.lng),
            latitude: parseFloat(location.lat),
            address,
            name: store_name
          })
        }}
      >
        <ItemCell
          icon={{ name: 'icondizhitubiao', color: '#F4630BFF' }}
          label={address}
        />
      </a>
      <a href={`tel:${store_phone}`}>
        <ItemCell
          icon={{ name: 'icondianhuatubiao', color: '#F4630BFF' }}
          label={`${store_phone}（餐厅电话）`}
        />
      </a>
      <ItemCell
        icon={{ name: 'iconshijiantubiao', color: '#F4630BFF' }}
        label={`${dayjs(reserve_time).format(
          'YYYY'
        )}年 ${yuyue_date} ${yuyue_week} ${yuyue_clock}`}
        arrow={false}
      />
      <ItemCell
        icon={{ name: 'iconrenwutubiao', color: '#F4630BFF' }}
        label={`${people_num}人`}
        arrow={false}
      />
      {/* <ItemCell
        icon={{ name: 'iconjinezhifu', color: '#F4630BFF' }}
        label={`${predict_cost}元`}
        arrow={false}
      /> */}
    </ColumnView>
  )
}

export default ShopInfoCard
