import React, { FC, SyntheticEvent } from 'react'

import { useClassNames } from '../../hooks/classname'
import './index.global.less'
import placeHolderImg from '../../assets/placeHolderImg.png'
import { match } from 'react-router-dom'

export type QCImageProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  defaultSrc?: string
  defaultReg?: RegExp
}

// 如果需要自定义占位图的规则/图片，需要传入defaultSrc/defaultSrc
const QCImage: FC<QCImageProps> = ({
  className = '',
  defaultReg = '',
  defaultSrc = placeHolderImg,
  ...otherProps
}) => {
  const mergedClassName = useClassNames(['qc-image', className])
  const params = { ...otherProps },
    { src } = { ...otherProps }

  // src路径不合法，修改src路径为defaultSrc
  if (src?.match(defaultReg) === null) {
    params.src = defaultSrc
  } else {
    // 砍掉url中的 protocol，保证与当前host一致
    params.src = params.src?.replace(/^(https:|http:)/, '')
  }

  // src路径合法，但src请求失败
  function addDefaultSrc(e: SyntheticEvent<HTMLImageElement, Event>) {
    e.currentTarget.src = defaultSrc
  }
  return <img onError={addDefaultSrc} className={mergedClassName} {...params} />
}

export default QCImage
