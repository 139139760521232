import React, { FC } from 'react'
import { Flex } from 'antd-mobile'
import { ColumnView, RowView, XCenterView } from '../QCFlex'
import TextIcon from '../TextIcon'
import Text from '../Text'
import Icon from '../Icon'
import Cell from '../Cell'
import ImageListView from '../ImageListView'
import PhoneCallBtn from '../PhoneCallBtn'
import { isZero } from '../../utils/lodash'

import {
  callBtn,
  cardB,
  imageListView,
  vLine,
  title,
  noticesContainer,
  textIcon
} from './index.less'
import useWeixin from '../../utils/weixin'
type TImages = string | string[]
export interface IRestaurantInfo {
  phones?: string[]
  rating?: string
  cookStyle?: string
  consume?: number
  address?: string
  storeName?: string
  location?: { lat?: number | string; lng?: number | string }
  area?: string
  indoor?: TImages
  outdoor?: TImages
  notices?: Definitions.Europe.NoticeInfo[]
  businessHours?: string
  restaurantType?: 'online' | 'unsigned'
}
interface NoticesCardProps {
  notices?: Definitions.Europe.NoticeInfo[]
}
const getUrls = (input: TImages) =>
  input ? (typeof input === 'string' ? [input] : input) : []
const getNoticeConfig = (title?: string) => {
  if (title === '外卖公告') return { text: '外', color: '#66B5FFFF' }
  if (title === '餐厅公告') return { text: '告', color: '#FF6D66FF' }
}
const NoticesCard: FC<NoticesCardProps> = ({ notices = [] }) => {
  return (
    <ColumnView className={noticesContainer}>
      {notices.map((noctice, idx) => {
        const config = getNoticeConfig(noctice.title)
        return (
          <RowView key={idx}>
            <TextIcon
              text={config?.text}
              color={config?.color}
              className={textIcon}
            />
            <Text color="#666666FF" size={28}>
              {noctice.content}
            </Text>
          </RowView>
        )
      })}
    </ColumnView>
  )
}
const RestaurantInfo: FC<IRestaurantInfo> = ({
  storeName,
  rating,
  consume,
  address,
  cookStyle,
  area,
  // 目前固定为商宴通客服
  phones = ['400 050 1717'],
  indoor = '',
  outdoor = '',
  location,
  notices,
  restaurantType = 'online',
  businessHours
}) => {
  const indoors = getUrls(indoor)
  const outdoors = getUrls(outdoor)
  const images = indoors.concat(outdoors)
  const showCallBtn = !!phones.length
  const abledNotices = notices?.filter((item) => !!item.content)
  const showNotices = !!abledNotices?.length
  const { openMap } = useWeixin()

  return (
    <ColumnView className={`${cardB} qc-section-card`}>
      <Text color="#181818FF" size={34} className={title}>
        {storeName}
      </Text>
      <RowView hAlign="between">
        <RowView>
          {!isZero(rating) && (
            <Text color="#FF9847FF" size={26}>
              <Icon name="iconfuwupingjiaxingxing" />
              {rating}
            </Text>
          )}
          {!isZero(rating) && consume && (
            <Text className={vLine} color="#999999FF" size={26}>
              |
            </Text>
          )}
          {consume && (
            <Text color="#999999FF" size={26}>
              ￥{consume}/人
            </Text>
          )}
        </RowView>
        <RowView>
          {cookStyle && (
            <Text color="#999999FF" size={24}>
              {cookStyle}
            </Text>
          )}
          {cookStyle && area && (
            <Text className={vLine} color="#999999FF" size={24}>
              |
            </Text>
          )}
          {area && (
            <Text color="#999999FF" size={24}>
              {area}
            </Text>
          )}
        </RowView>
      </RowView>
      <ImageListView urls={images} className={imageListView} />
      <ColumnView>
        {address && (
          <Cell>
            <Flex
              direction="row"
              onClick={() => {
                openMap({
                  longitude: parseFloat((location?.lng as string) || '0'),
                  latitude: parseFloat((location?.lat as string) || '0'),
                  address,
                  name: storeName
                })
              }}
            >
              <Cell.Icon size={26} color="#CCCCCCFF" name="icondingwei" />
              <Cell.Left>
                <Text color="#666666FF" size={28}>
                  {address}
                </Text>
              </Cell.Left>
            </Flex>
            {showCallBtn && (
              <Cell.Right>
                <XCenterView className={callBtn}>
                  <PhoneCallBtn phones={phones} />
                </XCenterView>
              </Cell.Right>
            )}
          </Cell>
        )}
        {businessHours && (
          <Cell>
            <Cell.Icon size={26} color="#CCCCCCFF" name="iconshijiantubiao" />
            <Cell.Left>
              <Text color="#666666FF" size={28}>
                营业时间：{businessHours}
              </Text>
            </Cell.Left>
          </Cell>
        )}
      </ColumnView>
      {showNotices && <NoticesCard notices={abledNotices} />}
    </ColumnView>
  )
}

export default RestaurantInfo
