import React, { FC } from 'react'
import Text from '../../components/Text'
import { IStatus } from '../../constants/other'
import { ColumnView } from '../../components/QCFlex'
interface RecommendStatusCardProps {
  status: string
  statusHint?: string
}
type IStatusConfig = {
  [K in keyof IStatus]?: {
    name?: string
    color?: string
    dsc?: string
  }
}
const StatusConfig: IStatusConfig = {
  APPLY_AVALIABLE: {
    name: '未推荐',
    color: '#666666FF',
    dsc: '您未推荐此餐厅，验证通过后即可使用'
  },
  VALIDATION_WAIT: {
    name: '验证中',
    color: '#51A04CFF',
    dsc: ''
  },
  VALIDATION_FAIL: {
    name: '验证不通过',
    color: '#F76060FF',
    dsc: '餐厅资质不通过，根据企业政策，不可使用该餐厅用餐'
  }
}
const RecommendStatusCard: FC<RecommendStatusCardProps> = ({
  status,
  statusHint
}) => {
  const statusName = StatusConfig[status]?.name
  const statusColor = StatusConfig[status]?.color
  const statusDsc = StatusConfig[status]?.dsc
  return (
    <ColumnView className="qc-section-card">
      <Text size={34} color={statusColor}>
        {statusName}
      </Text>
      <Text color="#999999FF" size={28}>
        {statusDsc || statusHint}
      </Text>
    </ColumnView>
  )
}

export default RecommendStatusCard
