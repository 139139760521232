import React, { FC } from 'react'
import QCImage from '../../components/QCImage'

import { RowView } from '../QCFlex'
import './index.global.less'
export interface IImageListView {
  className?: string
  urls: string[]
  width?: number | string
}
const ImageListView: FC<IImageListView> = ({
  urls,
  className,
  width = '100%'
}) => (
  <RowView className={`qc-image-list-view ${className}`} width={width}>
    {urls.map((url, idx) => (
      <QCImage key={`${url}-${idx}`} src={url} />
    ))}
  </RowView>
)

export default ImageListView
