import React, { FC } from 'react'
import Icon from '../../components/Icon'
import { headerStatus } from './index.less'
import { RowView } from '../../components/QCFlex'
interface HeaderStatusProps {
  status?: number
  order_state_hint?: string
}

const colorMap: { [name: string]: string } = {
  // 待确认
  1: 'waitConfirm',
  // 预订成功
  2: 'success',
  // 待支付
  3: 'payWaiting',
  // 支付失败
  4: 'payFail',
  // 已完成支付
  5: 'paySuccess',
  // 已取消
  6: 'cancel',
  // 待下单
  7: 'waitBookOrder',
  // 默认
  0: ''
}
const iconsMap: { [name: string]: string } = {
  success: 'icongougou-xuanzhong',
  fail: 'iconchacha',
  payDone: 'iconjinezhifu',
  payWaiting: 'iconjinezhifu',
  paySuccess: 'iconjinezhifu',
  payFail: 'iconchacha',
  waitConfirm: 'icondengdai',
  waitBookOrder: 'icondengdai',
  cancel: 'iconquxiao'
}
const HeaderStatus: FC<HeaderStatusProps> = ({ status, order_state_hint }) => {
  const formatedOrderStatus = colorMap[status || 0]
  const mergedClassname = [headerStatus, formatedOrderStatus].join(' ')
  return (
    <RowView className={mergedClassname} vAlign="center">
      <Icon name={iconsMap[formatedOrderStatus]} />
      {/* 偷了个懒，文案直接取自接口返回的order_state_hint字段*/}
      {order_state_hint}
    </RowView>
  )
}

export default HeaderStatus
