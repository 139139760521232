import React, { FC, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Modal, Toast } from 'antd-mobile'
import BrotherButton from '../../components/BrotherButton'
import Button from '../../components/Button'
import { XCenterView } from '../../components/QCFlex'
import { useUnionOrderCancel, useCreateRule, useCheckQcPay } from '../../api/Order'
import {
  useRestaurantDetail
} from '../../api/RecommendRestaurant'
import { useResendEmail } from '../../api/Order'
import { actionBar } from './index.less'
import { useRestToastFail } from '../../hooks/toast'
import { useCondition, useMount } from '../../hooks/common'
import { useGuanAiTongPayment } from '../../hooks/payment'
import * as qs from 'qs'
const ActionBar: FC<QCOrderInfo> = ({
  order_state,
  order_id,
  rating,
  ticket_info_confirmed,
  money,
  receipt_images,
  receipt_consistency,
  invoice_consistency,
  thumb_postfix,
  invoice_images,
  res_invoice_title,
  store_name,
  res_tax_identification_number,
  biz_type,
  restaurant_id,
  isAlipay
}) => {
  const router = useHistory()
  const location = useLocation<{ orderType?: 'RESERVATION' | 'RECOMMEND' }>()

  const [cancelOrderRes, cancelOrderPost] = useUnionOrderCancel(
    location.state?.orderType
  )({
    useCache: true,
    manual: true,
    data: {
      orderId: order_id
    }
  })

  const [resendEmailRes, resendEmailPost] = useResendEmail({
    useCache: true,
    manual: true,
    data: {
      orderNum: order_id,
      // 不确定这个type有啥用，反正重发的接口写的是0
      type: '0'
    }
  })

  const [createRuleRes, createRulePost] = useCreateRule({
    useCache: true,
    manual: true,
    data: qs.stringify({
      orderId: order_id,
    })
  })
  const [checkPayRes, checkPayPost] = useCheckQcPay({
    useCache: true,
    manual: true,
    data: qs.stringify({
      orderId: order_id,
      payType: 'qrcode'
      // payType: 'scan'
    })
  })
  const goGATPayment = useGuanAiTongPayment({
    orderId: order_id,
    orderType: biz_type === 'UNSIGNED' ? 'RECOMMEND' : 'RESERVATION',
    bizType: biz_type,
    amount: money,
    isAlipay: isAlipay
  })
  // 我猜测这个字段是用来判断是否发送过消费确认函的
  // 目前已知包含的值   CONSISTENCY | UNCONFIRMED | REFUSED
  const ticketInfoConfirmed = ticket_info_confirmed === 'CONSISTENCY'
  const ticketInfoUnconfirmed = ticket_info_confirmed === 'UNCONFIRMED'
  const ticketInfoRefused = ticket_info_confirmed === 'REFUSED'
  // order_state base ../../constants/other.EATIN_ORDER_STATUS

  // 评价服务
  const showServiceEvaluation = ['6'].includes(order_state) && !rating
  const goServiceEvaluation = () => {
    router.push(`/eatinOrderEvaluation/${order_id}`, {
      orderType: location.state?.orderType
    })
  }
  // 取消订单
  const showCancelBtn = ['1', '2'].includes(order_state)
  const cancelOrder = () => {
    Modal.alert('确认取消?', '是否取消此订单', [
      {
        text: '关闭'
      },
      {
        text: '确认取消',
        onPress() {
          cancelOrderPost()
        }
      }
    ])
  }
  // 取消操作成功
  useCondition(cancelOrderRes.data?.status === 0, () => {
    Toast.success('取消成功', 2, () => {
      window.location.reload()
    })
  })
  // 去确认支付
  const showConfirmBtn = ['2'].includes(order_state)
  const goToConfirm = () => {
    router.push('/orderConfirm', {
      orderId: order_id,
      orderType: biz_type === 'UNSIGNED' ? 'RECOMMEND' : 'RESERVATION',
      bizType: biz_type
    })
  }
  // 去支付
  const showPayBtn = ['3', '5'].includes(order_state)
  const gotoPay = () => {
    goGATPayment()
  }
  // 消费确认函
  // 票据审核状态
  // const showSendEmail = ['6'].includes(order_state)
  // 不要消费确认函了
  // 赋值成null而不是false单纯为了过ts校验
  const showSendEmail = null
  const sendEmail = () => {
    if (ticketInfoRefused) {
      Toast.show('票据审核未通过，请修改票据信息')
    } else if (ticketInfoUnconfirmed) {
      Toast.show('票据正在审核，审核通过自动发送确认函')
    } else {
      resendEmailPost()
    }
  }
  // 上传发票小票
  // base {TICKET_CHECK_STATUS} ../../constants/other
  // "ticket_info_confirmed": "CONSISTENCY" ，CONSISTENCY("已提交，一致") 无上传票据、无编辑按钮
  // "ticket_info_confirmed": "UNCONFIRMED", UNCONFIRMED("已提交，未确认") 无上传票据、无编辑按钮
  // "ticket_info_confirmed": "REFUSED", REFUSED("已提交，被拒绝") 展示审核拒绝状态，无上传票据，编辑按钮
  // "ticket_info_confirmed": "CONFIRMED"，CONFIRMED("已提交，已确认")无上传票据、无编辑按钮
  // "ticket_info_confirmed": "NOT_SUBMITTED"，NOT_SUBMITTED("未提交") 有上传票据按钮，无编辑按钮
  const showUploadTicket =
    ['6'].includes(order_state) &&
    ['NOT_SUBMITTED'].includes(ticket_info_confirmed)
  const goUploadTicket = () => {
    router.push('/uploadTicket', {
      detail: {
        receipt_images,
        receipt_consistency,
        invoice_consistency,
        thumb_postfix,
        ticket_info_confirmed,
        invoice_images,
        res_invoice_title,
        store_name,
        res_tax_identification_number,
        order_id
      },
      orderType: 'eatin'
    })
  }

  //商宴通企业码支付
  const qcodePayBtn = isAlipay == 1 && ['10'].includes(order_state)
  const goQcode = () => {
    createRulePost().then(res => {
      if (res.data?.status == 0) {
        checkPayPost().then(res => {
          console.log(res.data?.data)
          const sdata: any = res.data?.data
          const url = window.location.protocol + "//" + window.location.host + "/CheckAuthor?type=play&orderId=" + order_id + '&token=' + localStorage.getItem('yinglian_up_token') + "&url=" + sdata
          window.location.href = url
        })
      }
    })
  }

  // 至少有一种可以操作的状态吧
  const payBtnText = ['3'].includes(order_state) ? '立即支付' : '重新支付'
  const actionBarConfig = [
    showPayBtn && {
      text: payBtnText,
      action: gotoPay
    },
    qcodePayBtn && {
      text: '商宴通企业码支付',
      action: goQcode
    },
    showConfirmBtn && {
      text: '确认消费',
      action: goToConfirm
    },
    showSendEmail && {
      text: ticketInfoConfirmed ? '重发消费确认函' : '发送消费确认函',
      action: sendEmail
    },
    showCancelBtn && {
      text: '取消订单',
      action: cancelOrder
    },
    showServiceEvaluation && {
      text: '对本次服务评价',
      action: goServiceEvaluation
    },
    showUploadTicket && {
      text: '上传发票小票',
      action: goUploadTicket
    }
  ].filter((item) => !!item)
  // 最多不超过两种按钮，再多就重新设计
  // 两个按钮就用BrotherButton
  // 其他数量的按钮就全部遍历出来
  const haveTwoButton = actionBarConfig.length === 2
  const showActionBar = !!actionBarConfig.length
  useRestToastFail([cancelOrderRes, resendEmailRes, createRuleRes, checkPayRes])

  return showActionBar ? (
    <XCenterView className={actionBar}>
      {haveTwoButton
        ? actionBarConfig[0] &&
        actionBarConfig[1] && (
          <BrotherButton
            leftText={actionBarConfig[0].text}
            onLeftClick={actionBarConfig[0].action}
            rightText={actionBarConfig[1].text}
            onRightClick={actionBarConfig[1].action}
          />
        )
        : actionBarConfig.map(
          (item) =>
            item && (
              <Button key={item.text} onClick={item.action}>
                {item.text}
              </Button>
            )
        )}
    </XCenterView>
  ) : (
    <></>
  )
}

export default ActionBar
