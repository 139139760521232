// 餐厅信息纠错
import React, { FC } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
interface IUrlParams {
  restaurantId?: string
}
const RestaurantInfoCorrecting: QCPage = () => {
  const location = useLocation<IUrlParams>()
  return (
    <div>
      {location.state?.restaurantId}
      <Link to="/login">To Login</Link>
    </div>
  )
}

RestaurantInfoCorrecting.config = {
  title: ''
}

export default RestaurantInfoCorrecting
