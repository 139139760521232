import React, { FC } from 'react'

import { ColumnView } from '../QCFlex'
import Text from '../Text'
import EmptyResult from '../../assets/EmptyResult.svg'
import { img } from './index.less'

const EmptyView: FC<{ text?: string }> = ({ text = '暂无搜索结果' }) => (
  <ColumnView hAlign="center">
    <img className={img} src={EmptyResult} />
    <Text size={26} color="#999999">
      {text}
    </Text>
  </ColumnView>
)
export default EmptyView
