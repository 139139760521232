import React, { useState } from 'react'
import qs from 'querystring'
import { useHistory, useLocation } from 'react-router-dom'
import isEmpty from 'lodash.isempty'
import { usePaymentSearch, useSetPaymentPending } from '../../api/Payment'
import { useCondition, useMount, useOnMessage } from '../../hooks/common'
import { OrderType } from '../../constants/order'
import { useInterval } from '../../hooks/timer'
import { ColumnView, XCenterView } from '../../components/QCFlex'
import { useCreateCardAccountWorkform } from '../../api/RecommendOrder'
import Text from '../../components/Text'
import { Icon, Toast } from 'antd-mobile'
import { useCountdown } from '../../hooks/coutdown'
import { useUnionOrderDetail } from '../../api/Order'
import {
  useRestaurantDetail
} from '../../api/RecommendRestaurant'
const manualConfig = {
  manual: true
}

const HandleGuanAiTongCallback: QCPage = () => {
  const history = useHistory()
  const location = useLocation()
  const [paymentId, setPaymentId] = useState<string | undefined>()
  const orderType = location.pathname.replace(
    '/handleGuanAiTongCallback/',
    ''
  ) as OrderType

  const [, refetch] = useUnionOrderDetail(
    orderType
  )({
    useCache: true,
    manual: true,
  })
  const [, fetchDetail] = useRestaurantDetail({
    useCache: true,
    manual: true
  })
  const [, setPaymentPending] = useSetPaymentPending(manualConfig)
  const [{ data: payment }, searchPayment] = usePaymentSearch(manualConfig)
  const [, createWorkForm] = useCreateCardAccountWorkform(manualConfig)

  const { start, stop, current: currentSec } = useCountdown(10)

  useMount(() => {
    const urlObj = qs.parse(location.search.replace('?', ''))
    window.parent.postMessage(
      { params: urlObj, __from: 'handleGuanAiTongCallback' },
      '*'
    )
  })

  // 倒计时开始 且 paymentId 存在, 开始轮询支付状态
  useInterval(
    () => searchPayment({ params: { paymentId } }),
    currentSec && paymentId ? 3000 : null
  )

  useCondition(
    !isEmpty(payment?.paymentRecord) && currentSec !== null,
    async () => {
      const paymentInfo = payment?.paymentRecord![0]

      if (paymentInfo?.status === 'succeed') {
        stop()
        refetch({
          data: {
            orderId: paymentInfo?.orderId
          }
        }).then(res => {
          const restaurantId = res.data?.data?.restaurant_id
          if (restaurantId) {
            fetchDetail({
              data: {
                restaurantId: restaurantId
              }
            }).then(res => {
              const isAlipay: any = res.data?.data?.isAlipay
              if (isAlipay == '1') {
                history.replace(`/eatinOrderDetail/${paymentInfo?.orderId}`)
              } else {
                history.replace(
                  orderType === 'TAKEAWAY'
                    ? '/takeawayOrderPayResult'
                    : '/eatinOrderPayResult',
                  {
                    orderId: paymentInfo?.orderId
                  }
                )
              }
            })
          }
        })

      }
    }
  )

  // 接收到消息后
  useOnMessage(async (e) => {
    if (e.data.__from === 'handleGuanAiTongCallback') {
      const { outer_trade_no = '', appid = '' } = e.data.params || {}
      const paymentId = outer_trade_no?.replace(appid, '')

      if (!paymentId) {
        Toast.fail('非法支付')
        return
      }

      setPaymentId(paymentId)

      // 将支付状态置为pending
      await setPaymentPending({
        data: {
          paymentId,
          paymentType: '4'
        }
      })

      // 查找支付数据
      const { data } = await searchPayment({
        params: { paymentId }
      })

      if (isEmpty(data?.paymentRecord)) {
        Toast.fail('无关联订单')
        return
      }

      // 当面付订单发送卡账工单
      if (orderType === 'RECOMMEND') {
        await createWorkForm({
          data: {
            orderId: data?.paymentRecord?.[0].orderId
          }
        })
      }

      // 开始计时
      start()
    }
  })

  return (
    <ColumnView vAlign="center" bgColor="#fff" style={{ flex: 1 }}>
      <ColumnView hAlign="center" className="qc-section-card">
        <Icon type="loading" color="#51A04CFF" size="lg" />
        <Text size={34} color="#181818FF" margin={[40, 0, 0, 0]}>
          支付确认中,请稍后
        </Text>
        <Text margin={[20, 0, 40, 0]} color="#999999FF" size={26}>
          {currentSec ? `将在${currentSec}s后跳转` : ''}
        </Text>
      </ColumnView>
      <XCenterView margin={[120, 0, 0, 0]}></XCenterView>
      <XCenterView margin={[70, 0, 0, 0]}>
        {/* <Text color="#999999FF" size={34}>
          返回首页
        </Text> */}
      </XCenterView>
    </ColumnView>
  )
}

export default HandleGuanAiTongCallback

HandleGuanAiTongCallback.config = {
  hasTitlebar: false
}
