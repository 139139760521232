import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { Toast } from 'antd-mobile'
import BrotherButton from '../../components/BrotherButton'
import Button from '../../components/Button'
import Text from '../../components/Text'
import { XCenterView } from '../../components/QCFlex'
import { useCancelOrder } from '../../api/EatinOrder'
import { useResendEmail } from '../../api/Order'
import { actionBar } from './index.less'
import { useRestToastFail } from '../../hooks/toast'
import { STY_CUSTOMER_PHONE } from '../../constants/other'
import { useCondition } from '../../hooks/common'

const ActionBar: FC<QCOrderInfo> = ({
  order_state,
  order_id,
  biz_type,
  rating,
  receipt_images,
  receipt_consistency,
  invoice_consistency,
  thumb_postfix,
  invoice_images,
  res_invoice_title,
  store_name,
  res_tax_identification_number,
  ticket_info_confirmed
}) => {
  const router = useHistory()
  const [cancelOrderRes, cancelOrderPost] = useCancelOrder({
    useCache: true,
    manual: true,
    data: {
      orderId: order_id
    }
  })
  const [resendEmailRes, resendEmailPost] = useResendEmail({
    useCache: true,
    manual: true,
    data: {
      orderNum: order_id,
      // 不确定这个type有啥用，反正重发的接口写的是0
      type: '0'
    }
  })
  // 我猜测这个字段是用来判断是否发送过消费确认函的
  // 目前已知包含的值   CONSISTENCY | UNCONFIRMED | REFUSED
  const ticketInfoConfirmed = ticket_info_confirmed === 'CONSISTENCY'
  const ticketInfoUnconfirmed = ticket_info_confirmed === 'UNCONFIRMED'
  const ticketInfoRefused = ticket_info_confirmed === 'REFUSED'
  // order_state base ../../constants/other.EATIN_ORDER_STATUS
  // 评价服务
  const showServiceEvaluation = [5].includes(order_state) && !rating
  const goServiceEvaluation = () => {
    router.push(`/takeawayOrderEvaluation/${order_id}`)
  }
  // 取消订单
  const showCancelBtn = [2].includes(order_state)
  const cancelOrder = () => {}
  // 去支付
  const showPayBtn = [2, 4].includes(order_state)
  const gotoPay = () => {
    router.push('/orderConfirm', {
      orderId: order_id,
      orderType: 'TAKEAWAY',
      bizType: biz_type
    })
  }
  // 取消操作成功
  useCondition(cancelOrderRes.data?.status === 0, () => {
    Toast.success('取消成功', 2, () => {
      window.location.reload()
    })
  })
  // 上传发票小票
  // base {TICKET_CHECK_STATUS} ../../constants/other
  // "ticket_info_confirmed": "CONSISTENCY" ，CONSISTENCY("已提交，一致") 无上传票据、无编辑按钮
  // "ticket_info_confirmed": "UNCONFIRMED", UNCONFIRMED("已提交，未确认") 无上传票据、无编辑按钮
  // "ticket_info_confirmed": "REFUSED", REFUSED("已提交，被拒绝") 展示审核拒绝状态，无上传票据，编辑按钮
  // "ticket_info_confirmed": "CONFIRMED"，CONFIRMED("已提交，已确认")无上传票据、无编辑按钮
  // "ticket_info_confirmed": "NOT_SUBMITTED"，NOT_SUBMITTED("未提交") 有上传票据按钮，无编辑按钮
  const showUploadTicket =
    [5].includes(order_state) &&
    ['NOT_SUBMITTED'].includes(ticket_info_confirmed)
  const goUploadTicket = () => {
    router.push('/uploadTicket', {
      detail: {
        receipt_images,
        receipt_consistency,
        invoice_consistency,
        thumb_postfix,
        invoice_images,
        res_invoice_title,
        ticket_info_confirmed,
        store_name,
        res_tax_identification_number,
        order_id
      },
      orderType: 'takeaway'
    })
  }
  // 消费确认函
  // const showSendEmail = [5].includes(order_state)
  // 不要消费确认函了
  // 赋值成null而不是false单纯为了过ts校验
  const showSendEmail = null
  const sendEmail = () => {
    if (ticketInfoRefused) {
      Toast.show('票据审核未通过，请修改票据信息')
    } else if (ticketInfoUnconfirmed) {
      Toast.show('票据正在审核，审核通过自动发送确认函')
    } else {
      resendEmailPost()
    }
  }
  // 至少有一种可以操作的状态吧
  const payBtnText = [4].includes(order_state)
    ? '我已收货，重新支付'
    : '我已收货，支付'
  const actionBarConfig = [
    showPayBtn && {
      text: payBtnText,
      action: gotoPay
    },
    showSendEmail && {
      text: ticketInfoConfirmed ? '重发消费确认函' : '发送消费确认函',
      action: sendEmail
    },
    showCancelBtn && {
      text: '取消订单',
      action: cancelOrder,
      render: (
        <a href={`tel:${STY_CUSTOMER_PHONE}`}>
          <Text color="#ffffff">取消订单</Text>
        </a>
      )
    },
    showServiceEvaluation && {
      text: '对本次服务评价',
      action: goServiceEvaluation
    },
    showUploadTicket && {
      text: '上传发票小票',
      action: goUploadTicket
    }
  ].filter((item) => !!item)
  // 最多不超过两种按钮，再多就重新设计
  // 两个按钮就用BrotherButton
  // 其他数量的按钮就全部遍历出来
  const haveTwoButton = actionBarConfig.length === 2
  const showActionBar = !!actionBarConfig.length
  useRestToastFail([cancelOrderRes, resendEmailRes])
  return showActionBar ? (
    <XCenterView className={actionBar}>
      {haveTwoButton
        ? actionBarConfig[0] &&
          actionBarConfig[1] && (
            <BrotherButton
              leftText={actionBarConfig[0].render || actionBarConfig[0].text}
              onLeftClick={actionBarConfig[0].action}
              rightText={actionBarConfig[1].render || actionBarConfig[1].text}
              onRightClick={actionBarConfig[1].action}
            />
          )
        : actionBarConfig.map(
            (item) =>
              item && (
                <Button key={item.text} onClick={item.action}>
                  {item.render || item.text}
                </Button>
              )
          )}
    </XCenterView>
  ) : (
    <></>
  )
}

export default ActionBar
