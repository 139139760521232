import { Toast } from 'antd-mobile'
import { useQuerySignature } from '../api/Weixin'

interface OpenLocationConfig {
  latitude: number
  longitude: number
  name?: string
  address?: string
  scale?: number
  infoUrl?: string
}

const warn = () => {
  console.warn('no wx enviroment')
}

const proxyed = (tar: any) =>
  new Proxy<any>(tar, {
    get(target, property) {
      return target[property] || warn
    }
  })

const weixinClient = proxyed(typeof wx !== 'undefined' ? wx : {})

const useWeixin = () => {
  const [, querySignature] = useQuerySignature({ manual: true })

  const config = async (jsApiList?: string[]) => {
    jsApiList = jsApiList || ['getLocation', 'openLocation']

    const sigRes = await querySignature({
      params: {
        url: window.location.href.split('#')[0]
      }
    })

    console.log(sigRes)

    weixinClient.config({
      debug: false,
      appId: process.env.WX_APPID,
      timestamp: sigRes.data?.timestamp,
      nonceStr: sigRes.data?.noncestr,
      signature: sigRes.data?.signature,
      jsApiList
    })
  }

  const openMap = async (openLocationConfig: OpenLocationConfig) => {
    Toast.loading('')
    if (isAndroid()) {
      await config()
    }
    weixinClient.ready(() => {
      weixinClient?.openLocation({
        scale: 12,
        complete: () => {
          Toast.hide()
        },
        ...openLocationConfig
      })
    })
  }

  return { config, openMap, client: weixinClient }
}

const isAndroid = () => {
  return navigator.userAgent.toLowerCase().includes('android')
}
const browser = {
  versions: (function () {
    const u = navigator.userAgent
    return {
      trident: u.indexOf('Trident') > -1, //IE内核
      presto: u.indexOf('Presto') > -1, //opera内核
      webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
      gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
      mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
      android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, //android终端
      iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
      iPad: u.indexOf('iPad') > -1, //是否iPad
      webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
      weixin: u.indexOf('MicroMessenger') > -1 //是否微信
    }
  })()
}
export { weixinClient, isAndroid, browser }

export default useWeixin
