// 外卖订单填写
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import groupBy from 'lodash.groupby'

import QCForm, { FormRefProps } from '../../components/QCForm'
import { ColumnView } from '../../components/QCFlex'

import DeliveryCard from './DeliveryCard'
import GoodsCard from './GoodsCard'
import InfoCard from './InfoCard'
import ActionBar from './ActionBar'

import { useBookOrder, useGetDeliveryTime } from '../../api/TakeawayOrder'
import { formatSubmitData } from './util'
import { useGlobalStores } from '../../hooks/store'
import { container, content } from './index.less'
import { IFormValues, IGoodsMenuItem } from './interface'
import { useRestToastFail, useToastSuccess } from '../../hooks/toast'
import { useCondition } from '../../hooks/common'
import { useOrderLockCheck } from '../../hooks/order'

interface IUrlState {
  shopName: string
  restaurantId: string
  goodList: Definitions.Agalia.MenuItem[]
}
const TakeawayOrderApply: QCPage = () => {
  const inputRef = useRef<FormRefProps<IFormValues> | null>(null)
  const router = useHistory()
  const location = useLocation<IUrlState>()
  const {
    cityStore,
    formStore,
    userStore,
    takeawaySearchStore
  } = useGlobalStores()
  const [showGoodsList, updateShowGoodsList] = useState<IGoodsMenuItem[]>([])
  const city = cityStore.info?.name || ''
  // 下单时间配置
  const [deliveryTimeRes, getDeliveryTime] = useGetDeliveryTime({
    manual: true
  })
  // 下单
  const [bookOrderRes, bookOrder] = useBookOrder({
    manual: true
  })
  // 初始化表单
  // 从store里面取
  const defaultFormData: IFormValues = Object.assign(
    {},
    {
      // 用户默认的
      // 费用中心取自bu
      costCenterId: userStore?.profile?.bu?.groupId,
      linkmanName: userStore?.profile?.name,
      linkmanPhone: userStore?.profile?.mobile,
      // 前个页面带过来的
      address: takeawaySearchStore.getOnceData()
    },
    formStore.getOnceData()
  )

  const goodList = location.state?.goodList || []
  const shopName = location.state?.shopName || ''
  const restaurantId = location.state?.restaurantId || ''

  // 包含餐盒费
  // 合并
  useEffect(() => {
    if (deliveryTimeRes.data) {
      // 商品
      const showGoodsListsGrouped = groupBy(goodList, 'menu_id')
      const meregedGoodsList: IGoodsMenuItem[] = Object.keys(
        showGoodsListsGrouped
      ).map((item) => {
        const showGoodsItem = showGoodsListsGrouped[item]
        return {
          ...showGoodsItem[0],
          count: showGoodsItem.length
        }
      })
      // 配送费
      const data = deliveryTimeRes.data.data || {}
      const deliveryItems: IGoodsMenuItem[] = [
        {
          count: 0,
          price: data.packing_box_fee?.toString() || '',
          menu_name: '餐盒费'
        },
        {
          count: 0,
          price: data.shipping_fee?.toString() || '',
          menu_name: '配送费'
        }
      ]
      updateShowGoodsList(meregedGoodsList.concat(deliveryItems))
      // 设置发票信息
      // hack 一下
      inputRef &&
        inputRef.current?.setValue('tax', {
          invoiceTitle: deliveryTimeRes?.data?.data?.company_invoice_title,
          taxIdentificationNumber:
            deliveryTimeRes?.data?.data?.tax_identification_number
        })
    }
  }, [deliveryTimeRes.data])
  // 获取配送信息
  useEffect(() => {
    const count = goodList.length
    if (count) {
      const money = goodList.reduce(
        (total, curr) => total + Number(curr.price || 0),
        0
      )
      getDeliveryTime({
        data: {
          id: location.state.restaurantId,
          money: money.toFixed(2),
          count: count.toString()
        }
      })
    }
  }, [goodList.length])

  // 只检查锁单，不检查限额
  const handleOrderCheckOk = () => {
    router.replace('/#orderManage')
  }
  const [orderCheckSuccess, orderCheck] = useOrderLockCheck({
    manual: true,
    onOk: handleOrderCheckOk
  })
  // 下单
  const bookSubmit = () => {
    inputRef &&
      inputRef?.current?.qcSubmit((data: IFormValues) => {
        const submitData = formatSubmitData({
          formData: data,
          goodList: showGoodsList,
          city,
          rid: restaurantId
        })
        bookOrder({ data: submitData })
      })
  }
  // 提交按钮
  const handleSubmit = () => {
    inputRef &&
      inputRef.current?.qcSubmit((data: IFormValues) => {
        // bookSubmit()
        // 限额 | 锁单的判断
        orderCheck()
      })
  }
  // 触发了pagepicker
  const handlePagePickerPush = useCallback(() => {
    if (inputRef) {
      formStore.data = inputRef.current?.getValues()
    }
  }, [inputRef])

  // 未超限额 | 锁单 提交book
  useCondition(orderCheckSuccess === true, () => {
    bookSubmit()
  })

  // 兜底所有接口失败交互
  useRestToastFail([deliveryTimeRes, bookOrderRes])
  // 下单成功
  useToastSuccess(
    bookOrderRes.data?.status === 0 &&
      (bookOrderRes.data?.data as any)?.['oid'] &&
      '下单成功',
    {
      // 自动关闭的延时，单位秒
      duration: 2,
      onClose: () => {
        router.replace(`/takeawayOrderApplyResult`, {
          orderId: (bookOrderRes.data?.data as any)?.['oid']
        })
      }
    }
  )
  return (
    <ColumnView className={container}>
      <ColumnView className={content}>
        <QCForm<IFormValues>
          formRef={(el) => {
            inputRef.current = el
          }}
          {...(defaultFormData && { defaultValues: defaultFormData })}
        >
          <DeliveryCard
            onPush={handlePagePickerPush}
            rid={location?.state?.restaurantId}
            dates={deliveryTimeRes.data?.data?.list}
          />
          <InfoCard />
          <GoodsCard list={showGoodsList} shopName={shopName} />
        </QCForm>
      </ColumnView>
      <ActionBar onSubmit={handleSubmit} list={showGoodsList} />
    </ColumnView>
  )
}

TakeawayOrderApply.config = {
  title: '外卖下单'
}

export default TakeawayOrderApply
