import React, { FC, memo, useMemo } from 'react'
import { Drawer } from 'antd-mobile'
import { ItemCell } from '../../components/Cell'
import { modalCard, sortTypeDrawer } from './index.less'
import { ColumnView } from '../../components/QCFlex'
import { SortTypeListProps, SortTypeDrawerProps } from './interface'
const SortTypeList: FC<SortTypeListProps> = ({ onItemClick, sortList }) => {
  const handleItemClick = (sort?: string) => {
    onItemClick && onItemClick(sort)
  }
  return (
    <ColumnView className={modalCard}>
      {sortList?.map((sort) => (
        <ItemCell
          key={sort.value}
          align="start"
          value={sort.title}
          arrow={false}
          onClick={() => handleItemClick(sort.value)}
        />
      ))}
    </ColumnView>
  )
}
const SortTypeDrawer: FC<SortTypeDrawerProps> = ({
  onChange,
  open,
  sortList,
  onOpenChange
}) => {
  // hack fadeIn的动画
  const fakeStyle = useMemo(
    () => ({
      zIndex: open ? 1 : -1,
      ...(!open && { transitionProperty: 'z-index', transitionDelay: '0.4s' })
    }),
    [open]
  )
  return (
    <Drawer
      className={sortTypeDrawer}
      open={open}
      position="top"
      style={fakeStyle}
      enableDragHandle
      onOpenChange={onOpenChange}
      sidebar={
        <SortTypeList
          sortList={sortList}
          onItemClick={(item) => {
            onChange && onChange(item)
            onOpenChange && onOpenChange(false)
          }}
        />
      }
    >
      <></>
    </Drawer>
  )
}

export default memo(SortTypeDrawer)
