import React, { FC } from 'react'
import { ColumnView } from '../../components/QCFlex'
import { FormTextareaItem, FormSwitchItem } from '../../components/FormItems'

const NoMustCard: FC = () => {
  return (
    <ColumnView className="qc-section-card">
      <FormSwitchItem label="我需要定包厢" field="isRooms" />
      <FormSwitchItem label="如订不到包厢，可接受大厅" field="isHall" />
      <FormTextareaItem
        placeholder="填写我的特别要求"
        label="其它"
        field="message"
        rules={{
          maxLength: { message: '备注最多输入50个字符', value: 50 }
        }}
      />
    </ColumnView>
  )
}

export default NoMustCard
