import { observable, computed, action } from 'mobx'
import isEmpty from 'lodash.isempty'
import {
  DEFAULT_TB_TITLE,
  DEFAULT_TB_BGCOLOR,
  DEFAULT_TB_TXTCOLOR,
  DEFAULT_PAGE_BGCOLOR
} from '../constants/app'

export class AppStore {
  @observable
  title: MaybeNull<string> = DEFAULT_TB_TITLE

  @observable
  hasBackArrow = true

  @observable
  tbBgColor = DEFAULT_TB_BGCOLOR

  @observable
  tbTextColor = DEFAULT_TB_TXTCOLOR

  @observable
  hasTitlebar = true

  @observable
  pageBgColor = DEFAULT_TB_BGCOLOR

  @action
  setByQCPageConfig(config?: QCPageStaticProps) {
    const mconfig = {
      titlebarBgColor: DEFAULT_TB_BGCOLOR,
      titlebarTextColor: DEFAULT_TB_TXTCOLOR,
      title: DEFAULT_TB_TITLE,
      pageBgColor: DEFAULT_PAGE_BGCOLOR,
      hasTitlebar: true,
      noBack: false,
      ...config
    }

    this.hasBackArrow = !mconfig.noBack
    this.title = mconfig.title
    this.tbBgColor = mconfig.titlebarBgColor
    this.tbTextColor = mconfig.titlebarTextColor
    this.hasTitlebar = mconfig.hasTitlebar
    this.pageBgColor = mconfig.pageBgColor
  }
}
