import React, { FC } from 'react'
import { ItemCell } from '../../components/Cell'
import { ColumnView } from '../../components/QCFlex'
import Text from '../../components/Text'

const OrderInfoCard: FC<QCOrderInfo> = ({
  store_name,
  res_invoice_title,
  res_tax_identification_number
}) => {
  return (
    <ColumnView className="qc-section-card">
      <ItemCell
        label={
          <Text size={34} color="#999999FF">
            餐厅开票信息
          </Text>
        }
        arrow={false}
      />
      <ItemCell label="餐厅名称" value={store_name} arrow={false} />
      <ItemCell label="发票抬头" value={res_invoice_title} arrow={false} />
      <ItemCell
        label="发票税号"
        value={res_tax_identification_number}
        arrow={false}
      />
    </ColumnView>
  )
}

export default OrderInfoCard
