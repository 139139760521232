import { observable, action, toJS } from 'mobx'

export class FormStore {
  @observable
  name = ''

  @observable
  data: any = null
  // 用完即毁
  @action
  getOnceData() {
    const cacheData = toJS(this.data)
    this.data = null
    return cacheData
  }
}
