import React, { FC, useEffect, useRef } from 'react'
import { Switch } from 'react-router-dom'
import { Flex } from 'antd-mobile'
import { hot } from 'react-hot-loader/root'
import { main } from './style/app.less'
import TitleBar from './components/Layout/Titlebar'
import Routers from './routes'

import { useCurrentUserQuery } from './generated/graphql'
import { useGlobalStores } from './hooks/store'
import { useCondition, useMount } from './hooks/common'
import { scrollElementContext } from './contexts'
import QCRoute from './components/QCRoute'
import { observer } from 'mobx-react-lite'
import { useRouterChange } from './hooks/route'
import useWeixin from './utils/weixin'
import { useQueryAuthorType } from './api/Author'
const App: FC = () => {
  const scrollElement = useRef()
  const { userStore, appStateStore } = useGlobalStores()
  const { loading, data } = useCurrentUserQuery()

  const [authorRes, authorType] = useQueryAuthorType({
    data: {
      returnUrl: ''
    }
  })
  useEffect(() => {
    const sdata = authorRes?.data?.data
    if (authorRes?.data?.status == 0) {
      userStore.setBindType(sdata)
    }
  }, [authorRes?.data])
  useCondition(!!data?.user, () => {
    userStore.profile = data!.user
  })

  const { config } = useWeixin()

  useMount(config)

  useRouterChange()
  if (loading) return null
  return (
    <Flex
      direction="column"
      align="stretch"
      alignContent="stretch"
      style={{ flex: 1 }}
    >
      <>
        {appStateStore.hasTitlebar && <TitleBar />}

        <Flex.Item
          className={main}
          ref={scrollElement}
          style={{
            backgroundColor: appStateStore.pageBgColor
          }}
        >
          <scrollElementContext.Provider value={scrollElement}>
            <Switch>
              {Routers.map((route) => {
                const props = {
                  key: route.name,
                  path: route.path,
                  ...route.extraProps
                }
                return (
                  <QCRoute
                    {...props}
                    key={route.name}
                    needAuth={!route.public}
                    render={(props) => {
                      console.log('render trigger')
                      return <route.component />
                    }}
                  ></QCRoute>
                )
              })}
            </Switch>
          </scrollElementContext.Provider>
        </Flex.Item>
      </>
    </Flex>
  )
}

export default hot(observer(App))
