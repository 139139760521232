import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useGlobalStores } from './store'
interface PagePickerRouterProps<T, S> {
  field?: string
  onPop?: (item: T) => void
  state?: S
}
export const usePagePickerRouter = <T extends any, S>({
  field = '',
  onPop
}: PagePickerRouterProps<T, S>) => {
  const { pagePickerStore } = useGlobalStores()
  const router = useHistory()

  const pop = (item: T) => {
    pagePickerStore.data = item
    router.goBack()
    // 感觉怪怪的，放到push里面会触发一次useEffect
    // 先放到这里hack
    pagePickerStore.active = true
  }
  const push = (url: string, state?: S) => {
    pagePickerStore.field = field
    router.push(url, state)
  }
  useEffect(() => {
    // 确认是当前field
    // 触发onPop
    if (pagePickerStore.field === field && pagePickerStore.active === true) {
      onPop && onPop(pagePickerStore.getOnceData())
      // pop完事儿后记得关闭
      pagePickerStore.active = false
    }
  }, [])
  return { pop, push }
}
