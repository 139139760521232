import React, { FC, useRef, useEffect } from 'react'
import { SearchBar as AntdSearchBar } from 'antd-mobile'
import { observer } from 'mobx-react-lite'
import { RowView, XCenterView } from '../QCFlex'
import Icon from '../Icon'
import Text from '../Text'
import './index.global.less'
import { useClassNames } from '../../hooks/classname'
import { useCurrentRegion } from '../../hooks/geolocation'
import { debounce } from '../../utils/lodash'

interface IQcSearchBar
  extends JSX.LibraryManagedAttributes<
    typeof AntdSearchBar,
    AntdSearchBar['props']
  > {
  onLocationPress?: () => void
}
interface QCSearchBarProps extends SpreadType<AntdSearchBar['props']> {
  debounceWait?: number
}
export const SearchBar: FC<QCSearchBarProps> = ({
  className,
  autoFocus,
  onChange,
  debounceWait = 400,
  ...otherProps
}) => {
  const classNames = useClassNames(['qc-search-bar', className])
  const ref = useRef<AntdSearchBar>(null)

  // 没有中文输入结束的方法，所以就加个debounce
  const handleChange = debounce((v: string) => {
    onChange && onChange(v)
  }, debounceWait)
  useEffect(() => {
    autoFocus && ref?.current?.focus()
  }, [autoFocus])
  return (
    <>
      <AntdSearchBar
        ref={ref}
        className={classNames}
        onChange={handleChange}
        {...otherProps}
      />
    </>
  )
}

const QcSearchBar: FC<IQcSearchBar> = ({ onLocationPress, ...otherProps }) => {
  const currentRegion = useCurrentRegion()
  return (
    <RowView className="qc-search-bar-wrap">
      <XCenterView className="qc-search-bar-location" onClick={onLocationPress}>
        <Icon name="icondingwei" size={30} color="#ffffff" />
        <Text color="#ffffff" size={34}>
          {currentRegion?.name || '请选择'}
        </Text>
      </XCenterView>
      <SearchBar {...otherProps} />
    </RowView>
  )
}
export default observer(QcSearchBar)
