import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { InputItem, Flex, Toast, WhiteSpace } from 'antd-mobile'
import { useHistory } from 'react-router-dom'
import {
  loginBox,
  submitButton,
  control as controlStyle,
  right as alignRight
} from '../Login/index.less'
import UserActionTab from '../../components/UserActionTab'
import Button from '../../components/Button'
import Page from '../../components/Layout/Page'
import SendValidationCode from '../../components/SendValidationCode'
import { useClassNames } from '../../hooks/classname'
import { useUserRegisterMutation } from '../../generated/graphql'
import { useToastFail } from '../../hooks/toast'
import { useCondition } from '../../hooks/common'
import { useGlobalStores } from '../../hooks/store'
import {
  COMPANY_THEME_TXTCOLOR,
  COMPANY_THEME_COLOR
} from '../../constants/app'

interface RegisterParams {
  mobile: string
  password: string
  rptPassword: string
  sessionId: string
  validationCode: string
}

const Register: QCPage = () => {
  const history = useHistory()
  const { userStore } = useGlobalStores()
  const controlAlignRight = useClassNames([controlStyle, alignRight])

  // 注册表单字段
  const { handleSubmit, control, watch, errors, setValue } = useForm<
    RegisterParams
  >()

  // 观察mobile字段
  const watchMobile = watch('mobile')
  const watchPwd = watch('password')

  const [
    register,
    { loading: regLoading, error: regErr, data: regData }
  ] = useUserRegisterMutation()

  // 注册成功, 跳转
  useCondition(!!regData?.registerResult, () => {
    userStore.login(
      regData!.registerResult.token,
      regData!.registerResult.account
    )
    history.replace('/completeUserInfo')
  })

  // 注册失败, 弹框
  useToastFail(regErr?.message)

  // 提交表单成功, 注册
  const onSubmit = async (param: RegisterParams) => {
    if (!param.sessionId) {
      Toast.fail('请获取验证码')
      return
    }
    register({
      variables: {
        mobile: param.mobile,
        code: param.validationCode,
        password: param.password,
        sessionId: param.sessionId
      }
    })
  }

  return (
    <Page>
      <UserActionTab currentActive="register" />
      <form className={loginBox} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="mobile"
          rules={{
            required: {
              message: '必填',
              value: true
            }
          }}
          defaultValue=""
          render={(props) => (
            <InputItem
              className={controlAlignRight}
              placeholder="请输入手机号"
              {...props}
              error={!!errors.mobile}
              onErrorClick={() => {
                Toast.show(errors.mobile?.message)
              }}
            >
              手机号
            </InputItem>
          )}
        />
        <Controller
          control={control}
          name="validationCode"
          rules={{
            required: {
              value: true,
              message: '必填'
            }
          }}
          defaultValue=""
          render={(props) => (
            <InputItem
              className={controlStyle}
              maxLength={6}
              placeholder="请输入验证码"
              {...props}
              error={!!errors.validationCode}
              extra={
                <SendValidationCode
                  mobile={watchMobile}
                  onResult={(sessionId) => setValue('sessionId', sessionId)}
                />
              }
              onErrorClick={() => {
                Toast.show(errors.validationCode?.message)
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          rules={{
            required: {
              message: '必填',
              value: true
            },
            pattern: {
              value: /^[A-Za-z0-9]{8,}$/,
              message: '至少8位大小写字母或数字'
            }
          }}
          defaultValue=""
          render={(props) => (
            <InputItem
              className={controlAlignRight}
              placeholder="至少8位大小写字母或数字"
              type="password"
              autoComplete="new-password"
              error={!!errors.password}
              onErrorClick={() => {
                Toast.show(errors.password?.message)
              }}
              {...props}
            >
              密码
            </InputItem>
          )}
        />
        <Controller
          control={control}
          name="rptPassword"
          rules={{
            required: {
              message: '必填',
              value: true
            },
            pattern: {
              value: /^[A-Za-z0-9]{8,}$/,
              message: '至少8位大小写字母或数字'
            },
            validate: {
              shouldEqualPwd: (value) => value === watchPwd
            }
          }}
          defaultValue=""
          render={(props) => (
            <InputItem
              placeholder="再次输入设定的密码"
              className={controlAlignRight}
              type="password"
              error={!!errors.rptPassword}
              autoComplete="new-password"
              onErrorClick={() => {
                Toast.show(errors.rptPassword?.message)
              }}
              {...props}
            >
              确认密码
            </InputItem>
          )}
        />
        <WhiteSpace size="lg" />
        <WhiteSpace size="lg" />
        <Flex
          alignContent="center"
          align="center"
          justify="center"
          direction="row"
        >
          <Button disabled={regLoading} type="submit" className={submitButton}>
            下一步
          </Button>
        </Flex>
      </form>
    </Page>
  )
}

Register.config = {
  title: '注册',
  noBack: true,
  titlebarBgColor: COMPANY_THEME_COLOR,
  titlebarTextColor: COMPANY_THEME_TXTCOLOR,
  pageBgColor: COMPANY_THEME_COLOR
}

export default Register
