import React, { FC } from 'react'

import AutoListView, {
  IAutoListViewFormatFn
} from '../../components/AutoListView'
import OrderItem from './OrderItem'
import { useOrderList } from '../../api/TakeawayOrder'
import { ColumnView } from '../../components/QCFlex'
import EmptyView from '../../components/EmptyView'
import { orderList, autoListContainer } from './index.less'
interface ListResponse {
  list?: QCOrderInfo[]
}
const ListEmptyView = () => <EmptyView text="暂无订单数据" />
const PAGE_SIZE = 10
const formatRes: IAutoListViewFormatFn<QCOrderInfo, ListResponse> = ({
  list = []
}) => ({
  list: list || [],
  nomore: list?.length < PAGE_SIZE
})
const renderRestaurantItem = (item: QCOrderInfo) => <OrderItem {...item} />

const itemKeyExtractor = (item: QCOrderInfo) => item.id
const TakeawayOrderList: QCPage = () => {
  return (
    <ColumnView className={orderList}>
      <AutoListView
        className={autoListContainer}
        startPage={1}
        pageSize={PAGE_SIZE}
        renderItem={renderRestaurantItem}
        format={formatRes}
        useService={useOrderList}
        itemKeyExtractor={itemKeyExtractor}
        EmptyView={ListEmptyView}
      />
    </ColumnView>
  )
}

TakeawayOrderList.config = {
  title: '外卖订单'
}

export default TakeawayOrderList
