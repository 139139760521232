import { AxiosRequestConfig } from 'axios'
import makeUseApi from '../utils/makeUseApi'

export const queryRegions: AxiosRequestConfig = {
  method: 'POST',
  url: '/europe/tool/getRegions'
}

export const useQueryClientOpenArea = makeUseApi<
  Definitions.Europe.获取城市信息,
  Definitions.Europe.SearchResponseOf获取城市信息返回值
>(queryRegions)
