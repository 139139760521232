// 公用
import HospitalSeaarch from '../../views/HospitalSeaarch'
import RestaurantInfoCorrecting from '../../views/RestaurantInfoCorrecting'
import RestaurantGoodsList from '../../views/RestaurantGoodsList'
import FormDemo from '../../views/FormDemo'
import DemoRestaurantList from '../../views/DemoRestaurantList'
import OnlineRestaurantDetail from '../../views/OnlineRestaurantDetail'
import HomeSearch from '../../views/HomeSearch'
import AllRestaurantSearch from '../../views/AllRestaurantSearch'
import UploadTicket from '../../views/UploadTicket'
import OrderConfirm from '../../views/OrderConfirm'
import FlexDemo from '../../views/FlexDemo'
import HandleGuanAiTongCallback from '../../views/HandleGuanAiTongCallback'
import QCMap from '../../views/Map'

const Routers = [
  {
    name: 'HospitalSeaarch',
    path: '/hospitalSeaarch',
    component: HospitalSeaarch
  },
  {
    name: 'RestaurantInfoCorrecting',
    path: '/restaurantInfoCorrecting/:restaurantId',
    component: RestaurantInfoCorrecting
  },
  {
    name: 'RestaurantGoodsList',
    path: '/restaurantGoodsList/:restaurantId',
    component: RestaurantGoodsList
  },
  {
    name: 'FormDemo',
    path: '/formDemo',
    public: true,
    component: FormDemo
  },
  {
    name: 'DemoRestaurantList',
    path: '/demoRestaurantList',
    public: true,
    component: DemoRestaurantList
  },
  {
    name: 'HomeSearch',
    path: '/homeSearch',
    public: true,
    component: HomeSearch
  },
  {
    name: 'OnlineRestaurantDetail',
    path: '/onlineRestaurantDetail/:restaurantId',
    component: OnlineRestaurantDetail
  },
  {
    name: 'AllRestaurantSearch',
    path: '/allRestaurantSearch',
    component: AllRestaurantSearch
  },
  { name: 'OrderConfirm', path: '/orderConfirm', component: OrderConfirm },
  { name: 'FlexDemo', path: '/flexDemo', component: FlexDemo },
  {
    name: 'UploadTicket',
    path: '/uploadTicket',
    component: UploadTicket
  },
  {
    name: 'HandleGuanAiTongCallback',
    path: '/handleGuanAiTongCallback',
    component: HandleGuanAiTongCallback
  },
  {
    name: 'Map',
    path: '/map',
    component: QCMap
  }
]

export default Routers
