import React, { FC, memo } from 'react'
import { useHistory } from 'react-router-dom'
import { RowView } from '../../components/QCFlex'
import Icon from '../../components/Icon'
import FormRow, { FormRowProps } from '../../components/FormItems/FormRow'
import {
  ConnectForm,
  ConnectFormProps,
  ConnectFormChildrenProps
} from '../../components/QCForm'
type BaseTaxPickerProps = FormRowProps

export interface FormTaxPickerProps
  extends BaseTaxPickerProps,
    ConnectFormProps {}

export interface TaxPickerProps
  extends BaseTaxPickerProps,
    Omit<ConnectFormChildrenProps, 'onBlur'>,
    ConnectFormProps {}
export const TaxPicker: FC<TaxPickerProps> = ({
  label,
  disabled,
  placeholder,
  value
}) => {
  const router = useHistory()

  const handleClick = () => {
    !disabled && router.push('/shippingTaxList')
  }
  const isPlaceholder = !value
  const textClassName = isPlaceholder ? 'qc-input-placeholder' : ''
  return (
    <FormRow label={label}>
      <RowView vAlign="center" hAlign="end">
        <span className={textClassName} onClick={handleClick}>
          {isPlaceholder ? placeholder : value?.invoiceTitle}
        </span>
        <Icon name="iconyoujiantou" size={18} color="#888888FF" />
      </RowView>
    </FormRow>
  )
}

const MTaxPicker: FC<ConnectFormChildrenProps> = memo(
  ({ formState, watch, ...otherProps }) => <TaxPicker {...otherProps} />,
  (prevProps, nextProps) =>
    prevProps.formState?.isDirty === nextProps.formState?.isDirty
)
MTaxPicker.displayName = 'MTaxPicker'
const FormTaxPicker: FC<FormTaxPickerProps> = ({ field, ...otherProps }) => (
  <ConnectForm field={field}>
    <TaxPicker {...otherProps} />
  </ConnectForm>
)

export default FormTaxPicker
