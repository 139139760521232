import React, { FC } from 'react'
import { Button } from 'antd-mobile'
import { button } from './index.less'
import { useCountdown } from '../../hooks/coutdown'
import { useSendVerifyCodeMutation } from '../../generated/graphql'
import { useToastFail } from '../../hooks/toast'
import { useCondition } from '../../hooks/common'

interface SendValidationCode {
  onResult: (sessionId: string) => void
  mobile?: string
}

const SendValidationCode: FC<SendValidationCode> = ({ mobile, onResult }) => {
  const { start, current } = useCountdown(60)
  const [send, { data, loading, error }] = useSendVerifyCodeMutation()

  useCondition(!!data?.sessionId, () => {
    onResult(data?.sessionId!)
    start()
  })

  useToastFail(error?.message)

  const sendCode = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault()
    if (loading || !mobile || current) {
      return
    }
    send({
      variables: {
        mobile
      }
    })
  }

  return (
    <Button
      onClick={sendCode}
      className={button}
      loading={loading}
      disabled={!mobile || !!current}
    >
      {current ? `${current}s 后重新发送` : '获取验证码'}
    </Button>
  )
}

export default SendValidationCode
