import React, { FC, useEffect, useState } from 'react'
import { browser } from '../../utils/weixin'
import { useHistory } from 'react-router-dom'
import * as authorCss from './index.less'
import { useQueryAuthorType } from '../../api/Author'
import { GetQueryString } from '../../utils/lodash'
import { useUnionOrderDetail } from '../../api/Order'
import { useCountdown } from '../../hooks/coutdown'
import { useInterval } from '../../hooks/timer'
import { useCondition } from '../../hooks/common'
import { ColumnView } from '../../components/QCFlex'
import Text from '../../components/Text'
import { Icon } from 'antd-mobile'
import WecharTip from '../../assets/wecharTip.jpg'
const CheckAuthor: QCPage = () => {
    const router = useHistory()
    const [isWeixin, setIsWeixin] = useState(true)
    const type = GetQueryString('type')
    const orderId = GetQueryString('orderId') || undefined
    const orderType = GetQueryString('orderType') ? 'RESERVATION' : 'RECOMMEND'
    const [authorRes, authorType] = useQueryAuthorType({
        manual: true,
        data: {
            returnUrl: window.location.protocol + "//" + window.location.host + "/BindEntyCode"
        }
    })
    const [{ data, error }, refetch] = useUnionOrderDetail(
        orderType
    )({
        useCache: true,
        manual: true,
        data: {
            orderId
        }
    })
    // function pushHistory() {
    //     var state = {
    //         title: "title",
    //         url: "#"
    //     };
    //     window.history.pushState(state, "title", "#forward");
    // }

    useEffect(() => {
        // pushHistory();
        // setTimeout(() => {
        //     window.addEventListener('popstate', function (e) {
        //         // setIsWeixin(false)
        //     }, false)
        // }, 0)
        const openurl = GetQueryString('url')
        localStorage.setItem('isfirstBind', 'true')
        setIsWeixin(browser.versions.weixin)
        if (openurl) {
            if (!browser.versions.weixin) {
                if (type == 'play') {
                    const newurl = `https://render.alipay.com/p/s/i/?scheme=${encodeURIComponent(openurl)}`
                    window.location.href = newurl
                }
                else if (type == 'author') {
                    window.location.href = openurl;
                }
            }

        }
    }, [])

    useEffect(() => {
        if (!isWeixin) {
            if (type == 'play') checkAplyStatus()
            else if (type == 'author') checkStatus()
        }
    }, [
        isWeixin
    ])

    /**检查授权状态 */
    const { start: auStart, stop: auStop, current: currentAuthor } = useCountdown(10)
    useCondition(authorRes?.data?.data && currentAuthor !== null, async () => {
        const status = authorRes?.data?.data?.state
        if (status == 'SUCCESS') {
            auStop()
            console.log("我是授权成功了")
            router.replace('/')
        }

    })
    useInterval(
        () => {
            return authorType();
        },
        currentAuthor ? 3000 : null
    )
    useEffect(() => {
        if (currentAuthor == 0) {
            router.replace('/BindEntyCode', {
                authorStatus: false
            })
        }
    }, [currentAuthor])
    const checkStatus = () => {
        auStart()
    }


    //轮询商宴通企业码支付状态
    const { start, stop, current: currentSec } = useCountdown(10)
    useCondition(data?.data && currentSec !== null, async () => {
        console.log("我是获取到了支付结果")
        if (data?.data?.order_state == '6') {
            console.log("支付成功")
            stop()
            router.replace(`/eatinOrderDetail/${orderId}`)
        }
    })
    useEffect(() => {
        if (currentSec == 0) {
            console.log("我轮询订单结果结束")
            router.replace(`/eatinOrderDetail/${orderId}`)
        }
    }, [currentSec])
    useInterval(
        () => {
            return refetch();
        },
        currentSec && orderId ? 3000 : null
    )
    /**检查企业付状态 */
    const checkAplyStatus = () => {
        start()
    }
    return (
        <>
            {
                isWeixin ? <div className={authorCss.weixintip}>
                    <img src={WecharTip} alt="" style={{ width: '100vw', height: '100%' }} />
                </div> : <div className={authorCss.author}>
                    <ColumnView hAlign="center" className="qc-section-card" style={{ background: 'none' }}>
                        <Icon type="loading" color="#51A04CFF" size="lg" />
                        <Text size={34} color="#181818FF" margin={[40, 0, 0, 0]}>
                            {

                                type == 'play' ? '等待企业码支付，请稍后' : '等待企业码授权，请稍后'
                            }
                        </Text>
                        <Text margin={[20, 0, 40, 0]} color="#999999FF" size={26}>
                            {currentSec ? `将在${currentSec}s后跳转` : ''}
                            {currentAuthor ? `将在${currentAuthor}s后跳转` : ''}
                        </Text>
                    </ColumnView>

                </div>

            }
        </>
    )
}
CheckAuthor.config = {
    title: '支付宝引导页',
}
export default CheckAuthor