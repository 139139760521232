import React, { useState, useEffect, memo } from 'react'
import { TabBar } from 'antd-mobile'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation } from 'react-router-dom'
import Icon from '../../components/Icon'
import FixedCustomerBtn from '../../components/FixedCustomerBtn'

import Dashboard from './Dashboard'
import UserInfo from './UserInfo'
import OrderManage from './OrderManage'
import { useGlobalStores } from '../../hooks/store'
import { usePullRefresh } from '../../hooks/layout'
import { useOrderLockCheck } from '../../hooks/order'
enum ETabName {
  home = 'home',
  mine = 'mine',
  orderManage = 'orderManage'
}
type TAllTabName = keyof typeof ETabName
interface IUrlParams {
  tab?: TAllTabName
}
interface ITabOption {
  icon?: string
  title?: string
  name: TAllTabName
  content?: JSX.Element
}
interface LocationState {
  hash?: TAllTabName
}
const CustomerBtn = memo(FixedCustomerBtn)
const TabBarOptions: ITabOption[] = [
  {
    icon: 'iconshouye',
    title: '首页',
    name: ETabName.home,
    content: <Dashboard />
  },
  {
    icon: 'icondingdan',
    title: '订单',
    name: ETabName.orderManage,
    content: <OrderManage />
  },
  {
    icon: 'iconwode',
    title: '我的',
    name: ETabName.mine,
    content: <UserInfo />
  }
]
const Home: QCPage = () => {
  const { hash } = useLocation<LocationState>()
  const router = useHistory()
  const { appStateStore } = useGlobalStores()
  const defaultTab = hash ? hash.replace(/#/g, '') : ETabName.home
  // 这里react router 的useLocation ts定义有问题
  // 强行as一下
  const [selectedTab, updateSelectedTab] = useState<TAllTabName>(
    defaultTab as TAllTabName
  )
  const handleTabItemClick = (item: ITabOption) => {
    window.history.pushState({}, '', `#${item.name}`)
    updateSelectedTab(item.name)
  }
  // 初始化hash
  useEffect(() => {
    !hash && window.history.pushState({}, '', `#${ETabName.home}`)
  }, [])
  useEffect(() => {
    const current = TabBarOptions.find((item) => item.name === selectedTab)
    appStateStore.title = current?.title
    appStateStore.hasTitlebar = current?.name !== 'home'

    console.log('trigger home tab change effect')
  }, [selectedTab])
  // 锁单检查
  // 只有首页生效
  // 不要锁单检查了 2020年09月23日16:54:05
  // 只检查锁单，不检查限额
  const handleOrderLockCheckOk = () => {
    window.history.pushState({}, '', `#orderManage`)
    updateSelectedTab('orderManage')
  }
  useOrderLockCheck({
    manual: !['home'].includes(selectedTab),
    visible: ['home'].includes(selectedTab),
    onOk: handleOrderLockCheckOk
  })
  usePullRefresh(() => {
    window.location.reload()
  })
  return (
    <>
      <TabBar
        unselectedTintColor="#888888"
        tintColor="#181818ff"
        barTintColor="white"
        tabBarPosition="bottom"
      >
        {TabBarOptions.map((item) => (
          <TabBar.Item
            key={item.name}
            selected={selectedTab === item.name}
            selectedIcon={
              <Icon name={item.icon} color="rgba(255,152,71,1)" size={36} />
            }
            title={item.title}
            icon={<Icon name={item.icon} color="#cccccc" size={36} />}
            onPress={() => handleTabItemClick(item)}
          >
            {item.content}
          </TabBar.Item>
        ))}
      </TabBar>
      <CustomerBtn />
    </>
  )
}

Home.config = {
  title: '首页',
  noBack: true,
  hasTitlebar: false
}

export default observer(Home)
