// 定制FormRow,样式统一且固定
// 如有需求另行封装
import React, { FC, PropsWithChildren, useCallback } from 'react'
import { useClassNames } from '../../../hooks/classname'
import './index.global.less'
export type TDirection = 'horizontal' | 'vertical'
// 这个interface 纯粹是为了隔离下
// div、input等原生标签的一切属性名相同但是签名不同
// eg. onClick
interface NativeFormRowProps {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}
export interface FormRowProps {
  label?: React.ReactNode
  direction?: TDirection
  required?: boolean
}
interface MergedFormRowProps
  extends PropsWithChildren<FormRowProps>,
    NativeFormRowProps {}
const FormRow: FC<MergedFormRowProps> = ({
  label,
  direction = 'horizontal',
  children,
  required,
  onClick
}) => {
  const mergedClassName = useClassNames([
    'qc-form-row',
    direction,
    required ? 'required' : null
  ])
  return (
    <div className={mergedClassName} onClick={onClick}>
      <label className="qc-form-row-label">{label}</label>
      <div className="qc-form-row-input">{children}</div>
    </div>
  )
}

export default FormRow
