import { persist } from 'mobx-persist'
import { observable, action, toJS } from 'mobx'

const MAX_CACHE_STATE_LENGTH = 10

export class StateInfo {
  @observable
  data: any = null

  @persist
  @observable
  key = ''
}
export class CacheStateStore {
  @persist('list', StateInfo)
  @observable
  states: StateInfo[] = []
  @action
  getDataByKey(cacheKey: string): any {
    const data = this.states.find((item) => item.key === cacheKey)?.data
    return data ? toJS(data) : undefined
  }
  // 定向删除
  @action
  clearDataByKey(cacheKey: string): void {
    const stateIdx = this.states.findIndex((item) => item.key === cacheKey)
    if (stateIdx > -1) {
      this.states.splice(stateIdx, 1)
    }
  }

  // 删除所有
  @action
  clearAll(): void {
    this.states = []
  }
  // 添加
  @action
  add(state: StateInfo): void {
    if (!state.data || !state.key) return
    const existIdx = this.states.findIndex((c) => c.key === state.key)
    // 已缓存的就更新缓存
    if (existIdx >= 0) {
      this.states[existIdx] = state
      return
    }
    // 超出阈值删除一个
    if (this.states.length >= MAX_CACHE_STATE_LENGTH) {
      this.states = this.states.slice(1, MAX_CACHE_STATE_LENGTH)
    }
    this.states.push(state)
  }
}
