import React, { FC, useCallback, memo } from 'react'
import { useHistory } from 'react-router-dom'

import { ColumnView } from '../../../components/QCFlex'
import SearchBar from '../../../components/SearchBar'

import Takeawaylogo from '../../../assets/takeawaylogo.png'
import EatinLogo from '../../../assets/eatinLogo.png'

import FoodCard from './FoodCard'

import { container, cardContainerWarp } from './index.less'
const DashboardOptions = [
  {
    type: '订座',
    color: '#3BB880FF',
    label: '店内用餐',
    dsc: '在线订座享安心',
    avtar: EatinLogo,
    path: '/eatinRestaurantSearch'
  },
  {
    type: '外卖',
    color: '#B8963BFF',
    label: '星选外卖',
    dsc: '优质外卖准时达',
    avtar: Takeawaylogo,
    path: '/takeawayRestaurantSearch'
  }
]
const Dashboard: FC = () => {
  const router = useHistory()
  const handleLocationPress = useCallback(() => {
    router.push('/cityLocation', { a: 1 })
  }, [])
  const goSearch = useCallback(() => {
    router.push('/homeSearch')
  }, [])
  return (
    <ColumnView className={container}>
      <SearchBar
        onLocationPress={handleLocationPress}
        onFocus={goSearch}
        placeholder="请输入餐厅或医院名称"
      />
      <ColumnView className={cardContainerWarp}>
        {DashboardOptions.map((opt) => (
          <FoodCard
            key={opt.type}
            type={opt.type}
            color={opt.color}
            label={opt.label}
            dsc={opt.dsc}
            avtar={opt.avtar}
            path={opt.path}
          />
        ))}
      </ColumnView>
    </ColumnView>
  )
}

export default memo(Dashboard)
