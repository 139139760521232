import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import Icon from '../../../components/Icon'
import Text from '../../../components/Text'
import { avtar, card, container } from './index.less'
import { ColumnView, RowView, XCenterView } from '../../../components/QCFlex'

export interface OrderCardProps {
  avtarName?: string
  avtarColor?: string
  count?: number
  name?: string
}

const OrderCard: FC<OrderCardProps> = ({ avtarName, avtarColor, name }) => (
  <RowView
    className={`qc-section-card ${card}`}
    vAlign="center"
    hAlign="between"
  >
    <XCenterView className={avtar} bgColor={avtarColor}>
      <Icon name={avtarName} size={42} color="#ffffff" />
    </XCenterView>
    <Text color="#181818FF" size={34} style={{ flex: 1 }}>
      {name}
    </Text>
    {/* <Badge overflowCount={99} text={count} /> */}
  </RowView>
)

const OrderManageOptions = [
  {
    name: '餐厅订单',
    count: 4,
    avtarName: 'iconcantingdingdan1',
    avtarColor: '#ADCFD2',
    path: '/eatinOrderList'
  },
  {
    name: '外卖订单',
    count: 1,
    avtarName: 'iconwaimaidingdan1',
    avtarColor: '#D2ADC9',
    path: '/takeawayOrderList'
  }
]

const OrderManage: FC = () => {
  return (
    <ColumnView className={container}>
      {OrderManageOptions.map((item) => (
        <Link key={item.name} to={item.path}>
          <OrderCard
            avtarColor={item.avtarColor}
            name={item.name}
            avtarName={item.avtarName}
            count={item.count}
          />
        </Link>
      ))}
    </ColumnView>
  )
}

export default OrderManage
