import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { ColumnView } from '../../components/QCFlex'
import { ItemCell } from '../../components/Cell'
import Text from '../../components/Text'
import { STY_CUSTOMER_PHONE } from '../../constants/other'
const ShopInfoCard: FC<QCOrderInfo> = ({
  store_name,
  store_phone = '',
  delivery_date = '',
  delivery_clock = '',
  restaurant_id
}) => {
  return (
    <ColumnView className="qc-section-card">
      <ItemCell
        label={
          <Text size={34} color="#999999FF">
            商户信息
          </Text>
        }
        arrow={false}
      />
      <Link to={`/onlineRestaurantDetail/${restaurant_id}`}>
        <ItemCell
          icon={{ name: 'icondianputubiao', color: '#F4630BFF' }}
          label={store_name}
        />
      </Link>
      <a href={`tel:${STY_CUSTOMER_PHONE}`}>
        <ItemCell
          icon={{ name: 'icondianhuatubiao', color: '#F4630BFF' }}
          label={`${store_phone}（餐厅电话）`}
        />
      </a>
      <ItemCell
        icon={{ name: 'iconshijiantubiao', color: '#F4630BFF' }}
        label={`预计${delivery_date} ${delivery_clock}送达`}
        arrow={false}
      />
    </ColumnView>
  )
}

export default ShopInfoCard
