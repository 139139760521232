import React, { FC, memo } from 'react'
import FormRow, { FormRowProps } from '../FormRow'
import {
  ConnectForm,
  ConnectFormProps,
  ConnectFormChildrenProps
} from '../../QCForm'
import './index.global.less'
import { useClassNames } from '../../../hooks/classname'
export type NativeInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>
export type MInputItemProps = Omit<ConnectFormChildrenProps, 'value' | 'onBlur'>
export interface FormInputItemProps
  extends FormRowProps,
    ConnectFormProps,
    NativeInputProps {}
export interface InputItemProps
  extends FormRowProps,
    NativeInputProps,
    Omit<ConnectFormChildrenProps, 'value' | 'onBlur'> {
  field?: string
}
type TOnblur = ((event: React.FocusEvent<HTMLInputElement>) => void) | undefined
const onBlurWrap = (onblur: TOnblur) => (
  e: React.FocusEvent<HTMLInputElement>
) => {
  onblur && onblur(e)
  // fix#ios 键盘收起页面推上去没有自动拉下来的bug
  window.scrollTo()
}
export const InputItem: FC<InputItemProps> = ({
  label,
  className,
  sync,
  register,
  onBlur,
  field,
  rules,
  ...otherProps
}) => {
  const mergedClassName = useClassNames(['qc-input', className])
  return (
    <FormRow label={label} required={!!rules?.required}>
      <input
        name={field}
        onBlur={onBlurWrap(onBlur)}
        ref={rules ? register?.(rules) : register}
        className={mergedClassName}
        {...otherProps}
      />
    </FormRow>
  )
}
// 针对 FormProvider 的 带优化的inputitem
const MInputItem: FC<MInputItemProps> = memo(
  ({ formState, watch, ...otherProps }) => <InputItem {...otherProps} />,
  (prevProps, nextProps) =>
    prevProps.formState?.isDirty === nextProps.formState?.isDirty
)
const FormInputItem: FC<FormInputItemProps> = (props) => (
  <ConnectForm>
    <MInputItem {...props} />
  </ConnectForm>
)
export default FormInputItem
