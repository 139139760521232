import React, { FC } from 'react'
import { ColumnView } from '../../components/QCFlex'
import { FormInputItem, FormTextareaItem } from '../../components/FormItems'
import FormTaxPicker from './FormTaxPicker'

const InfoCard: FC = () => {
  return (
    <ColumnView className="qc-section-card">
      <FormInputItem
        placeholder="请输入用餐人数"
        label="用餐人数"
        field="peopleNum"
        type="number"
        rules={{
          required: { message: '请输入用餐人数', value: true },
          max: { message: '用餐人数最多输入9999', value: 9999 }
        }}
      />
      <FormTaxPicker
        placeholder="请选择发票抬头"
        label="发票信息"
        field="tax"
        disabled
      />
      <FormTextareaItem
        placeholder="请填写备注"
        label="备注"
        field="message"
        rules={{
          maxLength: { message: '备注最多输入50个字符', value: 50 }
        }}
      />
    </ColumnView>
  )
}

export default InfoCard
