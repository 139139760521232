import React, { FC, memo, useCallback, useMemo } from 'react'
import { Picker } from 'antd-mobile'
import uniqby from 'lodash.uniqby'
import dayjs from 'dayjs'

import Icon from '../../Icon'
import FormRow, { FormRowProps } from '../FormRow'
import {
  ConnectForm,
  ConnectFormProps,
  ConnectFormChildrenProps
} from '../../QCForm'
import { PickerData } from 'antd-mobile/lib/picker/PropsType'
interface BaseDeliveryTimePickerProps extends FormRowProps {
  dates?: Definitions.Aimee.DateModel[]
}

export interface FormDeliveryTimePickerProps
  extends BaseDeliveryTimePickerProps,
    ConnectFormProps {}

export interface DeliveryTimePickerProps
  extends BaseDeliveryTimePickerProps,
    Omit<ConnectFormChildrenProps, 'onBlur'>,
    ConnectFormProps {}
interface ShowTextProps {
  placeholder?: string
  onClick?: () => void
  extra?: string
}
interface IListData {
  id: string
  pId: string
  value: string
  label: string
  children?: IListData[]
}
// 转化
const listToTree = (list: IListData[]) => {
  const map: { [key: string]: IListData } = {}
  list.forEach((item) => {
    if (!map[item.id]) {
      map[item.id] = item
    }
  })

  list.forEach((item) => {
    if (item.pId !== 'root') {
      map[item.pId].children
        ? map[item.pId]?.children?.push(item)
        : (map[item.pId].children = [item])
    }
  })

  return list.filter((item) => {
    if (item.pId === 'root') {
      return item
    }
  })
}
// 格式化
const old_format = (dates: Definitions.Aimee.DateModel[]): PickerData[] => {
  // [{value:'',label:'',children:[]}]
  let resultArray: IListData[] = []
  // 遍历转化数据结构 resultMap = {2020:{09:{09:{18:{45:{}}}}}}
  dates?.map((item) => {
    // 2020-09-19[]
    const [year, month, day] = item.date?.split('-')
    resultArray = resultArray.concat([
      {
        pId: 'root',
        id: year,
        value: year,
        label: year
      },
      // 月
      {
        pId: year,
        id: `${year}-${month}`,
        value: month,
        label: month
      },
      // 日
      {
        pId: `${year}-${month}`,
        id: `${year}-${month}-${day}`,
        value: day,
        label: day
      }
    ])
    item.time?.map((time) => {
      // 18:50
      // 给一波默认值
      const [hours, minutes] = time.split(':')
      resultArray = resultArray.concat([
        // 时
        {
          pId: `${year}-${month}-${day}`,
          id: `${year}-${month}-${day}-${hours}`,
          label: hours,
          value: hours
        },
        // 分
        {
          pId: `${year}-${month}-${day}-${hours}`,
          id: `${year}-${month}-${day}-${hours}-${minutes}`,
          label: minutes,
          value: minutes
        }
      ])
    })
  })
  return listToTree(uniqby(resultArray, 'id'))
}
const ShowText: FC<ShowTextProps> = memo(
  ({ onClick, extra, placeholder }) => {
    const isPlaceholder = useMemo(() => extra === placeholder, [extra])
    const textClassName = isPlaceholder ? 'qc-input-placeholder' : ''
    return (
      <span className={textClassName} onClick={onClick}>
        {isPlaceholder ? placeholder : extra}
        <Icon name="iconyoujiantou" size={20} color="rgb(136, 136, 136)" />
      </span>
    )
  },
  (prevProps, nextProps) =>
    prevProps.extra === nextProps.extra &&
    prevProps.placeholder === nextProps.placeholder
)
ShowText.displayName = 'MShowText'
const format = (dates: Definitions.Aimee.DateModel[]): PickerData[] => {
  // 删除date 中的周一
  return dates.map((item) => {
    const dateValue = item.date?.split('  ')[0] || ''
    return {
      value: dateValue,
      label: dateValue,
      children: item.time?.map((timeItem) => ({
        value: timeItem,
        label: timeItem
      }))
    }
  })
}
export const DeliveryTimePicker: FC<DeliveryTimePickerProps> = ({
  label,
  sync,
  dates,
  placeholder,
  disabled,
  value,
  rules,
  ...otherProps
}) => {
  const formatedData = dates ? format(dates) : []
  const handleChange = useCallback(
    (date: any) => {
      const [yearMonthDay, hoursMunites] = date
      sync && sync(`${yearMonthDay} ${hoursMunites}`)
    },
    [sync]
  )
  const formatValue = value
    ? dayjs(value).format('YYYY-MM-DD HH:mm').split(' ')
    : undefined
  const showDateFormat = (values: React.ReactNode[]) => {
    const [yearMonthDay, hoursMunites] = values
    return values.length ? `${yearMonthDay} ${hoursMunites}` : ''
  }
  return (
    <FormRow label={label} required={!!rules?.required}>
      <Picker
        data={formatedData}
        disabled={disabled}
        value={formatValue}
        format={showDateFormat}
        cols={2}
        {...otherProps}
        onChange={handleChange}
        // 先自定义个extra用来判断placeholder
        extra={placeholder}
      >
        <ShowText placeholder={placeholder} />
      </Picker>
    </FormRow>
  )
}

const FormDeliveryTimePicker: FC<FormDeliveryTimePickerProps> = ({
  field,
  ...otherProps
}) => (
  <ConnectForm field={field} rules={otherProps.rules}>
    <DeliveryTimePicker {...otherProps} />
  </ConnectForm>
)

export default FormDeliveryTimePicker
