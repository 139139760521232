import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import Text from '../../components/Text'
import { STY_CUSTOMER_PHONE } from '../../constants/other'
import { footer } from './index.less'
import { XCenterView } from '../../components/QCFlex'

const Footer: FC = () => (
  <XCenterView className={footer}>
    <Text color="#CCCCCCFF" size={28}>
      如您发现餐厅信息有误，可
      <a href={`tel:${STY_CUSTOMER_PHONE}`}>
        <Text color="#FF9847FF">联系客服</Text>
      </a>
      进行纠错
    </Text>
  </XCenterView>
)

export default Footer
