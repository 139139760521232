import { Toast } from 'antd-mobile'
import { useEffect } from 'react'
import { ResponseValues } from 'axios-hooks'
type TRestToastFail = ResponseValues<
  MaybeNull<Partial<Definitions.Agalia.RestResponse>>
>
const methods = [
  'show',
  'info',
  'success',
  'fail',
  'offline',
  'loading'
] as const

const createUseToast = (method: typeof methods[number]) => (
  message?: string | boolean | null,
  config?: {
    duration?: number
    mask?: boolean
    onClose?: () => void
  }
) => {
  useEffect(() => {
    if (!message) {
      Toast.hide()
      return
    }
    if (method === 'show') {
      Toast.show(message, config?.duration, config?.mask)
    } else {
      Toast[method](message, config?.duration, config?.onClose, config?.mask)
    }
    // return () => {
    //   Toast.hide()
    // }
  }, [message])
}

export const getErrorMessage = <T extends TRestToastFail>(
  res: T,
  placeholder = '网络请求失败'
): string | undefined =>
  (res.data?.status && res.data?.status !== 0
    ? res.data?.message || placeholder
    : null) || res?.error?.message

export const useToastInfo = createUseToast('info')
export const useToastSuccess = createUseToast('success')
export const useToastFail = createUseToast('fail')
export const useToastOffline = createUseToast('offline')
export const useToastLoading = createUseToast('loading')
export const useRestToastFail = (resArray: TRestToastFail[]): void => {
  const errorMessage = resArray
    ?.map((item) => getErrorMessage(item))
    .find((item) => !!item)
  return useToastFail(errorMessage)
}
