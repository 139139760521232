// 堂食餐厅订座
import React, { useRef, useState, useCallback, memo } from 'react'
import { useLocation, Link } from 'react-router-dom'

import { ColumnView, XCenterView } from '../../components/QCFlex'
import Text from '../../components/Text'
import { useRestaurantSearch } from '../../api/EatinRestaurant'
import AutoListView, {
  IAutoListViewFormatFn
} from '../../components/AutoListView'
import EmptyView from '../../components/EmptyView'
import { useGlobalStores } from '../../hooks/store'
import SearchFilter from './SearchFilter'
import RestaurantItem from './RestaurantItem'

import { container, restaurantList } from './index.less'
import { useCurrentAmapGeo } from '../../hooks/geolocation'
import {
  IRestaurant,
  HistoryStateProps,
  FormatParamsProps,
  IFilterMethods
} from './interface'
const Footer = memo(() => (
  <XCenterView margin={[30, 0, 0, 0]}>
    <Link to="/recommendRestaurantList">
      <Text color="#CCCCCCFF" size={28}>
        去推荐餐厅 {'>'}
      </Text>
    </Link>
  </XCenterView>
))
Footer.displayName = 'MFooter'
const ListEmptyView = () => (
  <>
    <EmptyView />
    <Footer />
  </>
)

const itemKeyExtractor = (item: IRestaurant) => item.rid || item.poiId || ''
// 带医院和不带医院搜索是两种搜索方式
const formatParams = ({
  hospital,
  cook_style,
  city,
  sort
}: FormatParamsProps) =>
  hospital
    ? {
        address: hospital.address,
        keywords: hospital.hospitalName,
        lat: hospital.location?.lat?.toString(),
        lng: hospital.location?.lng?.toString(),
        city_id: city?.id,
        city: city?.name || '',
        cook_style,
        // 有医院是3
        type: '3',
        sort
      }
    : {
        city_id: city?.id,
        city: city?.name || '',
        cook_style,
        // 没有医院是1
        type: '1',
        sort
      }
const EatinRestaurantSearch: QCPage = () => {
  const location = useLocation<HistoryStateProps>()
  const { cityStore } = useGlobalStores()
  const filterRef = useRef<IFilterMethods | null>(null)

  const cityInfo = cityStore?.info
  const hospital = location.state?.hospital
  // 带默认参数的情况
  const poiParams = formatParams({ hospital, city: cityStore?.info })
  const defaultFilterParams = {
    hospital
  }
  // filter参数
  const [filterParams, updateFilterParams] = useState<
    Definitions.Europe.搜索商户参数
  >({
    needUnsigned: true,
    sort: '4',
    ...poiParams
  })
  // filter菜系参数，从每次返回中获取，所以把formatRes放到了内部
  const [cateList, updateCateList] = useState<Definitions.Europe.菜品分类[]>([])

  // autoList formatRes
  const formatRes: IAutoListViewFormatFn<
    IRestaurant,
    Definitions.Europe.搜索商户返回值
  > = useCallback(({ list, categoryList }) => {
    updateCateList(categoryList)
    return {
      list: list,
      // 因为接口没有找到pagesize参数，每次返回10条
      // 所以nomore就根据这个来判断
      nomore: list?.length < 10
    }
  }, [])
  // filter change
  const handleFilterChange = useCallback(
    (params) => {
      updateFilterParams((preParams) =>
        Object.assign(
          {},
          preParams,
          formatParams({
            hospital: params.hospital,
            cook_style: params.cook_style,
            sort: params.sort,
            city: cityInfo
          })
        )
      )
    },
    [updateFilterParams]
  )

  // 点击单个餐厅就缓存当前筛选条件
  const handleItemClick = () => {
    filterRef.current?.addCache()
  }
  const renderRestaurantItem = (item: IRestaurant) => (
    <RestaurantItem item={item} onClick={handleItemClick} />
  )
  return (
    <ColumnView className={container}>
      <SearchFilter
        filterRef={(ref) => (filterRef.current = ref)}
        defaultValue={defaultFilterParams}
        onChange={handleFilterChange}
        cateList={cateList}
      />
      <ColumnView className={restaurantList}>
        <AutoListView
          params={filterParams}
          renderItem={renderRestaurantItem}
          format={formatRes}
          itemKeyExtractor={itemKeyExtractor}
          useService={useRestaurantSearch}
          FooterView={Footer}
          EmptyView={ListEmptyView}
        />
      </ColumnView>
    </ColumnView>
  )
}

EatinRestaurantSearch.config = {
  title: '餐厅搜索'
}

export default EatinRestaurantSearch
