import { WhiteSpace } from 'antd-mobile'
import hoistNonReactStatics from 'hoist-non-react-statics'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import BrotherButton from '../../components/BrotherButton'
import Icon from '../../components/Icon'
import { ColumnView, RowView } from '../../components/QCFlex'
import Text from '../../components/Text'

import {
  AccountStatus,
  useSendActivitationMailMutation
} from '../../generated/graphql'
import { useGlobalStores } from '../../hooks/store'
import { useToastSuccess } from '../../hooks/toast'

const RegisterSuccess: QCPage = () => {
  const history = useHistory()
  const { userStore } = useGlobalStores()
  const [sendMail, { data: sendResult }] = useSendActivitationMailMutation()

  useToastSuccess(sendResult?.result ? '发送成功' : null)

  useEffect(() => {
    if (userStore.profile?.status === AccountStatus.AccountActivated) {
      history.replace('/')
    }
  }, [userStore.profile])

  return (
    <ColumnView padding={[20, 30, 20, 30]}>
      <ColumnView hAlign="center" className="qc-section-card">
        <Icon
          margin={[40, 0, 0, 0]}
          name="iconchenggong"
          size={80}
          color="#51A04CFF"
        />
        <Text size={34} color="#51A04C" margin={[40, 0, 0, 0]}>
          祝贺您，注册成功
        </Text>
        <Text margin={[20, 0, 0, 0]} color="#999999FF" size={26}>
          还差一步，即可开通商宴通
        </Text>
        <Text margin={[0, 0, 40, 0]} color="#999999FF" size={26}>
          请立即登录您的企业邮箱，激活账号
        </Text>
      </ColumnView>
      <WhiteSpace size="xl" />
      <WhiteSpace size="xl" />
      <WhiteSpace size="xl" />
      <BrotherButton
        leftText="重发激活邮件"
        onLeftClick={() => sendMail()}
        rightText="我已激活"
        onRightClick={() => userStore.fetch()}
      ></BrotherButton>
      <RowView hAlign="center" margin={[80, 20, 20, 20]}>
        <a
          onClick={() => userStore.logout()}
          style={{ color: '#999', fontSize: 14 }}
        >
          登出账户
        </a>
      </RowView>
    </ColumnView>
  )
}

RegisterSuccess.config = {
  title: '注册成功',
  noBack: true
}

export default hoistNonReactStatics(observer(RegisterSuccess), RegisterSuccess)
