// 堂食
import EatinOrderList from '../../views/EatinOrderList'
import EatinOrderApply from '../../views/EatinOrderApply'
import EatinOrderDetail from '../../views/EatinOrderDetail'
import EatinOrderPayResult from '../../views/EatinOrderPayResult'
import EatinRestaurantSearch from '../../views/EatinRestaurantSearch'
import EatinOrderApplyResult from '../../views/EatinOrderApplyResult'
import EatinOrderEvaluation from '../../views/EatinOrderEvaluation'

const Routers = [
  {
    name: 'EatinOrderList',
    path: '/eatinOrderList',
    component: EatinOrderList
  },
  {
    name: 'EatinOrderApply',
    path: '/eatinOrderApply',
    component: EatinOrderApply
  },
  {
    name: 'EatinOrderDetail',
    path: '/eatinOrderDetail/:orderId',
    component: EatinOrderDetail
  },
  {
    name: 'EatinOrderPayResult',
    path: '/eatinOrderPayResult',
    component: EatinOrderPayResult
  },
  {
    name: 'EatinRestaurantSearch',
    path: '/eatinRestaurantSearch',
    component: EatinRestaurantSearch
  },
  {
    name: 'EatinOrderApplyResult',
    path: '/eatinOrderApplyResult',
    component: EatinOrderApplyResult
  },
  {
    name: 'EatinOrderEvaluation',
    path: '/eatinOrderEvaluation/:orderId',
    component: EatinOrderEvaluation
  }
]

export default Routers
