import React, { FC, memo } from 'react'

import Text from '../../components/Text'
import StatusTag from '../../components/StatusTag'
import { card, left, right, typeTag } from './index.less'
import { ColumnView, RowView } from '../../components/QCFlex'
import { Link, useHistory } from 'react-router-dom'
interface IStatus {
  color?: string
  icon?: string
  description?: string
  label?: string
}
const STATUS_MAP: { [key: string]: IStatus } = {
  APPLY_AVALIABLE: {
    color: '#3D5CA0',
    icon: 'icon-tuijian',
    description: '该餐厅暂未被推荐',
    label: '可申请推荐'
  },
  VALIDATION_WAIT: {
    color: '#F4BE40',
    icon: 'icon-tuijian',
    description: '餐厅资质验证中',
    label: '等待验证'
  },
  VALIDATION_FAIL: {
    color: '#E94C5D',
    icon: 'icon-tuijian',
    description: '餐厅资质验证不通过',
    label: '验证不通过'
  },
  VALIDATION_SUCCESS_ONLINE: {
    color: '#38A00A',
    icon: 'icon-tuijian',
    description: '餐厅资质已验证通过',
    label: '线上餐厅'
  },
  VALIDATION_WAIT_CLIENT: {},
  VALIDATION_FAIL_APPLY_AGAIN: {
    color: '#E94C5D',
    icon: 'icon-tuijian',
    description: '餐厅资质验证不通过',
    label: '验证不通过'
  },
  VALIDATION_SUCCESS_OFFLINE: {
    color: '#38A00A',
    icon: 'icon-tuijian',
    description: '餐厅资质已验证通过',
    label: '线上餐厅'
  }
}
const RestaurantStatusTag = memo(
  StatusTag,
  (prevProps, nextProps) => prevProps.text !== nextProps.text
)

const RestaurantItem: FC<Definitions.Agalia.UnsignedRestaurantDetail> = ({
  name,
  outdoorUrl,
  address,
  type,
  category,
  district,
  region,
  rid,
  status = '',
  rating,
  sourceId
}) => {
  const statusConfig = STATUS_MAP[status]
  const router = useHistory()
  const goto = () => {
    const isUnsignRetaurant = type === 'unsigned'
    if (isUnsignRetaurant) {
      router.push(`/recommendRestaurantDetail`, { sourceId })
    } else {
      router.push(`/onlineRestaurantDetail/${rid}`, { detail: { rating } })
    }
  }
  return (
    <RowView className={`clickable-ripple ${card}`} onClick={goto}>
      <img className={left} src={outdoorUrl} />
      <ColumnView className={right}>
        <RowView className="restaurant-name-title" vAlign="center">
          <Text size={34} color="#181818FF">
            {name}
          </Text>
          <RestaurantStatusTag
            color={statusConfig.color}
            text={statusConfig.label || ''}
          />
        </RowView>
        <Text size={24} color="#CCCCCCFF" className={typeTag}>
          {category}
        </Text>
        <Text size={24} color="#999999FF">
          {region}&nbsp;&nbsp;
          {district}
        </Text>
      </ColumnView>
    </RowView>
  )
}

export default RestaurantItem
