import React, { FC } from 'react'
import { ItemCell } from '../../components/Cell'
import Text from '../../components/Text'
import { ColumnView } from '../../components/QCFlex'
const TakeawayCard: FC<Definitions.Europe.TakeawayInfo> = ({
  maxItems,
  deliveryTime
}) => {
  const maxItemsValue = maxItems ? `${maxItems}份以上需要提前预定` : '暂无信息'
  const deliveryTimeValue = deliveryTime ? deliveryTime : '暂无信息'

  return (
    <ColumnView className="qc-section-card">
      <ItemCell
        value={
          <Text size={34} color="#999999FF">
            配送信息
          </Text>
        }
        align="start"
        arrow={false}
      />
      <ItemCell
        label="配送时间"
        value={deliveryTimeValue}
        arrow={false}
        align="start"
      />
      <ItemCell label={maxItemsValue} arrow={false} align="start" />
    </ColumnView>
  )
}

export default TakeawayCard
