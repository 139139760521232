import { useCallback, useEffect, useRef, useState } from 'react'
import { useGlobalStores } from './store'

export const useCondition = (
  condition: boolean,
  callback: () => void,
  always?: boolean
): void => {
  const previous = usePrevious(condition)

  useEffect(() => {
    if (condition && (always || previous !== condition)) {
      callback()
    }
  })
}

export const useThrottle = (value: any, limit: number) => {
  const [throttledValue, setThrottledValue] = useState(value)
  const lastRan = useRef(Date.now())

  useEffect(() => {
    const handler = setTimeout(function () {
      if (Date.now() - lastRan.current >= limit) {
        setThrottledValue(value)
        lastRan.current = Date.now()
      }
    }, limit - (Date.now() - lastRan.current))

    return () => {
      clearTimeout(handler)
    }
  }, [value, limit])

  return throttledValue
}

const createRootElement = (id: string) => {
  const rootContainer = document.createElement('div')
  rootContainer.setAttribute('id', id)
  return rootContainer
}

const addRootElement = (rootElem: Element) => {
  document.body.insertBefore(
    rootElem,
    document.body.lastElementChild?.nextElementSibling || null
  )
}

export const usePortal = (id: string) => {
  const rootElemRef = useRef<HTMLDivElement | null>(null)

  const getRootElem = () => {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div')
    }
    return rootElemRef.current
  }

  useEffect(function setupElement() {
    const existingParent = document.querySelector(`#${id}`)

    const parentElem = existingParent || createRootElement(id)

    if (!existingParent) {
      addRootElement(parentElem)
    }

    rootElemRef?.current && parentElem.appendChild(rootElemRef.current)

    return function removeElement() {
      rootElemRef.current?.remove()
      if (parentElem.childNodes.length === -1) {
        parentElem.remove()
      }
    }
  }, [])

  return getRootElem()
}

export const usePrevious = <T,>(value: T) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>()

  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current
}

export type noop = (...args: any[]) => any

export const usePersistFn = <T extends noop>(fn: T) => {
  const ref = useRef<any>(() => {
    throw new Error('Cannot call function while rendering.')
  })

  ref.current = fn

  const persistFn = useCallback(((...args) => ref.current(...args)) as T, [ref])

  return persistFn
}

export const useMount = (fn: any) => {
  const fnPersist = usePersistFn(fn)

  useEffect(() => {
    if (fnPersist && typeof fnPersist === 'function') {
      fnPersist()
    }
  }, [])
}

export const useOnMessage = (fn: (e: MessageEvent) => void): void => {
  const fnPersist = usePersistFn(fn)

  useEffect(() => {
    window.addEventListener('message', fnPersist)
    return () => {
      window.removeEventListener('message', fnPersist)
    }
  })
}

export const useBoolean = (initValue: boolean) => {
  const [val, setVal] = useState<boolean>(initValue)
  return {
    value: val,
    setTrue: () => {
      setVal(true)
    },
    setFalse: () => {
      setVal(false)
    }
  }
}

// 带缓存的state,缓存到store
// 下次mounted的时候默认初始化store
interface CacheStateProps<S> {
  // 是否手动启用，留个开关先
  manual?: boolean
  // 初始化State
  initialState: S | (() => S)
  // 唯一标识
  cacheKey: string
}
export function useCacheState<S>(
  props: CacheStateProps<S>
): [S, React.Dispatch<React.SetStateAction<S>>, () => void, () => void] {
  const cacheRef = useRef<S | null>(null)
  // 默认manual = false 自动缓存
  const { initialState, cacheKey, manual = false } = props
  const { cacheStateStore } = useGlobalStores()
  // 优先取cache
  // 除了undefined 其它任何值都可以被缓存
  const cacheData = cacheStateStore.getDataByKey(cacheKey)
  const [state, updateState] = useState<S>(
    cacheData === undefined ? initialState : cacheData
  )
  // 暴露出去个clear方法
  const onClear = () => {
    cacheStateStore.clearDataByKey(cacheKey)
  }
  // 暴露出去个onSaveCache方法
  const onAddCache = () => {
    cacheStateStore.add({
      key: props.cacheKey,
      data: cacheRef.current
    })
  }
  useEffect(() => {
    cacheRef.current = state
  }, [state])
  useEffect(() => {
    return () => {
      // 非手动的组件卸载时缓存
      !manual && onAddCache()
    }
  }, [])
  return [state, updateState, onClear, onAddCache]
}
