// 推荐餐厅
import RecommendRestaurantList from '../../views/RecommendRestaurantList'
import RecommendRestaurantSearch from '../../views/RecommendRestaurantSearch'
import RecommendRestaurantFlow from '../../views/RecommendRestaurantFlow'
import RecommendRestaurantDetail from '../../views/RecommendRestaurantDetail'

const Routers = [
  {
    name: 'RecommendRestaurantList',
    path: '/recommendRestaurantList',
    component: RecommendRestaurantList
  },
  {
    name: 'RecommendRestaurantSearch',
    path: '/recommendRestaurantSearch',
    component: RecommendRestaurantSearch
  },
  {
    name: 'RecommendRestaurantFlow',
    path: '/recommendRestaurantFlow',
    component: RecommendRestaurantFlow
  },
  {
    name: 'RecommendRestaurantDetail',
    path: '/recommendRestaurantDetail',
    component: RecommendRestaurantDetail
  }
]

export default Routers
