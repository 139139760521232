import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../components/Button'
import Text from '../../components/Text'
import { actionBtn } from './index.less'
interface ActionProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  detail?: Definitions.Agalia.UnsignedRestaurantDetail
}
const ActionCard: FC<ActionProps> = ({ onClick, detail }) => {
  const router = useHistory()
  const showBookBtn = ['VALIDATION_SUCCESS_OFFLINE'].includes(
    detail?.status || ''
  )
  const showRecBtn = ['APPLY_AVALIABLE'].includes(detail?.status || '')
  const goBook = () => {
    router.replace('/eatinOrderApply', {
      orderType: 'RECOMMEND',
      restaurantId: detail?.rid,
      isAlipay: detail?.isAlipay
    })
  }
  return (
    <>
      {showRecBtn && (
        <Button className={actionBtn} onClick={onClick}>
          <Text size={34} color="#ffffff">
            推荐餐厅
          </Text>
        </Button>
      )}
      {showBookBtn && (
        <Button className={actionBtn} onClick={goBook}>
          <Text size={34} color="#ffffff">
            立即预订
          </Text>
        </Button>
      )}
    </>
  )
}

export default ActionCard
