// WIP 感觉移动端不会用到这种多选的checkbox
// 如果有请参考 ./Radio
import React, { FC, memo } from 'react'
import FormRow, { FormRowProps } from '../FormRow'
import {
  ConnectForm,
  ConnectFormProps,
  ConnectFormChildrenProps
} from '../../QCForm'
import './index.global.less'
import { useClassNames } from '../../../hooks/classname'
export type NativeCheckboxProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>
export type MCheckboxItemProps = Omit<
  ConnectFormChildrenProps,
  'value' | 'onBlur'
>
export interface FormCheckboxItemProps
  extends FormRowProps,
    ConnectFormProps,
    NativeCheckboxProps {}
export interface CheckboxItemProps
  extends FormRowProps,
    NativeCheckboxProps,
    Omit<ConnectFormChildrenProps, 'value' | 'onBlur'> {
  field?: string
}

export const CheckboxItem: FC<CheckboxItemProps> = ({
  label,
  className,
  sync,
  register,
  onBlur,
  field,
  rules,
  ...otherProps
}) => {
  const mergedClassName = useClassNames(['qc-checkbox', className])
  return (
    <FormRow label={label}>
      <input
        type="checkbox"
        name={field}
        ref={rules ? register?.(rules) : register}
        className={mergedClassName}
        {...otherProps}
      />
    </FormRow>
  )
}
// 针对 FormProvider 的 带优化的Checkboxitem
const MCheckboxItem: FC<MCheckboxItemProps> = memo(
  ({ formState, watch, ...otherProps }) => <CheckboxItem {...otherProps} />,
  (prevProps, nextProps) =>
    prevProps.formState?.isDirty === nextProps.formState?.isDirty
)
const FormCheckboxItem: FC<FormCheckboxItemProps> = (props) => (
  <ConnectForm>
    <MCheckboxItem {...props} />
  </ConnectForm>
)
export default FormCheckboxItem
