import { CSSProperties, useContext, useCallback } from 'react'
import p2r, { P2PProps } from '../utils/p2r'
import { scrollElementContext, pullRefeshContext } from '../contexts'
import { findDOMNode } from 'react-dom'
export interface BaseStyleProps {
  margin?: P2PProps[]
  padding?: P2PProps[]
  width?: P2PProps
  height?: P2PProps
  bgColor?: string
}
export const useBaseStyle = ({
  padding,
  margin,
  width,
  height,
  bgColor
}: BaseStyleProps): CSSProperties => {
  let mergedStyle = {}
  if (padding) {
    mergedStyle = Object.assign(mergedStyle, {
      paddingTop: p2r(padding[0]),
      paddingRight: p2r(padding[1]),
      paddingBottom: p2r(padding[2]),
      paddingLeft: p2r(padding[3])
    })
  }
  if (margin) {
    mergedStyle = Object.assign(mergedStyle, {
      marginTop: p2r(margin[0]),
      marginRight: p2r(margin[1]),
      marginBottom: p2r(margin[2]),
      marginLeft: p2r(margin[3])
    })
  }
  if (width) {
    mergedStyle = Object.assign(mergedStyle, { width: p2r(width) })
  }
  if (height) {
    mergedStyle = Object.assign(mergedStyle, { height: p2r(height) })
  }
  if (bgColor) {
    mergedStyle = Object.assign(mergedStyle, { backgroundColor: bgColor })
  }
  return mergedStyle
}

export const useScrollContainer = () => {
  const scrollElementRef = useContext(scrollElementContext)
  const containerNode = findDOMNode(scrollElementRef?.current) as MaybeNull<
    HTMLElement
  >

  return containerNode
}

export const usePullRefresh = (callback?: () => void): void => {
  const pullRefeshRef = useContext(pullRefeshContext)
  pullRefeshRef?.callback?.(callback)
}
