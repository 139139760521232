// 这个搜索列表没有分页操作等交互，所以直接全部渲染出来了
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Tabs } from 'antd-mobile'

import { ColumnView } from '../../components/QCFlex'
import { SearchBar } from '../../components/SearchBar'

import Restaurant from './Restaurant'
import Hospital from './Hospital'

import { container } from './index.less'
interface TabData {
  title?: React.ReactNode
  sub?: string
}

const tabs = [
  { title: '餐厅', sub: 'restaurant' },
  { title: '医院', sub: 'hospital' }
]
const HomeSearch: QCPage = () => {
  const [keywords, updateKeywords] = useState<string>('')
  const [tabName, updateTabName] = useState<string>('restaurant')
  const router = useHistory()

  const handleKeywordChange = (kw: string) => {
    updateKeywords(kw)
  }
  const handleCancel = () => {
    router.goBack()
  }
  const handleTabChange = (tab: TabData) => {
    updateTabName(tab?.sub || '')
  }
  const placeholder =
    tabName === 'hospital' ? '请输入医院进行搜索' : '请输入餐厅进行搜索'
  return (
    <ColumnView className={container}>
      <SearchBar
        placeholder={placeholder}
        onChange={handleKeywordChange}
        showCancelButton
        onCancel={handleCancel}
      />
      <Tabs
        tabBarActiveTextColor="#181818FF"
        tabBarInactiveTextColor="#999999FF"
        initialPage={0}
        tabs={tabs}
        onChange={handleTabChange}
        tabBarUnderlineStyle={{ display: 'none' }}
        swipeable={false}
      >
        <Restaurant keywords={keywords} tabName={tabName} />
        <Hospital keywords={keywords} tabName={tabName} />
      </Tabs>
    </ColumnView>
  )
}

HomeSearch.config = {
  title: '搜索'
}

export default HomeSearch
