import React from 'react'
import { render } from 'react-dom'
import 'antd-mobile/dist/antd-mobile.css'
import 'mobx-react-lite/batchingForReactDom'
import { configure } from 'axios-hooks'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/react'
import VConsole from 'vconsole'
import App from './app'
import { axios } from './utils/request'
import { client } from './utils/apollo'
import { init as initSentry } from './utils/sentry'
// import 'antd-mobile/dist/antd-mobile.less'
import './style/common.global.less'

if (process.env.NODE_ENV !== 'production') {
  // if (process.env.NODE_ENV !== 'local') {
  new VConsole({})
}

configure({ axios })

initSentry(process.env.SENTRY_DSN!)

render(
  <Sentry.ErrorBoundary
    fallback={'An error has occured'}
    showDialog
    beforeCapture={(scope) => {
      console.log(scope)
    }}
  >
    <ApolloProvider client={client}>
      <Router>
        <App />
      </Router>
    </ApolloProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById('app')
)
