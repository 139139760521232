import React, { FC, CSSProperties } from 'react'
import { useBaseStyle, BaseStyleProps } from '../../hooks/layout'
import p2r from '../../utils/p2r'

export interface IconProps
  extends React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLElement>,
      HTMLElement
    >,
    BaseStyleProps {
  name?: string
  // 预留属性 方便以后使用
  size?: number
  color?: string
  style?: CSSProperties
  className?: string
}

const Icon: FC<IconProps> = ({
  name,
  size = 30,
  color,
  style,
  className = '',
  padding,
  margin,
  ...otherProps
}) => {
  const baseStyle: CSSProperties = useBaseStyle({
    padding,
    margin
  })
  const mergedStyle: CSSProperties = Object.assign(
    baseStyle,
    { color, fontSize: p2r(size) },
    style
  )
  return (
    <i
      className={`iconfont ${name} ${className}`}
      style={mergedStyle}
      {...otherProps}
    />
  )
}

export default Icon
