import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ColumnView, XCenterView } from '../../components/QCFlex'
import Button from '../../components/Button'
import Text from '../../components/Text'
import Icon from '../../components/Icon'
import { container } from './index.less'
interface IUrlParams {
  orderId?: string
}
const TakeawayOrderApplyResult: QCPage = () => {
  const router = useHistory()
  const location = useLocation<IUrlParams>()
  const goDetail = () => {
    router.replace(`/takeawayOrderDetail/${location.state.orderId}`)
  }
  const goHome = () => {
    router.replace(`/`)
  }
  return (
    <ColumnView className={container}>
      <ColumnView hAlign="center" className="qc-section-card">
        <Icon
          margin={[40, 0, 0, 0]}
          name="iconchenggong"
          size={80}
          color="#51A04CFF"
        />
        <Text size={34} color="#181818FF" margin={[40, 0, 0, 0]}>
          提交成功！
        </Text>
        <Text margin={[20, 0, 40, 0]} color="#999999FF" size={26}>
          商宴通将在2～10分钟之内以短信通知您预订结果
        </Text>
      </ColumnView>
      <XCenterView margin={[120, 0, 0, 0]}>
        <Button onClick={goDetail}>查看订单</Button>
      </XCenterView>
      <XCenterView margin={[70, 0, 0, 0]}>
        <Text color="#999999FF" size={34} onClick={goHome}>
          返回首页
        </Text>
      </XCenterView>
    </ColumnView>
  )
}

TakeawayOrderApplyResult.config = {
  title: '下单成功'
}

export default TakeawayOrderApplyResult
