import { Modal } from 'antd-mobile'
import { ModalProps } from 'antd-mobile/lib/modal/Modal'
import React, { FC } from 'react'
import { ColumnView } from '../../components/QCFlex'

const RuleModal: FC<ModalProps> = (props) => {
  return (
    <Modal
      maskClosable
      closable
      {...props}
      style={{ margin: 30, maxHeight: '90%', overflow: 'auto' }}
      bodyStyle={{ padding: '0 30px' }}
    >
      <ColumnView style={{ textAlign: 'left' }}>
        <h4>1、服务条款的确认和接纳</h4>
        <p>
          商宴通由全程电子商务（上海）有限公司运营，涉及具体产品服务的将由有资质的服务商提供。用户通过完成注册程序并点击一下“提交”的按钮，这表示用户明确知晓以上事实，并与全程电子商务（上海）有限公司达成协议并接受所有的服务条款。
        </p>
        <h4>2、服务简介</h4>
        <p>
          商宴通运用自己的操作系统通过互联网等方式为全程电子商务（上海）有限公司（以下简称全程）的员工（以下简称用户）提供本服务（以下简称本服务）。
          用户必须
        </p>
        <p>(1) 提供设备，系统为iOS8.0或以上，或者安卓4.0或以上的移动设备。 </p>
        <p> (2) 个人上网和支付与此服务有关的电话费用。 </p>
        <p>
          考虑到本服务的重要性，用户同意 (1) 提供及时、详尽及准确的个人资料。
          (2)
          不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将引用为注册资料。
          另外，用户可授权商宴通向第三方（指第三方支付平台）透露其基本资料，但商宴通不能公开用户的补充资料。
          除非 (1) 用户要求商宴通或授权某人通过电子邮件服务透露这些信息。 (2)
          相应的法律要求及程序要求商宴通提供用户的个人资料。如果用户提供的资料不准确，商宴通保留结束用户使用商宴通本服务的权利。用户在享用本服务的同时，同意接受商宴通在线订餐平台提供的各类信息服务。
        </p>
        <h4>3、用户隐私制度</h4>
        <p>
          为了遵守全程合规集中用餐的要求，以及向您提供更个性化、更便捷的服务，我们需要识别您的身份。在您注册账号或使用商宴通提供的各项服务时，您可能要向我们提供一些个人信息，例如您的姓名、员工工号、电话号码和电子邮件地址等信息及相关附加信息（如您所在的省份和城市、业务部门等）。如您使用的商宴通服务需与您的银行账户或其他支付工具的账户关联方能实现时，您需要向我们提供您的银行账户信息或其他支付工具的账户信息。例如，您在第一次使用支付服务，将您的银行卡与商宴通绑定时，您需向我们提供您的银行卡信息。
          为便于您查询您的交易状态或历史记录，也为了遵守法律法规的规定，我们会保存您使用商宴通产生的交易信息，并严格按照法律法规的规定对这些信息进行妥善保管。
          为保障您的信息安全，我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您的信息不会被泄漏、毁损或者丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制。我们对可能接触到您的信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施。
        </p>
        <h4>4、用户的帐号，密码和安全性</h4>
        你一旦注册成功成为用户，你将得到一个密码和帐号。如果你不保管好自己的帐号和密码安全，将负全部责任。另外，每个用户都要对其帐户中的所有活动和事件负全责。你可随时改变你的密码。用户若发现任何非法使用用户帐号或安全漏洞的情况，立即通告商宴通。
        <h4>5、信息的储存及限制</h4>{' '}
        <p>
          商宴通不对用户所发布信息的删除或储存失败负责。商宴通没有对信息的传输量规定上限，但是它有判定用户的行为是否符合本服务条款的要求和精神的保留权利，如果用户违背了服务条款的规定，则中断其商宴通在线订餐平台的帐号。
          商宴通会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，商宴通将在任何时候尽力做到使您的信息不被泄漏、毁损或丢失。
          您的信息存储于中国的服务器上，为了安全及备份的需要，商宴通可能将您的信息和资料储存到全程企业管理后台和（或）阿里云服务器上。
        </p>
        <h4>6、账号管理</h4>
        用户单独承担发布内容的责任。用户对服务的使用是根据所有适用于网站服务的地方法律、国家法律和国际法律标准的。
        用户必须遵循 (1) 发布信息时必须符合中国有关法规。 (2)
        使用本服务不作非法用途。 (3) 不干扰或混乱网络服务。 (4)
        遵守所有使用本服务的网络协议、规定和程序。
        本服务是利用因特网发送和收取信息。所以，用户的行为指引是根据国家有关因特网的法规，政策和程序的。用户须承诺不传输任何非法的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽等信息资料。另外，用户也不能传输任何教唆他人构成犯罪行为的资料；不能传输助长国内不利条件和涉及国家安全的资料；不能传输任何不符合当地法规、国家法律和国际法律的资料。未经许可而非法进入其它电脑系统是禁止的。若用户的行为不符合以上提到的服务条款，商宴通将作出独立判断立即取消用户服务帐号。用户需对自己在商宴通中的行为承担法律责任。用户若在商宴通中散布和传播反动、色情或其他违反国家法律的信息，商宴通的系统记录有可能作为用户违反法律的证据。
        <h4>7、离职账号管理</h4>
        用户离职的情况下，由全程人力资源部提交离职人员信息后，商宴通将立即取消用户服务账号，禁用前的操作都将视为无效操作。为确保用户行为符合当地法规和国家法律，商宴通将会对用户资料以及所有的操作进行备份储存。
        离职的用户账号应当不存在任何由于该账户被注销而导致的未了结的交易与其他基于该账户的存在而产生或维持的权利义务，及本公司认为注销该账户会由此产生未了结的权利义务而产生纠纷的情况。
        <h4>8、责任说明</h4>
        基于不可预见的原因而导致的服务中断，或者因用户的非法操作而造成的损失，商宴通不承担任何责任。用户应当自行承担一切因自身行为而直接或者间接导致的民事或刑事法律责任。
        <h4>9、通告</h4>
        所有发给用户的通告都可通过电子邮件或常规的信件传送。商宴通会通过邮件服务发报消息给用户，告诉他们服务条款的修改、服务变更、或其它重要事情。
        <h4>10、隐私权规则</h4>
        商宴通网络技术有限公司（以下简称“商宴通”或“我们”）尊重并保护用户隐私，商宴通将按照本隐私权规则（以下简称“本规则”）收集、使用及共享您的个人信息。本规则包含了我们收集、存储、保护、使用和共享您的个人信息的条款，我们建议您完整地阅读本规则，以帮助您了解维护自己隐私权的方式。为了使您充分理解本规则，本规则中与您的权益存在或可能存在重大关系的条款，我们已采用粗体字进行标注提示您注意。当您同意《商宴通用户注册服务协议》时，或您访问商宴通移动设备客户端时，或您使用我们提供的任一服务时，即表示您已同意我们按照本规则来合法使用和保护您的个人信息。
        我们收集、使用您的信息是出于遵守国家法律法规的规定以及向您提供服务及提升服务质量的目的（包括支持我们开发新产品或完善已有产品功能，为您和其他用户提供更为优质的服务）。若您向我们提供了本规则中列明的信息，您可使用更多的商宴通服务，和（或）享受更便捷的客户服务，和（或）让我们更好地保护您的账户及资金安全，和（或）避免我们无法及时与您取得联系而产生对您不利的影响等。
        如何收集个人信息
        当您访问商宴通移动设备客户端时，或您使用商宴通服务时，您会向我们主动提供一些信息，我们也会收集您的一些信息，我们也可能会从商宴通关联公司、全程电子商务（上海）有限公司及其关联公司和商业合作伙伴获得信息以补充我们自己收集的信息，例如我们从商宴通获得您的消费信息及金额，以向您提供支付服务。
        我们收集信息的范围主要包括 (1)
        为了遵守法律法规的要求，以及向您提供更个性化、更便捷的服务，我们需要识别您的身份。您在注册账户或使用我们的服务时，向我们提供的相关个人信息，例如真实姓名、员工工号、电话号码、电子邮件。
        (2)
        如您使用的商宴通服务需与您的银行账户或其他支付工具的账户关联方能实现时，您需要向我们提供您的银行账户信息或其他支付工具的账户信息。例如，您在开通快捷支付服务，将您的银行卡与商宴通账户绑定时，您需向我们提供您的银行卡信息（仅加密保存基本信息，CVV等支付信息不作保存）。
        (3)
        为便于您查询您的交易状态或历史记录，也为了遵守法律法规的规定，我们会保存您使用商宴通服务产生的交易信息，并严格按照法律法规的规定对这些信息进行妥善保管。
        (4)
        为了充分保护您的账户安全，当您访问商宴通网站及其相关网站、或商宴通移动设备客户端时，或使用商宴通提供的服务时，我们可能会记录您操作的相关信息，包括但不限于设备及软件信息，例如您的移动设备、网页浏览器或用于接入我们服务的其他程序所提供的配置信息、您的IP地址和移动设备所用的版本以及与商宴通服务相关的日志信息，这些信息可帮助我们更好地识别您的身份以及保护您的账户安全，例如您的账户在可疑操作环境下登录的，商宴通系统可能监控到您的账户风险，采取一些措施避免您的资金损失。
        除上述信息外，我们还可能为了提供服务及改进服务质量的合理需要而收集您的其他信息，包括您与我们的客户服务团队联系时您提供的相关信息，您使用服务时向我们发送的评价信息，以及您与商宴通关联公司互动时我们收集的相关信息。
        如何保护和存储个人信息
        为保障您的信息安全，我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您的信息不会被泄漏、毁损或者丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制。我们对可能接触到您的信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施。
        商宴通会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，商宴通将在任何时候尽力做到使您的信息不被泄漏、毁损或丢失。
        您的信息存储于中国的服务器上，为了安全及备份的需要，商宴通可能将您的信息和资料储存到全程企业管理后台和（或）阿里云服务器上。
        如何使用个人信息
        因收集您的信息是出于遵守国家法律法规的规定以及向您提供服务及提升服务质量的目的，为了实现这一目的，我们会把您的信息用于下列用途：
        (1) 向您提供商宴通的各项服务及客户服务，并维护、改进这些服务。 (2)
        比较信息的准确性，并与第三方（指第三方支付平台）进行验证。例如，将您向我们提交的身份信息与身份验证的服务机构进行验证。
        (3)
        为使您知晓自己使用商宴通服务的情况或了解商宴通的服务，向您发送服务状态的通知、营销活动及其他商业性电子信息。
        (4)
        对商宴通用户的身份数据、交易信息等进行综合统计、分析或加工，并出于全程电子商务（上海）有限公司合规需求而使用、共享或披露。
        (5) 预防或禁止非法的活动。 (6) 经您许可的其他用途。 如何共享个人信息
        我们对您的信息承担保密义务，但我们会在下列情况下将您的信息与第三方（指第三方支付平台）共享：
        (1) 获得您的同意或授权。 (2)
        为了向您提供或推荐服务、产品，或为了向您提供更完善的服务，我们会与包括商宴通关联公司和合作商户在内的第三方（指第三方支付平台）共享您的相关信息。例如，您的消费通过商宴通付款后，我们会向商户发送您已付款成功的信息。
        (3)
        某些情况下，只有共享您的信息，才能提供您需要的服务和（或）产品，或处理您与他人的交易纠纷或争议。例如，与您交易的相对方或您本人否认交易为本人操作时，我们可能会将您提交的消费凭证、消费发票，或在商宴通系统中的交易信息等信息共享给银行或您的交易相对方，以证明交易的真实存在，维护您的合法权益。
        (4) 为了判断您的商宴通账户或交易是否安全。 (5)
        某些服务和（或）产品由我们的合作伙伴提供或由我们与合作伙伴、供应商共同提供，我们会与其共享提供服务和（或）产品需要的信息。例如，您在商宴通客户端使用外卖、茶歇服务时，我们必须把您填写的地址和姓名等信息提供给合作的供应商，才能提供服务。
        (6)
        为维护商宴通及商宴通关联公司、全程电子商务（上海）有限公司及其关联公司和其他合作商户的合法权益。
        (7)
        根据法律规定及合理商业习惯，在我们计划与其他公司合并或被其收购或进行其他资本市场活动（包括但不限于IPO，债券发行）时，以及其他情形下我们需要接受来自其他主体的尽职调查时，我们会把您的信息提供给必要的主体，但我们会通过和这些主体签署保密协议等方式要求其对您的个人信息采取合理的保密措施。
        (8) 为了维护和改善我们的服务。 (9) 根据法律法规的规定或有权机关的要求。
        如何使用Cookie
        为使您获得更轻松的访问体验，您访问商宴通网站或使用商宴通提供的服务时，我们可能会通过小型数据文件识别您的身份，这么做是帮您省去重复输入注册信息的步骤，或者帮助判断您的账户安全。这些数据文件可能是Cookie，Flash
        Cookie，或您的浏览器或关联应用程序提供的其他本地存储（统称“Cookie”）。
        请您理解，我们的某些服务只能通过使用“Cookie”才可得到实现。如果您的浏览器或浏览器附加服务允许，您可以修改对Cookie的接受程度或者拒绝商宴通的Cookie，但这一举动在某些情况下可能会影响您安全访问商宴通网站和使用商宴通提供的服务。
        如何查询和管理个人信息
        您可随时登录您的商宴通账户查询并管理该账户下您的个人信息，如您遇到任何障碍或疑问，可联系商宴通客服400
        050 1717。 适用范围
        本规则属于《商宴通用户注册服务协议》不可分割的一部分，本规则与《商宴通用户注册服务协议》的约定不一致的，以本规则为准。除需适用商宴通其他单独制订的隐私权规则的服务外，本规则适用于您访问商宴通移动设备客户端和商宴通提供的任一服务。
        如何修订本规则
        我们可能随时会对本规则进行变更，由于商宴通的用户群过于庞大和分散，因此如发生变更，我们将选择在商宴通网站以公告的方式予以公布而不再另行单独通知您，该等变更将在公布时即时生效。若您在本规则变更后继续访问商宴通网站及其相关网站、商宴通移动设备客户端，或使用商宴通提供的任一服务，我们相信这代表您已充分阅读、理解并接受修改后的本规则并受之约束。
        <h4>11、联系</h4>
        如您对本规则存在任何疑问，或任何相关的投诉、意见，请联系商宴通客服400
        050 1717。
      </ColumnView>
    </Modal>
  )
}

export default RuleModal
