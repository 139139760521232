import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { InputItem, Flex, Toast, WhiteSpace } from 'antd-mobile'
import SendValidationCode from '../../components/SendValidationCode'
import { useClassNames } from '../../hooks/classname'
import Page from '../../components/Layout/Page'
import Button from '../../components/Button'
import {
  COMPANY_THEME_TXTCOLOR,
  COMPANY_THEME_COLOR
} from '../../constants/app'
import { useUserResetPasswordMutation } from '../../generated/graphql'
import {
  loginBox,
  submitButton,
  control as controlStyle,
  right as alignRight
} from '../Login/index.less'
import {
  haveRadius
} from './index.less'
import { useCondition } from '../../hooks/common'
import { useHistory } from 'react-router-dom'
import { useToastFail, useToastSuccess } from '../../hooks/toast'


// 定义属性
interface ResetParams {
  mobile: string
  newPassword: string
  sessionId: string
  validationCode: string
  rptPassword: string
}

const ResetPassword: QCPage = () => {
  const history = useHistory()
  const controlAlignRight = useClassNames([controlStyle, alignRight, haveRadius])
  const { handleSubmit, control, watch, errors, setValue } = useForm<ResetParams>()

  const watchMobile = watch('mobile')
  const watchPwd = watch('newPassword')

  const [resetPassword, { loading: regLoading, error: regErr, data: resetData }] = useUserResetPasswordMutation()

  useCondition(!!resetData?.resetPasswordResult, () => {
    history.replace('/login')
  })

  // 成功提示
  useToastSuccess(!!resetData?.resetPasswordResult && '修改密码成功')
  // 失败提示
  useToastFail(regErr?.message)

  // 提交
  const onSubmit = async (param: ResetParams) => {
    resetPassword({
      variables: {
        mobile: param.mobile,
        newPassword: param.newPassword,
        sessionId: param.sessionId,
        code: param.validationCode
      }
    })
  }

  return (
    <Page>
      <form className={loginBox} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name='mobile'
          rules={{
            required: { message: '必填', value: true }
          }}
          defaultValue=""
          render={(props) => (
            <InputItem
              className={controlAlignRight}
              placeholder="请输入手机号"
              {...props}
              error={!!errors.mobile}
              onErrorClick={() => {
                Toast.show(errors.mobile?.message)
              }}
            >
              手机号
            </InputItem>
          )}
        >
        </Controller>

        <Controller
          control={control}
          name="validationCode"
          rules={{ required: true }}
          defaultValue=""
          render={(props) => (
            <InputItem
              className={controlStyle}
              maxLength={6}
              placeholder="请输入验证码"
              {...props}
              error={!!errors.validationCode}
              extra={
                <SendValidationCode
                  mobile={watchMobile}
                  onResult={(sessionId) => setValue('sessionId', sessionId)}
                />
              }
            />
          )}
        />

        <Controller
          control={control}
          name="newPassword"
          rules={{
            required: true,
            validate: (value) => {
              // 至少8位大小写字母或数字
              return /^[A-Za-z0-9]{8,}$/.test(value)
            }
          }}
          defaultValue=""
          render={(props) => (
            <InputItem
              className={controlAlignRight}
              placeholder="至少8位大小写字母或数字"
              type="password"
              autoComplete="new-password"
              error={!!errors.newPassword}
              {...props}
            >
              密码
            </InputItem>
          )}
        />

        <Controller
          control={control}
          name="rptPassword"
          rules={{
            required: true,
            validate: {
              shouldEqualPwd: (value) => value === watchPwd
            }
          }}
          defaultValue=""
          render={(props) => (
            <InputItem
              placeholder="再次输入设定的密码"
              className={controlAlignRight}
              type="password"
              error={!!errors.rptPassword}
              autoComplete="new-password"
              {...props}
            >
              确认密码
            </InputItem>
          )}
        />

        <WhiteSpace size="lg" />
        <WhiteSpace size="lg" />
        <WhiteSpace size="lg" />
        <WhiteSpace size="lg" />

        <Flex
          alignContent="center"
          align="center"
          justify="center"
          direction="row"
        >
          <Button disabled={regLoading} type="submit" className={submitButton}>
            确认
          </Button>
        </Flex>

      </form>
    </Page>
  )
}

ResetPassword.config = {
  title: '修改密码',
  titlebarBgColor: COMPANY_THEME_COLOR,
  titlebarTextColor: COMPANY_THEME_TXTCOLOR
}

export default ResetPassword
