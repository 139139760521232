import React, { useCallback, useMemo, FC, memo } from 'react'

import Icon from '../Icon'
import './index.global.less'

export interface RateItemProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  > {
  character?: React.ReactNode
  className?: string
}

export const RateItem: FC<RateItemProps> = (props) => {
  const { character, className, ...otherProps } = props
  const mergedClassname = ['qc-star', className].join(' ')

  const characterNode =
    typeof character === 'function' ? character(props) : character
  return (
    <span className={mergedClassname} {...otherProps}>
      {characterNode}
    </span>
  )
}
export interface StarProps {
  size?: number
  selected?: boolean
  onClick?: () => void
}
const Star: FC<StarProps> = ({ size, selected = false, onClick }) => {
  const color = selected ? '#FF9847' : '#DDDDDD'
  return (
    <RateItem
      onClick={onClick}
      character={<Icon name="iconxingjipingfen" size={size} color={color} />}
    />
  )
}
export interface RateProps {
  count?: number
  value?: number
  onChange: (starIndex: number) => void
  disabled?: boolean
}
const Rate: FC<RateProps> = ({
  count = 5,
  value = 0,
  onChange,
  disabled = false
}) => {
  const onStarClick = useCallback(
    (starIndex) => {
      !disabled && onChange && onChange(starIndex + 1)
    },
    [onChange]
  )
  const stars = [...new Array(count).keys()].map((k) => {
    // 小于等于评分的一律填充为选中icon
    // 评分最低为0分，下标从0开始，所以value-1
    const selected = k <= value - 1
    return (
      <Star
        key={k}
        selected={selected}
        onClick={() => {
          onStarClick(k)
        }}
      />
    )
  })
  return <div className="qc-rate">{stars}</div>
}

export default Rate
