import { FunctionComponent } from 'react'
import { RouteProps as ReactDomRouteProps } from 'react-router-dom'
import UserRouters from './user'
import CommonRouters from './common'
import EatinRouters from './eatin'
import TakeawayRouters from './takeaway'
import RecommendRouters from './recommend'
interface QCRouterProps {
  name: string
  path: string
  component: FunctionComponent
  extraProps?: Omit<ReactDomRouteProps, 'path'>
  public?: boolean
}

const Routers: QCRouterProps[] = [
  ...UserRouters,
  ...CommonRouters,
  ...EatinRouters,
  ...TakeawayRouters,
  ...RecommendRouters
]
export default Routers
