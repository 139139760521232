import React, { FC } from 'react'
import { container } from './index.less'

import Card from './Card'
import { useUserMonthlyConsumes } from '../../api/Statics'
const MockItem = {
  time: '2020年8月',
  type: '餐饮费用',
  total: 2000,
  actual: 2100
}
const MockItem1 = {
  time: '2020年8月',
  type: '餐饮费用',
  total: 2000,
  actual: 1000
}
const ConsumStatistics: QCPage = () => {
  const [{ data }] = useUserMonthlyConsumes()
  return (
    <section className={container}>
      {data?.data?.map?.((item) => {
        return (
          <Card
            key={item.month}
            time={item.month}
            type="餐饮费用"
            total={item.quota}
            actual={item.totalAmount}
          />
        )
      })}
    </section>
  )
}

ConsumStatistics.config = {
  title: '我的消费统计'
}

export default ConsumStatistics
