import React from 'react'

import { ColumnView, XCenterView } from '../../components/QCFlex'
import {
  FormActionSheetPickerItem,
  FormDatePickerItem,
  FormRateItem,
  FormImagePicker,
  FormGroupPicker,
  FormPagePicker
} from '../../components/FormItems'

const uploadSync = () => {
  console.log('upload image ...')
  return []
}
const ControlerForm = () => (
  <ColumnView className="qc-section-card">
    <XCenterView>受控组件</XCenterView>
    <FormGroupPicker
      placeholder="请选择成本中心"
      label="成本中心"
      field="costcenter"
      type="costcenter"
      rules={{ required: { message: '请选择成本中心', value: true } }}
    />
    <FormPagePicker
      field="address"
      label="选择地址"
      placeholder="请选择地址"
      rules={{ required: { message: '请选择一个地址', value: true } }}
    />
    <FormImagePicker
      label="图片上传"
      field="images"
      max={9}
      rules={{
        required: { message: '图片上传', value: true }
      }}
    />
    <FormActionSheetPickerItem
      placeholder="请选择角色"
      options={['讲师', '教授', '主持人']}
      label="角色"
      field="rule"
      rules={{ required: { message: '请选择角色', value: true } }}
    />
    <FormDatePickerItem
      placeholder="请选择日期"
      label="日期"
      field="startDate"
      rules={{ required: { message: '请选择日期', value: true } }}
    />
    <FormDatePickerItem
      placeholder="请选择时间"
      mode="time"
      label="时间"
      field="startTime"
      rules={{ required: { message: '请选择时间', value: true } }}
    />
    <FormRateItem
      label="评分"
      field="rate"
      rules={{ required: { message: '请选择一个评分', value: true } }}
    />
  </ColumnView>
)

export default ControlerForm
