import React, { FC } from 'react'
import { ItemCell } from '../../components/Cell'
import { ColumnView } from '../../components/QCFlex'
import Text from '../../components/Text'

const OrderInfoCard: FC<QCOrderInfo> = ({
  order_id,
  invoice_title,
  is_hall,
  is_rooms,
  consumption_type,
  cost_center,
  buy_reason,
  message
}) => {
  const boxText = `${is_rooms === '1' ? '' : '不'}需要包厢,${
    is_hall === '1' ? '' : '不'
  }接受大厅`
  return (
    <ColumnView className="qc-section-card">
      <ItemCell
        label={
          <Text size={34} color="#999999FF">
            订单信息
          </Text>
        }
        arrow={false}
      />
      <ItemCell label="订单编号" value={order_id} arrow={false} />
      <ItemCell label="成本中心" value={cost_center} arrow={false} />
      <ItemCell label="费用类别" value={consumption_type} arrow={false} />
      <ItemCell label="宴请事由" value={buy_reason || ''} arrow={false} />
      <ItemCell label="发票信息" value={invoice_title} arrow={false} />
      <ItemCell label="包厢需求" value={boxText} arrow={false} />
      <ItemCell label="备注" value={message || ''} arrow={false} />
    </ColumnView>
  )
}

export default OrderInfoCard
