import React, { useEffect, FC, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import InvoiceCard from './InvoiceCard'
import OrderInfoCard from './OrderInfoCard'
import ActionBar from './ActionBar'
import { ColumnView } from '../../components/QCFlex'
import QCForm, { FormRefProps } from '../../components/QCForm'
import { useBindTicketPhoto } from '../../api/Order'

import { container, content } from './index.less'
import { useRestToastFail } from '../../hooks/toast'
import { FormValues } from './interface'
import { validate } from './util'
import { Toast } from 'antd-mobile'

const getRealImageUrl = (urlWithPostfix = '') => urlWithPostfix.split('?')[0]
const UploadTicket: QCPage = () => {
  const router = useHistory()
  const location = useLocation<{
    detail: QCOrderInfo
    orderType: 'eatin' | 'takeaway'
  }>()
  const [bindRes, bindTicketPhoto] = useBindTicketPhoto({
    manual: true
  })
  const detail = location.state?.detail || {}
  const inputRef = useRef<FormRefProps<FormValues> | null>(null)
  const onSubmit = () => {
    inputRef &&
      inputRef.current?.qcSubmit((data: FormValues) => {
        const validateRes = validate(data)
        if (validateRes.success) {
          bindTicketPhoto({
            data: {
              orderId: detail.order_id,
              invoice:
                data.invoices?.map((url) => getRealImageUrl(url)).join(',') ||
                '',

              // FBI WARNING
              // 显示跟取值是反着来的
              // invoiceConsistency: !data.invoiceConsistency,
              // receiptConsistency: !data.receiptConsistency,
              invoiceConsistency: false,
              receiptConsistency: false,
              receipt:
                data.receipts?.map((url) => getRealImageUrl(url)).join(',') ||
                ''
            }
          })
        } else {
          Toast.show(validateRes.message)
        }
      })
  }
  useEffect(() => {
    // 请求成功
    // 返回首页
    // 根据订单类型来判断跳转到相应类型订单详情页
    if (bindRes.data) {
      const orderDetailPath =
        location.state?.orderType === 'eatin'
          ? `/eatinOrderDetail/${detail.order_id}`
          : `/takeawayOrderDetail/${detail.order_id}`
      router.replace(orderDetailPath)
    }
  }, [bindRes.data])
  // 整个缩略图
  const invoiceImages = detail?.invoice_images?.map(
    (url: string) => `${url}${detail.thumb_postfix}`
  )
  const receiptImages = detail?.receipt_images?.map(
    (url: string) => `${url}${detail.thumb_postfix}`
  )
  const defaultValues = {
    // FBI WARNING
    // 显示跟取值是反着来的
    invoiceConsistency: detail.invoice_consistency === false ? true : false,
    receiptConsistency: detail.receipt_consistency === false ? true : false,
    receipts: receiptImages,
    invoices: invoiceImages
  }
  useRestToastFail([bindRes])
  return (
    <ColumnView className={container}>
      <ColumnView className={content}>
        <OrderInfoCard {...detail} />
        <QCForm<FormValues>
          formRef={(el) => {
            inputRef.current = el
          }}
          defaultValues={defaultValues}
        >
          <InvoiceCard {...detail} />
        </QCForm>
      </ColumnView>
      <ActionBar onClick={onSubmit} />
    </ColumnView>
  )
}

UploadTicket.config = {
  title: '上传票据'
}

export default UploadTicket
