import React, { FC } from 'react'
import { ItemCell } from '../../components/Cell'
import { ColumnView } from '../../components/QCFlex'
import { FormImagePicker, FormSwitchItem } from '../../components/FormItems'
import Text from '../../components/Text'
import { ALIOSS_BUCKET, ALIOSS_BUCKET_PREFIX } from '../../constants/other'
import { useOssClient } from '../../hooks/alioss'
interface InvoiceCardProps {
  order_id?: string
  ticket_info_confirmed?: string
}
const InvoiceCard: FC<InvoiceCardProps> = ({
  order_id,
  ticket_info_confirmed
}) => {
  const ossClient = useOssClient(ALIOSS_BUCKET)
  const uploadInvoice = async (files: File[]) => {
    console.log('uploadInvoice')
    if (ossClient) {
      console.log('ossClient')

      const ossUrls = await Promise.all(
        files.map(async (file) => {
          const fileName = `/${ALIOSS_BUCKET_PREFIX}/${order_id}/invoices/${Date.now()}-${
            file.name
          }`
          const ret = await ossClient.put(fileName, file)
          return ret.res.requestUrls[0]
        })
      )
      return ossUrls
    }
  }
  const uploadReceipt = async (files: File[]) => {
    if (ossClient) {
      const ossUrls = await Promise.all(
        files.map(async (file) => {
          const fileName = `/${ALIOSS_BUCKET_PREFIX}/${order_id}/receipts/${Date.now()}-${
            file.name
          }`
          const ret = await ossClient.put(fileName, file)
          return ret.res.requestUrls[0]
        })
      )
      return ossUrls
    }
  }
  return (
    <ColumnView className="qc-section-card">
      <ItemCell
        label={
          <Text color="#999999FF" size={34} margin={[0, 20, 0, 0]}>
            票据信息
          </Text>
        }
        arrow={false}
      />
      <FormImagePicker
        uploadSync={uploadInvoice}
        label="发票"
        field="invoices"
        max={3}
      />
      {/* <FormSwitchItem
        label="小票上餐厅名称与上述餐厅名称/发票抬头不一致"
        field="invoiceConsistency"
      /> */}
      <FormImagePicker
        uploadSync={uploadReceipt}
        label="小票/水单"
        field="receipts"
        max={3}
      />
      {/* <FormSwitchItem
        label="发票上销售方名称与上述发票抬头不一致"
        field="receiptConsistency"
      /> */}
    </ColumnView>
  )
}

export default InvoiceCard
