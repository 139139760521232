// 堂食订单详情
import React, { FC } from 'react'
import { Link } from 'react-router-dom'

const HospitalSeaarch: QCPage = () => {
  return (
    <div>
      HospitalSeaarch
      <Link to="/login">To Login</Link>
    </div>
  )
}

export default HospitalSeaarch
