import React, { FC, CSSProperties } from 'react'
import p2r from '../../utils/p2r'
import { useBaseStyle, BaseStyleProps } from '../../hooks/layout'

export interface TextProps
  extends React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    >,
    BaseStyleProps {
  size?: number
  color?: string
  weight?: number
  style?: CSSProperties
  lineHeight?: string
}
const Text: FC<TextProps> = ({
  children,
  size,
  color,
  weight = 'initial',
  style,
  lineHeight = 'normal',
  padding,
  margin,
  ...otherProps
}) => {
  const baseStyle: CSSProperties = useBaseStyle({
    padding,
    margin
  })
  const mergedStyle: CSSProperties = Object.assign(
    baseStyle,
    {
      ...(color && { color }),
      ...(size && { fontSize: p2r(size) }),
      fontWeight: weight,
      lineHeight
    },
    style
  )
  return (
    <span {...otherProps} style={mergedStyle}>
      {children}
    </span>
  )
}
export default Text
