// 堂食订单填写
import React, { useCallback, useEffect, useRef } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import QCForm, { FormRefProps } from '../../components/QCForm'
import { ColumnView } from '../../components/QCFlex'
import ActionBar from './ActionBar'
import DeliveryCard from './DeliveryCard'
import NoMustCard from './NoMustCard'
import { useRestToastFail, useToastSuccess } from '../../hooks/toast'
import { useOrderLockCheck } from '../../hooks/order'
import { useGlobalStores } from '../../hooks/store'
import { useUnionGetPreOrderInfo, useUnionOrderBook } from '../../api/Order'
import { formatSubmitData } from './util'
import { IFormValues } from './interface'
import { container, content } from './index.less'
import { useCondition } from '../../hooks/common'
interface IUrlState {
  restaurantId?: string
  orderType?: 'RESERVATION' | 'RECOMMEND',
  isAlipay?: Number
}
const EatinOrderApply: QCPage = () => {
  const inputRef = useRef<FormRefProps<IFormValues> | null>(null)
  const router = useHistory()
  const location = useLocation<IUrlState>()
  const { userStore } = useGlobalStores()
  // 初始化表单
  // 从store里面取
  const defaultFormData: IFormValues = {
    // 用户默认的
    // 费用中心取自bu
    costCenterId: userStore?.profile?.bu?.groupId
  }
  // 下单时间等配置
  const [preOrderInfoRes] = useUnionGetPreOrderInfo(location.state?.orderType)({
    data: {
      id: location.state?.restaurantId
    },
    useCache: true
  })

  // 下单
  const [bookOrderRes, bookOrder] = useUnionOrderBook(
    location.state?.orderType
  )({
    manual: true
  })

  // 下单submit
  const bookSubmit = useCallback(() => {
    inputRef &&
      inputRef.current?.qcSubmit((data: IFormValues) => {
        console.log('bookSubmit')
        let newparams;
        if (location.state?.isAlipay == 1) {  //支持企业付
          newparams = formatSubmitData({
            formData: data,
            rid: location.state?.restaurantId || '',
            orderType:
              location.state?.orderType === 'RECOMMEND'
                ? 'unsigned'
                : 'reservation',
            tradeType: 'alipay'
          })
        } else {
          newparams = formatSubmitData({
            formData: data,
            rid: location.state?.restaurantId || '',
            orderType:
              location.state?.orderType === 'RECOMMEND'
                ? 'unsigned'
                : 'reservation'
          })
        }
        bookOrder({
          data: newparams
        })
      })
  }, [inputRef])

  // 只检查锁单，不检查限额
  const handleOrderCheckOk = () => {
    router.replace('/#orderManage')
  }
  const [orderCheckSuccess, orderCheck] = useOrderLockCheck({
    manual: true,
    onOk: handleOrderCheckOk
  })
  const handleSubmit = () => {
    inputRef &&
      inputRef.current?.qcSubmit((data: IFormValues) => {
        // bookSubmit()
        // 限额 | 锁单的判断
        orderCheck()
      })
  }

  // 未超限额 | 锁单 提交book
  useCondition(orderCheckSuccess, () => {
    bookSubmit()
  })

  // 兜底所有异步请求失败交互
  useRestToastFail([preOrderInfoRes, bookOrderRes])

  // 预定成功
  useToastSuccess(
    bookOrderRes.data?.status === 0 &&
    (bookOrderRes.data?.data as any)?.['order_id'] &&
    '下单成功',
    {
      // 自动关闭的延时，单位秒
      duration: 2,
      onClose: () => {
        router.replace(`/eatinOrderApplyResult`, {
          orderId: (bookOrderRes.data?.data as any)?.['order_id']
        })
      }
    }
  )

  useEffect(() => {
    // 设置发票信息
    // hack 一下
    inputRef &&
      inputRef?.current?.setValue('tax', {
        invoiceTitle: preOrderInfoRes?.data?.data?.company_invoice_title,
        taxIdentificationNumber:
          preOrderInfoRes?.data?.data?.tax_identification_number
      })
  }, [preOrderInfoRes.data])
  console.log('defaultFormData', defaultFormData)
  return (
    <ColumnView className={container}>
      <ColumnView className={content}>
        <QCForm<IFormValues>
          formRef={(el) => {
            inputRef.current = el
          }}
          {...(defaultFormData && { defaultValues: defaultFormData })}
        >
          <DeliveryCard dates={preOrderInfoRes.data?.data?.list} />
          <NoMustCard />
        </QCForm>
      </ColumnView>
      <ActionBar onSubmit={handleSubmit} />
    </ColumnView>
  )
}

EatinOrderApply.config = {
  title: '订座'
}

export default EatinOrderApply
