import React, { FC } from 'react'
import { ItemCell } from '../../components/Cell'
import Text from '../../components/Text'
import { ColumnView } from '../../components/QCFlex'
import { STY_CUSTOMER_PHONE } from '../../constants/other'
const Footer: FC<Definitions.Europe.ApiRestaurentDetail> = ({ rid }) => (
  <ColumnView className="qc-section-card">
    <a href={`tel:${STY_CUSTOMER_PHONE}`}>
      <ItemCell
        label={
          <Text color="#999999FF" size={30}>
            如您发现餐厅信息有误，可点击进行纠错
          </Text>
        }
      />
    </a>
  </ColumnView>
)

export default Footer
