// 堂食订单详情
import React, { FC, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import HeaderStatus from './HeaderStatus'
import ShopInfoCard from './ShopInfoCard'
import OrderInfoCard from './OrderInfoCard'
import InvoiceCard from './InvoiceCard'
import TipCard from './TipCard'
import ConsumerCard from './ConsumerCard'
import ActionBar from './ActionBar'
import ServiceEvaluationCard from './ServiceEvaluationCard'
import { ColumnView } from '../../components/QCFlex'
import { useUnionOrderDetail } from '../../api/Order'
import { container, content } from './index.less'
import { useToastFail } from '../../hooks/toast'
import {
  useRestaurantDetail
} from '../../api/RecommendRestaurant'
import { useMount } from '../../hooks/common'
import { usePullRefresh } from '../../hooks/layout'
interface IUrlParams {
  orderId?: string
}
interface IUrlState {
  orderType?: 'RESERVATION' | 'RECOMMEND'
  aplaydone?: Boolean
}
const EatinOrderDetail: QCPage = () => {
  // 订单状态 base ../../constants/other.EATIN_ORDER_STATUS
  const params = useParams<IUrlParams>()
  const location = useLocation<IUrlState>()
  const [{ data, error }, refetch] = useUnionOrderDetail(
    location.state?.orderType
  )({
    useCache: true,
    manual: true,
    data: {
      orderId: params.orderId
    }
  })

  const detail = (data?.data as QCOrderInfo) || {}
  const [detailRes, fetchDetail] = useRestaurantDetail({
    useCache: true,
    manual: true
  })
  useEffect(() => {
    if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
      window.addEventListener('pageshow', function () {
        console.log("我是页面展示")
        if (localStorage.getItem('isfirstBind') == 'true') {
          localStorage.removeItem('isfirstBind')
          // refetch()
          // fetchDetail()
          window.location.reload()
        }
      });
    }
  }, [])
  useEffect(() => {
    if (detail?.restaurant_id) {
      fetchDetail({
        data: {
          restaurantId: detail?.restaurant_id
        }
      })
    }
  }, [detail?.restaurant_id])

  useToastFail(
    data?.status && data?.status !== 0
      ? data.message || '网络错误'
      : null || error?.message
  )
  useMount(refetch)

  // 下拉刷新
  usePullRefresh(() => {
    refetch()
    fetchDetail()
  })
  return (
    <ColumnView className={container}>
      <HeaderStatus {...detail} />
      <ColumnView className={content}>
        <ShopInfoCard {...detail} />
        <OrderInfoCard {...detail} />
        <InvoiceCard {...detail} />
        <ServiceEvaluationCard {...detail} />
        <TipCard {...detail} />
        <ConsumerCard {...detail} />
      </ColumnView>
      <ActionBar {...detail} isAlipay={detailRes.data?.data?.isAlipay} />
    </ColumnView>
  )
}

EatinOrderDetail.config = {
  title: '订单详情'
}

export default EatinOrderDetail
