export const useFormattedProps = <V extends any[], T extends (v: any) => void>({
  value,
  onChange
}: {
  value?: V[0]
  onChange?: T
}) => {
  return {
    onChange: (values: any[] | undefined) => {
      onChange?.(values?.[0] || '')
    },
    value: value ? [value] : undefined
  }
}
