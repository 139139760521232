import React, { FC } from 'react'

import { XCenterView } from '../QCFlex'
import Text, { TextProps } from '../Text'
import './index.global.less'
export interface TextIconProps extends TextProps {
  text?: string
  color?: string
  className?: string
}
const TextIcon: FC<TextIconProps> = ({
  text,
  color,
  className,
  margin,
  padding
}) => (
  <XCenterView
    className={`qc-text-icon ${className}`}
    style={{ backgroundColor: color }}
  >
    <Text size={20} color="#ffffff" margin={margin} padding={padding}>
      {text}
    </Text>
  </XCenterView>
)

export default TextIcon
