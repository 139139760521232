import React, { FC } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Text from '../../components/Text'
import { ColumnView, RowView } from '../../components/QCFlex'
import { TAKEAWAY_ORDER_STATUS } from '../../constants/other'

import { card, title, restaurantName, statusTag } from './index.less'
const colorMap: { [name: string]: string } = {
  // 预订成功
  '2': '#51A04CFF',
  // 默认
  '0': '#999999FF'
}
const OrderItem: FC<QCOrderInfo> = ({
  store_name,
  order_state,
  delivery_date,
  order_state_hint,
  delivery_clock,
  oid
}) => {
  const router = useHistory()
  const status = TAKEAWAY_ORDER_STATUS.find(
    (item) => item.value === order_state
  )
  const statusText = status?.name
  // 外卖接口没有预定失败的状态，所以除了预订成功其他都是#999999FF
  const statusColor = colorMap[status?.value || '0']
  const goto = () => {
    router.push(`/takeawayOrderDetail/${oid}`)
  }
  return (
    <ColumnView className={card} onClick={goto}>
      <RowView className={title} hAlign="between">
        <Text
          className={`ellipsis ${restaurantName}`}
          color="#181818FF"
          size={32}
        >
          {store_name}
        </Text>
        <Text className={statusTag} color={statusColor} size={28}>
          {statusText}
        </Text>
      </RowView>
      <Text color="#999999FF" size={28}>
        预约时间：{delivery_date} {delivery_clock}
      </Text>
    </ColumnView>
  )
}

export default OrderItem
