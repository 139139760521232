import React, { FC, useState } from 'react'

import { RowView, XCenterView, ColumnView } from '../../components/QCFlex'
import QCBadge from '../../components/QCBadge'
import Icon from '../../components/Icon'
import Text from '../../components/Text'
import Button from '../../components/Button'
import QCModal from '../../components/QCModal'
import GoodsItem from './GoodsItem'
import {
  footerCart,
  badge,
  carIconWrap,
  submitBtn,
  left,
  modalContent,
  modalBody,
  cartModal,
  modalTitle
} from './index.less'
import { Toast } from 'antd-mobile'

interface CardProps {
  shipping_fee_min?: string
  goodList?: Definitions.Agalia.MenuItem[]
  onAdd?: (item: Definitions.Agalia.MenuItem) => void
  onDelete?: (item: Definitions.Agalia.MenuItem) => void
  onClear?: () => void
  onSubmit?: () => void
}
interface IMenuItem extends Definitions.Agalia.MenuItem {
  selectedCount: number
}
const Cart: FC<CardProps> = ({
  shipping_fee_min,
  goodList = [],
  onDelete,
  onAdd,
  onClear,
  onSubmit
}) => {
  const [showModal, updateShowModal] = useState<boolean>(false)
  // 计算总数
  let totalCount = 0
  let totalAmount = 0
  const showList: IMenuItem[] = []
  goodList?.map((item) => {
    totalAmount += Number(item.price || 0)
    totalCount++
    // 处理弹出层菜单
    // showList已存在
    const isExistMenuItemIdx = showList.findIndex(
      (menu) => menu.menu_id === item.menu_id
    )
    if (isExistMenuItemIdx === -1) {
      showList.push({ ...item, selectedCount: 1 })
    } else {
      showList[isExistMenuItemIdx].selectedCount++
    }
  })
  const fixedTotalAmount = totalAmount.toFixed(2)
  const toggleModal = () => {
    updateShowModal((showModal) => !showModal)
  }
  // 选好了去下单页
  const handleSubmit = () => {
    const selectedCount = goodList.length
    // 至少点一个菜吧亲
    if (selectedCount) {
      onSubmit && onSubmit()
    } else {
      Toast.show('请选择菜品')
    }
  }
  // 弹层清空按钮
  const handleClear = () => {
    onClear && onClear()
    // 关闭弹层
    toggleModal()
  }
  // 小于最低配送费就不让去下单
  const diffAmount = Number(shipping_fee_min) - Number(fixedTotalAmount)
  const disabled = diffAmount > 0
  const submitBtnText =
    diffAmount > 0 ? `还差${diffAmount.toFixed(2)}元起送` : '选好了'
  return (
    <RowView className={footerCart} vAlign="center" hAlign="between">
      {/* 弹出层已选中菜单 */}
      <QCModal
        popup
        prefixCls={cartModal}
        visible={showModal}
        maskClosable
        onClose={toggleModal}
        animationType="slide-up"
      >
        <ColumnView className={modalContent}>
          <RowView hAlign="between" className={modalTitle}>
            <Text size={30} color="#181818FF">
              已选商品
            </Text>
            <Text size={30} color="#888888FF" onClick={handleClear}>
              <Icon name="iconqingkong" color="#CCCCCCFF" />
              清空
            </Text>
          </RowView>
          <ColumnView className={modalBody}>
            {showList?.map((item) => (
              <GoodsItem
                key={item.menu_id}
                item={item}
                count={item.selectedCount}
                onAdd={onAdd}
                onDelete={onDelete}
              />
            ))}
          </ColumnView>
        </ColumnView>
      </QCModal>
      {/*  footer 购物车显示按钮 */}
      <XCenterView className={left} onClick={toggleModal}>
        <Text className={carIconWrap}>
          <Icon name="icongouwuche" size={44} color="#666666FF" />
          {!!totalCount && (
            <QCBadge overflowCount={100} text={totalCount} className={badge} />
          )}
        </Text>
        {!!totalAmount && (
          <Text color="#FF9847FF" size={34}>
            <Text size={24}>￥</Text>
            {fixedTotalAmount}
          </Text>
        )}
      </XCenterView>
      <Button disabled={disabled} className={submitBtn} onClick={handleSubmit}>
        {submitBtnText}
      </Button>
    </RowView>
  )
}

export default Cart
