import { observable, action, toJS } from 'mobx'

export class TakeawaySearchStore {
  @observable
  address: any = null
  // 用完即毁
  getOnceData() {
    const cacheData = toJS(this.address)
    // this.address = null
    return cacheData
  }
}
