import React, { FC } from 'react'
import { RowView } from '../../components/QCFlex'
import Button from '../../components/Button'
import Text from '../../components/Text'
import { IGoodsMenuItem } from './interface'
import { actionBar } from './index.less'
interface ActionBarProps {
  list?: IGoodsMenuItem[]
  onSubmit?: () => void
}
const ActionBar: FC<ActionBarProps> = ({ onSubmit, list = [] }) => {
  let amount = 0
  // 总费用
  list.map((item) => {
    // 配送费、打包费、餐盒费count是0
    amount += item.count ? Number(item.price) * item.count : Number(item.price)
  })
  return (
    <RowView className={actionBar}>
      <Text color="#FF9847FF" size={34}>
        ￥{amount.toLocaleString('en-US')}
      </Text>
      <Button onClick={onSubmit}>确认并下单</Button>
    </RowView>
  )
}

export default ActionBar
