import { AxiosRequestConfig } from 'axios'
import { string } from 'yup'
import makeUseApi from '../utils/makeUseApi'

/**查看支付宝绑定状态 */
export const queryAuthorType: AxiosRequestConfig = {
  method: 'POST',
  url: '/aimee/qcpay/alipay/bindState',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
}
export const useQueryAuthorType = makeUseApi<
  {
    returnUrl?: string
  },
  Definitions.Aimee.RestAuthorResponse
>(queryAuthorType)

/**绑定支付宝 */
export const bindAuthor: AxiosRequestConfig = {
  method: 'POST',
  url: '/aimee/qcpay/alipay/bind',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}

export const usebindAuthor = makeUseApi<
  // Definitions.Aimee.BindAuthorRequest,
  {},
  Definitions.Aimee.RestResponse
>(bindAuthor)
