// sleep
export const sleep = (time: number) => {
  return new Promise((resolve) => setTimeout(resolve, time))
}

// debounce
export type Procedure = (...args: any[]) => void
export const debounce = <F extends Procedure>(
  method: F,
  wait: number
): ((this: ThisParameterType<F>, ...args: Parameters<F>) => void) => {
  let timeout: ReturnType<typeof setTimeout> | undefined
  return function (this: ThisParameterType<F>, ...args: Parameters<F>) {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      method.apply(this, args)
    }, wait)
  }
}

// isEmpty
export const isEmpty = (value: any) => [undefined, null, ''].includes(value)

// chunk
export const chunk = (arr: any[], size: number) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  )
// isZero
export const isZero = (value: any) => !Number(value)

// format distance

export const formatDistance = (distance: number) => {
  let unit = 'm'
  let res = distance
  if (distance) {
    if (res > 1000) {
      res = Number(((distance || 0) / 1000).toFixed(3))
      unit = 'km'
    }
    return `${res}${unit}`
  } else {
    return ''
  }
}

export const GetQueryString = (name: string) => {
  if (name == 'url') {
    //特殊参数所以特殊处理
    const r = window.location.search.substr(1).split(`${name}=`)
    if (r != null) return r[1]
    return null
  }
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  const r = window.location.search.substr(1).match(reg)
  if (r != null) return unescape(r[2])
  return null
}
