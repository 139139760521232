import { AxiosRequestConfig } from 'axios'
import makeUseApi from '../utils/makeUseApi'
import { COMPANY_NAME } from '../constants/app'
import {
  useOrderDetail,
  useCancelOrder as useEatinCancelOrder,
  useGetPreOrderInfo as useEatinGetPreOrderInfo,
  useBookOrder as useEatinBookOrder
} from './EatinOrder'
import {
  useOrderDetail as useRecOrderDetail,
  useCancelOrder as useRecCancelOrder,
  useComfirm as useRecOrderComfirm,
  useGetPreOrderInfo as useRecGetPreOrderInfo,
  useBookOrder as useRecBookOrder
} from './RecommendOrder'
import {
  useOrderDetail as useTAOrderDetail,
  useComfirmOrder
} from './TakeawayOrder'
import { OrderType } from '../constants/order'
// 订单评价
export const OrderEvaluation: AxiosRequestConfig = {
  url: '/aimee/order/appraise',
  headers: {
    Company: COMPANY_NAME
  },
  method: 'POST'
}
export const useOrderEvaluation = makeUseApi<
  Definitions.Aimee.Rating,
  Definitions.Aimee.RestResponse
>(OrderEvaluation)

// 确认订单
export const OrderConfirm: AxiosRequestConfig = {
  url: '/aimee/order/confirm',
  headers: {
    Company: COMPANY_NAME
  },
  method: 'POST'
}
export const useOrderConfirm = makeUseApi<
  Definitions.Aimee.确认订单DTO,
  Definitions.Aimee.RestResponse
>(OrderConfirm)

// 发送消费确认函
export const ResendEmail: AxiosRequestConfig = {
  url: '/aimee/order/resendEmail',
  headers: {
    Company: COMPANY_NAME
  },
  method: 'POST'
}
export const useResendEmail = makeUseApi<
  Definitions.Aimee.ResendConfirmEmailRequest,
  Definitions.Aimee.RestResponse
>(ResendEmail)

// 上传票据信息
export const BindTicketPhoto: AxiosRequestConfig = {
  url: '/aimee/order/bindTicketPhoto',
  method: 'POST'
}
export const useBindTicketPhoto = makeUseApi<
  Definitions.Aimee.BindTicketPhotoRequest,
  Definitions.Aimee.RestResponse
>(BindTicketPhoto)

// union 详情
export const useUnionOrderDetail = (type: OrderType = 'RESERVATION') => {
  if (type === 'RESERVATION') {
    return useOrderDetail
  }
  if (type === 'RECOMMEND') {
    return useRecOrderDetail
  }
  return useTAOrderDetail
}
// union confirm
export const useUnionOrderConfirm = (type: OrderType = 'RESERVATION') => {
  if (type === 'RESERVATION') {
    return useOrderConfirm
  }
  if (type === 'RECOMMEND') {
    return useRecOrderComfirm
  }
  return useComfirmOrder
}
// union cancel
export const useUnionOrderCancel = (
  type: 'RESERVATION' | 'RECOMMEND' = 'RESERVATION'
) => {
  if (type === 'RECOMMEND') {
    return useRecCancelOrder
  }
  return useEatinCancelOrder
}
// union book
export const useUnionOrderBook = (
  type: 'RESERVATION' | 'RECOMMEND' = 'RESERVATION'
) => {
  if (type === 'RECOMMEND') {
    return useRecBookOrder
  }
  return useEatinBookOrder
}

// union GetPreOrderInfo
// 堂食（eatin）|| 当面付（unsigned）
export const useUnionGetPreOrderInfo = (
  type: 'RESERVATION' | 'RECOMMEND' = 'RESERVATION'
) => {
  if (type === 'RECOMMEND') {
    return useRecGetPreOrderInfo
  }
  return useEatinGetPreOrderInfo
}

// 限额

export const MonthLimit: AxiosRequestConfig = {
  url: '/aimee/order/monthLimit',
  headers: {
    Company: COMPANY_NAME
  },
  method: 'POST'
}
export const useMonthLimit = makeUseApi<
  Definitions.Aimee.月限制额请求,
  Definitions.Aimee.RestResponseOf月限制额返回
>(MonthLimit)

// 锁单
export const OrderLockCheck: AxiosRequestConfig = {
  url: '/aimee/orderlock/check',
  headers: {
    Company: COMPANY_NAME
  },
  method: 'POST'
}
export const useOrderLockCheck = makeUseApi<
  null,
  Definitions.Aimee.OrderLockCheckResponse
>(OrderLockCheck)

/**阿里支付费控规则创建 */
export const createRule: AxiosRequestConfig = {
  method: 'POST',
  url: '/aimee/Qcpay/alipay/ruleCreateByOrderId',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}

export const useCreateRule = makeUseApi<
  {
    // orderId?: string
  },
  Definitions.Aimee.RestResponse
>(createRule)
/**调用支付宝企业码 */
export const checkQcPay: AxiosRequestConfig = {
  method: 'POST',
  url: '/aimee/Qcpay/alipay/payUrl',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}

export const useCheckQcPay = makeUseApi<
  {
    // orderId?: string
    // payType: string
  },
  Definitions.Aimee.RestResponse
>(checkQcPay)
