import React, { FC, memo, useMemo } from 'react'
import { Drawer } from 'antd-mobile'
import { ItemCell } from '../../components/Cell'
import { ColumnView } from '../../components/QCFlex'
import { modalCard, foodTypeDrawer } from './index.less'
import { FoodTypeListProps, FoodTypeDrawerProps } from './interface'
const FoodTypeList: FC<FoodTypeListProps> = ({ onItemClick, cateList }) => {
  const handleItemClick = (cate?: string) => {
    onItemClick && onItemClick(cate)
  }
  return (
    <ColumnView className={modalCard}>
      {cateList?.map((cate) => (
        <ItemCell
          key={cate.value}
          align="start"
          value={cate.title}
          arrow={false}
          onClick={() => handleItemClick(cate.value)}
        />
      ))}
    </ColumnView>
  )
}
const FoodTypeDrawer: FC<FoodTypeDrawerProps> = ({
  onChange,
  open,
  cateList,
  onOpenChange
}) => {
  // hack fadeIn的动画
  const fakeStyle = useMemo(
    () => ({
      zIndex: open ? 1 : -1,
      ...(!open && { transitionProperty: 'z-index', transitionDelay: '0.4s' })
    }),
    [open]
  )
  return (
    <Drawer
      className={foodTypeDrawer}
      open={open}
      position="top"
      style={fakeStyle}
      enableDragHandle
      onOpenChange={onOpenChange}
      sidebar={
        <FoodTypeList
          cateList={cateList}
          onItemClick={(item) => {
            onChange && onChange(item)
            onOpenChange && onOpenChange(false)
          }}
        />
      }
    >
      <></>
    </Drawer>
  )
}

export default memo(FoodTypeDrawer)
