import { useHistory, Link } from 'react-router-dom'
import React, { FC, memo } from 'react'
import AutoListView, {
  IAutoListViewFormatFn
} from '../../components/AutoListView'
import { XCenterView } from '../../components/QCFlex'
import { SearchBar } from '../../components/SearchBar'
import Text from '../../components/Text'
import EmptyRestaurantImg from '../../assets/EmptyResult.svg'
import RestaurantItem from './RestaurantItem'
import { useRestaurantPoints } from '../../api/Restaurant'
import { useGlobalStores } from '../../hooks/store'

import {
  container,
  restaurantSearch,
  emptyContainer,
  img,
  result,
  dsc
} from './index.less'
import { ColumnView } from '../../components/QCFlex'

interface HistoryStateProps {
  keywords?: string
}
const PAGE_SIZE = 10

const Footer = memo(() => (
  <XCenterView margin={[30, 0, 0, 0]}>
    <Link to="/recommendRestaurantList">
      <Text color="#CCCCCCFF" size={28}>
        去推荐餐厅 {'>'}
      </Text>
    </Link>
  </XCenterView>
))
Footer.displayName = 'MFooter'
const EmptyView = memo(() => (
  <ColumnView hAlign="center" className={emptyContainer}>
    <img src={EmptyRestaurantImg} className={img} />
    <Text color="#181818FF" size={34} className={result}>
      没有搜索结果
    </Text>
    <Text color="#CCCCCCFF" size={28} className={dsc}>
      未搜索到餐厅，换个关键词试试吧~
    </Text>
    <Footer />
  </ColumnView>
))
EmptyView.displayName = 'MEmptyView'
const renderRestaurantItem = (item: Definitions.Europe.首页搜索) => (
  <RestaurantItem {...item} />
)
const itemKeyExtractor = (item: Definitions.Agalia.UnsignedRestaurantDetail) =>
  item.rid || item.sourceId || ''
const formatPageInfo = ({
  pSize,
  pNum
}: {
  pSize?: number
  pNum?: number
}) => ({
  pageSize: pSize,
  page: pNum
})

const formatRes: IAutoListViewFormatFn<
  Definitions.Europe.餐厅信息,
  Definitions.Europe.搜索商户返回值
> = ({ list = [] }) => ({
  list: list,
  // pagesize 为PAGE_SIZE 简单判断小于PAGE_SIZE条就算是nomore
  nomore: list?.length < PAGE_SIZE
})

const AllRestaurantSearch: QCPage = () => {
  const router = useHistory<HistoryStateProps>()
  const keywords = router.location?.state?.keywords || ''
  const { cityStore } = useGlobalStores()
  const city = cityStore?.info?.name
  const handleCancelClick = () => {
    router.goBack()
  }
  const params = { keywords, needUnsigned: true, city }
  return (
    <ColumnView className={container}>
      {/* 本页面仅做展示所以不提供交互 */}
      <SearchBar
        showCancelButton
        className={restaurantSearch}
        onFocus={handleCancelClick}
        onCancel={handleCancelClick}
        defaultValue={keywords}
      />
      {keywords && (
        <AutoListView
          formatPageInfo={formatPageInfo}
          params={params}
          startPage={1}
          pageSize={PAGE_SIZE}
          renderItem={renderRestaurantItem}
          format={formatRes}
          useService={useRestaurantPoints}
          itemKeyExtractor={itemKeyExtractor}
          EmptyView={EmptyView}
          FooterView={Footer}
        />
      )}
    </ColumnView>
  )
}

AllRestaurantSearch.config = {
  title: '餐厅搜索'
}

export default AllRestaurantSearch
