import React from 'react'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router-dom'
import { ColumnView } from '../../components/QCFlex'
import { useForm, Controller } from 'react-hook-form'
import { Checkbox, InputItem, List, Toast } from 'antd-mobile'
import Button from '../../components/Button'
import Text from '../../components/Text'
import { useUnionOrderConfirm, useUnionOrderDetail } from '../../api/Order'
import { useCondition } from '../../hooks/common'
import { useToastFail } from '../../hooks/toast'
import { useGuanAiTongPayment } from '../../hooks/payment'

import { confirmButton } from './index.less'
import logo from '../../assets/guanaitongLogo.png'
import { OrderType } from '../../constants/order'

interface OrderConfirmParams {
  num: number
  amount: number
}

const OrderConfirm: QCPage = () => {
  const location = useLocation<
    MaybeNull<{
      orderId: string
      orderType: OrderType
      bizType: string
    }>
  >()

  const orderType = location.state?.orderType || 'RESERVATION'
  const bizType = location.state?.bizType || 'SYT'
  const orderId = location.state?.orderId || '104200910120746001'

  const { watch, control, handleSubmit, errors } = useForm<OrderConfirmParams>()
  const { num, amount } = watch()

  const gotoGATPayment = useGuanAiTongPayment({
    orderId,
    orderType,
    bizType,
    amount
  })

  const [
    { data: confirmOrderRes, loading: confirming },
    confirmOrder
  ] = useUnionOrderConfirm(orderType)({
    manual: true,
    data: {
      id: orderId,
      orderNum: orderId,
      people: `${num}`,
      money: `${amount}`,
      type: '0'
    }
  })

  useCondition(confirmOrderRes?.status === 0, () => gotoGATPayment())
  useToastFail(confirmOrderRes?.status! > 0 ? confirmOrderRes?.message : false)

  const [{ data: orderDetail }] = useUnionOrderDetail(
    location.state?.orderType
  )({
    data: {
      orderId
    }
  })

  const onSubmit = async (data: OrderConfirmParams) => {
    console.log(data)
    confirmOrder()
  }

  return (
    <div style={{ backgroundColor: '#F9F9FA', flex: 1 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ColumnView bgColor="#F9F9FA" padding={[20, 30, 30, 20]}>
          <Controller
            name="num"
            control={control}
            defaultValue={''}
            rules={{
              required: { message: '请输入用餐人数', value: true },
              maxLength: { message: '用餐人数最多支持4位数', value: 4 }
            }}
            render={(props) => (
              <InputItem
                {...props}
                type="money"
                style={{ textAlign: 'right' }}
                placeholder="请请输入用餐人数"
                error={!!errors.num}
                onErrorClick={() => {
                  Toast.show(errors.num?.message)
                }}
              >
                用餐人数
              </InputItem>
            )}
          ></Controller>
          <Controller
            name="amount"
            control={control}
            defaultValue={''}
            rules={{
              required: { message: '请输入本次消费总额', value: true },
              max: { message: '最大消费金额1000000', value: 1000000 }
            }}
            render={(props) => (
              <InputItem
                {...props}
                type="money"
                placeholder="请请输入本次消费总额"
                error={!!errors.amount}
                onErrorClick={() => {
                  Toast.show(errors.amount?.message)
                }}
              >
                消费总额
              </InputItem>
            )}
          ></Controller>
        </ColumnView>
        <ColumnView bgColor="#F9F9FA" padding={[20, 30, 30, 20]}>
          <List>
            <List.Item>
              企业对公支付
              <Text color="#CCCCCCFF" size={26}>
                （企业支付无需个人付款）
              </Text>
            </List.Item>
            <List.Item extra={<Checkbox checked />} align="middle">
              <img src={logo} /> 关爱通
            </List.Item>
          </List>
        </ColumnView>
        <Button
          type="submit"
          className={confirmButton}
          disabled={confirming}
          loading={confirming}
        >
          确认
        </Button>
      </form>
    </div>
  )
}

OrderConfirm.config = {
  title: '确认消费',
  pageBgColor: '#fff'
}

export default observer(OrderConfirm)
