import { observable, computed, action, toJS } from 'mobx'
export class PagePickerStore {
  // 用完即销毁？

  @observable
  data: any = null

  @observable
  field?: string = ''

  @observable
  active?: boolean = false

  // 用完即毁
  @action
  getOnceData() {
    const cacheData = toJS(this.data)
    this.data = null
    return cacheData
  }
}
