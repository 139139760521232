// 外卖餐厅订座
import React, { FC, useState, useCallback, memo, useRef } from 'react'
import { useLocation, Link } from 'react-router-dom'

import { ColumnView, XCenterView } from '../../components/QCFlex'
import Text from '../../components/Text'
import EmptyView from '../../components/EmptyView'
import { useRestaurantSearch } from '../../api/TakeawayRestaurant'
import AutoListView, {
  IAutoListViewFormatFn
} from '../../components/AutoListView'
import { useGlobalStores } from '../../hooks/store'
import SearchFilter from './SearchFilter'
import RestaurantItem from './RestaurantItem'
import {
  IFormatParams,
  IFilterMethods,
  IRestaurant,
  HistoryStateProps
} from './interface'
import { container, restaurantList } from './index.less'
const Footer = memo(() => (
  <XCenterView margin={[30, 0, 0, 0]}>
    <Link to="/recommendRestaurantList">
      <Text color="#CCCCCCFF" size={28}>
        去推荐餐厅 {'>'}
      </Text>
    </Link>
  </XCenterView>
))
Footer.displayName = 'MFooter'
const ListEmptyView = () => (
  <>
    <EmptyView />
    <Footer />
  </>
)

const itemKeyExtractor = (item: IRestaurant) => item.rid || item.poiId || ''

const formatFilterParams = ({ category, address, sort }: IFormatParams) => ({
  address: address?.address,
  hospitalId: address?.hospitalId,
  lat: address?.lat,
  lng: address?.lng,
  category,
  sort
})

const TakeawayRestaurantSearch: QCPage = () => {
  const location = useLocation<HistoryStateProps>()
  const { cityStore } = useGlobalStores()
  const filterRef = useRef<IFilterMethods | null>(null)
  const cityInfo = cityStore?.info
  const hospital = location.state?.hospital
  // 带默认参数的情况
  const poiParams = {
    // 有医院取医院的
    address: hospital ? hospital?.address : '',
    city: cityInfo.name,
    cidy_id: cityInfo.id,
    hospital_id: hospital?.hospitalId,
    lat: hospital ? hospital?.location?.lat?.toString() : '',
    lng: hospital ? hospital?.location?.lng?.toString() : '',
    // 默认排序方式 距离
    sort: '1'
  }
  const defaultFilterParams = {
    hospital
  }
  // filter参数
  const [filterParams, updateFilterParams] = useState<
    Definitions.Europe.搜索外卖商户列表入参
  >(poiParams)
  // filter菜系参数，从每次返回中获取，所以把formatRes放到了内部
  const [cateList, updateCateList] = useState<Definitions.Europe.菜品分类[]>([])

  // autoList formatRes
  const formatRes: IAutoListViewFormatFn<
    IRestaurant,
    Definitions.Europe.搜索商户返回值
  > = useCallback(({ list, categoryList }) => {
    updateCateList(categoryList)
    return {
      list: list,
      // 因为接口没有找到pagesize参数，每次返回10条
      // 所以nomore就根据这个来判断
      nomore: list?.length < 10
    }
  }, [])
  // filter change
  const handleFilterChange = useCallback(
    (params) => {
      updateFilterParams((preParams) =>
        Object.assign(
          {},
          preParams,
          formatFilterParams({
            category: params.category,
            address: params.address,
            sort: params.sort
          })
        )
      )
    },
    [updateFilterParams]
  )
  // 点击单个餐厅就缓存当前筛选条件
  const handleItemClick = () => {
    filterRef.current?.addCache()
  }
  const renderRestaurantItem = (item: IRestaurant) => (
    <RestaurantItem item={item} onClick={handleItemClick} />
  )
  return (
    <ColumnView className={container}>
      <SearchFilter
        filterRef={(ref) => (filterRef.current = ref)}
        defaultValue={defaultFilterParams}
        onChange={handleFilterChange}
        cateList={cateList}
      />
      <ColumnView className={restaurantList}>
        <AutoListView
          params={filterParams}
          renderItem={renderRestaurantItem}
          format={formatRes}
          itemKeyExtractor={itemKeyExtractor}
          useService={useRestaurantSearch}
          FooterView={Footer}
          EmptyView={ListEmptyView}
        />
      </ColumnView>
    </ColumnView>
  )
}

TakeawayRestaurantSearch.config = {
  title: '外卖餐厅搜索'
}

export default TakeawayRestaurantSearch
