import React, { FC, memo } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'

import { XCenterView, ColumnView } from '../../../components/QCFlex'
import Text from '../../../components/Text'
import StatusTag from '../../../components/StatusTag'
import Cell, { ItemCell } from '../../../components/Cell'

import { CurrentUserQuery } from '../../../generated/graphql'
import { useGlobalStores } from '../../../hooks/store'

import { container, footer, tag } from './index.less'

export interface IUserInfoShowItem {
  key: keyof NonNullable<CurrentUserQuery['user']>
  icon: string
}

export interface IUserManageItem {
  name: string
  icon: string
  link: string
  external?: boolean
}
interface UserInfoCardProps {
  info: CurrentUserQuery['user']
}
interface UserManageCardProps {
  items: IUserManageItem[]
}
const UserInfoShowList: IUserInfoShowItem[] = [
  {
    key: 'staffId',
    icon: 'iconwode-renwu'
  },
  {
    key: 'mobile',
    icon: 'iconwode-dianhua'
  },
  {
    key: 'email',
    icon: 'iconwode-youxiang'
  }
]

const UserInfoCard: FC<UserInfoCardProps> = memo(({ info }) => {
  const mergedClassName = `qc-section-card`
  return (
    <ColumnView className={mergedClassName}>
      <Cell>
        <Cell.Left>
          <Text size={40} color="#181818FF">
            {info?.name}
          </Text>
          <StatusTag
            className={tag}
            text={info?.cityName || ''}
            color="#FF9847FF"
          />
        </Cell.Left>
      </Cell>
      {UserInfoShowList.map((item: IUserInfoShowItem) => (
        <Cell key={item.key}>
          <Cell.Icon name={item.icon} size={26} color="#888888ff" />
          <Cell.Left>
            <Text size={28} color="#888888FF">
              {info?.[item.key]}
            </Text>
          </Cell.Left>
        </Cell>
      ))}
    </ColumnView>
  )
})
UserInfoCard.displayName = 'MUserInfoCard'
const UserManageCard: FC<UserManageCardProps> = memo(({ items }) => {
  const mergedClassName = `qc-section-card`
  const router = useHistory()
  const goTo = (path: string, external?: boolean) => () => {
    if (external) {
      location.href = path
      return
    }
    router.push(path)
  }
  return (
    <ColumnView className={mergedClassName}>
      {items.map((item: IUserManageItem) => (
        <ItemCell
          key={item.name}
          onClick={goTo(item.link, item.external)}
          icon={{ name: item.icon, size: 26, color: '#F4630BFF' }}
          label={item.name}
        />
      ))}
    </ColumnView>
  )
})
UserManageCard.displayName = 'MUserManageCard'
const FooterCard: FC = memo(() => {
  const { userStore } = useGlobalStores()
  const logout = () => {
    userStore.logout()
  }
  return (
    <XCenterView className={footer}>
      <Text color="#CCCCCCFF" size={28} onClick={logout}>
        退出登录
      </Text>
    </XCenterView>
  )
})
FooterCard.displayName = 'MFooterCard'
const manageItems = [
  {
    name: '我的推荐餐厅',
    icon: 'icondianputubiao',
    link: '/recommendRestaurantList'
  },
  {
    name: '我的消费统计',
    icon: 'iconwodexiaofeitongji',
    link: '/consumStatistics'
  },
  {
    name: '企业开票信息',
    icon: 'iconqiyekaipiaoxinxi',
    link: '/enterpriseInvoiceInfo'
  },
  {
    name: '使用指南',
    icon: 'iconshiyongzhinan',
    link: '/userManual'
  },
  {
    name: '用户反馈',
    icon: 'iconyonghufankui',
    link: '/feedback'
  }
]
const UserInfoBar: FC = () => {
  const { userStore } = useGlobalStores()
  return (
    <ColumnView className={container}>
      <UserInfoCard info={userStore.profile} />
      <UserManageCard items={manageItems} />
      <FooterCard />
    </ColumnView>
  )
}

export default observer(UserInfoBar)
