import React, {
  FC,
  memo,
  useState,
  useCallback,
  useMemo,
  ReactText,
  useEffect
} from 'react'
import { Picker as AtPicker } from 'antd-mobile'
import { PickerPropsType } from 'antd-mobile/lib/picker/PropsType'
import Icon from '../../Icon'
import FormRow, { FormRowProps } from '../FormRow'
import {
  ConnectForm,
  ConnectFormProps,
  ConnectFormChildrenProps
} from '../../QCForm'
import { useCompanyGroupsLazyQuery } from '../../../generated/graphql'
import { useFormattedProps } from '../../../hooks/pickerHelper'

interface BaseGroupPickerProps
  extends FormRowProps,
    Omit<PickerPropsType, 'data'> {
  type?: string
}

export interface FormGroupPickerProps
  extends BaseGroupPickerProps,
    ConnectFormProps {}

export interface GroupPickerProps
  extends BaseGroupPickerProps,
    Omit<ConnectFormChildrenProps, 'value' | 'onBlur'>,
    ConnectFormProps {}
interface ShowTextProps {
  placeholder?: string
  onClick?: () => void
  extra?: string
}
const ShowText: FC<ShowTextProps> = memo(
  ({ onClick, extra, placeholder }) => {
    const isPlaceholder = useMemo(() => extra === placeholder, [extra])
    const textClassName = isPlaceholder ? 'qc-input-placeholder' : ''
    return (
      <span className={textClassName} onClick={onClick}>
        {isPlaceholder ? placeholder : extra}
        <Icon name="iconyoujiantou" size={20} color="rgb(136, 136, 136)" />
      </span>
    )
  },
  (prevProps, nextProps) =>
    prevProps.extra === nextProps.extra &&
    prevProps.placeholder === nextProps.placeholder
)
ShowText.displayName = 'MShowText'
export const GroupPicker: FC<GroupPickerProps> = ({
  label,
  sync,
  placeholder,
  disabled,
  value,
  type,
  rules,
  ...otherProps
}) => {
  type = type || 'structure'

  const [group, updateGroup] = useState<ReactText[] | undefined>(value)
  const [queryGroups, { data }] = useCompanyGroupsLazyQuery({
    variables: {
      type
    }
  })

  const handleChange = useCallback(
    (pickergroup) => {
      updateGroup(() => {
        !disabled && sync && sync(pickergroup)
        return pickergroup
      })
    },
    [sync]
  )

  const formattedProps = useFormattedProps({
    value: group,
    onChange: handleChange
  })
  useEffect(() => {
    queryGroups()
  }, [])
  return (
    <FormRow label={label} required={!!rules?.required}>
      <AtPicker
        {...otherProps}
        // 先自定义个extra用来判断placeholder
        extra={placeholder}
        cols={1}
        value={formattedProps.value}
        onChange={formattedProps.onChange}
        // onVisibleChange={(visible) => visible && queryGroups()}
        data={
          data?.groups.map((group) => ({
            value: group.groupId,
            label: group.name
          })) || []
        }
      >
        <ShowText placeholder={placeholder} />
      </AtPicker>
    </FormRow>
  )
}

const MGroupPicker: FC<ConnectFormChildrenProps> = memo(
  ({ formState, watch, ...otherProps }) => <GroupPicker {...otherProps} />,
  (prevProps, nextProps) =>
    prevProps.formState?.isDirty === nextProps.formState?.isDirty
)
const FormGroupPicker: FC<FormGroupPickerProps> = ({
  field,
  ...otherProps
}) => (
  <ConnectForm field={field} rules={otherProps.rules}>
    <MGroupPicker {...otherProps} />
  </ConnectForm>
)

export default FormGroupPicker
