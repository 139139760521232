import React, { FC } from 'react'

import Text from '../../components/Text'
import h2r from '../../utils/hex2rgba'
import { statusTag } from './index.less'

interface StatusTagProps {
  text: string
  color?: string
  className?: string
}

const StatusTag: FC<StatusTagProps> = ({
  text,
  color = '#85A8F9FF',
  className = ''
}) => (
  <Text
    size={24}
    className={`${statusTag} ${className}`}
    color={color}
    style={{ backgroundColor: h2r(color, 0.2) }}
  >
    {text}
  </Text>
)
export default StatusTag
