// 这个搜索列表没有分页操作等交互，所以直接全部渲染出来了
import React, { FC, useEffect, memo } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { ColumnView, RowView, XCenterView } from '../../components/QCFlex'
import EmptyView from '../../components/EmptyView'
import Text from '../../components/Text'
import ErrorView from '../../components/ErrorView'

import { useGlobalStores } from '../../hooks/store'

import { useRestaurantSuggestList } from '../../api/Restaurant'

import { restaurantItem, tip } from './index.less'

interface IItem {
  name?: string
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const Footer = memo(() => (
  <XCenterView margin={[30, 0, 0, 0]}>
    <Link to="/recommendRestaurantList">
      <Text color="#CCCCCCFF" size={28}>
        去推荐餐厅 {'>'}
      </Text>
    </Link>
  </XCenterView>
))
Footer.displayName = 'MFooter'
const Item = memo(({ name, onClick }: IItem) => (
  <RowView className={restaurantItem} onClick={onClick}>
    {name}
  </RowView>
))

interface RestaurantProps {
  keywords?: string
  tabName?: string
}
const Restaurant: FC<RestaurantProps> = ({ keywords, tabName }) => {
  const router = useHistory()
  const { cityStore } = useGlobalStores()
  const city = cityStore?.info?.name
  const [restaurantRes, refetchRestaurants] = useRestaurantSuggestList({
    useCache: true,
    manual: true,
    data: {
      keywords,
      needUnsigned: true,
      city
    }
  })
  const goto = (kw?: string) => {
    router.push('/allRestaurantSearch', { keywords: kw })
  }
  const handleSearchkeywords = () => {
    router.push('/allRestaurantSearch', { keywords })
  }
  useEffect(() => {
    tabName === 'restaurant' && refetchRestaurants()
  }, [keywords, tabName])

  return (
    <ColumnView>
      {keywords && tabName === 'restaurant' && (
        <>
          <RowView className={tip}>
            <Text color="#181818FF" size={28} onClick={handleSearchkeywords}>
              搜索“{keywords}”
            </Text>
          </RowView>
          {restaurantRes.loading ? (
            '加载中....'
          ) : restaurantRes.error ? (
            <ErrorView text={restaurantRes.error.message} />
          ) : !restaurantRes?.data?.data?.list?.length ? (
            <>
              <EmptyView text="未搜索到餐厅，请输入关键词重新搜索" />
              <Footer />
            </>
          ) : (
            restaurantRes?.data?.data?.list?.map((item) => (
              <Item
                key={item.id}
                name={item.name}
                onClick={() => goto(item.store_name)}
              />
            ))
          )}
        </>
      )}
    </ColumnView>
  )
}

export default Restaurant
