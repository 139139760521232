import { FormValues } from './interface'
export const validate = (formData: FormValues) => {
  let res = {
    message: '',
    success: true
  }
  // 发票
  if (!formData.invoices?.length) {
    res = {
      message: '请至少上传一张发票',
      success: false
    }
    return res
  }
  // 小票
  if (!formData.receipts?.length) {
    res = {
      message: '请至少上传一张小票',
      success: false
    }
    return res
  }
  return res
}
