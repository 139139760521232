import { useHistory } from 'react-router-dom'
import React, { FC, memo, useState } from 'react'
import AutoListView, {
  IAutoListViewFormatFn
} from '../../components/AutoListView'
import { SearchBar } from '../../components/SearchBar'
import Text from '../../components/Text'
import EmptyRestaurantImg from '../../assets/EmptyResult.svg'
import RestaurantItem from './RestaurantItem'
import { useRestaurantSearch } from '../../api/RecommendRestaurant'
import { useGlobalStores } from '../../hooks/store'

import {
  container,
  restaurantSearch,
  emptyContainer,
  img,
  result,
  dsc
} from './index.less'
import { ColumnView } from '../../components/QCFlex'

const PAGE_SIZE = 10

const EmptyView = memo(() => (
  <ColumnView hAlign="center" className={emptyContainer}>
    <img src={EmptyRestaurantImg} className={img} />
    <Text color="#181818FF" size={34} className={result}>
      没有搜索结果
    </Text>
    <Text color="#CCCCCCFF" size={28} className={dsc}>
      未搜索到餐厅，换个关键词试试吧~
    </Text>
  </ColumnView>
))

const renderRestaurantItem = (
  item: Definitions.Agalia.UnsignedRestaurantDetail
) => <RestaurantItem {...item} />
const itemKeyExtractor = (item: Definitions.Agalia.UnsignedRestaurantDetail) =>
  item.rid || item.sourceId || ''
const formatPageInfo = ({
  pSize,
  pNum
}: {
  pSize?: number
  pNum?: number
}) => ({
  pageSize: pSize,
  page: pNum
})

const RecommendRestaurantSearch: QCPage = () => {
  const [keywords, updateKeywords] = useState<string>('')
  const router = useHistory()
  const { cityStore } = useGlobalStores()
  const city = cityStore?.info?.name
  const formatRes: IAutoListViewFormatFn<
    Definitions.Agalia.UnsignedRestaurantDetail,
    Definitions.Agalia.SearchResponse
  > = ({ list = [] }) => ({
    list: list,
    // pagesize 为PAGE_SIZE 简单判断小于PAGE_SIZE条就算是nomore
    nomore: list?.length < PAGE_SIZE
  })
  const handleKeywordChange = (value: string) => {
    updateKeywords(value)
  }
  const handleCancelClick = () => {
    router.goBack()
  }
  const params = { keywords, needUnsigned: true, city }
  return (
    <ColumnView className={container}>
      <SearchBar
        placeholder={'请输入餐厅名称'}
        showCancelButton
        className={restaurantSearch}
        onChange={handleKeywordChange}
        onCancel={handleCancelClick}
      />
      {keywords && (
        <AutoListView
          formatPageInfo={formatPageInfo}
          params={params}
          startPage={1}
          pageSize={PAGE_SIZE}
          renderItem={renderRestaurantItem}
          format={formatRes}
          useService={useRestaurantSearch}
          itemKeyExtractor={itemKeyExtractor}
          EmptyView={EmptyView}
        />
      )}
    </ColumnView>
  )
}

RecommendRestaurantSearch.config = {
  title: '推荐餐厅搜索'
}

export default RecommendRestaurantSearch
