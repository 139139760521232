// 订单评价
import React, { FC, useEffect, useRef } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { Toast } from 'antd-mobile'

import QCForm, { FormRefProps } from '../../components/QCForm'
import { FormRateItem, FormTextareaItem } from '../../components/FormItems'
import Button from '../../components/Button'
import Text from '../../components/Text'
import { ColumnView, RowView } from '../../components/QCFlex'
import { useToastFail } from '../../hooks/toast'

import { useOrderEvaluation } from '../../api/Order'
import { container, submitBtn, title } from './index.less'
interface FormValues {
  replyContent: string
  restaurantScore: number
  dishScore: number
  sytServiceScore: number
}
interface UrlParams {
  orderId?: string
}
const formatMustSubmit = (data: FormValues) => {
  // 只要操作了一个就必须走提交
  // 不评价内容也不打星就直接跳转详情
  return (
    data.replyContent ||
    data.restaurantScore ||
    data.dishScore ||
    data.sytServiceScore
  )
}
const validate = (data: FormValues) => {
  let res = {
    message: '',
    success: true
  }
  // 必须三个评星全选
  if (!(data.restaurantScore && data.dishScore && data.sytServiceScore)) {
    res = {
      message: '请完善评分',
      success: false
    }
    return res
  }
  return res
}
const OrderEvaluation: QCPage = () => {
  const { orderId } = useParams<UrlParams>()
  const location = useLocation<{ orderType?: 'RESERVATION' | 'RECOMMEND' }>()
  const router = useHistory()
  const [{ data }, submit] = useOrderEvaluation({
    manual: true
  })
  const inputRef = useRef<FormRefProps<FormValues> | null>(null)
  const onSubmit = () => {
    inputRef &&
      inputRef.current?.qcSubmit((data: FormValues) => {
        const mustSubmit = formatMustSubmit(data)
        if (mustSubmit) {
          // 校验
          const validateRes = validate(data)
          validateRes.success
            ? submit({
                data: {
                  orderNum: orderId,
                  restaurantScore: data.restaurantScore,
                  dishScore: data.dishScore,
                  sytServiceScore: data.sytServiceScore,
                  replyContent: data.replyContent
                }
              })
            : Toast.show(validateRes.message)
        } else {
          goDetail()
        }
      })
  }

  const goDetail = () => {
    router.replace(`/eatinOrderDetail/${orderId}`, {
      orderType: location.state?.orderType
    })
  }
  // 成功
  useEffect(() => {
    data?.status === 0 && goDetail()
  }, [data?.status])
  useToastFail(data?.status !== 1 ? data?.message : null)
  return (
    <ColumnView className={container}>
      <ColumnView className="qc-section-card">
        <QCForm<FormValues>
          formRef={(el) => {
            inputRef.current = el
          }}
        >
          <RowView className={title} vAlign="center">
            <Text size={34} color="#999999FF">
              请对本次服务进行评价
            </Text>
          </RowView>
          <FormRateItem label="餐厅环境" field="restaurantScore" />
          <FormRateItem label="菜品口味" field="dishScore" />
          <FormRateItem label="商宴通服务" field="sytServiceScore" />
          <FormTextareaItem
            label="备注"
            field="replyContent"
            placeholder="请输入本次服务的详细体验"
          />
        </QCForm>
      </ColumnView>
      <Button className={submitBtn} onClick={onSubmit}>
        提交评价
      </Button>
    </ColumnView>
  )
}

OrderEvaluation.config = {
  title: '订单评价'
}

export default OrderEvaluation
