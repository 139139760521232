// fork from ../Input
import React, { FC, memo, useState } from 'react'
import FormRow, { FormRowProps } from '../FormRow'
import {
  ConnectForm,
  ConnectFormProps,
  ConnectFormChildrenProps
} from '../../QCForm'
import './index.global.less'
import { useClassNames } from '../../../hooks/classname'
export type NativeTextareaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>
export interface FormTextareaItemProps
  extends FormRowProps,
    ConnectFormProps,
    NativeTextareaProps {}

export type MTextareaItemProps = Omit<
  ConnectFormChildrenProps,
  'value' | 'onBlur'
>
export interface TextareaItemProps
  extends FormRowProps,
    NativeTextareaProps,
    Omit<ConnectFormChildrenProps, 'value' | 'onBlur'> {
  field?: string
}
type TOnblur = (
  event: React.FocusEvent<HTMLTextAreaElement>
) => void | undefined

const onBlurWrap = (onblur?: TOnblur) => (
  e: React.FocusEvent<HTMLTextAreaElement>
) => {
  onblur && onblur(e)
  // fix#ios 键盘收起页面推上去没有自动拉下来的bug
  window.scrollTo()
}
export const TextareaItem: FC<TextareaItemProps> = ({
  label,
  className,
  sync,
  register,
  onBlur,
  field,
  rules,
  maxLength,
  ...otherProps
}) => {
  const [length, updateLength] = useState<number>(0)
  const mergedClassName = useClassNames(['qc-textarea', className])
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    updateLength(e.target?.value?.length)
  }
  return (
    <FormRow label={label} direction="vertical" required={!!rules?.required}>
      <div className="qc-textarea-container">
        <textarea
          draggable={false}
          maxLength={maxLength}
          name={field}
          onBlur={onBlurWrap(onBlur)}
          ref={rules ? register?.(rules) : register}
          className={mergedClassName}
          onChange={handleChange}
          {...otherProps}
        />
        {maxLength && (
          <span className="qc-textarea-count">
            {length}/{maxLength}
          </span>
        )}
      </div>
    </FormRow>
  )
}
// 针对 FormProvider 的 带优化的TextareaItem
const MTextareaItem: FC<MTextareaItemProps> = memo(
  ({ formState, watch, ...otherProps }) => <TextareaItem {...otherProps} />,
  (prevProps, nextProps) =>
    prevProps.formState?.isDirty === nextProps.formState?.isDirty
)
const FormTextareaItem: FC<FormTextareaItemProps> = (props) => (
  <ConnectForm>
    <MTextareaItem {...props} />
  </ConnectForm>
)
export default FormTextareaItem
