import React, { FC, useCallback, useEffect, useMemo } from 'react'
import isEmpty from 'lodash.isempty'
import groupBy from 'lodash.groupby'
import { List, Flex, Toast } from 'antd-mobile'
import { useObserver } from 'mobx-react-lite'
import { useHistory, useLocation } from 'react-router-dom'
import { useQueryClientOpenArea } from '../../api/City'
import { useCurrentAmapGeo } from '../../hooks/geolocation'
import { useGlobalStores } from '../../hooks/store'
import {
  container,
  quickSearchBar,
  initialSection,
  headerSection,
  usedTag,
  usedTagLine
} from './index.less'
import { useScrollContainer } from '../../hooks/layout'
import { CityInfo } from '../../stores/city'

const CityLocation: QCPage = () => {
  const location = useLocation<{ first?: boolean } | undefined>()
  const history = useHistory()
  const scrollContainer = useScrollContainer()
  const { cityStore, appStateStore } = useGlobalStores()

  const [{ data: regionData, loading: regionLoading }] = useQueryClientOpenArea(
    {
      useCache: true,
      data: {}
    }
  )
  const regeoCode = useCurrentAmapGeo((msg) => Toast.fail(msg))

  const province = regeoCode?.addressComponent?.province
  // 按首字母排序region
  const regions = useMemo(
    () =>
      regionData?.data?.clientRegions?.sort((prev, current) =>
        prev.initial! > current.initial! ? 0 : -1
      ) || [],
    [JSON.stringify(regionData?.data?.clientRegions)]
  )

  useEffect(() => {
    appStateStore.hasBackArrow = !location.state?.first
  }, [location.state?.first])

  const groupedByInitial = useMemo(() => groupBy(regions, 'initial'), [regions])
  const handleRegionClick = useCallback(
    (region: MaybeNull<CityInfo>) => {
      if (!region) return
      cityStore.addToUsed({
        id: cityStore.info.id,
        name: cityStore.info.name
      })
      cityStore.info = {
        id: region.id,
        name: region.name
      }
      location.state?.first ? history.replace('/') : history.goBack()
    },
    [cityStore, history]
  )

  return useObserver(() => (
    <div className={container}>
      <List
        renderHeader={
          <div>
            <Flex direction="row" className={headerSection}>
              <p>当前位置： </p>
              {cityStore.info.name || (
                <div
                  onClick={() => {
                    if (!isEmpty(province)) {
                      cityStore.info.name = (province as string).replace(
                        '市',
                        ''
                      )
                    }
                  }}
                >
                  {isEmpty(province)
                    ? '定位中...'
                    : (province as string).replace('市', '')}
                </div>
              )}
            </Flex>
            {cityStore.used.length > 0 && (
              <Flex
                direction="column"
                className={headerSection}
                alignContent="start"
                align="start"
                justify="start"
              >
                <p>经常使用：</p>
                <Flex direction="row" className={usedTagLine}>
                  {cityStore.used.map((city) => {
                    return (
                      <div
                        key={city.id}
                        className={usedTag}
                        onClick={() => handleRegionClick(city)}
                      >
                        {city.name}
                      </div>
                    )
                  })}
                </Flex>
              </Flex>
            )}
          </div>
        }
      >
        {Object.keys(groupedByInitial).map((initial) => {
          return (
            <React.Fragment key={initial}>
              <List.Item
                className={[initialSection, `section_${initial}`].join(' ')}
              >
                {initial}
              </List.Item>

              {groupedByInitial[initial].map((region) => (
                <List.Item
                  key={region.regionId}
                  onClick={() =>
                    handleRegionClick(
                      region
                        ? {
                            id: region.regionId!,
                            name: region.regionName!
                          }
                        : null
                    )
                  }
                >
                  {region.regionName}
                </List.Item>
              ))}
            </React.Fragment>
          )
        })}
      </List>
      <div className={quickSearchBar}>
        {Object.keys(groupedByInitial).map((initial) => (
          <a
            key={initial}
            onClick={() => {
              const rect = document
                .querySelector(`.section_${initial}`)
                ?.nextElementSibling?.getBoundingClientRect()
              if (scrollContainer) {
                scrollContainer.scrollTop += (rect?.top || 0) - 44
              }
            }}
          >
            {initial}
          </a>
        ))}
      </div>
    </div>
  ))
}

CityLocation.config = {
  title: '选择城市'
}
export default CityLocation
