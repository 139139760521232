// 外卖订单支付结果
import React, { FC, useCallback, useEffect, useRef } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import QCForm, { FormRefProps } from '../../components/QCForm'
import ResultCard from './ResultCard'
import InvoiceCard from './InvoiceCard'
import Footer from './Footer'
import EvaluationCard from './EvaluationCard'
import { ColumnView, RowView } from '../../components/QCFlex'
import { container, hLine, content } from './index.less'
import { useOrderDetail } from '../../api/TakeawayOrder'
import { useOrderEvaluation } from '../../api/Order'
import { useRestToastFail } from '../../hooks/toast'
import { validate, formatMustSubmit } from './utils'
import { IFormValues } from './interface'
import { Toast } from 'antd-mobile'
const TakeawayOrderPayResult: QCPage = () => {
  const router = useHistory()
  const inputRef = useRef<FormRefProps<IFormValues> | null>(null)
  const location = useLocation<{ orderId?: string; gatParams: any }>()
  const [{ data: detail }] = useOrderDetail({
    data: {
      orderId: location.state?.orderId
    }
  })

  const [orderEvaluationRes, orderEvaluation] = useOrderEvaluation({
    manual: true
  })
  const handleSubmit = () => {
    inputRef &&
      inputRef.current?.qcSubmit((formData: IFormValues) => {
        const mustSubmit = formatMustSubmit(formData)
        if (mustSubmit) {
          // 校验
          const validateRes = validate(formData)
          validateRes.success
            ? orderEvaluation({
                data: {
                  orderNum: location.state?.orderId,
                  ...formData
                }
              })
            : Toast.show(validateRes.message)
        } else {
          gotoDetail()
        }
      })
  }
  const gotoDetail = () => {
    router.replace('/uploadTicket', {
      detail: detail?.data,
      orderType: 'takeaway'
    })
  }
  // 成功
  useEffect(() => {
    orderEvaluationRes?.data?.status === 0 && gotoDetail()
  }, [orderEvaluationRes?.data?.status])
  useRestToastFail([orderEvaluationRes])

  return (
    <ColumnView className={container}>
      <ColumnView className={content}>
        <ColumnView className="qc-section-card" hAlign="center">
          <ResultCard detail={detail?.data} />
          <RowView className={hLine} />
          <QCForm<IFormValues>
            formRef={(el) => {
              inputRef.current = el
            }}
          >
            <EvaluationCard />
          </QCForm>
        </ColumnView>
        <InvoiceCard detail={detail?.data as QCOrderInfo} />
      </ColumnView>
      <Footer onClick={handleSubmit} />
    </ColumnView>
  )
}

TakeawayOrderPayResult.config = {
  title: '支付成功'
}

export default TakeawayOrderPayResult
