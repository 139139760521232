import React, { FC } from 'react'
import Text from '../../components/Text'
import Icon from '../../components/Icon'
const ResultCard: FC<{
  detail: any
}> = ({ detail }) => (
  <>
    <Icon name="iconzhifuchenggongtubiao" size={120} color="#51A04CFF" />
    <Text color="#51A04CFF" size={30} margin={[20, 0, 0, 0]}>
      支付成功
    </Text>
    <Text color="#181818FF" size={34} margin={[30, 0, 0, 0]}>
      <Text size={16}>￥</Text>
      {detail?.money}
    </Text>
  </>
)

export default ResultCard
