import { FC } from 'react'
import FormRadioGroup from './RadioGroup'
import FormRadioItem, { RadioItem } from './Radio'
import { FormRadioItemProps } from './interface'

interface CompoundedComponent extends FC<FormRadioItemProps> {
  Group: typeof FormRadioGroup
  Item: typeof RadioItem
}
const FormRadio = FormRadioItem as CompoundedComponent
FormRadio.Group = FormRadioGroup
FormRadio.Item = RadioItem

export default FormRadio
