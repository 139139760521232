import React, { FC } from 'react'
import { ItemCell } from '../../components/Cell'
import { ColumnView } from '../../components/QCFlex'
import Text from '../../components/Text'

const TipCard: FC<QCOrderInfo> = ({ order_state }) => {
  const showTipCard = ['1', '2'].includes(order_state)
  return showTipCard ? (
    <ColumnView className="qc-section-card">
      <ItemCell
        label={
          <Text size={34} color="#999999FF">
            温馨提示
          </Text>
        }
        arrow={false}
      />
      <ItemCell
        value={
          <Text color="#181818FF" size={30}>
            到店用餐时，请告知服务员通过商宴通预订，并出示订单号
          </Text>
        }
        arrow={false}
      />
    </ColumnView>
  ) : (
    <></>
  )
}

export default TipCard
