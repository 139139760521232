import { AxiosRequestConfig } from 'axios'
import makeUseApi from '../utils/makeUseApi'
// 首页搜索框

export const RestaurantSuggestList: AxiosRequestConfig = {
  url: '/europe/search/suggestList',
  method: 'POST'
}
export const useRestaurantSuggestList = makeUseApi<
  Definitions.Europe.首页搜索,
  Definitions.Europe.SearchResponseOf首页搜索返回值
>(RestaurantSuggestList)
// 首页搜索框-points

export const RestaurantPoints: AxiosRequestConfig = {
  url: '/europe/search/points',
  method: 'POST'
}
export const useRestaurantPoints = makeUseApi<
  Definitions.Europe.搜索商户参数,
  Definitions.Europe.SearchResponseOf搜索商户返回值
>(RestaurantPoints)
// 餐厅详情
export const RestaurantDetail: AxiosRequestConfig = {
  url: '/europe/search/getRestaurantDetail',
  method: 'POST'
}
export const useRestaurantDetail = makeUseApi<
  Definitions.Europe.获取餐厅详情,
  Definitions.Europe.SearchResponseOf获取餐厅详情返回值
>(RestaurantDetail)

// 餐厅菜品
export const RestaurantMenu: AxiosRequestConfig = {
  url: '/aglaia-new/aglaia/waimai/menu',
  method: 'POST'
}
export const useRestaurantMenu = makeUseApi<
  Definitions.Agalia.WaimaiMenuRequest,
  Definitions.Agalia.RestResponseOfWaimaiRestaurant
>(RestaurantMenu)
