// 外卖订单详情
import React, { FC } from 'react'
import { useParams } from 'react-router-dom'

import { ColumnView } from '../../components/QCFlex'
import HeaderStatus from './HeaderStatus'
import ShopInfoCard from './ShopInfoCard'
import OrderInfoCard from './OrderInfoCard'
import GoodsListCard from './GoodsListCard'
import InvoiceCard from './InvoiceCard'
import ConsumerCard from './ConsumerCard'
import ActionBar from './ActionBar'
import ServiceEvaluationCard from './ServiceEvaluationCard'

import { useOrderDetail } from '../../api/TakeawayOrder'
import { container, content } from './index.less'
import { useMount } from '../../hooks/common'
import { usePullRefresh } from '../../hooks/layout'

interface IUrlParams {
  orderId?: string
}
const TakeawayOrderDetail: QCPage = () => {
  const params = useParams<IUrlParams>()
  const [{ data }, refetch] = useOrderDetail({
    //useCache: true,
    manual: true,
    data: {
      orderId: params.orderId
    }
  })
  const detail = (data?.data as QCOrderInfo) || {}

  useMount(refetch)

  // 下拉刷新
  usePullRefresh(() => {
    refetch()
  })
  return (
    <ColumnView className={container}>
      <HeaderStatus
        status={detail?.order_state}
        order_state_hint={detail?.order_state_hint}
      />
      <ColumnView className={content}>
        <ShopInfoCard {...detail} />
        <OrderInfoCard {...detail} />
        <GoodsListCard {...detail} />
        <InvoiceCard {...detail} />
        <ServiceEvaluationCard {...detail} />
        {/* <TipCard {...detail} /> */}
        <ConsumerCard {...detail} />
      </ColumnView>
      <ActionBar {...detail} />
    </ColumnView>
  )
}

TakeawayOrderDetail.config = {
  title: '订单详情'
}

export default TakeawayOrderDetail
