import React, { FC, memo } from 'react'
import { RowView, ColumnView } from '../../components/QCFlex'
import QCImage from '../../components/QCImage'
import Text from '../../components/Text'
import Icon from '../../components/Icon'
import {
  goodsItem,
  img,
  operate,
  operateIcon,
  goodsItemRight
} from './index.less'
export interface OperateProps {
  count?: number
  onAdd?: () => void
  onDelete?: () => void
}
export interface GoodsItemProps {
  count?: number
  item: Definitions.Agalia.MenuItem
  onAdd?: (item: Definitions.Agalia.MenuItem) => void
  onDelete?: (item: Definitions.Agalia.MenuItem) => void
}

const Operate: FC<OperateProps> = memo(
  ({ onAdd, onDelete, count }) => {
    const handleIncrease = () => {
      onAdd && onAdd()
    }
    const handleDecrease = () => {
      onDelete && onDelete()
    }
    return (
      <RowView className={operate} hAlign={count ? 'between' : 'end'}>
        {count ? (
          <>
            <Icon
              name="iconjianshao"
              size={40}
              className={operateIcon}
              onClick={handleDecrease}
            />
            <Text color="#666666FF" size={28}>
              {count}
            </Text>
          </>
        ) : (
          ''
        )}
        <Icon
          name="icontianjia"
          size={40}
          className={operateIcon}
          onClick={handleIncrease}
        />
      </RowView>
    )
  },
  (prevProps, nextProps) => prevProps.count === nextProps.count
)
Operate.displayName = 'MOperate'
const GoodsItem: FC<GoodsItemProps> = ({ item, count, onAdd, onDelete }) => {
  const handleAdd = () => {
    onAdd && onAdd(item)
  }
  const handleDelete = () => {
    onDelete && onDelete(item)
  }
  return (
    <RowView className={goodsItem} hAlign="between">
      <QCImage src={item.url} className={img} />
      <ColumnView vAlign="between" className={goodsItemRight}>
        <Text>{item.menu_name}</Text>
        <RowView hAlign="between">
          <Text color="#FF9847FF" size={28}>
            ¥{item.price}
          </Text>
          <Operate onAdd={handleAdd} onDelete={handleDelete} count={count} />
        </RowView>
      </ColumnView>
    </RowView>
  )
}

export default memo(GoodsItem)
