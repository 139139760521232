import React from 'react'
import { create } from 'mobx-persist'
import { UserStore } from '../stores/user'
import { FormStore } from '../stores/form'
import { CityStore } from '../stores/city'
import { AppStore } from '../stores/app'
import { PagePickerStore } from '../stores/pagePicker'
import { TakeawaySearchStore } from '../stores/takeawaySearch'
import { CacheStateStore } from '../stores/cacheState'

const hydrate = create({})

export const stores = {
  userStore: new UserStore(),
  formStore: new FormStore(),
  cityStore: new CityStore(),
  pagePickerStore: new PagePickerStore(),
  appStateStore: new AppStore(),
  takeawaySearchStore: new TakeawaySearchStore(),
  cacheStateStore: new CacheStateStore()
}

hydrate('mobx_persist_city', stores.cityStore)
hydrate('mobx_persist_cachestate', stores.cacheStateStore)

export const storesContext = React.createContext(stores)

export const scrollElementContext = React.createContext<React.MutableRefObject<
  any
> | null>(null)

export const pullRefeshContext = React.createContext<any>(null)
