// 堂食商户详情 --线上餐厅
// 参考外卖商户详情页 -- TakeawayRestaurantDetail
import React, { FC, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'

import { ColumnView } from '../../components/QCFlex'
import { RestaurantInfoCardB as RestaurantInfoCard } from '../../components/RestaurantInfoCard'
import TakeawayCard from './TakeawayCard'
import BookingCard from './BookingCard'
import ActionBar from './ActionBar'
import Footer from './Footer'

import { useRestaurantDetail } from '../../api/Restaurant'

import { container, body } from './index.less'
import { useGlobalStores } from '../../hooks/store'
import { useCondition } from '../../hooks/common'
interface UrlParamsProps {
  restaurantId?: string
}
interface UrlStateProps {
  detail?: Definitions.Europe.获取餐厅详情
}
const OnlineRestaurantDetail: QCPage = () => {
  const { appStateStore } = useGlobalStores()
  const { restaurantId } = useParams<UrlParamsProps>()
  // 详情页的接口评分数据取不出来，所以需要从上个页面带过来
  const location = useLocation<UrlStateProps>()
  const defaultDetail = location.state?.detail || {}

  const [{ data, loading, error }, refetch] = useRestaurantDetail({
    useCache: true,
    data: {
      id: restaurantId
    }
  })
  // 合并来自url的传参
  const detail = Object.assign(
    {},
    data?.data?.restaurentDetail || {},
    defaultDetail
  )

  useCondition(!!data?.data?.restaurentDetail?.storeName, () => {
    appStateStore.title = data?.data?.restaurentDetail?.storeName
  })

  return (
    <ColumnView className={container}>
      <ColumnView className={body}>
        <RestaurantInfoCard
          {...detail}
          businessHours={detail.booking?.businessHours}
        />
        {detail.takeaway && <TakeawayCard {...detail.takeaway} />}
        <BookingCard {...detail.booking} />
        <Footer rid={detail.rid} />
      </ColumnView>
      <ActionBar {...detail} />
    </ColumnView>
  )
}

OnlineRestaurantDetail.config = {
  title: '餐厅详情'
}

export default OnlineRestaurantDetail
