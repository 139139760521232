import React, { useRef } from 'react'

import QCForm, { FormRefProps } from '../../components/QCForm'
import { ColumnView, XCenterView } from '../../components/QCFlex'
import BrotherButton from '../../components/BrotherButton'

import ControlerForm from './ControlerForm'
import UnControlerForm from './UnControlerForm'
import { container, content } from './index.less'

interface FormValues {
  name?: string
  phone?: string
  mark?: string
  toggle?: boolean
  rule?: string
  startDate?: Date
  startTime?: Date
  rate?: number
  checkbox?: boolean
  group?: string
  images?: string[]
  address?: string
}
const FormDemo = () => {
  const defaultValues: FormValues = {
    name: '',
    phone: '',
    mark: '',
    toggle: false,
    rule: '11',
    startDate: new Date(),
    startTime: new Date(),
    rate: 0,
    checkbox: false,
    group: '',
    images: ['string'],
    address: ''
  }
  const inputRef = useRef<FormRefProps<FormValues> | null>(null)
  const onSubmit = () => {
    console.log('inputRef', inputRef.current?.errors)
    inputRef &&
      inputRef?.current?.qcSubmit((data: FormValues) => {
        console.log('data', data)
      })
  }
  const onReset = () => {
    inputRef && inputRef.current?.reset({})
  }
  return (
    <ColumnView className={container}>
      <ColumnView className={content}>
        <QCForm<FormValues>
          formRef={(el) => {
            inputRef.current = el
          }}
          defaultValues={defaultValues}
        >
          <ControlerForm />
          <UnControlerForm />
        </QCForm>
      </ColumnView>
      <XCenterView height={160} width="100%" bgColor="#ffffff">
        <BrotherButton
          leftText="提交"
          onLeftClick={onSubmit}
          rightText="重置"
          onRightClick={onReset}
        />
      </XCenterView>
    </ColumnView>
  )
}

export default FormDemo
