import React from 'react'
import { Flex } from 'antd-mobile'

import Text from '../Text'
const ErrorView = ({ text = '获取数据异常' } = {}) => (
  <Flex justify="center">
    <img src="" alt="" />
    <Text size={26} color="#999999">
      {text}
    </Text>
  </Flex>
)
export default ErrorView
