import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { useObserver } from 'mobx-react-lite'
import {
  titlebar,
  titlebar__title,
  titlebar__left,
  titlebar__right
} from './index.less'
import { useGlobalStores } from '../../../hooks/store'

export interface TitleBarProps {
  title?: string
}

const TitleBar: FC<TitleBarProps> = ({ title }) => {
  const history = useHistory()
  const { userStore, appStateStore } = useGlobalStores()

  return useObserver(() => (
    <div
      className={titlebar}
      style={{ backgroundColor: appStateStore.tbBgColor }}
    >
      {appStateStore.hasBackArrow && (
        <div className={titlebar__left} onClick={() => history.goBack()}>
          <a
            className="iconfont iconfanhuijiantou"
            style={{ color: appStateStore.tbTextColor }}
          />
        </div>
      )}
      <div
        className={titlebar__title}
        style={{ color: appStateStore.tbTextColor }}
      >
        {appStateStore.title || '珠海英联'}
      </div>
      <div
        className={titlebar__right}
        onClick={userStore.logout.bind(userStore)}
      ></div>
    </div>
  ))
}

export default TitleBar
