import { useState, useEffect } from 'react'
import { useGlobalStores } from './store'
import { useQueryCityByCoords, useConvertFrom } from '../api/Geolocation'

export const useGeolocation = () => {
  const [coord, updateCoord] = useState<Coordinates | null>(null)
  navigator.geolocation.getCurrentPosition(
    (position) => {
      updateCoord(position.coords)
    },
    (err) => {
      console.error(err.code, err.message)
      updateCoord(null)
    },
    {
      maximumAge: 60000,
      enableHighAccuracy: false,
      timeout: 3000
    }
  )
  return coord
}

export const useCurrentAmapGeo = (
  onError?: (msg: string | undefined) => void
) => {
  const coords = useCurrentGpsAmapGeo()
  const [{ data }] = useQueryCityByCoords({
    manual: !coords?.longitude || !coords?.latitude,
    noAuth: true,
    params: {
      location: `${coords?.longitude},${coords?.latitude}`
    }
  })

  useEffect(() => {
    if (data && data?.status !== '1') {
      onError?.(data?.info)
    }
  }, [data?.status, data?.info])
  return data?.regeocode
}

export const useCurrentRegion = () => {
  const [region, setRegion] = useState<{ name: string; id: string }>()
  const { cityStore } = useGlobalStores()

  useEffect(() => {
    setRegion(cityStore.info)
  }, [])

  return region
}

// 返回gps获取的经纬度并转成高德的
export interface IGps2AmapGeo {
  longitude?: string
  latitude?: string
}
export const useCurrentGpsAmapGeo = (): IGps2AmapGeo => {
  const coords = useGeolocation()
  const [{ data }] = useConvertFrom({
    manual: !coords?.longitude || !coords?.latitude,
    noAuth: true,
    params: {
      locations: `${coords?.longitude},${coords?.latitude}`,
      coordsys: 'gps'
    }
  })
  // locations 转换之后的坐标。若有多个坐标，则用 “;”进行区分和间隔
  // 只取第一个
  const locations = data?.locations?.split(';')?.[0]?.split(',') || []
  return {
    longitude: locations[0],
    latitude: locations[1]
  }
}
