import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { ColumnView, RowView, XCenterView } from '../../components/QCFlex'
import StatusTag from '../../components/StatusTag'
import Icon from '../../components/Icon'
import Text from '../../components/Text'
import { VLine } from '../../components/Line'
import { isZero } from '../../utils/lodash'
import { dsc, item, info, name, tag } from './index.less'
const RestaurantItem: FC<Definitions.Europe.餐厅信息> = ({
  rid,
  thumb,
  cook_style = '',
  consume,
  title,
  distance,
  city,
  rating,
  thumb_postfix,
  is_aglaia,
  area
}) => {
  const router = useHistory()
  const isAbledRating = !isZero(rating)
  const isAbledConsume = !isZero(consume)
  const goto = () => {
    // 当面付餐厅
    const isUnsignRetaurant = [2].includes(is_aglaia || 0)
    if (isUnsignRetaurant) {
      router.push(`/recommendRestaurantDetail`, { restaurantId: rid })
    } else {
      router.push(`/onlineRestaurantDetail/${rid}`, { detail: { rating } })
    }
  }
  return (
    <RowView className={item} onClick={goto}>
      <img src={`${thumb}${thumb_postfix}`} />
      <ColumnView vAlign="between" className={info}>
        <Text className={`ellipsis ${name}`}>{title}</Text>
        <RowView hAlign="between" className={dsc}>
          <XCenterView>
            {isAbledRating && (
              <>
                <Icon name="iconxingjipingfen" color="#FF9847FF" size={26} />
                <Text color="#FF9847FF">{rating}</Text>
              </>
            )}
            {isAbledRating && isAbledConsume && <VLine gap={10} />}
            {isAbledConsume && (
              <Text color="#999999FF" size={24}>
                ￥{consume}/人
              </Text>
            )}
          </XCenterView>
          {distance}
        </RowView>
        <RowView hAlign="between">
          <RowView className={tag}>
            <StatusTag
              className="ellipsis"
              text={cook_style}
              color="#FF9847FF"
            />
          </RowView>
          <Text color="#999999FF" size={24}>
            {area} | {city}
          </Text>
        </RowView>
      </ColumnView>
    </RowView>
  )
}

export default RestaurantItem
