export const STY_CUSTOMER_PHONE = '4000501717'

export interface IRecommendStatusMap {
  [key: string]: string
}
export const RESTAURANT_RECOMMEND_STATUS_MAP: IRecommendStatusMap = {
  NO_PASS: '验证不通过',
  RECOMMEND_ING: '验证中',
  NO_RECOMMEND: '未验证'
}

export const TAKEAWAY_ORDER_STATUS = [
  { name: '待确认', value: 1 },
  { name: '预订成功', value: 2 },
  { name: '支付中', value: 3 },
  { name: '支付失败', value: 4 },
  { name: '已完成（支付）', value: 5 },
  { name: '订单已取消', value: 6 },
  { name: '待下单', value: 7 }
]
export const EATIN_ORDER_STATUS = [
  { name: '待确认', value: '1' },
  { name: '预订成功', value: '2' },
  { name: '待支付', value: '3' },
  { name: '支付中', value: '4' },
  { name: '支付失败', value: '5' },
  { name: '已完成（支付成功）', value: '6' },
  { name: '订单已取消', value: '7' },
  { name: '预订失败', value: '8' },
  { name: '待下单', value: '9' },
  { name: '等待企业码支付', value: '10' }
]

interface ITag {
  label?: string
  color?: string
}
export interface IStatus {
  [name: string]: ITag
}
export const REC_RESTAURANT_STATUS_MAP: IStatus = {
  APPLY_AVALIABLE: {
    label: '可申请推荐',
    color: '#FF9847FF'
  },
  VALIDATION_FAIL: {
    label: '验证不通过',
    color: '#f8584f'
  },
  VALIDATION_SUCCESS_ONLINE: {
    label: '线上餐厅',
    color: '#85A8F9FF'
  },
  VALIDATION_WAIT: {
    label: '等待验证',
    color: '#FF9847FF'
  },
  VALIDATION_WAIT_CLIENT: {},
  VALIDATION_FAIL_APPLY_AGAIN: {
    label: '验证不通过',
    color: '#999999FF'
  },
  VALIDATION_SUCCESS_OFFLINE: {
    label: '验证通过',
    color: '#51A04CFF'
  }
}
export const TICKET_CHECK_STATUS = {
  NONE: {
    name: '不需要'
  },
  NOT_SUBMITTED: {
    name: '未提交'
  },
  CONSISTENCY: {
    name: '已提交，一致'
  },
  UNCONFIRMED: {
    name: '已提交，未确认'
  },
  CONFIRMED: {
    name: '已提交，已确认'
  },
  REFUSED: {
    name: '已提交，被拒绝'
  }
}
export const ALIOSS_BUCKET = 'qc-upload'
export const ALIOSS_BUCKET_PREFIX = 'yinglian'
