/**
 * 此组件提供基础自动化的列表支持
 * 如有更加定制化的需求或者优化
 * 请选择 antd-mobile ListView 自行设计
 */
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  FC,
  Fragment
} from 'react'
import { ListView, PullToRefresh, Flex } from 'antd-mobile'
import SafeGet from 'dlv'
import { ApiOptions } from '../../utils/makeUseApi'
import Text from '../Text'
import DefaultEmptyView from '../EmptyView'

import './index.less'
// 初始化 | 触底加载中 | 下拉到顶部刷新 | 加载结束
export type TAutoListViewLoadingStatus = 'init' | 'bottom' | 'top' | 'false'
export interface IAutoListViewFormatRes<T> {
  list: T[]
  nomore: boolean
}
export interface IAutoListViewFormatFn<T, P> {
  (porps: P): IAutoListViewFormatRes<T>
}
export interface IListDataSource {
  cloneWithRows: any
  getRowCount: any
}
export interface FormatPageInfoProps {
  pSize?: number
  pNum?: number
}
export interface AutoListViewProps<T, P> {
  className?: string
  autolistClassName?: string
  refreshable?: boolean
  pageSize?: number
  params?: P
  startPage?: number
  paramsField?: 'data' | 'params'
  formatPageInfo?: ({ pSize, pNum }: FormatPageInfoProps) => object
  // todo
  useService: any
  format: IAutoListViewFormatFn<T, any>
  renderItem: (item: T) => JSX.Element
  HeaderRefreshView?: FC<{}>
  FooterLoadingView?: FC<{}>
  LoadingView?: FC<{}>
  FooterView?: FC<{}>
  EmptyView?: FC<{ text?: string }>
  ErrorView?: FC<{ message?: string }>
  itemKeyExtractor: (item: T) => string
  serviceConfig?: ApiOptions<P>
  dataPath?: string
}
const DefautFooterView: FC<{}> = () => (
  <Flex justify="center">
    <Text>~我是有底线的~</Text>
  </Flex>
)
const DefautHeaderRefreshView: FC<{}> = () => (
  <Flex justify="center">
    <Text>松开下拉刷新...</Text>
  </Flex>
)
const DefautFooterLoadingView: FC<{}> = () => (
  <Flex justify="center">
    <Text>底部加载中...</Text>
  </Flex>
)
const DefautLoadingView: FC<{}> = () => (
  <Flex justify="center">
    <Text>加载中...</Text>
  </Flex>
)
const DefaultFormatPageInfo = ({ pSize, pNum }: FormatPageInfoProps) => ({
  p: pNum,
  pageSize: pSize
})
const AutoListView = <T extends any, P = {}>({
  refreshable = true,
  format,
  pageSize = 20,
  renderItem,
  className = '',
  params,
  HeaderRefreshView = DefautHeaderRefreshView,
  LoadingView = DefautLoadingView,
  FooterView = DefautFooterView,
  FooterLoadingView = DefautFooterLoadingView,
  EmptyView = DefaultEmptyView,
  itemKeyExtractor,
  formatPageInfo = DefaultFormatPageInfo,
  autolistClassName,
  useService,
  serviceConfig,
  paramsField = 'data',
  dataPath = 'data',
  // 好多列表接口都是从第0页开始查所以就默认从第0页开始查
  startPage = 0
}: AutoListViewProps<T, P>) => {
  // 固定数据对象并初始化
  const listDataSource: IListDataSource = useMemo(
    () =>
      new ListView.DataSource({
        rowHasChanged: (rowL: any, rowR: any) =>
          itemKeyExtractor(rowL) !== itemKeyExtractor(rowR)
      }),
    [itemKeyExtractor]
  )
  const [listData, updateListData] = useState<T[]>([])
  const [currentPage, updateCurrentPage] = useState<number>(startPage)
  // 加载中状态
  const [loading, updateLoading] = useState<TAutoListViewLoadingStatus>('init')
  const [nomore, updateNomore] = useState<boolean>(false)
  // 监听params变化
  useEffect(() => {
    updateCurrentPage(startPage)
  }, [JSON.stringify(params)])
  // 合并params
  const queryParams = Object.assign(
    formatPageInfo({ pSize: pageSize, pNum: currentPage }),
    params
  )
  // 获取数据
  const options = Object.assign({}, { useCache: false }, serviceConfig)

  const [{ data, loading: fetchLoading }, refetch] = useService({
    ...options,
    [paramsField]: queryParams
  })

  // 获取数据
  useEffect(() => {
    // 非标准接口返回数据格式可能不一样
    // 就是为了绕过签名才没有把datapath放到format里面
    if (fetchLoading === false) {
      const { list, nomore } = format(
        (dataPath ? SafeGet(data, dataPath) : data) || {}
      )
      updateNomore(nomore)
      updateListData((preList) => {
        // 初始化 || 下拉刷新 最新的
        let newList = list
        if (loading === 'bottom') {
          // 加载更多
          newList = preList.concat(list)
        }
        updateLoading('false')
        return newList
      })
    }
  }, [data])
  // 当首次进列表，就下拉刷新(loading === 'top')时，由于queryParams未改变
  // 不会触发fetch，所以effect吧
  useEffect(() => {
    loading === 'top' && currentPage === startPage && refetch()
  }, [loading, currentPage])
  // 触底加载更多
  const handleEndReached = useCallback(() => {
    if (loading !== 'false' || nomore) {
      return
    }
    updateLoading('bottom')
    updateCurrentPage((p) => p + 1)
  }, [updateLoading, updateCurrentPage, loading, nomore])

  // footer
  const renderFooter = useCallback(
    () => (
      <>
        {loading === 'bottom' && FooterLoadingView && <FooterLoadingView />}
        {loading === 'false' && FooterView && <FooterView />}
      </>
    ),
    [loading, FooterView, FooterLoadingView]
  )

  // 下拉刷新
  const handleRefresh = useCallback(async () => {
    updateLoading('top')
    updateCurrentPage(startPage)
  }, [updateLoading])

  // 渲染item
  const row = useCallback(
    (rowData) => {
      const itemKey = itemKeyExtractor(rowData)
      return <Fragment key={itemKey}>{renderItem(rowData)}</Fragment>
    },
    [renderItem, itemKeyExtractor]
  )
  // 一些变量
  const dataSource = listDataSource.cloneWithRows(listData || [])
  const mergedClassName = useMemo(() => `qc-auto-list ${className}`, [
    className
  ])
  const refreshing = useMemo(() => loading === 'top', [loading])
  const totalListCount = useMemo(() => dataSource.getRowCount() || 0, [
    dataSource
  ])
  const isEmpty = useMemo(() => loading === 'false' && totalListCount === 0, [
    loading,
    totalListCount
  ])
  const isInitLoading = useMemo(() => loading === 'init', [loading])
  return (
    <section className={mergedClassName}>
      {isInitLoading ? (
        <LoadingView />
      ) : isEmpty ? (
        <EmptyView />
      ) : (
        <ListView
          sectionBodyClassName={autolistClassName}
          onEndReached={handleEndReached}
          dataSource={dataSource}
          initialListSize={pageSize}
          pageSize={pageSize}
          renderRow={row}
          renderFooter={renderFooter}
          {...(refreshable && {
            pullToRefresh: (
              <PullToRefresh
                indicator={{
                  deactivate: <HeaderRefreshView />
                }}
                distanceToRefresh={20}
                direction="down"
                getScrollContainer={() => <div />}
                refreshing={refreshing}
                onRefresh={handleRefresh}
              />
            )
          })}
        />
      )}
    </section>
  )
}

export default AutoListView
