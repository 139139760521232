import { AxiosRequestConfig } from 'axios'
import makeUseApi from '../utils/makeUseApi'
// 餐厅搜索
export const HospitalSearch: AxiosRequestConfig = {
  url: '/europe/search/hospitals',
  method: 'POST'
}

export const useHospitalSearch = makeUseApi<
  Definitions.Europe.搜索医院参数,
  Definitions.Europe.SearchResponseOf搜索医院返回值
>(HospitalSearch)
