import { AxiosRequestConfig } from 'axios'
import makeUseApi from '../utils/makeUseApi'

// 我的餐厅列表
export const RestaurantList: AxiosRequestConfig = {
  url: '/aglaia-new/aglaia/myUnsigned',
  headers: {
    company: 'zhyl'
  },
  method: 'POST'
}

export const useRestaurantList = makeUseApi<
  Definitions.Agalia.PageRequest,
  Definitions.Agalia.RestResponseOfUnsignedResponse
>(RestaurantList)

// 餐厅搜索
export const RestaurantSearch: AxiosRequestConfig = {
  url: '/aglaia-new/aglaia/searchAglaia',
  headers: {
    company: 'zhyl'
  },
  method: 'POST'
}

export const useRestaurantSearch = makeUseApi<
  Definitions.Agalia.SearchAglaiaRequest,
  Definitions.Agalia.RestResponseOfSearchResponse
>(RestaurantSearch)

// 餐厅详情
export const RestaurantDetail: AxiosRequestConfig = {
  url: '/aglaia-new/aglaia/unsignedDetail',
  headers: {
    company: 'zhyl'
  },
  method: 'POST'
}

export const useRestaurantDetail = makeUseApi<
  Definitions.Agalia.UnsignedRestaurantRequest,
  Definitions.Agalia.RestResponseOfUnsignedRestaurantDetail
>(RestaurantDetail)

// 推荐餐厅
export const AddRestaurantApplication: AxiosRequestConfig = {
  url: '/aglaia-new/aglaia/addRestaurantApplication',
  headers: {
    company: 'zhyl'
  },
  method: 'POST'
}

export const useAddRestaurantApplication = makeUseApi<
  Definitions.Agalia.AddAglaiaRequest,
  Definitions.Agalia.RestResponse
>(AddRestaurantApplication)
