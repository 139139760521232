import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ColumnView } from '../../components/QCFlex'
import { usePullRefresh } from '../../hooks/layout'

import { RestaurantInfoCardB as RestaurantInfoCard } from '../../components/RestaurantInfoCard'
import RecommendStatusCard from './RecommendStatusCard'
import ActionCard from './ActionCard'
import Footer from './Footer'
import {
  useRestaurantDetail,
  useAddRestaurantApplication
} from '../../api/RecommendRestaurant'
import { formatDetail } from './util'
import { container } from './index.less'
import { useToastSuccess, useRestToastFail } from '../../hooks/toast'
import { useGlobalStores } from '../../hooks/store'
import { useCondition, useMount } from '../../hooks/common'
interface UrlParamsProps {
  sourceId?: string
  restaurantId?: string
}

const RecommendRestaurantDetail: QCPage = () => {
  const { appStateStore } = useGlobalStores()
  const router = useHistory()
  const location = useLocation<UrlParamsProps>()
  const { sourceId, restaurantId } = location.state
  const [detailRes, fetchDetail] = useRestaurantDetail({
    useCache: false,
    manual: true,
    data: {
      restaurantId,
      sourceId
    }
  })
  const [addRestaurantRes, addRestaurant] = useAddRestaurantApplication({
    manual: true
  })
  const detail = detailRes?.data?.data || {}
  const restaurantInfoCardDetail = formatDetail(detail)
  const handleAddRestaurant = () => {
    addRestaurant({
      data: {
        unsigned: true,
        sourceId: restaurantId || sourceId
      }
    })
  }
  const successMessage =
    addRestaurantRes?.data?.status === 0
      ? addRestaurantRes?.data?.message
      : null
  useMount(fetchDetail)
  useEffect(() => {
    addRestaurantRes.data?.status === 0 &&
      router.replace('/recommendRestaurantList')
  }, [addRestaurantRes.data?.status])
  useCondition(!!detail.name, () => {
    appStateStore.title = detail.name
  })
  useToastSuccess(successMessage)
  useRestToastFail([detailRes, addRestaurantRes])

  // 下拉刷新
  usePullRefresh(() => {
    fetchDetail()
  })
  return (
    <ColumnView className={container}>
      <RestaurantInfoCard
        {...restaurantInfoCardDetail}
        restaurantType="unsigned"
      />
      <RecommendStatusCard
        status={detail.status || ''}
        statusHint={detail.statusHint}
      />
      <ActionCard onClick={handleAddRestaurant} detail={detail} />
      <Footer />
    </ColumnView>
  )
}

RecommendRestaurantDetail.config = {
  title: '餐厅详情'
}

export default RecommendRestaurantDetail
