import React, { FC } from 'react'
import './index.global.less'
export interface BrotherButtonProps {
  leftText: React.ReactNode
  rightText: React.ReactNode
  onLeftClick?: React.EventHandler<React.MouseEvent>
  onRightClick?: React.EventHandler<React.MouseEvent>
}
// 定制组件，样式固定，如有需求，重新封装
const BrotherButton: FC<BrotherButtonProps> = ({
  leftText,
  rightText,
  onLeftClick,
  onRightClick
}) => (
  <div className="qc-brother-button">
    <button className="qc-brother-button-left" onClick={onLeftClick}>
      {leftText}
    </button>
    <button className="qc-brother-button-right" onClick={onRightClick}>
      {rightText}
    </button>
  </div>
)

export default BrotherButton
