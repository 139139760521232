import React, { FC } from 'react'
import Icon from '../../components/Icon'
import { RowView } from '../../components/QCFlex'
import Text from '../../components/Text'
import { headerStatus } from './index.less'
export type TStatus = 'fail' | 'success' | 'waitingPay' | 'waitingConfirm'
interface HeaderStatusProps {
  order_state?: string
  order_state_hint?: string
}
const colorMap: { [name: string]: string } = {
  // 待确认
  '1': 'waitConfirm',
  // 预订成功
  '2': 'success',
  // 待支付
  '3': 'payWaiting',
  // 支付失败
  '5': 'payFail',
  // 支付成功
  '6': 'paySuccess',
  // 已取消
  '7': 'cancel',
  // 默认
  '0': '',
  //待商宴通企业付
  '10': 'payWaiting'
}
const iconsMap: { [name: string]: string } = {
  success: 'icongougou-xuanzhong',
  fail: 'iconchacha',
  payDone: 'iconjinezhifu',
  payWaiting: 'iconjinezhifu',
  paySuccess: 'iconjinezhifu',
  payFail: 'iconchacha',
  waitConfirm: 'icondengdai',
  cancel: 'iconquxiao'
}
const HeaderStatus: FC<HeaderStatusProps> = ({
  order_state,
  order_state_hint
}) => {
  const formatedOrderStatus = colorMap[order_state || '0']
  const mergedClassname = [headerStatus, formatedOrderStatus].join(' ')
  return (
    <RowView className={mergedClassname}>
      <Icon name={iconsMap[formatedOrderStatus]} />
      {/* 偷了个懒，文案直接取自接口返回的order_state_hint字段*/}
      <Text>{order_state_hint}</Text>
    </RowView>
  )
}

export default HeaderStatus
