import { useEffect } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import Routers from '../routes'
import { usePrevious } from './common'
import { useGlobalStores } from './store'

export const useRouterChange = (): void => {
  const { appStateStore } = useGlobalStores()
  const location = useLocation()
  const previousLocation = usePrevious(location)

  useEffect(() => {
    const matchedRouteComponent = Routers.find((route) =>
      matchPath(location.pathname, { path: route.path, ...route.extraProps })
    )?.component as QCPage | undefined

    appStateStore.setByQCPageConfig(matchedRouteComponent?.config)

    const onPopState = (event: PopStateEvent) => {
      console.log(
        event.state,
        'previous: ',
        previousLocation?.pathname,
        previousLocation?.state
      )
    }

    window.addEventListener('popstate', onPopState)

    return () => {
      window.removeEventListener('popstate', onPopState)
    }
  }, [location, appStateStore, previousLocation])
}
