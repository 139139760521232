import React from 'react'
import manualPic1 from '../../assets/manuals/1.jpg'
import manualPic2 from '../../assets/manuals/2.jpg'
import manualPic3 from '../../assets/manuals/3.jpg'
import manualPic4 from '../../assets/manuals/4.jpg'
import manualPic5 from '../../assets/manuals/5.jpg'
import manualPic6 from '../../assets/manuals/6.jpg'
import manualPic7 from '../../assets/manuals/7.jpg'
import manualPic8 from '../../assets/manuals/8.jpg'
import manualPic9 from '../../assets/manuals/9.jpg'
import manualPic10 from '../../assets/manuals/10.jpg'

const UserManual: QCPage = () => {
  return (
    <div>
      <img src={manualPic1} width="100%" />
      <img src={manualPic2} width="100%" />
      <img src={manualPic3} width="100%" />
      <img src={manualPic4} width="100%" />
      <img src={manualPic5} width="100%" />
      <img src={manualPic6} width="100%" />
      <img src={manualPic7} width="100%" />
      <img src={manualPic8} width="100%" />
      <img src={manualPic9} width="100%" />
      <img src={manualPic10} width="100%" />
    </div>
  )
}

UserManual.config = {
  title: '使用指南'
}

export default UserManual
