import { useInterval } from './timer'
import { useState } from 'react'

export const useCountdown = (seconds: number) => {
  const [current, setCurrent] = useState<number | null>(null)
  const [running, setRunning] = useState(false)

  useInterval(
    () => {
      setCurrent((current) => current && current - 1)
    },
    running && current && current > 0 ? 1000 : null
  )

  return {
    start: () => {
      setCurrent(seconds)
      setRunning(true)
    },
    stop: () => {
      setCurrent(null)
      setRunning(false)
    },
    pause: () => {
      setRunning(false)
    },
    resume: () => {
      setRunning(true)
    },
    current
  }
}
