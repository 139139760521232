import { AxiosRequestConfig } from 'axios'
import makeUseApi from '../utils/makeUseApi'

export const UserMonthlyConsumes: AxiosRequestConfig = {
  url: '/aimee/consumption/getUserMonthlyConsumes',
  method: 'POST'
}

export const useUserMonthlyConsumes = makeUseApi<
  unknown,
  Definitions.Aimee.RestResponseOfListOfUserMonthlyConsumeDTO
>(UserMonthlyConsumes)
