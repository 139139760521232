import React, { FC } from 'react'
import { RowView } from '../../components/QCFlex'
import Button from '../../components/Button'
import { actionBar } from './index.less'
interface ActionBarProps {
  onSubmit?: () => void
}
const ActionBar: FC<ActionBarProps> = ({ onSubmit }) => {
  return (
    <RowView className={actionBar}>
      <Button onClick={onSubmit}>提交订单</Button>
    </RowView>
  )
}

export default ActionBar
