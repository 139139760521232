import React, { FC, memo, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import FormRow, { FormRowProps } from '../FormRow'
import {
  ConnectForm,
  ConnectFormProps,
  ConnectFormChildrenProps
} from '../../QCForm'
import './index.global.less'
import { useClassNames } from '../../../hooks/classname'
import Icon from '../../Icon'
export type NativePagePickerProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>
export type MPagePickerItemProps = Omit<
  ConnectFormChildrenProps,
  'value' | 'onBlur'
>
export interface FormPagePickerItemProps
  extends FormRowProps,
    ConnectFormProps,
    NativePagePickerProps {}
export interface PagePickerItemProps
  extends FormRowProps,
    NativePagePickerProps,
    Omit<ConnectFormChildrenProps, 'value' | 'onBlur'> {
  field?: string
}
export const PagePickerItem: FC<PagePickerItemProps> = ({
  label,
  className,
  sync,
  register,
  onBlur,
  field,
  placeholder,
  rules,
  ...otherProps
}) => {
  const history = useHistory()
  const { state } = useLocation()
  const mergedClassName = useClassNames(['qc-PagePicker', className])
  const handleClick = () => {
    history.push('/shippingAddressList', {
      field: 'pagepicker'
    })
  }
  useEffect(() => {
    console.log('PagePickerItem state', state)
  }, [JSON.stringify(state)])
  return (
    <FormRow label={label} onClick={handleClick} required={!!rules?.required}>
      <span className="qc-input-placeholder">
        {placeholder}
        <Icon name="iconyoujiantou" size={20} />
      </span>
    </FormRow>
  )
}
// 针对 FormProvider 的 带优化的PagePickeritem
const MPagePickerItem: FC<MPagePickerItemProps> = memo(
  ({ formState, watch, ...otherProps }) => <PagePickerItem {...otherProps} />,
  (prevProps, nextProps) =>
    prevProps.formState?.isDirty === nextProps.formState?.isDirty
)
const FormPagePickerItem: FC<FormPagePickerItemProps> = (props) => (
  <ConnectForm rules={props.rules}>
    <MPagePickerItem {...props} />
  </ConnectForm>
)
export default FormPagePickerItem
