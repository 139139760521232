// 这个搜索列表没有分页操作等交互，所以直接全部渲染出来了
import React, { FC, memo } from 'react'

import { useHistory } from 'react-router-dom'
import AutoListView, {
  IAutoListViewFormatFn
} from '../../components/AutoListView'
import { ColumnView, RowView } from '../../components/QCFlex'
import Text from '../../components/Text'

import { useGlobalStores } from '../../hooks/store'

import { useHospitalSearch } from '../../api/Hospital'

import { restaurantItem, tip } from './index.less'

interface IItem {
  name?: string
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}
interface HospitalProps {
  keywords?: string
  tabName?: string
}
const Item = memo(({ name, onClick }: IItem) => (
  <RowView className={restaurantItem} onClick={onClick}>
    {name}
  </RowView>
))
Item.displayName = 'MItem'
const PAGE_SIZE = 20
const formatRes: IAutoListViewFormatFn<
  Definitions.Europe.Hospital,
  Definitions.Europe.搜索医院返回值
> = ({ hospitals }) => ({
  list: hospitals,
  nomore: hospitals?.length < PAGE_SIZE
})
const itemKeyExtractor = (item: Definitions.Europe.Hospital) =>
  (item.hospitalId || item.hospitalName || '').toString()
const Hospital: FC<HospitalProps> = ({ keywords, tabName }) => {
  const router = useHistory()
  const { cityStore, userStore } = useGlobalStores()
  const cityId = cityStore?.info?.id
  const companyId = userStore.profile?.companyId
  const searchHospitalParams = {
    cityId,
    companyId,
    keywords
  }
  const goto = (hospital: Definitions.Europe.Hospital) => {
    router.push('/eatinRestaurantSearch', { hospital })
  }
  const renderHospitalItem = (item: Definitions.Europe.Hospital) => {
    return <Item name={item.hospitalName} onClick={() => goto(item)} />
  }

  return (
    <ColumnView height="100%">
      {keywords && tabName === 'hospital' && (
        <>
          <RowView className={tip}>
            <Text color="#CCCCCCFF" size={28}>
              选择以下医院，搜索医院附近餐厅
            </Text>
          </RowView>
          <AutoListView
            startPage={1}
            params={searchHospitalParams}
            pageSize={PAGE_SIZE}
            renderItem={renderHospitalItem}
            format={formatRes}
            useService={useHospitalSearch}
            itemKeyExtractor={itemKeyExtractor}
          />
        </>
      )}
    </ColumnView>
  )
}

export default Hospital
