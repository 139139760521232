// 目前基于ant-mobile的imagePicker
// 期待原生重写

// 只针对图片进行上传
// 所以限制了选取文件类型
// 如有上传其他格式文件需求，可参考此组件
import React, { FC, memo, useState, useMemo, useRef, useEffect } from 'react'
import { ImagePicker as AMImagePicker } from 'antd-mobile'
import FormRow from '../FormRow'
import { ConnectForm, ConnectFormChildrenProps } from '../../QCForm'
import './index.global.less'
import { useClassNames } from '../../../hooks/classname'
import {
  ImagePickerLabelProps,
  ImageFile,
  ImagePickerProps,
  FormImagePickerProps
} from './interface'
import { ValidationValueMessage } from 'react-hook-form'
// 判断图片类型
const isImage = (fileName: File): boolean => {
  const reg = /^image\//
  return reg.test(fileName.type || '')
}
const parseFiles = (urls: string[]): ImageFile[] =>
  urls
    ? urls.map((url) => ({
        url
      }))
    : []
const ImagePickerLabel: FC<ImagePickerLabelProps> = memo(
  ({ label, count, max, edit }) => (
    <>
      {label}
      {edit && (
        <span className="qc-image-picker-count">
          (<span className="qc-image-picker-num">{count}</span>/{max})
        </span>
      )}
    </>
  ),
  (prevProps, nextProps) =>
    prevProps.label === nextProps.label &&
    prevProps.count === nextProps.count &&
    prevProps.max === nextProps.max
)
export const ImagePicker: FC<ImagePickerProps> = ({
  label,
  sync,
  value,
  className,
  max = 999,
  // 手动传oss上传方法
  uploadSync,
  rules,
  disabled
}) => {
  // 上传状态
  const [uploading, updateUploading] = useState<boolean>(false)
  //
  const fakeFileInputRef = useRef<HTMLInputElement>(null)
  const [files, updateFiles] = useState<string[]>(value || [])

  const filesCount = useMemo(() => files?.length || 0, [files])
  const classNames = useClassNames([
    'qc-image-picker',
    className,
    uploading ? 'uploading' : ''
  ])
  const mergedClassName = useMemo(() => classNames, [className, uploading])
  // 模拟打开文件选择
  const onAddImageClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault()
    // event.stopPropagation();
    !uploading &&
      !disabled &&
      filesCount <= max &&
      fakeFileInputRef?.current?.click()
  }
  const onChoose = async (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('event event', event.target.files)
    updateUploading(true)
    const limit = max - filesCount
    const pickerFiles = event.target.files
      ? Array.from(event.target.files).slice(0, limit)
      : []
    const urls = uploadSync ? await uploadSync(pickerFiles) : []

    updateFiles((pre) => pre.concat(urls || []))
    updateUploading(false)
  }
  const handleAmpickerChange = (
    changeFiles: ImageFile[],
    operationType: string,
    index?: number
  ) => {
    // 删除
    if (operationType === 'remove' && index !== undefined) {
      updateFiles((pre) => {
        const next = pre.concat()
        next.splice(index, 1)
        return next
      })
    }
  }
  // 同步数据到表单
  useEffect(() => {
    sync && sync(files)
  }, [JSON.stringify(files)])
  // 是否可以继续添加
  const selectable = useMemo(() => filesCount < max && !disabled, [
    filesCount,
    disabled,
    max
  ])
  return (
    <FormRow
      label={
        <ImagePickerLabel
          edit={!disabled}
          label={label}
          count={filesCount}
          max={max}
        />
      }
      direction="vertical"
      required={!!rules?.required}
    >
      <div className="qc-image-picker-wrap">
        {/* HIDDEN INPUT TO FAKE UPLOAD */}
        <input
          type="file"
          accept="image/*"
          // hack 同名文件无法触发onChange事件
          value=""
          multiple
          onChange={onChoose}
          className="qc-image-picker-input"
          ref={fakeFileInputRef}
        />
        <AMImagePicker
          className={mergedClassName}
          multiple
          accept="image/*"
          files={parseFiles(files)}
          onAddImageClick={onAddImageClick}
          disableDelete={disabled}
          onChange={handleAmpickerChange}
          selectable={selectable}
        />
      </div>
    </FormRow>
  )
}
const FormImagePicker: FC<FormImagePickerProps> = ({
  field,
  ...otherProps
}) => {
  // 目前图片只有最大限制，是在选图片的交互上
  // 所以toast只是校验并吐出了required的
  // max = number | {message:'',value:number}
  const maxLimit =
    typeof otherProps?.rules?.max === 'number'
      ? otherProps?.rules?.max
      : (otherProps?.rules?.max as ValidationValueMessage)?.value
  const required = otherProps?.rules?.required
  const newRules = {
    validate: (value: string[]) =>
      value?.length ? true : (required as ValidationValueMessage).message
  }
  return (
    <ConnectForm field={field} rules={newRules}>
      <ImagePicker
        {...otherProps}
        {...(maxLimit && { max: Number(maxLimit) })}
      />
    </ConnectForm>
  )
}

export default FormImagePicker
