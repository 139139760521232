import { AxiosRequestConfig } from 'axios'
import makeUseApi from '../utils/makeUseApi'
import { COMPANY_NAME } from '../constants/app'

// 订单详情
export const OrderDetail: AxiosRequestConfig = {
  url: '/aimee/order/unsigned/detail',
  headers: {
    Company: COMPANY_NAME
  },
  method: 'POST'
}
export const useOrderDetail = makeUseApi<
  Definitions.Aimee.OrderIdRequest,
  Definitions.Aimee.RestResponse
>(OrderDetail)

// 取消订单
export const CancelOrder: AxiosRequestConfig = {
  url: '/aimee/order/unsigned/cancel',
  headers: {
    Company: COMPANY_NAME
  },
  method: 'POST'
}
export const useCancelOrder = makeUseApi<
  Definitions.Aimee.OrderIdRequest,
  Definitions.Aimee.RestResponse
>(CancelOrder)

// 预约下单
export const BookOrder: AxiosRequestConfig = {
  url: '/aimee/order/unsigned/book',
  headers: {
    Company: COMPANY_NAME,
    // TODO
    VersionCode: '5.1.6'
  },
  method: 'POST'
}
export const useBookOrder = makeUseApi<
  Definitions.Aimee.YudingOrderFromApp,
  Definitions.Aimee.RestResponse
>(BookOrder)

// 确认订单
export const Comfirm: AxiosRequestConfig = {
  url: '/aimee/order/unsigned/confirm',
  headers: {
    Company: COMPANY_NAME
  },
  method: 'POST'
}
export const useComfirm = makeUseApi<
  Definitions.Aimee.确认订单DTO,
  Definitions.Aimee.RestResponse
>(Comfirm)

// 用餐时间等信息
export const GetPreOrderInfo: AxiosRequestConfig = {
  url: '/aimee/order/unsigned/preOrderInfo',
  headers: {
    Company: COMPANY_NAME,
    // TODO
    VersionCode: '5.1.6'
  },
  method: 'POST'
}
export const useGetPreOrderInfo = makeUseApi<
  Definitions.Aimee.PreOrderInfoRequest,
  Definitions.Aimee.RestResponseOfPreOrderInfo
>(GetPreOrderInfo)

export const CreateCardAccountWorkform: AxiosRequestConfig = {
  url: '/zelda/sytapi/paymentWorkOrder/createPaymentWorkOrder',
  method: 'POST',
  headers: {
    Company: COMPANY_NAME,
    // TODO
    VersionCode: '5.1.6'
  }
}

export const useCreateCardAccountWorkform = makeUseApi<
  Definitions.Zelda.PaymentWorkRequest,
  Definitions.Zelda.RestResponseOfT
>(CreateCardAccountWorkform)
