import React, { FC, memo } from 'react'
import { useHistory } from 'react-router-dom'

import { ColumnView, RowView } from '../../components/QCFlex'
import Text from '../../components/Text'
import StatusTag from '../../components/StatusTag'
import { REC_RESTAURANT_STATUS_MAP } from '../../constants/other'
import { card } from './index.less'
const MemoTag = memo(
  StatusTag,
  (prveProps, nextProps) => prveProps.text !== nextProps.text
)
const RestaurantItem: FC<Definitions.Agalia.UnsignedRestaurantDetail> = ({
  rid,
  sourceId,
  name,
  address,
  region,
  status
}) => {
  // 从是否有Rid来判断是否是线上餐厅
  const router = useHistory()
  const isOnline = !!rid
  const handleClick = () => {
    if (isOnline) {
      router.push(`/recommendRestaurantDetail`, { restaurantId: rid })
    } else {
      // 推荐餐厅带上sourceId
      router.push(`/recommendRestaurantDetail`, { sourceId })
    }
  }
  const formatedStatus = status ? REC_RESTAURANT_STATUS_MAP[status] : {}
  return (
    <ColumnView className={card} onClick={handleClick}>
      <RowView hAlign="between" className="restaurant-name-title">
        <Text color="#181818FF" size={30}>
          {name}
        </Text>
        <MemoTag
          color={formatedStatus.color}
          text={formatedStatus.label || ''}
        />
      </RowView>
      <RowView>
        <Text color="#666666FF" size={24}>
          {region}
        </Text>
        <Text color="#A5A5A5FF" size={24}>
          {address}
        </Text>
      </RowView>
    </ColumnView>
  )
}

export default RestaurantItem
