// 收货地址编辑
import { useParams, useHistory, useLocation } from 'react-router-dom'
import React, { FC, useEffect, useRef } from 'react'

import QCForm, { FormRefProps } from '../../components/QCForm'
import { FormInputItem } from '../../components/FormItems'
import { ColumnView } from '../../components/QCFlex'
import Button from '../../components/Button'
import FormPoiPicker from './FormPoiPicker'
import {
  useAddressAdd,
  useAddressEdit,
  useAddressDetail
} from '../../api/Address'
import { formatData } from './util'
import { container, submitBtn, formCard } from './index.less'
import { FormValues } from './interface'
import { useGlobalStores } from '../../hooks/store'
import { useCondition } from '../../hooks/common'
import { validate } from './util'
import { Toast } from 'antd-mobile'

const ShippingAddressEdit: QCPage = () => {
  const router = useHistory()
  const { cityStore } = useGlobalStores()
  const cityId = cityStore.info.id
  const location = useLocation<{ addressId: string }>()
  const isEdit = !!location.state?.addressId
  const submitText = isEdit ? '编辑' : '添加'
  // 获取地址数据
  const [detailRes, fetchDetail] = useAddressDetail({
    manual: !isEdit,
    params: {
      id: Number(location.state?.addressId || 0)
    }
  })
  const useSave = isEdit ? useAddressEdit : useAddressAdd
  const [{ data, loading, error }, save] = useSave({
    manual: true
  })
  const inputRef = useRef<FormRefProps<FormValues> | null>(null)
  const onSubmit = () => {
    inputRef &&
      inputRef?.current?.qcSubmit((data: FormValues) => {
        const validateRes = validate(data, 100)
        if (validateRes.success) {
          const submitData = formatData(
            data,
            cityId,
            detailRes.data?.data?.addressId?.toString()
          )
          save({
            data: submitData
          })
        } else {
          Toast.show(validateRes.message)
        }
      })
  }
  const addressDetail = detailRes.data?.data
  useEffect(() => {
    data?.success && router.goBack()
  }, [data?.success])
  // 设置初始值
  useCondition(detailRes.data?.status === 0, () => {
    inputRef?.current?.setValue('landmark', {
      address: addressDetail?.landmarkAddress,
      id: addressDetail?.addressId?.toString(),
      location: `${addressDetail?.lng},${addressDetail?.lat}`,
      name: addressDetail?.landmark
    })
    inputRef?.current?.setValue('address', addressDetail?.address)
  })
  return (
    <ColumnView className={container}>
      <ColumnView className={`qc-section-card ${formCard}`}>
        <QCForm<FormValues>
          formRef={(el) => {
            inputRef.current = el
          }}
        >
          <FormPoiPicker
            placeholder="请填写地址"
            label="地址"
            field="landmark"
          />
          <FormInputItem
            placeholder="例：5号楼1603室"
            label="门牌号"
            field="address"
          />
        </QCForm>
      </ColumnView>
      <Button className={submitBtn} onClick={onSubmit}>
        确认
      </Button>
    </ColumnView>
  )
}

ShippingAddressEdit.config = {
  title: '修改地址'
}

export default ShippingAddressEdit
