import { observable, action, computed } from 'mobx'
import * as Sentry from '@sentry/react'
import {
  CurrentUserQuery,
  AccountStatus,
  CurrentUserDocument,
  CurrentUserQueryResult
} from '../generated/graphql'
import { client } from '../utils/apollo'

const storedToken = localStorage.getItem(process.env.TOKEN_KEY!)
const bindTypeProp = {
  state: '',
  url: ''
}
export class UserStore {
  @observable
  profile: MaybeNull<CurrentUserQuery['user']> = null

  @computed
  get isLoggedIn(): boolean {
    return !!this.token
  }

  @computed
  get isActive(): boolean {
    return this.profile
      ? this.profile.status === AccountStatus.AccountActivated
      : true
  }

  @observable
  token = storedToken

  @observable
  bindType = bindTypeProp

  @action
  setBindType(bindTypeProps: any): void {
    this.bindType = bindTypeProps
  }

  @action
  setProfile(profile: MaybeNull<CurrentUserQuery['user']>): void {
    this.profile = profile
    profile &&
      Sentry.setUser({
        id: profile.accountId || 'unknown',
        username: profile.name || 'unknown',
        email: profile.email || 'unknown'
      })
  }
  @action
  setToken(token: string): void {
    localStorage.setItem(process.env.TOKEN_KEY!, token)
    this.token = token
  }
  @action
  login(token: string, profile: MaybeNull<CurrentUserQuery['user']>): void {
    if (!profile) return
    localStorage.setItem(process.env.TOKEN_KEY!, token)
    this.token = token
    this.setProfile(profile)
  }

  @action
  logout(): void {
    localStorage.removeItem(process.env.TOKEN_KEY!)
    this.setProfile(null)
    this.token = null
  }

  @action
  hasAuth(authId: string): boolean {
    return !!this.profile?.roleAuthorities?.find(
      (auth) => auth.appId === authId
    )
  }

  @action
  async fetch(): Promise<void> {
    const { data } = await client.query<CurrentUserQuery>({
      query: CurrentUserDocument,
      fetchPolicy: 'no-cache'
    })
    this.setProfile(data?.user)
  }
}
