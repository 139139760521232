import React, {
  FC,
  useCallback,
  useState,
  memo,
  useMemo,
  useEffect
} from 'react'
import Icon from '../../components/Icon'
import Text from '../../components/Text'

import { useCacheState } from '../../hooks/common'
import { useCurrentAmapGeo } from '../../hooks/geolocation'
import { RowView, XCenterView } from '../../components/QCFlex'
import { useClassNames } from '../../hooks/classname'
import FoodTypeDrawer from './FoodTypeDrawer'
import SortTypeDrawer from './SortTypeDrawer'
import HospitalSearchDrawer from './HospitalSearchDrawer'
import {
  filterSearchBar,
  text,
  filterItem,
  icon,
  filterActive
} from './index.less'
import {
  FilterItemProps,
  SearchFilterProps,
  SearchFilterParams,
  TToggleDrawerValue
} from './interface'
const FilterItem = memo(
  <TValue extends Record<string, any> | string>({
    value,
    placeholder,
    onClick,
    flod
  }: FilterItemProps<TValue>) => {
    const mergedClassName = useClassNames([
      filterItem,
      flod ? filterActive : ''
    ])
    const handleClick = useCallback(() => {
      onClick && onClick()
    }, [onClick])
    return (
      <XCenterView onClick={handleClick} className={mergedClassName}>
        <Text
          className={text}
          color={value ? '#181818FF' : '#999999FF'}
          size={28}
        >
          {value || placeholder}
        </Text>
        <Icon
          className={icon}
          name="iconxialajiantou"
          size={12}
          color="#999999FF"
        />
      </XCenterView>
    )
  }
)
FilterItem.displayName = 'MFilterItem'
const SORT_LIST = [
  {
    title: '默认排序',
    value: '4'
  },
  {
    title: '距离最近',
    value: '0'
  },
  {
    title: '人气最高',
    value: '3'
  },
  {
    title: '人均最高',
    value: '2'
  },
  {
    title: '人均最低',
    value: '1'
  }
]
const SearchFilter: FC<SearchFilterProps> = ({
  onChange,
  defaultValue = {},
  filterRef,
  cateList
}) => {
  const [params, updateParams, , cacheParams] = useCacheState<
    SearchFilterParams
  >({
    initialState: defaultValue,
    manual: true,
    cacheKey: 'eatin-search-filter'
  })
  // 进图默认浏览器定位
  const regeoCode = useCurrentAmapGeo((msg) => console.error(msg))

  const [regeoCodePoiLng, regeoCodePoiLat] =
    regeoCode?.addressComponent?.streetNumber?.location?.split(',') || []
  const [drawerName, updateDrawerName] = useState<string>('')

  // 展示折叠选项
  const toggleDrawer = (filter: keyof SearchFilterParams) => {
    // 如果toggle的filter和当前name一致就判断为关闭drawer
    updateDrawerName((name) => (name === filter ? '' : filter))
  }
  const handleDrawerSelectChange = (filter: keyof SearchFilterParams) => (
    value?: TToggleDrawerValue
  ) => {
    const mergedParams: SearchFilterParams = params
    mergedParams[filter] = value as any
    if (filter === 'address' || filter === 'hospital') {
      // 医院或地址清空菜品
      mergedParams['cook_style'] = undefined
    }
    value && updateParams(mergedParams)
  }
  // 菜系展开
  const isFoodFlod = useMemo(() => drawerName === 'cook_style', [drawerName])
  // 排序展开
  const isSortFlod = useMemo(() => drawerName === 'sort', [drawerName])
  const selectedSort = SORT_LIST.find((item) => item.value === params.sort)
  // 医院搜索
  const isHospitalSearchFlod = useMemo(() => drawerName === 'hospital', [
    drawerName
  ])
  useEffect(() => {
    onChange && onChange(params)
  }, [JSON.stringify(params)])

  // 暴露出去个cache方法 方便缓存state
  useEffect(() => {
    filterRef && filterRef({ addCache: cacheParams })
  }, [])

  useEffect(() => {
    // 根据regeoCodePoiLat是否有值来判断是否取到浏览器定位
    // 根据当前是否有lat来判断是否选择过hospital
    if (regeoCodePoiLat && !params.hospital?.location?.lat) {
      // 获取到浏览器定位且没有选择过地址
      const regeoParams: SearchFilterParams = {
        hospital: {
          location: {
            lat: Number(regeoCodePoiLat),
            lng: Number(regeoCodePoiLng)
          },
          address: regeoCode?.formatted_address
        }
      }
      updateParams((prevParams) => {
        return Object.assign({}, prevParams, regeoParams)
      })
    }
  }, [regeoCodePoiLat])
  return (
    <>
      <RowView className={filterSearchBar}>
        <FilterItem
          value={params.hospital?.hospitalName}
          placeholder="医院附近"
          onClick={() => toggleDrawer('hospital')}
        />
        <FilterItem
          value={params.cook_style}
          flod={isFoodFlod}
          placeholder="全部菜系"
          onClick={() => toggleDrawer('cook_style')}
        />
        <FilterItem
          value={selectedSort?.title}
          flod={isSortFlod}
          placeholder="默认排序"
          onClick={() => toggleDrawer('sort')}
        />
      </RowView>
      {/* 三个蒙层 */}
      <FoodTypeDrawer
        cateList={cateList}
        open={isFoodFlod}
        onOpenChange={() => toggleDrawer('cook_style')}
        onChange={handleDrawerSelectChange('cook_style')}
      />
      <SortTypeDrawer
        sortList={SORT_LIST}
        open={isSortFlod}
        onOpenChange={() => toggleDrawer('sort')}
        onChange={handleDrawerSelectChange('sort')}
      />
      <HospitalSearchDrawer
        open={isHospitalSearchFlod}
        onOpenChange={() => toggleDrawer('hospital')}
        onChange={handleDrawerSelectChange('hospital')}
      />
    </>
  )
}

export default SearchFilter
