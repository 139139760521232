import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { XCenterView } from '../../components/QCFlex'
import BrotherButton from '../../components/BrotherButton'
import Button from '../../components/Button'
import Text from '../../components/Text'
import { actionBar } from './index.less'
const ActionBar: FC<Definitions.Europe.ApiRestaurentDetail> = ({
  takeaway,
  rid
}) => {
  const router = useHistory()
  const canTakeaway = !!takeaway
  const leftText = (
    <>
      <Text>叫外卖</Text>
      <Text size={20}>{takeaway?.shippingFeeMin}元起送</Text>
    </>
  )
  const handleBookingClick = () => {
    router.push(`/eatinOrderApply`, { restaurantId: rid })
  }
  const handleTakeawayClick = () => {
    router.push(`/restaurantGoodsList/${rid}`)
  }
  return (
    <XCenterView height={170} className={actionBar}>
      {canTakeaway ? (
        <BrotherButton
          leftText={leftText}
          rightText="立即订座"
          onLeftClick={handleTakeawayClick}
          onRightClick={handleBookingClick}
        />
      ) : (
        <Button onClick={handleBookingClick}>立即订座</Button>
      )}
    </XCenterView>
  )
}

export default ActionBar
