import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { RowView, ColumnView, XCenterView } from '../../components/QCFlex'
import Text from '../../components/Text'
import { VLine } from '../../components/Line'

import { useRestaurantMenu } from '../../api/Restaurant'
import { useCacheState } from '../../hooks/common'

import GoodsItem from './GoodsItem'
import GroupItem from './GroupItem'
import Cart from './Cart'

import {
  container,
  content,
  tabbar,
  vline,
  groupsContainer,
  goodsContainer,
  goodsSectionTile
} from './index.less'
// 全受控
// 待优化
// 选中菜单的数据结构
// 每次添加就往数组concat
// 删除就对数组进行splice
interface IUrlParams {
  restaurantId?: string
}
const RestaurantGoodsList: QCPage = () => {
  const router = useHistory()
  const { restaurantId } = useParams<IUrlParams>()
  const goodsContainerRef = useRef<HTMLDivElement>(null)
  // 已选中的
  // 带个restaurantId做key
  const [selectedList, updateSelectedList] = useCacheState<
    Definitions.Agalia.MenuItem[]
  >({ initialState: [], cacheKey: `restaurant-goodlist-${restaurantId}` })
  // 可视菜单区域
  const [stickyCategoryId, updateStickyCategoryId] = useState<string>('')

  const [{ data }] = useRestaurantMenu({
    useCache: true,
    data: {
      id: restaurantId
    }
  })
  const detail = data?.data
  const goodsList = detail?.list || []
  const gotoDetail = () => {
    router.push(`/onlineRestaurantDetail/${restaurantId}}`)
  }

  // 添加菜品
  const handleAddItem = (item: Definitions.Agalia.MenuItem) => {
    updateSelectedList((list) => list.concat(item))
  }
  // 删除菜品
  const handleDeleteItem = (item: Definitions.Agalia.MenuItem) => {
    updateSelectedList((list) => {
      const oldList = list.concat([])
      const isSavedItemIdx = list.findIndex((it) => it.menu_id === item.menu_id)
      if (isSavedItemIdx > -1) {
        // 反正push进去的相同menu_id的menuItem的数据结构都一样，
        // 就把找到的第一个删除了算了
        // 把这个菜品从已选列表中删除
        oldList.splice(isSavedItemIdx, 1)
      }

      return oldList
    })
  }
  // 清空已选中菜品
  const handleClearAll = () => {
    updateSelectedList([])
  }
  // 下单去咯
  const handleSubmit = () => {
    router.push('/takeawayOrderApply', {
      goodList: selectedList,
      shopName: detail?.store_name,
      restaurantId: detail?.rid
    })
  }
  // 点击groupitem
  const handleGroupItemClick = (categoryId: string) => {
    updateStickyCategoryId(categoryId)
  }
  // 计算同类型选中的数量
  const getGroupSelectedCount = (categoryId?: string) =>
    selectedList?.reduce((total, curr) => {
      if (curr.category_id === categoryId) {
        total++
      }
      return total
    }, 0)
  // 计算选中菜品数量
  const getGoodsSelectedCount = (menuid?: string) =>
    selectedList?.reduce((total, curr) => {
      if (curr.menu_id === menuid) {
        total++
      }
      return total
    }, 0)
  // duangduang 交互特效
  useEffect(() => {
    // 点击左侧右侧滚动
    const stickyDom = document
      .querySelector(`.goods-section-${stickyCategoryId}`)
      ?.previousElementSibling?.getBoundingClientRect()
    goodsContainerRef &&
      goodsContainerRef.current &&
      (goodsContainerRef.current.scrollTop += stickyDom?.top || 0 - 44)
  }, [stickyCategoryId])
  return (
    <ColumnView className={container}>
      {/* 点击商户就跳转详情页了，所以就做个静态的 */}
      <RowView className={tabbar}>
        <XCenterView>
          <Text color="#181818FF">点餐</Text>
        </XCenterView>
        <VLine gap={0} className={vline} />
        <XCenterView onClick={gotoDetail}>
          <Text color="#999999FF">商户</Text>
        </XCenterView>
      </RowView>
      {/* 选菜区 */}
      <RowView className={content}>
        {/* 左侧 --- 菜品种类 */}
        <ColumnView className={groupsContainer}>
          <ColumnView>
            {goodsList?.map((item) => (
              <GroupItem
                key={item.category_id}
                text={item.category_name}
                count={getGroupSelectedCount(item.category_id)}
                categoryId={item.category_id}
                onClick={handleGroupItemClick}
                selected={stickyCategoryId === item.category_id}
              />
            ))}
          </ColumnView>
        </ColumnView>
        {/* 右侧 --- 所有菜品 */}
        <ColumnView className={goodsContainer} qcRef={goodsContainerRef}>
          <ColumnView className="qc-goods-list-container">
            {goodsList?.map((section) => (
              <Fragment key={section.category_id}>
                <RowView
                  vAlign="center"
                  className={`${goodsSectionTile} goods-section-${section.category_id}`}
                >
                  {section.category_name}
                </RowView>
                {section.menu?.map((item) => (
                  <GoodsItem
                    count={getGoodsSelectedCount(item.menu_id)}
                    item={item}
                    onAdd={handleAddItem}
                    onDelete={handleDeleteItem}
                    key={item.menu_id}
                  />
                ))}
              </Fragment>
            ))}
          </ColumnView>
        </ColumnView>
      </RowView>
      {/* 底部购物车 */}
      <Cart
        goodList={selectedList}
        onAdd={handleAddItem}
        onDelete={handleDeleteItem}
        onClear={handleClearAll}
        onSubmit={handleSubmit}
        shipping_fee_min={detail?.shipping_fee_min}
      />
    </ColumnView>
  )
}

RestaurantGoodsList.config = {
  title: '外卖菜单'
}

export default RestaurantGoodsList
