import React, { FC } from 'react'
import { ItemCell } from '../../components/Cell'
import Text from '../../components/Text'
import { RateItem } from '../../components/FormItems/Rate'
import { ColumnView } from '../../components/QCFlex'
interface IRate {
  title?: string
  score?: number
}
interface IRateing {
  list?: IRate[]
  rating_content?: string
  rating_time?: string
  reply_content?: string
}
const InvoiceCard: FC<QCOrderInfo> = ({ rating }) => {
  const showServiceEvaluationCard = !!rating
  return showServiceEvaluationCard ? (
    <ColumnView className="qc-section-card">
      <ItemCell
        label={
          <Text color="#999999FF" size={34}>
            服务评价
          </Text>
        }
        arrow={false}
      />
      {rating?.list?.map((rate: IRate) => (
        <RateItem
          key={rate.title}
          label={rate.title}
          value={rate.score}
          disabled
        />
      ))}
      <Text>{rating.reply_content}</Text>
    </ColumnView>
  ) : (
    <></>
  )
}

export default InvoiceCard
