import React, { FC } from 'react'
import { Flex } from 'antd-mobile'
import Text from '../../components/Text'
import Progress from '../../components/Progress'

import { card, statistic, progress } from './index.less'

interface IConsumStatistic {
  time?: string
  type?: string
  total?: number
  actual?: number
}

const Card: FC<IConsumStatistic> = ({ time, type, total = 1, actual = 0 }) => {
  const percent = (actual / total) * 100
  const mergedClassName = `qc-section-card ${card}`
  return (
    <Flex className={mergedClassName} direction="column" align="start">
      <Text color="#181818FF" size={34}>
        {time}
      </Text>
      <Flex className={statistic} justify="between">
        <Text color="#999999FF" size={30}>
          {type}
        </Text>
        <Flex>
          <Text color="#181818FF" size={30}>
            {actual ? actual.toLocaleString('en-us') : ''}
          </Text>
          {/* <Text color="#999999FF" size={30}>
            /{total}
          </Text> */}
        </Flex>
      </Flex>
      {/* <Flex className={progress}>
        <Progress percent={percent} />
      </Flex> */}
    </Flex>
  )
}

export default Card
