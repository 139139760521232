import React from 'react'
import { Map, Marker } from 'react-amap'
import { useLocation } from 'react-router-dom'
import isEmpty from 'lodash.isempty'

import { useConvertFrom, TCoordsys } from '../../api/Geolocation'

const QCMap: QCPage = () => {
  const location = useLocation<{
    address?: string
    location?: { lng: number; lat: number }
    // 经纬度来源，高德和百度
    // 线上餐厅是百度经纬度
    poiSource?: TCoordsys
  }>()

  const needTransform = location.state?.poiSource !== 'autonavi'

  const [{ data }] = useConvertFrom({
    manual: !needTransform,
    noAuth: true,
    params: {
      locations: `${location.state?.location?.lng},${location.state?.location?.lat}`,
      coordsys: location.state?.poiSource || 'autonavi'
    }
  })

  const croodPair = data?.locations?.split(',') || []

  const center = needTransform
    ? isEmpty(croodPair)
      ? null
      : {
          lng: Number(croodPair[0]),
          lat: Number(croodPair[1])
        }
    : location.state?.location

  return (
    <Map
      amapkey={process.env.AMAP_KEY!}
      {...(center && { center })}
      zoomEnable
      zoom={15}
    >
      {center && <Marker position={center}></Marker>}
    </Map>
  )
}

QCMap.config = {
  title: '查看地图'
}

export default QCMap
