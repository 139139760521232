import React, { FC, memo } from 'react'
import hoistNonReactStatic from 'hoist-non-react-statics'

import step1Img from './images/step1.png'
import step2Img from './images/step2.png'
import step3Img from './images/step3.png'
import step4Img from './images/step4.png'
import { container } from './index.less'
const RecommendRestaurantFlow: QCPage = () => (
  <section className={container}>
    <img src={step1Img} />
    <img src={step2Img} />
    <img src={step3Img} />
    <img src={step4Img} />
  </section>
)

RecommendRestaurantFlow.config = {
  title: '推荐流程'
}
// 静态页，没啥变动的 直接缓存吧
export default hoistNonReactStatic(
  memo(RecommendRestaurantFlow),
  RecommendRestaurantFlow
)
