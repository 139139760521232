import React, { FC, useMemo } from 'react'
import { useClassNames } from '../../hooks/classname'
import './index.global.less'
export interface QCBadgeProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  > {
  text?: React.ReactNode
  overflowCount?: number
}
const QCBadge: FC<QCBadgeProps> = ({ className = '', text, overflowCount }) => {
  const mergedClassName = useClassNames(['qc-badge', className])
  const showText = useMemo(() => {
    const textIsNumber = typeof text === 'number'
    return textIsNumber && overflowCount && overflowCount <= (text || 0)
      ? `${overflowCount}+`
      : text
  }, [overflowCount, text])
  return <span className={mergedClassName}>{showText}</span>
}

export default QCBadge
