import React, { FC, useMemo, useState, useCallback, memo } from 'react'
import { Drawer } from 'antd-mobile'
import { ColumnView, RowView } from '../../components/QCFlex'
import { SearchBar } from '../../components/SearchBar'
import Text from '../../components/Text'
import Icon from '../../components/Icon'
import AutoListView, {
  IAutoListViewFormatFn
} from '../../components/AutoListView'
import {
  useSearchPointsByKeywords,
  SearchPOI,
  SearchPointsByKeywordsResponse
} from '../../api/Geolocation'
import { poiDrawerContent, autoListContainer, poi } from './index.less'
import { useGlobalStores } from '../../hooks/store'

interface PoiSearchDrawerProps {
  open?: boolean
  onChange: (item?: SearchPOI) => void
}
interface PoiItemProps {
  item?: SearchPOI
  onClick?: (item?: SearchPOI) => void
}
interface PoiSearchProps {
  onCancel?: () => void
  onChoose?: (item?: SearchPOI) => void
  autoFocus?: boolean
}
const PoiItem: FC<PoiItemProps> = ({ item, onClick }) => {
  const handleClick = useCallback(() => {
    onClick && onClick(item)
  }, [])
  return (
    <RowView className={poi} onClick={handleClick}>
      <Icon name="icondizhidingwei" color="#999999FF" size={28} />
      <ColumnView margin={[0, 0, 0, 10]} vAlign="center">
        <Text color="#666666" size={28}>
          {item?.name}
        </Text>
        <Text color="#999999" size={28}>
          {item?.address}
        </Text>
      </ColumnView>
    </RowView>
  )
}
const PAGE_SIZE = 20
const formatRes: IAutoListViewFormatFn<
  SearchPOI,
  SearchPointsByKeywordsResponse
> = ({ pois = [] }) => ({
  list: pois,
  nomore: pois?.length < PAGE_SIZE
})
const itemKeyExtractor = (item: SearchPOI) =>
  (item.location || item.id || '').toString()
const formatPageInfo = ({
  pSize,
  pNum
}: {
  pSize?: number
  pNum?: number
}) => ({ offset: pSize, page: pNum })
const PoiSearch: FC<PoiSearchProps> = ({ onCancel, onChoose, autoFocus }) => {
  const [keywords, updateKeywords] = useState<string>('')
  const { cityStore } = useGlobalStores()
  const handleChange = useCallback((text) => {
    updateKeywords(text)
  }, [])
  const renderHospitalItem = (item: SearchPOI) => {
    return <PoiItem item={item} onClick={() => onChoose && onChoose(item)} />
  }
  const params = {
    keywords,
    city: cityStore.info?.name || '上海'
    // types: '标志性建筑物'
  }
  const serviceConfig = {
    noAuth: true
  }
  return (
    <ColumnView className={poiDrawerContent}>
      <SearchBar
        autoFocus={autoFocus}
        placeholder="请输入关键词"
        onChange={handleChange}
        onCancel={onCancel}
      />
      <ColumnView className={autoListContainer}>
        {keywords && (
          <AutoListView
            params={params}
            dataPath=""
            startPage={1}
            paramsField="params"
            serviceConfig={serviceConfig}
            formatPageInfo={formatPageInfo}
            pageSize={PAGE_SIZE}
            renderItem={renderHospitalItem}
            format={formatRes}
            useService={useSearchPointsByKeywords}
            itemKeyExtractor={itemKeyExtractor}
          />
        )}
      </ColumnView>
    </ColumnView>
  )
}
const PoiSearchDrawer: FC<PoiSearchDrawerProps> = ({ open, onChange }) => {
  // hack fadeIn的动画
  const fakeStyle = useMemo(
    () => ({
      zIndex: open ? 1 : -1,
      ...(!open && { transitionProperty: 'z-index', transitionDelay: '0.4s' })
    }),
    [open]
  )
  return (
    <Drawer
      open={open}
      // hack fadeIn的动画
      style={fakeStyle}
      position="right"
      {...(!open && { style: { zIndex: -1 } })}
      enableDragHandle
      // 简单关闭drawer不需要传选中值
      onOpenChange={() => onChange()}
      sidebar={
        <PoiSearch onCancel={onChange} onChoose={onChange} autoFocus={open} />
      }
    >
      <></>
    </Drawer>
  )
}

export default memo(PoiSearchDrawer)
