import { useState, useRef, useEffect } from 'react'
import OSS from 'ali-oss'
import useAxios from 'axios-hooks'
import { useGlobalStores } from './store'

interface STSServerResponse {
  SecurityToken: string
  AccessKeyId: string
  AccessKeySecret: string
  Expiration: string
}

export const useOssClient = (bucket: string) => {
  const { userStore } = useGlobalStores()
  const [stsData, fetchSTS] = useAxios<STSServerResponse>({
    url: process.env.STS_SERVICE,
    headers: {
      token: userStore.token
    }
  })
  const [ossClient, updateOssClient] = useState<any>(null)
  useEffect(() => {
    stsData?.data?.AccessKeyId &&
      updateOssClient(
        new OSS({
          region: 'oss-cn-hangzhou',
          bucket,
          accessKeyId: stsData?.data?.AccessKeyId,
          accessKeySecret: stsData?.data?.AccessKeySecret,
          stsToken: stsData?.data?.SecurityToken
        })
      )
  }, [stsData?.data?.SecurityToken])

  const expired = stsData?.data?.Expiration
    ? new Date().getTime() - new Date(stsData?.data?.Expiration).getTime() >= 0
    : true
  useEffect(() => {
    if (expired) {
      fetchSTS()
    }
  }, [expired])
  return ossClient
}
