import React, { FC } from 'react'
import { tabContainer, tabItem, left, active } from './index.less'
import { Flex } from 'antd-mobile'
import { Link, NavLink } from 'react-router-dom'

interface UserActionTabProps {
  currentActive?: 'register' | 'login'
  loginPath?: string
  registerPath?: string
}

const UserActionTab: FC<UserActionTabProps> = ({
  currentActive = 'register',
  loginPath = '/login',
  registerPath = 'register'
}) => {
  return (
    <Flex className={tabContainer} align="center" alignContent="center">
      <Flex.Item
        className={[tabItem, left, currentActive === 'register' && active]}
      >
        <NavLink to={registerPath} activeClassName={active}>
          注册
        </NavLink>
      </Flex.Item>

      <Flex.Item className={[tabItem, currentActive === 'login' && active]}>
        <NavLink to={loginPath} activeClassName={active}>
          登录
        </NavLink>
      </Flex.Item>
    </Flex>
  )
}

export default UserActionTab
