// frok from ./Input
import React, { FC, memo, useCallback, useState, useEffect } from 'react'
import { Controller } from 'react-hook-form'

import FormRow, { FormRowProps } from '../FormRow'
import {
  ConnectForm,
  ConnectFormProps,
  ConnectFormChildrenProps
} from '../../QCForm'
// import './index.global.less'
import { useClassNames } from '../../../hooks/classname'
import Rate from '../../Rate'
import { ControllerProps } from 'react-hook-form/dist/types/props'
interface BaseRateItemProps {
  defaultValue?: any
  value?: any
}
export interface FormRateItemProps
  extends FormRowProps,
    BaseRateItemProps,
    ConnectFormProps {}
export interface RateItemProps
  extends FormRowProps,
    BaseRateItemProps,
    ConnectFormChildrenProps,
    ConnectFormProps {}
interface MRateItemProps
  extends BaseRateItemProps,
    ConnectFormChildrenProps,
    ConnectFormProps {}
export const RateItem: FC<RateItemProps> = ({
  label,
  sync,
  value,
  disabled,
  rules,
  ...otherProps
}) => {
  const [rateValue, updateRateValue] = useState<number>(value)
  const handleChange = useCallback(
    (count) => {
      !disabled && updateRateValue(count)
    },
    [updateRateValue]
  )
  useEffect(() => {
    sync && sync(rateValue)
  }, [rateValue])
  return (
    <FormRow label={label} required={!!rules?.required}>
      <Rate value={rateValue} onChange={handleChange} />
    </FormRow>
  )
}

// 针对 FormProvider 的 带优化的Rateitem
const MRateItem: FC<MRateItemProps> = memo(
  ({ formState, watch, control, ...otherProps }) => (
    <RateItem {...otherProps} />
  ),
  (prevProps, nextProps) =>
    prevProps.formState?.isDirty === nextProps.formState?.isDirty
)
const FormRateItem: FC<FormRateItemProps> = ({ field, ...otherProps }) => (
  <ConnectForm field={field} rules={otherProps.rules}>
    <MRateItem {...otherProps} />
  </ConnectForm>
)
export default FormRateItem
