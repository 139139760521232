import React, { FC } from 'react'
import Button from '../../components/Button'
import { XCenterView } from '../../components/QCFlex'
import { footer } from './index.less'
interface FooterProps {
  onClick?: () => void
}
const Footer: FC<FooterProps> = ({ onClick }) => {
  return (
    <XCenterView className={footer} onClick={onClick}>
      <Button>上传票据</Button>
    </XCenterView>
  )
}

export default Footer
