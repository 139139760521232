import { Toast } from 'antd-mobile'
import { useEffect } from 'react'
import { useGetCheckoutCounterUrl } from '../api/Payment'
import { OrderType } from '../constants/order'
import { useGlobalStores } from './store'

type UseGuanAiTongPaymentParams = {
  orderId: string
  amount: number
  bizType: string
  orderType: OrderType
  isAlipay: string
}

export const useGuanAiTongPayment = (params: UseGuanAiTongPaymentParams) => {
  const { userStore } = useGlobalStores()
  const [{ data, loading }, getUrl] = useGetCheckoutCounterUrl({
    manual: true,
    data: {
      orderId: params.orderId,
      totalAmount: params.amount * 100,
      accountId: userStore.profile?.accountId || '',
      costAmount: params.amount * 100,
      companyId: userStore.profile?.companyId || '',
      bizType: params.bizType,
      returnUrl:
        window.location.origin + '/handleGuanAiTongCallback/' + params.orderType
    }
  })

  useEffect(() => {
    if (loading) {
      Toast.loading('', 0)
    } else {
      Toast.hide()
    }
  }, [loading])

  useEffect(() => {
    data?.result && (window.location.href = data?.result)
  }, [data])

  return () => {
    getUrl()
  }
}
