// 收货地址列表
import React, { FC, memo, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { ColumnView, RowView, XCenterView } from '../../components/QCFlex'
import Icon from '../../components/Icon'
import Text from '../../components/Text'
import { useGlobalStores } from '../../hooks/store'
import { useAddressList } from '../../api/Address'
import {
  container,
  addressItem,
  addressMark,
  addressDetail,
  footer,
  autoListContainer,
  content,
  addressInfo,
  noDeliveryTag
} from './index.less'
import { usePagePickerRouter } from '../../hooks/pagePickerRouter'
interface IUrlState {
  rid?: string
  needCondition?: boolean
}
interface AddressItemProps {
  item?: Definitions.Europe.DeliveryAddress
  // 从外卖选地址来的需要做判断，其它不需要
  needCondition?: boolean
  onClick?: (item?: Definitions.Europe.DeliveryAddress) => void
  onEdit?: (item?: Definitions.Europe.DeliveryAddress) => void
}
const AddressItem: FC<AddressItemProps> = memo(
  ({ item, onClick, onEdit, needCondition }) => {
    /** 0 未验证 1 在配送范围内 2 旧地址 3 不在配送范围 */
    const canDelivery = needCondition
      ? needCondition && [0, 1].includes(item?.delivery_type || 0)
      : true
    const handleClick = () => {
      canDelivery && onClick && onClick(item)
    }
    const handleEdit = () => {
      canDelivery && onEdit && onEdit(item)
    }
    return (
      <RowView className={addressItem} hAlign="between">
        <ColumnView className={addressInfo} onClick={handleClick}>
          <Text className={addressMark}>{item?.landmark?.title}</Text>
          <Text className={addressDetail}>
            {item?.landmark?.landmark_address} {item?.address}
          </Text>
        </ColumnView>
        {canDelivery ? (
          <Icon
            name="iconbianji"
            padding={[0, 0, 0, 60]}
            size={34}
            color="#CCCCCCFF"
            onClick={handleEdit}
          />
        ) : (
          <Text className={noDeliveryTag}>不在配送范围内</Text>
        )}
      </RowView>
    )
  }
)
AddressItem.displayName = 'AddressItem'

const ShippingAddressList: QCPage = () => {
  const location = useLocation<IUrlState>()
  const { cityStore } = useGlobalStores()
  const pagePickerRouter = usePagePickerRouter({})
  const history = useHistory()
  const city = cityStore?.info?.name
  const params = {
    rid: location.state?.rid,
    address_id: '',
    city
  }
  const [{ data }] = useAddressList({
    useCache: false,
    data: params
  })
  const addAddress = () => {
    history.push(`/shippingAddressEdit`)
  }
  const handleItemClick = (item?: Definitions.Europe.DeliveryAddress) => {
    pagePickerRouter.pop(item)
  }
  const handleItemEdit = (item?: Definitions.Europe.DeliveryAddress) => {
    history.push(`/shippingAddressEdit`, { addressId: item?.address_id })
  }

  const list = data?.data?.list
  return (
    <ColumnView className={container}>
      <ColumnView className={content}>
        <ColumnView className={`${autoListContainer} qc-section-card`}>
          {list?.map((item) => (
            <AddressItem
              key={item.address_id}
              item={item}
              onClick={handleItemClick}
              onEdit={handleItemEdit}
              needCondition={location.state?.needCondition}
            />
          ))}
        </ColumnView>
      </ColumnView>
      <XCenterView onClick={addAddress} className={footer}>
        <Text color="#FF9847FF" size={34}>
          + 新增收货地址
        </Text>
      </XCenterView>
    </ColumnView>
  )
}

ShippingAddressList.config = {
  title: '我的地址'
}

export default observer(ShippingAddressList)
