import React, { FC } from 'react'
import { ItemCell } from '../../components/Cell'
import Text from '../../components/Text'
import { ColumnView } from '../../components/QCFlex'

const OrderInfoCard: FC<QCOrderInfo> = ({
  oid,
  invoice_title,
  address,
  linkman_name,
  linkman_phone,
  message,
  consumption_type,
  cost_center,
  buy_reason,
  people_num = ''
}) => {
  return (
    <ColumnView className="qc-section-card">
      <ItemCell
        label={
          <Text size={34} color="#999999FF">
            订单信息
          </Text>
        }
        arrow={false}
      />
      <ItemCell label="订单编号" value={oid} arrow={false} />
      <ItemCell label="成本中心" value={cost_center} arrow={false} />
      <ItemCell label="费用类别" value={consumption_type} arrow={false} />
      <ItemCell label="宴请事由" value={buy_reason || ''} arrow={false} />
      <ItemCell label="联系姓名" value={linkman_name} arrow={false} />
      <ItemCell label="联系手机" value={linkman_phone} arrow={false} />
      <ItemCell label="用餐人数" value={`${people_num}人`} arrow={false} />
      <ItemCell label="收货地址" value={address} arrow={false} />
      <ItemCell label="发票信息" value={invoice_title} arrow={false} />
      <ItemCell label="备注" value={message || ''} arrow={false} />
    </ColumnView>
  )
}

export default OrderInfoCard
