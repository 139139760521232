import React, {
  FC,
  memo,
  useState,
  useCallback,
  useMemo,
  ReactText,
  useEffect
} from 'react'
import { Picker as AtPicker } from 'antd-mobile'
import { PickerPropsType } from 'antd-mobile/lib/picker/PropsType'
import Icon from '../../Icon'
import FormRow, { FormRowProps } from '../FormRow'
import {
  ConnectForm,
  ConnectFormProps,
  ConnectFormChildrenProps
} from '../../QCForm'
import { useFormattedProps } from '../../../hooks/pickerHelper'

interface BasePickerProps extends FormRowProps, PickerPropsType {}
interface MPickerProps
  extends ConnectFormChildrenProps,
    Omit<PickerPropsType, 'value'> {}
export interface FormPickerProps extends BasePickerProps, ConnectFormProps {}

export interface PickerProps
  extends BasePickerProps,
    Omit<ConnectFormChildrenProps, 'value' | 'onBlur'>,
    ConnectFormProps {}
interface ShowTextProps {
  placeholder?: string
  onClick?: () => void
  extra?: string
}
const ShowText: FC<ShowTextProps> = memo(
  ({ onClick, extra, placeholder }) => {
    const isPlaceholder = useMemo(() => extra === placeholder, [extra])
    const textClassName = isPlaceholder ? 'qc-input-placeholder' : ''
    return (
      <span className={textClassName} onClick={onClick}>
        {isPlaceholder ? placeholder : extra}
        <Icon name="iconyoujiantou" size={20} color="rgb(136, 136, 136)" />
      </span>
    )
  },
  (prevProps, nextProps) =>
    prevProps.extra === nextProps.extra &&
    prevProps.placeholder === nextProps.placeholder
)
ShowText.displayName = 'MShowText'
export const Picker: FC<PickerProps> = ({
  label,
  sync,
  placeholder,
  disabled,
  value,
  rules,
  ...otherProps
}) => {
  const [group, updateGroup] = useState<ReactText[] | undefined>(value)

  const handleChange = useCallback(
    (pickergroup) => {
      updateGroup(() => {
        !disabled && sync && sync(pickergroup)
        return pickergroup
      })
    },
    [sync]
  )

  const formattedProps = useFormattedProps({
    value: group,
    onChange: handleChange
  })
  return (
    <FormRow label={label} required={!!rules?.required}>
      <AtPicker
        {...otherProps}
        // 先自定义个extra用来判断placeholder
        extra={placeholder}
        cols={1}
        value={formattedProps.value}
        onChange={formattedProps.onChange}
      >
        <ShowText placeholder={placeholder} />
      </AtPicker>
    </FormRow>
  )
}

const MPicker: FC<MPickerProps> = memo(
  ({ formState, watch, ...otherProps }) => <Picker {...otherProps} />,
  (prevProps, nextProps) =>
    prevProps.formState?.isDirty === nextProps.formState?.isDirty
)
MPicker.displayName = 'MPicker'
const FormPicker: FC<FormPickerProps> = ({ field, ...otherProps }) => (
  <ConnectForm field={field} rules={otherProps.rules}>
    <MPicker {...otherProps} />
  </ConnectForm>
)

export default FormPicker
