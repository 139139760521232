import { AxiosRequestConfig } from 'axios'
import makeUseApi from '../utils/makeUseApi'
import { COMPANY_NAME } from '../constants/app'
// 订单列表
export const OrderList: AxiosRequestConfig = {
  url: '/aimee/order/olist',
  method: 'POST'
}
export const useOrderList = makeUseApi<
  Definitions.Aimee.PageRequest,
  Definitions.Aimee.RestResponse
>(OrderList)

// 订单详情
export const OrderDetail: AxiosRequestConfig = {
  url: '/aimee/order/detail',
  headers: {
    Company: COMPANY_NAME
  },
  method: 'POST'
}
export const useOrderDetail = makeUseApi<
  Definitions.Aimee.OrderIdRequest,
  Definitions.Aimee.RestResponse
>(OrderDetail)

// 取消订单
export const CancelOrder: AxiosRequestConfig = {
  url: '/aimee/order/cancel',
  headers: {
    Company: COMPANY_NAME
  },
  method: 'POST'
}
export const useCancelOrder = makeUseApi<
  Definitions.Aimee.OrderIdRequest,
  Definitions.Aimee.RestResponse
>(CancelOrder)

// 预约下单
export const BookOrder: AxiosRequestConfig = {
  url: '/aimee/order/book',
  headers: {
    Company: COMPANY_NAME,
    // TODO
    VersionCode: '5.1.6'
  },
  method: 'POST'
}
export const useBookOrder = makeUseApi<
  Definitions.Aimee.YudingOrderFromApp,
  Definitions.Aimee.RestResponse
>(BookOrder)

// 用餐时间等信息
export const GetPreOrderInfo: AxiosRequestConfig = {
  url: '/aimee/order/preOrderInfo',
  headers: {
    Company: COMPANY_NAME,
    // TODO
    VersionCode: '5.1.6'
  },
  method: 'POST'
}
export const useGetPreOrderInfo = makeUseApi<
  Definitions.Aimee.PreOrderInfoRequest,
  Definitions.Aimee.RestResponseOfPreOrderInfo
>(GetPreOrderInfo)
