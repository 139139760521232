import { AxiosRequestConfig } from 'axios'
import makeUseApi from '../utils/makeUseApi'

export const querySignature: AxiosRequestConfig = {
  url: `/wechat-common/signature/query?appId=${process.env.WX_APPID}`,
  method: 'GET'
}

export const useQuerySignature = makeUseApi<
  { url: string },
  {
    timestamp: string
    noncestr: string
    signature: string
  }
>(querySignature)
