import React, { FC, memo, useState } from 'react'
import { Link } from 'react-router-dom'

import RestaurantItem from './RestaurantItem'
import EmptyRestaurantImg from '../../assets/EmptyRestaurant.svg'
import Text from '../../components/Text'
import Button from '../../components/Button'
import { useRestaurantList } from '../../api/RecommendRestaurant'
import { XCenterView } from '../../components/QCFlex'
import {
  container,
  emptyContainer,
  dsc,
  recommendBtn,
  flowBtn,
  img,
  recommendBtnWrap,
  listContainer,
  tip,
  result,
  paddingRight
} from './index.less'

import AutoListView, {
  IAutoListViewFormatFn
} from '../../components/AutoListView'
import { ColumnView, RowView } from '../../components/QCFlex'
import { Toast } from 'antd-mobile'

type IRestaurant = Definitions.Agalia.UnsignedRestaurantDetail

const EmptyView = memo(() => (
  <ColumnView className={emptyContainer} hAlign="center">
    <img src={EmptyRestaurantImg} className={img} />
    <Text color="#181818FF" size={34} className={result}>
      暂无推荐餐厅
    </Text>
    <Text color="#CCCCCCFF" size={28} className={dsc}>
      您还没有推荐餐厅哦，赶快去推荐~
    </Text>
    <Link to="/recommendRestaurantSearch" className={recommendBtnWrap}>
      <Button size="large" className={recommendBtn}>
        <Text color="#FFFFFFFF" size={34}>
          去推荐餐厅
        </Text>
      </Button>
    </Link>
    <Link to="/recommendRestaurantFlow" className={flowBtn}>
      <Text color="#CCCCCCFF" size={28}>
        查看推荐流程 {'>'}
      </Text>
    </Link>
  </ColumnView>
))
const renderRestaurantItem = (item: IRestaurant) => <RestaurantItem {...item} />
const itemKeyExtractor = (item: IRestaurant) => item.rid || item.sourceId || ''
const formatPageInfo = ({
  pSize,
  pNum
}: {
  pSize?: number
  pNum?: number
}) => ({
  pageSize: pSize,
  page: pNum
})

const PAGE_SIZE = 10
const RecommendRestaurantList: QCPage = () => {
  const [applyMessage, updateApplyMessage] = useState<string>('')
  const [isApplicable, setIsApplicable] = useState<boolean>(true)
  const [totalApply, setTotalApply] = useState<number>(0)

  const formatRes: IAutoListViewFormatFn<
    IRestaurant,
    Definitions.Agalia.UnsignedResponse
  > = ({ list = [], applyMessage, alsoApply, totalApply, totalCount }) => {
    updateApplyMessage(
      alsoApply
        ? `本月已申请${totalApply}，还能继续申请${alsoApply}`
        : applyMessage || ''
    )
    setIsApplicable(!!alsoApply)
    setTotalApply(totalApply || 0)

    return {
      list: list,
      // pagesize 为PAGE_SIZE 简单判断小于PAGE_SIZE条就算是nomore
      nomore: list?.length < PAGE_SIZE
    }
  }

  function onNoLinkClick() {
    Toast.show('您本月推荐餐厅名额已用完，请下月再推荐')
  }

  return (
    <ColumnView className={container}>
      <RowView vAlign="center" hAlign="between" wrap="wrap" className={tip}>
        {applyMessage}
        {isApplicable ? (
          <Link className={paddingRight} to="/recommendRestaurantSearch">
            <Text color="#CCCCCCFF" size={28}>
              去推荐餐厅 {'>'}
            </Text>
          </Link>
        ) : (
          <Text
            className={paddingRight}
            onClick={onNoLinkClick}
            color="#CCCCCCFF"
            size={28}
          >
            去推荐餐厅 {'>'}
          </Text>
        )}
      </RowView>
      <AutoListView
        startPage={1}
        pageSize={PAGE_SIZE}
        formatPageInfo={formatPageInfo}
        autolistClassName={listContainer}
        renderItem={renderRestaurantItem}
        format={formatRes}
        itemKeyExtractor={itemKeyExtractor}
        FooterView={memo(() => (
          <XCenterView>
            <Link to="/recommendRestaurantFlow" className={flowBtn}>
              <Text color="#CCCCCCFF" size={28}>
                查看推荐流程 {'>'}
              </Text>
            </Link>
          </XCenterView>
        ))}
        EmptyView={EmptyView}
        useService={useRestaurantList}
      />
    </ColumnView>
  )
}

RecommendRestaurantList.config = {
  title: '推荐餐厅列表'
}

export default RecommendRestaurantList
