import React, { FC, memo, useState, useCallback, useMemo } from 'react'
import { ActionSheet } from 'antd-mobile'

import FormRow, { FormRowProps } from '../FormRow'
import {
  ConnectForm,
  ConnectFormProps,
  ConnectFormChildrenProps
} from '../../QCForm'
import './index.global.less'
import { useClassNames } from '../../../hooks/classname'
import Icon from '../../Icon'
import { ActionSheetWithOptions } from 'antd-mobile/lib/action-sheet'
interface BaseActionSheetProps
  extends Omit<ActionSheetWithOptions, 'options'>,
    FormRowProps {
  options?: string[]
}

export interface FormActionSheetPickerProps
  extends BaseActionSheetProps,
    ConnectFormProps {}

export interface ActionSheetPickerProps
  extends BaseActionSheetProps,
    ConnectFormChildrenProps,
    ConnectFormProps {}

export const ActionSheetPicker: FC<ActionSheetPickerProps> = ({
  label,
  sync,
  options = [],
  placeholder,
  message,
  value,
  title,
  rules,
  disabled
}) => {
  const [text, updateText] = useState<string>(value)
  const mergedClassName = useClassNames(['qc-action-picker'])
  const mergedOptions = useMemo(() => options?.concat('取消'), [
    JSON.stringify(options)
  ])
  const mergedOptionsLength = mergedOptions?.length || 0
  const showActiconSheet = useCallback(() => {
    !disabled &&
      ActionSheet.showActionSheetWithOptions(
        {
          options: mergedOptions,
          cancelButtonIndex: mergedOptionsLength - 1,
          // destructiveButtonIndex: mergedOptionsLength - 2,
          message,
          title,
          // message: 'I am description, description, description',
          maskClosable: true
          // 'data-seed': 'logId',
        },
        (buttonIndex) => {
          // 判断是否是点击了底部的取消按钮
          // 奇怪的组件设计
          const isCancelBtn = buttonIndex === mergedOptionsLength - 1
          !isCancelBtn &&
            updateText((text) => {
              sync && sync(mergedOptions[buttonIndex])
              return mergedOptions[buttonIndex]
            })
        }
      )
  }, [disabled])
  const textClassName = useMemo(() => (text ? '' : 'qc-input-placeholder'), [
    text
  ])
  return (
    <FormRow label={label} required={!!rules?.required}>
      <span className={textClassName} onClick={showActiconSheet}>
        {text || placeholder}
        <Icon name="iconyoujiantou" size={20} color="rgb(136, 136, 136)" />
      </span>
    </FormRow>
  )
}

const MActionSheetPicker: FC<ConnectFormChildrenProps> = memo(
  ({ formState, watch, ...otherProps }) => (
    <ActionSheetPicker {...otherProps} />
  ),
  (prevProps, nextProps) =>
    prevProps.formState?.isDirty === nextProps.formState?.isDirty
)
const FormActionSheetPicker: FC<FormActionSheetPickerProps> = ({
  field,
  ...otherProps
}) => (
  <ConnectForm field={field} rules={otherProps.rules}>
    <MActionSheetPicker {...otherProps} />
  </ConnectForm>
)

export default FormActionSheetPicker
