import React, { FC, useCallback, memo, useState } from 'react'
import { Flex, Modal } from 'antd-mobile'
import Text from '../../components/Text'
import Icon from '../../components/Icon'

import { callModalContent } from './index.less'
const PhoneModal: FC<{ phones?: string[] }> = memo(
  ({ phones }) => (
    <Flex direction="column" className={callModalContent}>
      {phones?.map((phone, idx) => (
        <a key={`${phone}-${idx}`} href={`tel:${phone}`}>
          <Text size={34} color="#999999FF">
            {phone}
          </Text>
        </a>
      ))}
    </Flex>
  ),
  (prevProps, nextProps) =>
    JSON.stringify(prevProps.phones) !== JSON.stringify(nextProps.phones)
)
interface PhoneCallBtnProps {
  phones: string[]
}
const PhoneCallBtn: FC<PhoneCallBtnProps> = ({ phones = [] }) => {
  const [showModal, updateShowModal] = useState<boolean>(false)
  const handleModalClose = useCallback(() => updateShowModal(false), [])
  const onCall = useCallback(() => {
    updateShowModal(true)
  }, [JSON.stringify(phones)])
  return (
    <>
      <Icon name="icondianhua" color="#FF9847FF" size={28} onClick={onCall} />
      <Modal
        transparent
        transitionName="am-zoom"
        maskTransitionName="am-fade"
        platform="ios"
        visible={showModal}
        maskClosable
        closable={false}
        onClose={handleModalClose}
      >
        <PhoneModal phones={phones} />
      </Modal>
    </>
  )
}

export default PhoneCallBtn
