import React, { FC, memo } from 'react'
import { XCenterView, RowView } from '../../components/QCFlex'
import QCBadge from '../../components/QCBadge'
import {
  groupItem,
  groupItemSelected,
  groupItemInner,
  groupItemBadge
} from './index.less'
import { useClassNames } from '../../hooks/classname'

export interface ItemProps {
  count?: number
  selected?: boolean
  text?: string
  categoryId?: string
  onClick?: (categoryId: string) => void
}
const GroupItem: FC<ItemProps> = memo(
  ({ count, selected, text, categoryId, onClick }) => {
    const mergedClassName = useClassNames([
      groupItem,
      selected ? groupItemSelected : ''
    ])
    const handleClick = () => {
      onClick && onClick(categoryId || '')
    }
    return (
      <RowView className={mergedClassName} onClick={handleClick}>
        <XCenterView className={groupItemInner}>{text}</XCenterView>
        {count ? (
          <QCBadge
            className={groupItemBadge}
            overflowCount={100}
            text={count}
          />
        ) : (
          ''
        )}
      </RowView>
    )
  },
  (prevProps, nextProps) =>
    prevProps.count === nextProps.count &&
    prevProps.selected === nextProps.selected
)
GroupItem.displayName = 'MGroupItem'
export default GroupItem
