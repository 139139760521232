import React, { FC } from 'react'
import { useCompanyGroupsLazyQuery } from '../../generated/graphql'
import Picker, { PickerProps } from '../Picker'
import { useFormattedProps } from '../../hooks/pickerHelper'
export type GroupPickerProps = { type?: string } & Partial<PickerProps> & {
    onChange: (value: string | number) => void
    value: string
  }
const GroupPicker: FC<GroupPickerProps> = ({
  type,
  value,
  onChange,
  ...props
}) => {
  type = type || 'structure'

  const [queryGroups, { data }] = useCompanyGroupsLazyQuery({
    variables: {
      type
    }
  })

  const formattedProps = useFormattedProps({
    value,
    onChange
  })

  return (
    <Picker
      {...props}
      value={formattedProps.value}
      onChange={formattedProps.onChange}
      onVisibleChange={(visible) => visible && queryGroups()}
      data={
        data?.groups.map((group) => ({
          value: group.groupId,
          label: group.name
        })) || []
      }
    />
  )
}

export default GroupPicker
