import React, { FC } from 'react'
import { Flex } from 'antd-mobile'

import { STY_CUSTOMER_PHONE } from '../../constants/other'
import Icon from '../../components/Icon'
import Text from '../../components/Text'
import { customerBtn, icon, text } from './index.less'
const FixedCustomerBtn: FC = () => {
  return (
    <a href={`tel:${STY_CUSTOMER_PHONE}`}>
      <Flex justify="center" alignContent="center" className={customerBtn}>
        <Icon className={icon} size={38} name="iconkefu" color="#ffffff" />
        <Text className={text} size={28} color="#ffffff">
          联系客服
        </Text>
      </Flex>
    </a>
  )
}

export default FixedCustomerBtn
