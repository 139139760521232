import React, { FC } from 'react'
import { ColumnView } from '../../components/QCFlex'
import {
  FormInputItem,
  FormDeliveryTimePicker,
  FormPicker,
  FormTextareaItem,
  FormGroupPicker
} from '../../components/FormItems'
import FormTaxPicker from './FormTaxPicker'
import { approvalCodeInput } from './index.less'

interface DeliveryCardProps {
  onPush?: () => void
  dates?: Definitions.Aimee.DateModel[]
  rid?: string
}
const DeliveryCard: FC<DeliveryCardProps> = ({ dates }) => {
  return (
    <ColumnView className="qc-section-card">
      <FormGroupPicker
        placeholder="请选择成本中心"
        label="成本中心"
        field="costCenterId"
        // 存的type叫bu-structure
        type="costcenter"
        rules={{ required: { message: '请选择成本中心', value: true } }}
      />
      <FormPicker
        placeholder="请选择费用类别"
        // 直接前端固定，有更改需求改这个就行
        // 存的就是中文
        data={[
          { value: '会议用餐', label: '会议用餐' },
          { value: '非会议用餐', label: '非会议用餐' },
          { value: '内部员工聚餐', label: '内部员工聚餐' }
        ]}
        // options={['会议用餐', '非会议用餐']}
        label="费用类别"
        field="consumptionType"
        rules={{ required: { message: '请选择费用类别', value: true } }}
      />
      <FormInputItem
        className={approvalCodeInput}
        placeholder="请输入OA中的申请格式号"
        label="OA编号"
        field="approvalCode"
        // rules={{
        //   required: {
        //     message: '请输入OA中的申请格式号',
        //     value: true
        //   },
        //   pattern: {
        //     message: 'OA中的申请格式号输入错误',
        //     value: /^(ZH-ZDF|ZH-TJ|ZH-HY)\d{9}$/
        //   }
        // }}
      />
      <FormTextareaItem
        placeholder="请填写宴请事由"
        label="宴请事由"
        field="buyReason"
        maxLength={50}
        rules={{
          required: { message: '请填写宴请事由', value: true },
          maxLength: { message: '宴请事由最多输入50个字符', value: 50 }
        }}
      />
      <FormDeliveryTimePicker
        placeholder="请选择用餐时间"
        label="用餐时间"
        field="time"
        dates={dates}
        rules={{ required: { message: '请选择用餐时间', value: true } }}
      />
      <FormInputItem
        placeholder="请输入用餐人数"
        label="用餐人数"
        field="peopleNum"
        type="number"
        rules={{
          required: { message: '请输入用餐人数', value: true },
          max: { message: '用餐人数最多输入9999', value: 9999 }
        }}
      />
      <FormInputItem
        placeholder="请填写预计消费金额"
        label="预计消费金额"
        field="predictCost"
        type="number"
        rules={{
          required: { message: '请填写预计消费金额', value: true },
          max: { message: '消费金额最多输入1000000', value: 1000000 }
        }}
      />
      <FormTaxPicker
        placeholder="请选择发票抬头"
        label="发票信息"
        field="tax"
        disabled
      />
    </ColumnView>
  )
}

export default DeliveryCard
