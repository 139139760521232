// 用户反馈
import React, { FC, memo, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { TextareaItem, Toast } from 'antd-mobile'

import Button from '../../components/Button'
import Footer from './Footer'
import { useUserFeedback } from '../../api/Feedback'
interface FeedbackParams {
  text: string
}
const MFooter = memo(Footer)

const Feedback: QCPage = () => {
  const [{ data: resData }, submit] = useUserFeedback({
    manual: true
  })
  const { handleSubmit, control, errors } = useForm<FeedbackParams>()
  const onSubmit = (data: FeedbackParams) => {
    submit({ params: { content: data.text } })
  }

  useEffect(() => {
    resData?.data && Toast.show(resData?.data)
  }, [resData?.data])

  return (
    <section className="qc-page">
      <form className="qc-form qc-section-card">
        <Controller
          control={control}
          name="text"
          rules={{ required: { message: '必填', value: true } }}
          defaultValue=""
          render={(props) => (
            <TextareaItem
              placeholder="输入您对产品的意见和看法，我们期待您的声音"
              {...props}
              error={!!errors.text}
              onErrorClick={() => {
                Toast.show(errors.text?.message)
              }}
              className="qc-textarea-item"
            />
          )}
        />
      </form>
      <Button onClick={handleSubmit(onSubmit)} className="qc-page-submit-btn">
        提交
      </Button>
      <MFooter />
    </section>
  )
}

Feedback.config = {
  title: '产品反馈'
}

export default Feedback
