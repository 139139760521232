import { IFormValues } from './interface'
export const formatMustSubmit = (data: IFormValues): boolean => {
  // 只要操作了一个就必须走提交
  // 不评价内容也不打星就直接跳转详情
  return !!(
    data.replyContent ||
    data.sytServiceScore ||
    data.dishScore ||
    data.restaurantScore
  )
}
export const validate = (
  data: IFormValues
): { message: string; success: boolean } => {
  let res = {
    message: '',
    success: true
  }
  // 必须三个评星全选
  if (!(data.restaurantScore && data.dishScore && data.sytServiceScore)) {
    res = {
      message: '请完善评分',
      success: false
    }
    return res
  }
  return res
}
