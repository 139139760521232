import React from 'react'
import { ColumnView, RowView } from '../../components/QCFlex'

const FlexDemo = () => {
  return (
    <ColumnView style={{ height: '100%' }}>
      <RowView style={{ height: '100px' }}>header</RowView>
      <div
        // className="am-flexbox am-flexbox-dir-column"
        style={{
          overflowY: 'scroll',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <RowView style={{ height: '200px' }}>content</RowView>
        <RowView style={{ height: '200px' }}>content</RowView>
        <RowView style={{ height: '200px' }}>content</RowView>
        <RowView style={{ height: '200px' }}>content</RowView>
        <RowView style={{ height: '200px' }}>content</RowView>
        <RowView style={{ height: '200px' }}>content</RowView>
        <RowView style={{ height: '200px' }}>content</RowView>
        <RowView style={{ height: '200px' }}>content</RowView>
        <RowView style={{ height: '200px' }}>content</RowView>
      </div>
      <RowView style={{ height: '100px' }}>footer</RowView>
    </ColumnView>
  )
}

export default FlexDemo
