// frok from ./Input
import React, { FC, memo, useCallback } from 'react'
import FormRow, { FormRowProps } from '../FormRow'
import {
  ConnectForm,
  ConnectFormProps,
  ConnectFormChildrenProps
} from '../../QCForm'
import './index.global.less'
import { useClassNames } from '../../../hooks/classname'
export type NativeSwitchProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>
export interface FormSwitchItemProps
  extends FormRowProps,
    ConnectFormProps,
    NativeSwitchProps {}

export type MSwitchItemProps = Omit<
  ConnectFormChildrenProps,
  'value' | 'onBlur'
>
export interface SwitchItemProps
  extends FormRowProps,
    NativeSwitchProps,
    Omit<ConnectFormChildrenProps, 'value' | 'onBlur'> {
  field?: string
}
type TOnchange = ((checked: boolean) => void) | undefined

const onChangeWrap = (onChange: TOnchange) => (
  e: React.ChangeEvent<HTMLInputElement>
) => {
  onChange && onChange(e.target.checked)
}
export const SwitchItem: FC<SwitchItemProps> = ({
  label,
  className,
  sync,
  register,
  onBlur,
  field,
  rules,
  type,
  ...otherProps
}) => {
  const mergedClassName = useClassNames(['qc-switch', className])

  return (
    <FormRow label={label} required={!!rules?.required}>
      <input
        type="checkbox"
        name={field}
        ref={rules ? register?.(rules) : register}
        className={mergedClassName}
        {...otherProps}
      />
    </FormRow>
  )
}
// 针对 FormProvider 的 带优化的Switchitem
const MSwitchItem: FC<MSwitchItemProps> = memo(
  ({ formState, watch, ...otherProps }) => <SwitchItem {...otherProps} />,
  (prevProps, nextProps) =>
    prevProps.formState?.isDirty === nextProps.formState?.isDirty
)
const FormSwitchItem: FC<FormSwitchItemProps> = (props) => (
  <ConnectForm>
    <MSwitchItem {...props} />
  </ConnectForm>
)
export default FormSwitchItem
