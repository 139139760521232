import React from 'react'

import { ColumnView, XCenterView, RowView } from '../../components/QCFlex'
import {
  FormInputItem,
  FormTextareaItem,
  FormSwitchItem,
  FormCheckboxItem,
  FormRadio
} from '../../components/FormItems'
const UnControlerForm = () => {
  return (
    <ColumnView className="qc-section-card">
      <XCenterView>非受控组件</XCenterView>
      <FormInputItem
        placeholder="请填写姓名"
        label="姓名"
        field="name"
        rules={{
          required: {
            value: true,
            message: '请填写订餐人名'
          }
        }}
      />
      <FormInputItem placeholder="请填写手机号" label="手机号" field="phone" />
      <FormTextareaItem placeholder="请填写备注" label="备注" field="mark" />
      <FormSwitchItem label="开关" field="toggle" />
      <FormCheckboxItem label="多选" field="checkbox" />
      <FormRadio label="单选" field="radio" />
      <RowView>请从下面选出一项</RowView>
      <FormRadio.Group field="group">
        <FormRadio.Item label="选项1" value="a" />
        <FormRadio.Item label="选项2" value="b" />
        <FormRadio.Item label="选项3" value="c" />
      </FormRadio.Group>
    </ColumnView>
  )
}

export default UnControlerForm
