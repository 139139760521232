import React, { FC } from 'react'
import Text from '../Text'
export interface VLineProps {
  gap?: number
  color?: string
  size?: number
  className?: string
}
export const VLine: FC<VLineProps> = ({
  gap = 0,
  color = '#999',
  className = '',
  size = 24
}) => (
  <Text
    margin={[0, gap, 0, gap]}
    color={color}
    size={size}
    className={className}
  >
    |
  </Text>
)
