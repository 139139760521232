import { AxiosRequestConfig } from 'axios'
import makeUseApi from '../utils/makeUseApi'
import { COMPANY_NAME } from '../constants/app'
// 订单列表
export const OrderList: AxiosRequestConfig = {
  url: '/aimee/waimai/orderList',
  method: 'POST'
}
export const useOrderList = makeUseApi<
  Definitions.Aimee.PageRequest,
  Definitions.Aimee.RestResponse
>(OrderList)

// 订单详情
export const OrderDetail: AxiosRequestConfig = {
  url: '/aimee/waimai/orderDetail',
  headers: {
    Company: COMPANY_NAME
  },
  method: 'POST'
}
export const useOrderDetail = makeUseApi<
  Definitions.Aimee.OrderIdRequest,
  Definitions.Aimee.RestResponse
>(OrderDetail)

// 下单
export const BookOrder: AxiosRequestConfig = {
  url: '/aimee/Waimai/orderBook',
  headers: {
    Company: COMPANY_NAME,
    // TODO
    VersionCode: '5.1.6'
  },
  method: 'POST'
}
export const useBookOrder = makeUseApi<
  Definitions.Aimee.WaimaiOrderFromApp,
  Definitions.Aimee.RestResponse
>(BookOrder)

// 确认订单
export const ComfirmOrder: AxiosRequestConfig = {
  url: '/aimee/Waimai/confirmOrder',
  headers: {
    Company: COMPANY_NAME
  },
  method: 'POST'
}
export const useComfirmOrder = makeUseApi<
  Definitions.Aimee.确认订单DTO,
  Definitions.Aimee.RestResponse
>(ComfirmOrder)

// 获取配送费
export const GetDeliveryTime: AxiosRequestConfig = {
  url: '/aimee/Waimai/getDeliveryTime',
  headers: {
    Company: COMPANY_NAME
  },
  method: 'POST'
}
export const useGetDeliveryTime = makeUseApi<
  Definitions.Aimee.PreOrderInfoRequest,
  Definitions.Aimee.RestResponseOfPreOrderInfo
>(GetDeliveryTime)
