import React, { FC, memo } from 'react'
import { Accordion, Flex } from 'antd-mobile'
import Text from '../../components/Text'
import { container, card, cell } from './index.less'
import { ColumnView } from '../../components/QCFlex'
import { useInvoiceList } from '../../api/Invoice'

interface ICell {
  label?: string
  value?: string
}

const Cell: FC<ICell> = ({ label, value }) => (
  <Flex direction="column" align="start" className={cell}>
    <Text color="#999999FF" size={26}>
      {label}
    </Text>
    <Text color="#181818FF" size={30}>
      {value ?? '-'}
    </Text>
  </Flex>
)

const InvoiceCard: FC<Definitions.Aimee.ClientInvoiceResult> = memo(
  ({
    invoiceTitle,
    taxIdentificationNumber,
    taxRegistrationAddress,
    taxContactPhone,
    taxBankAccount,
    taxBankName
  }) => (
    <>
      <Cell label="发票抬头" value={invoiceTitle} />
      <Cell label="纳税人识别号" value={taxIdentificationNumber} />
      <Cell label="纳税登记地址" value={taxRegistrationAddress} />
      <Cell label="联系电话" value={taxContactPhone} />
      <Cell label="开户银行" value={taxBankName} />
      <Cell label="银行账户" value={taxBankAccount} />
    </>
  )
)
InvoiceCard.displayName = 'MInvoiceCard'
const Title: FC<{ text?: string }> = memo(({ text }) => (
  <Text color="#181818FF" size={34}>
    {text}
  </Text>
))
Title.displayName = 'MTitle'
const EnterpriseInvoiceInfo: QCPage = () => {
  const mergedClassName = `qc-section-card ${card}`
  const [{ data }] = useInvoiceList()
  const list = data?.data
  return (
    <ColumnView className={container}>
      {list && (
        <Accordion defaultActiveKey={list?.[0].id?.toString()}>
          {list?.map((item) => (
            <Accordion.Panel
              key={item.id?.toString()}
              className={mergedClassName}
              header={<Title text={item.invoiceTitle} />}
            >
              <InvoiceCard {...item} />
            </Accordion.Panel>
          ))}
        </Accordion>
      )}
    </ColumnView>
  )
}

EnterpriseInvoiceInfo.config = {
  title: '企业开票信息'
}

export default EnterpriseInvoiceInfo
