import React, { FC, memo, useEffect, useState } from 'react'
import { RowView } from '../../components/QCFlex'
import Icon from '../../components/Icon'
import FormRow, { FormRowProps } from '../../components/FormItems/FormRow'
import {
  ConnectForm,
  ConnectFormProps,
  ConnectFormChildrenProps
} from '../../components/QCForm'
import PoiSearch from './PoiSearch'
import { SearchPOI } from '../../api/Geolocation'
type BasePoiPickerProps = FormRowProps

export interface FormPoiPickerProps
  extends BasePoiPickerProps,
    ConnectFormProps {}

export interface PoiPickerProps
  extends BasePoiPickerProps,
    Omit<ConnectFormChildrenProps, 'onBlur'>,
    ConnectFormProps {}
export const PoiPicker: FC<PoiPickerProps> = ({
  label,
  sync,
  placeholder,
  disabled,
  field,
  value
}) => {
  const [open, updateOpen] = useState<boolean>(false)
  const [address, updateAddress] = useState<SearchPOI>(value)

  const handleChange = (item?: SearchPOI) => {
    console.log('handleChange', item)
    item &&
      updateAddress(() => {
        sync && sync(item)
        return item
      })
    updateOpen((status) => !status)
  }
  const handleClick = () => {
    !disabled && updateOpen((status) => !status)
  }
  const isPlaceholder = !address
  const textClassName = isPlaceholder ? 'qc-input-placeholder' : ''
  useEffect(() => {
    updateAddress(value)
  }, [value])
  return (
    <>
      <FormRow label={label}>
        <RowView vAlign="center" hAlign="end">
          <span className={textClassName} onClick={handleClick}>
            {isPlaceholder ? placeholder : address.name}
          </span>
          <Icon name="iconyoujiantou" size={18} color="#888888FF" />
        </RowView>
      </FormRow>
      {/* 这里有个小警告 */}
      {/* ant searchbar是用form包裹的 */}
      {/* 嵌入到Form表单时会有以下warning */}
      {/* Warning: validateDOMNesting(...): <form> cannot appear as a descendant of <form> */}
      <PoiSearch onChange={handleChange} open={open} />
    </>
  )
}

const MPoiPicker: FC<ConnectFormChildrenProps> = memo(
  ({ formState, watch, ...otherProps }) => <PoiPicker {...otherProps} />,
  (prevProps, nextProps) =>
    prevProps.formState?.isDirty === nextProps.formState?.isDirty
)
MPoiPicker.displayName = 'MPoiPicker'
const FormPoiPicker: FC<FormPoiPickerProps> = ({ field, ...otherProps }) => (
  <ConnectForm field={field}>
    <PoiPicker {...otherProps} />
  </ConnectForm>
)

export default FormPoiPicker
