import React, { FC } from 'react'
import { page } from './index.less'

const Page: FC<{ style?: React.CSSProperties }> = ({ children, style }) => {
  return (
    <div className={page} style={style}>
      {children}
    </div>
  )
}

export default Page
