import React, {
  FC,
  memo,
  useState,
  useCallback,
  useContext,
  useEffect
} from 'react'
import { ConnectForm } from '../../QCForm'
import {
  RadioGroupProps,
  MRadioItemProps,
  FormRadioGroupProps
} from './interface'
import { RadioGroupContext } from './context'
const RadioGroupContextProvider = RadioGroupContext.Provider
const RadioGroup: FC<RadioGroupProps> = ({
  onChange,
  children,
  field,
  defaultValue
}) => {
  const [groupValue, updateGroupValue] = useState<any>(defaultValue)
  const handleRadioGroupChange = useCallback((radioValue) => {
    console.log('groupRadioValue', radioValue)
    // updateGroupValue((gVlaue: any) => {
    //   onChange && onChange(radioValue)
    //   return radioValue
    // })
    updateGroupValue(radioValue)
  }, [])
  const childrenToRender = children
  useEffect(() => {
    onChange && onChange(groupValue)
  }, [groupValue])
  return (
    <RadioGroupContextProvider
      value={{
        value: groupValue,
        sync: handleRadioGroupChange,
        field
      }}
    >
      {childrenToRender}
    </RadioGroupContextProvider>
  )
}

// 针对 FormProvider 的 带优化的Radioitem
const MRadioGroup: FC<MRadioItemProps> = memo(
  ({ formState, watch, sync, ...otherProps }) => (
    <RadioGroup {...otherProps} onChange={sync} />
  ),
  (prevProps, nextProps) =>
    prevProps.formState?.isDirty === nextProps.formState?.isDirty
)
const FormRadioGroup: FC<FormRadioGroupProps> = ({ field, ...otherProps }) => (
  <ConnectForm field={field} rules={otherProps.rules}>
    <MRadioGroup {...otherProps} />
  </ConnectForm>
)

export default FormRadioGroup
