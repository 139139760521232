import { AxiosRequestConfig } from 'axios'
import makeUseApi from '../utils/makeUseApi'
// 地址列表

export const AddressList: AxiosRequestConfig = {
  url: '/europe/getAddressList',
  method: 'POST'
}
export const useAddressList = makeUseApi<
  Definitions.Europe.DeliveryAddressRequest,
  Definitions.Europe.SearchResponseOfDeliveryAddressResult
>(AddressList)

// 地址编辑
export const AddressEdit: AxiosRequestConfig = {
  url: '/europe/address',
  method: 'PUT'
}
export const useAddressEdit = makeUseApi<
  Definitions.Europe.DeliveryAddressChangeRequest,
  Definitions.Europe.SearchResponseOfDeliveryAddressChangeResult
>(AddressEdit)

// 地址添加
export const AddressAdd: AxiosRequestConfig = {
  url: '/europe/address',
  method: 'POST'
}
export const useAddressAdd = makeUseApi<
  Definitions.Europe.DeliveryAddressChangeRequest,
  Definitions.Europe.SearchResponseOfDeliveryAddressChangeResult
>(AddressAdd)
// 地址详情
export const AddressDetail: AxiosRequestConfig = {
  url: '/europe/address',
  method: 'GET'
}
export const useAddressDetail = makeUseApi<
  { id?: number },
  Definitions.Europe.SearchResponseOfDeliveryAddressChangeRequest
>(AddressDetail)
