import React, { FC, CSSProperties, Ref } from 'react'
import { useBaseStyle, BaseStyleProps } from '../../hooks/layout'
import { useClassNames } from '../../hooks/classname'
import './index.global.less'
export type TAlign = 'start' | 'end' | 'center' | 'between' | 'around'
export type TDirection = 'row' | 'column'
export type TWrap = 'wrap' | 'nowrap'
export interface QCFlexProps
  extends React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    BaseStyleProps {
  vAlign?: TAlign
  hAlign?: TAlign
  wrap?: TWrap
  direction?: TDirection
  qcRef?: Ref<HTMLDivElement>
}
const QCFlex: FC<QCFlexProps> = ({
  style,
  padding,
  margin,
  height,
  width,
  bgColor,
  className,
  direction = 'row',
  vAlign,
  hAlign,
  qcRef,
  wrap,
  ...props
}) => {
  const baseStyle: CSSProperties = useBaseStyle({
    padding,
    margin,
    height,
    width,
    bgColor
  })
  const mergedClassName = useClassNames([
    'qc-flex',
    direction === 'column' ? 'columnview' : 'rowview',
    vAlign && `v-${vAlign}`,
    hAlign && `h-${hAlign}`,
    wrap && `flex-${wrap}`,
    className
  ])
  const mergedStyle: CSSProperties = Object.assign(baseStyle, style)

  return (
    <div
      {...props}
      ref={qcRef}
      className={mergedClassName}
      style={mergedStyle}
    />
  )
}

export const ColumnView: FC<Omit<QCFlexProps, 'direction'>> = ({
  ...props
}) => {
  return <QCFlex {...props} direction="column" />
}
export const RowView: FC<Omit<QCFlexProps, 'direction'>> = ({ ...props }) => (
  <QCFlex {...props} direction="row" />
)
export const XCenterView: FC<Omit<
  QCFlexProps,
  'direction' | 'hAlign' | 'vAlign'
>> = (props) => (
  <QCFlex {...props} direction="row" hAlign="center" vAlign="center" />
)
export default QCFlex
