import React, { FC } from 'react'
import { Modal } from 'antd-mobile'
import { ModalProps } from 'antd-mobile/lib/modal/Modal'
import './index.global.less'
export interface QCModalProps extends ModalProps {}

const QCModal: FC<QCModalProps> = ({ prefixCls, children, ...otherProps }) => {
  const mergedPrefixCls = [prefixCls, 'qc-modal', 'am-modal'].join(' ')
  return (
    <Modal prefixCls={mergedPrefixCls} {...otherProps}>
      {children}
    </Modal>
  )
}

export default QCModal
