import React, { FC, memo, useState, useCallback, useMemo } from 'react'
import { DatePicker as AMDateOicker } from 'antd-mobile'
import { DatePickerPropsType } from 'antd-mobile/lib/date-picker/PropsType'

import Icon from '../../Icon'
import FormRow, { FormRowProps } from '../FormRow'
import {
  ConnectForm,
  ConnectFormProps,
  ConnectFormChildrenProps
} from '../../QCForm'
interface BaseDatePickerProps extends DatePickerPropsType, FormRowProps {}

export interface FormDatePickerProps
  extends BaseDatePickerProps,
    ConnectFormProps {}

export interface DatePickerProps
  extends BaseDatePickerProps,
    Omit<ConnectFormChildrenProps, 'value' | 'onBlur'>,
    ConnectFormProps {}
interface ShowTextProps {
  placeholder?: string
  onClick?: () => void
  extra?: string
}
const ShowText: FC<ShowTextProps> = memo(
  ({ onClick, extra, placeholder }) => {
    const isPlaceholder = useMemo(() => extra === placeholder, [extra])
    const textClassName = isPlaceholder ? 'qc-input-placeholder' : ''
    return (
      <span className={textClassName} onClick={onClick}>
        {isPlaceholder ? placeholder : extra}
        <Icon name="iconyoujiantou" size={20} color="rgb(136, 136, 136)" />
      </span>
    )
  },
  (prevProps, nextProps) =>
    prevProps.extra === nextProps.extra &&
    prevProps.placeholder === nextProps.placeholder
)
export const DatePicker: FC<DatePickerProps> = ({
  label,
  sync,
  placeholder,
  disabled,
  value,
  rules,
  mode = 'date',
  ...otherProps
}) => {
  const [date, updateDate] = useState<Date | undefined>(value)

  const handleChange = useCallback(
    (date) => {
      updateDate((text) => {
        sync && sync(date)
        return date
      })
    },
    [sync]
  )

  return (
    <FormRow label={label} required={!!rules?.required}>
      <AMDateOicker
        {...otherProps}
        disabled={disabled}
        onChange={handleChange}
        mode={mode}
        value={date}
        // 先自定义个extra用来判断placeholder
        extra={placeholder}
      >
        <ShowText placeholder={placeholder} />
      </AMDateOicker>
    </FormRow>
  )
}

const MDatePicker: FC<ConnectFormChildrenProps> = memo(
  ({ formState, watch, ...otherProps }) => <DatePicker {...otherProps} />,
  (prevProps, nextProps) =>
    prevProps.formState?.isDirty === nextProps.formState?.isDirty
)
const FormDatePicker: FC<FormDatePickerProps> = ({ field, ...otherProps }) => (
  <ConnectForm field={field} rules={otherProps.rules}>
    <MDatePicker {...otherProps} />
  </ConnectForm>
)

export default FormDatePicker
