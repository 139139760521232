import { AxiosRequestConfig } from 'axios'
import makeUseApi from '../utils/makeUseApi'

export const queryCityByCoords: AxiosRequestConfig = {
  url: `/v3/geocode/regeo?key=${process.env.AMAP_KEY}`,
  baseURL: 'https://restapi.amap.com',
  method: 'GET'
}

export type QueryCityByCoordsRequest = {
  location?: string
  poitype?: string
}
export interface IStreetNumber {
  direction: string
  distance: string
  location: string
  number: string
  street: string
}
export interface AddressComponent {
  country: string | string[]
  province: string | string[]
  city: string | string[]
  streetNumber: IStreetNumber
}
export interface POI {
  formatted_address?: string
}

export interface Regeocode {
  formatted_address?: string
  addressComponent?: AddressComponent
  pois: POI[]
}

export type QueryCityByCoordsResponse = {
  status: string
  info: string
  infocode: string
  regeocode: Regeocode
}

export const useQueryCityByCoords = makeUseApi<
  QueryCityByCoordsRequest,
  QueryCityByCoordsResponse
>(queryCityByCoords)
// 关键字搜索
export type SearchPOI = {
  id: string
  address?: string
  location?: string
  name?: string
}
export type SearchPointsByKeywordsRequest = {
  keywords?: string
  types?: string
  city?: string
  offset?: number
  page?: number
  location?: string
}
export type SearchPointsByKeywordsResponse = {
  status: string
  info: string
  infocode: string
  pois: SearchPOI[]
}
export const searchPointsByKeywords: AxiosRequestConfig = {
  url: `/v3/place/text?key=${process.env.AMAP_KEY}`,
  baseURL: 'https://restapi.amap.com',
  method: 'GET'
}
export const useSearchPointsByKeywords = makeUseApi<
  QueryCityByCoordsRequest,
  SearchPointsByKeywordsResponse
>(searchPointsByKeywords)

// 经纬度转化
export type TCoordsys = 'gps' | 'mapbar' | 'baidu' | 'autonavi'
export interface ConverFromRequest {
  locations: string
  // 原坐标系，可选值：gps;mapbar;baidu;autonavi(不进行转换)
  // 默认autonavi
  coordsys?: TCoordsys
}
export interface ConverFromResponse {
  status: string
  info: string
  infocode: string
  locations: string
}
export const ConvertFrom: AxiosRequestConfig = {
  url: `/v3/assistant/coordinate/convert?key=${process.env.AMAP_KEY}`,
  baseURL: 'https://restapi.amap.com',
  method: 'GET'
}
export const useConvertFrom = makeUseApi<ConverFromRequest, ConverFromResponse>(
  ConvertFrom
)
