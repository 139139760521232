// 外卖
import TakeawayOrderList from '../../views/TakeawayOrderList'
import TakeawayOrderApply from '../../views/TakeawayOrderApply'
import TakeawayOrderApplyResult from '../../views/TakeawayOrderApplyResult'
import TakeawayOrderDetail from '../../views/TakeawayOrderDetail'
import TakeawayOrderPayResult from '../../views/TakeawayOrderPayResult'
import TakeawayRestaurantSearch from '../../views/TakeawayRestaurantSearch'
import TakeawayOrderEvaluation from '../../views/TakeawayOrderEvaluation'

const Routers = [
  {
    name: 'TakeawayOrderList',
    path: '/takeawayOrderList',
    component: TakeawayOrderList
  },
  {
    name: 'TakeawayOrderApply',
    path: '/takeawayOrderApply',
    component: TakeawayOrderApply
  },
  {
    name: 'TakeawayOrderApplyResult',
    path: '/takeawayOrderApplyResult',
    component: TakeawayOrderApplyResult
  },
  {
    name: 'TakeawayOrderDetail',
    path: '/takeawayOrderDetail/:orderId',
    component: TakeawayOrderDetail
  },
  {
    name: 'TakeawayOrderPayResult',
    path: '/takeawayOrderPayResult',
    component: TakeawayOrderPayResult
  },
  {
    name: 'TakeawayRestaurantSearch',
    path: '/takeawayRestaurantSearch',
    component: TakeawayRestaurantSearch
  },
  {
    name: 'TakeawayOrderEvaluation',
    path: '/takeawayOrderEvaluation/:orderId',
    component: TakeawayOrderEvaluation
  }
]

export default Routers
