import dayjs from 'dayjs'
import { IFormValues } from './interface'
interface FormatSubmitDataProps {
  formData: IFormValues
  rid: string
  orderType?: 'reservation' | 'unsigned',
  tradeType?: string
}
export const formatSubmitData = ({
  formData,
  rid,
  orderType = 'reservation',
  tradeType
}: FormatSubmitDataProps): Definitions.Aimee.YudingOrderFromApp => {
  return {
    acceptOrderType: 'null',
    time: dayjs(formData.time).format('HH:mm'),
    predictCost: formData.predictCost?.toString(),
    isHall: formData.isHall as any,
    taxIdentificationNumber: formData.tax?.taxIdentificationNumber,
    costCenterId: formData.costCenterId,
    approvalCode: formData.approvalCode,
    consumptionType: formData.consumptionType,
    orderType,
    tradeType,
    date: dayjs(formData.time).format('YYYY-MM-DD'),
    peopleNum: formData.peopleNum?.toString(),
    message: formData.message,
    isRooms: formData.isRooms as any,
    restaurantId: rid,
    invoiceTitle: formData.tax?.invoiceTitle,
    buyReason: formData.buyReason,
    confirmOrder: true as any
  }
}
