import React, { FC } from 'react'
import { ItemCell } from '../../components/Cell'
import { ColumnView } from '../../components/QCFlex'
import Text from '../../components/Text'

const InvoiceCard: FC<{ detail: QCOrderInfo }> = ({ detail }) => {
  return (
    <ColumnView className="qc-section-card">
      <ItemCell
        label={
          <Text color="#999999FF" size={34}>
            开票信息
          </Text>
        }
        value={
          <Text color="#FF9847FF" size={26}>
            请要求服务员按以下信息进行开票
          </Text>
        }
        align="start"
        arrow={false}
      />
      <ItemCell
        label="开票金额"
        value={`¥${detail?.money || ''}`}
        arrow={false}
      />
      <ItemCell label="发票抬头" value={detail?.invoice_title} arrow={false} />
      <ItemCell
        label="发票税号"
        value={detail?.tax_identification_number}
        arrow={false}
      />
    </ColumnView>
  )
}

export default InvoiceCard
