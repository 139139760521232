import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { ColumnView, RowView, XCenterView } from '../../../components/QCFlex'
import Text from '../../../components/Text'
import h2r from '../../../utils/hex2rgba'

import { tag, avtar, cardWarp, card } from './index.less'

export interface TagProps {
  color?: string
  text?: string
}
export interface FoodCardProps {
  label?: string
  dsc?: string
  type?: string
  color?: string
  avtar?: string
  path?: string
}
export interface AvtarProps {
  url?: string
}
const Tag: FC<TagProps> = ({ color, text }) => (
  <XCenterView className={tag} bgColor={color}>
    {text}
  </XCenterView>
)

const Avtar: FC<AvtarProps> = ({ url }) => <img className={avtar} src={url} />

const FoodCard: FC<FoodCardProps> = ({
  label,
  dsc,
  type,
  color = '#ffffff',
  avtar,
  path = ''
}) => {
  return (
    <Link to={path}>
      <RowView className={cardWarp}>
        <Avtar url={avtar} />
        <ColumnView vAlign="center" className={card} bgColor={h2r(color, 0.1)}>
          <Text size={34} color={color}>
            {label}
          </Text>
          <Text size={28} color={h2r(color, 0.4)}>
            {dsc}
          </Text>
          <Tag color={color} text={type} />
        </ColumnView>
      </RowView>
    </Link>
  )
}

export default FoodCard
