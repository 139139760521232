import React, { FC } from 'react'
import { ItemCell } from '../../components/Cell'
import Text from '../../components/Text'
import { ColumnView } from '../../components/QCFlex'
const BookingCard: FC<Definitions.Europe.BookInfo> = ({
  boxNum,
  boxMinConsume,
  park,
  boxServiceFee
}) => {
  const boxNumValue = boxNum ? `${boxNum}间` : '无'
  const boxServiceFeeValue = boxServiceFee ? boxServiceFee : '无服务费'
  const boxMinConsumeValue = boxMinConsume ? boxMinConsume : '无最低消费'
  const parkValue = park ? park : '暂不提供停车'

  return (
    <ColumnView className="qc-section-card">
      <ItemCell
        value={
          <Text size={34} color="#999999FF">
            商宴信息
          </Text>
        }
        align="start"
        arrow={false}
      />
      <ItemCell
        label="包厢数量"
        value={boxNumValue}
        arrow={false}
        align="start"
      />
      <ItemCell
        label="服务费用"
        value={boxServiceFeeValue}
        arrow={false}
        align="start"
      />
      <ItemCell
        label="包厢低消"
        value={boxMinConsumeValue}
        arrow={false}
        align="start"
      />
      <ItemCell
        label="停车信息"
        value={parkValue}
        arrow={false}
        align="start"
      />
    </ColumnView>
  )
}

export default BookingCard
