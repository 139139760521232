import React, { FC } from 'react'
import { Picker as AtPicker, List, Flex } from 'antd-mobile'
import { PickerPropsType } from 'antd-mobile/lib/picker/PropsType'
import isEmpty from 'lodash.isempty'
import { groupPicker, hasValue } from './index.less'
import { useClassNames } from '../../hooks/classname'
import { ListItemPropsType } from 'antd-mobile/lib/list/PropsType'

export type PickerProps = { label?: string; error?: boolean } & PickerPropsType

const PickerItem: FC<{
  className?: string
  label?: string
  extra?: string
  error?: boolean
}> = ({ label, className, extra, error, ...props }) => {
  return (
    <List.Item
      {...props}
      className={className}
      extra={
        <Flex direction="row" justify="end">
          {extra}
          {error && <div className="am-input-error-extra" />}
        </Flex>
      }
    >
      {label}
    </List.Item>
  )
}

const Picker: FC<PickerProps> = ({ label, error, ...props }) => {
  const className = useClassNames([
    groupPicker,
    !isEmpty(props.value) ? hasValue : null,
    'am-input-error'
  ])

  return (
    <AtPicker {...props}>
      <PickerItem label={label} className={className} error={error} />
    </AtPicker>
  )
}

export default Picker
