import React, { FC, useEffect, useRef, useState } from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useGlobalStores } from '../../hooks/store'
import { AccountStatus } from '../../generated/graphql'
import QCPullRefresh from '../Layout/QCPullRefresh'
import PullRefreshHeaderView from '../PullRefreshHeaderView'
import { pullRefeshContext } from '../../contexts'
import { GetQueryString } from '../../utils/lodash'
const QCRoute: FC<RouteProps & { needAuth?: boolean }> = ({
  children,
  render,
  needAuth,
  ...rest
}) => {
  const { userStore, cityStore } = useGlobalStores()
  const isActive = userStore.isActive
  const isLoggedIn = userStore.isLoggedIn
  // 下拉刷新相关
  // 套到QCRoute这里是因为需要每次切换的时候先重置pullRefreshDisabled
  // 套到最外层app.tsx需要手动维护
  const [pullRefreshDisabled, updatePullRefreshDisabled] = useState<boolean>(
    true
  )
  const pullRefreshCallbackRef = useRef<() => void>()
  const callback = (func?: () => void) => {
    // 控制台会有个警告,无视
    // Warning: Cannot update a component from inside the function body of a different component
    updatePullRefreshDisabled(!func)
    pullRefreshCallbackRef.current = func
  }
  console.log('pullRefreshDisabled', pullRefreshDisabled)
  return (
    <Route
      {...rest}
      render={(props) => {
        let redirect: string | null = null
        const state: any = null
        const token = GetQueryString('token')
        if (token) {
          userStore.setToken(token)
        }
        if (isLoggedIn) {
          if (!isActive) {
            redirect =
              userStore.profile?.status === AccountStatus.AccountReviewPending
                ? '/registerSuccess'
                : '/completeUserInfo'
          }
          else if (userStore.bindType?.state != '' && userStore.bindType?.state != 'SUCCESS' && window.location?.pathname != '/BindEntyCode') {
            redirect = '/BindEntyCode'
          }

          // else if (
          //   !cityStore.info.id &&
          //   location.pathname !== '/cityLocation'
          // ) {
          //   redirect = '/cityLocation'
          //   state = { first: true }
          // }
        } else if (needAuth) {
          !['/login', '/register'].includes(location.pathname) &&
            (redirect = '/login')
        }

        return (
          <QCPullRefresh
            // hack 躲过ts校验
            disabled={pullRefreshDisabled}
            damping={60}
            getScrollContainer={() => undefined}
            indicator={{
              activate: <PullRefreshHeaderView status="activate" />,
              deactivate: <PullRefreshHeaderView status="deactivate" />,
              release: <PullRefreshHeaderView status="release" />,
              finish: <PullRefreshHeaderView status="finish" />
            }}
            // 塞个值只是为了过ts
            // getScrollContainer={() => <div />}
            direction="down"
            onRefresh={() => {
              pullRefreshCallbackRef?.current?.()
            }}
            distanceToRefresh={25}
          >
            <pullRefeshContext.Provider value={{ callback }}>
              {redirect && location.pathname !== redirect && !token ? (
                <Redirect
                  from={location.pathname}
                  to={{
                    pathname: redirect,
                    state
                  }}
                />
              ) : (
                children || render?.(props)
              )}
            </pullRefeshContext.Provider>
          </QCPullRefresh>
        )
      }}
    />
  )
}

export default observer(QCRoute)
