import { FormValues } from './interface'

export const formatData = (
  data: FormValues,
  cityId: string,
  addressId?: string
): Definitions.Europe.DeliveryAddressChangeRequest => {
  const [lng, lat] = data.landmark?.location?.split(',')
  return {
    address: data.address,
    addressId: addressId ? Number(addressId) : undefined,
    cityId: Number(cityId),
    landmarkAddress: data.landmark?.address,
    landmark: data.landmark?.name,
    lat,
    lng
  }
}
export const validate = (formData: FormValues, len = 0) => {
  let res = {
    message: '',
    success: true
  }
  // landmark
  if (!formData.landmark) {
    res = {
      message: '请选择一个地址',
      success: false
    }
    return res
  }
  // address
  if (!formData.address) {
    res = {
      message: '请输入门牌号',
      success: false
    }
    return res
  }
  // 门牌号长度限制
  if (len && formData.address.length > len) {
    res = {
      message: `门牌号不能超出${len}个字符`,
      success: false
    }
    return res
  }

  return res
}
