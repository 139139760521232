import React, { FC } from 'react'
import Button from '../../components/Button'
import { XCenterView } from '../../components/QCFlex'
import { actionBar } from './index.less'
interface ActionBarProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}
const ActionBar: FC<ActionBarProps> = ({ onClick }) => {
  return (
    <XCenterView className={actionBar}>
      <Button onClick={onClick}>上传</Button>
    </XCenterView>
  )
}

export default ActionBar
