import React, { useEffect, useState } from 'react'
import QCImage from '../../components/QCImage'
import * as codeCss from './index.less'
import { browser } from '../../utils/weixin'
import { useForm, Controller } from 'react-hook-form'
import { Router, useHistory, useLocation } from 'react-router-dom'
import { Flex, InputItem, Toast } from 'antd-mobile'
import { useRestToastFail } from '../../hooks/toast'
import { useQueryAuthorType } from '../../api/Author'
import Button from '../../components/Button'
import Text from '../../components/Text'
import AvLogo from '../../assets/logo.png'
import { usebindAuthor } from '../../api/Author'
import { useGlobalStores } from '../../hooks/store'
import * as qs from 'qs'

interface CodeParams {
    identity: string
}
interface IUrlState {
    authorStatus?: any
}
const BindEntyCode: QCPage = () => {
    const history = useHistory()
    const { userStore } = useGlobalStores()
    const { handleSubmit, reset, control, errors } = useForm<CodeParams>()
    const [isbound, setIsbound] = useState(false)
    const [, bindAuthor] = usebindAuthor({
        manual: true
    })
    const [, authorType] = useQueryAuthorType({
        manual: true,
        data: {
            returnUrl: window.location.protocol + "//" + window.location.host + "/BindEntyCode"
        }
    })
    useEffect(() => {
        if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
            window.addEventListener('pageshow', function () {
                console.log("我是ios返回刷新")
                if (localStorage.getItem('isfirstBind') == 'true') {
                    localStorage.removeItem('isfirstBind')
                    checkBindType()
                }
            });
        }
        else if (navigator.userAgent.match(/android/i)) {
            if (localStorage.getItem('isfirstBind') == 'true') {
                localStorage.removeItem('isfirstBind')
                checkBindType()

            }
        }

    }, [])
    const checkBindType = () => {
        authorType().then(res => {
            reset()
            if (res.data?.status == 0) {
                console.log("我是需要重新reload的")
                userStore.setBindType(res.data?.data)
                if (res.data?.data?.state == 'SUCCESS') {
                    setIsbound(true)
                    // Toast.success('绑定成功，请进入首页', 2)
                    history.replace('/')
                } else {
                    setIsbound(false)
                    // Toast.fail('开通企业支付码失败，请联系 商宴通运营人员，联系电话：138xxxxxxx', 2)
                }
            }
        })
    }
    const onSubmit = (data: CodeParams) => {
        const url = window.location.protocol + "//" + window.location.host + "/BindEntyCode"

        bindAuthor({
            data: qs.stringify({
                ...data,
                identityType: 'ALIPAY_LOGON_ID',
                returnUrl: url
            })
        }).then(res => {
            if (res?.data?.status == 0) {
                const sdata = res.data?.data
                if (sdata?.state != 'SUCCESS') {
                    console.log("我不是授权成功状态")
                    const url = window.location.protocol + "//" + window.location.host + "/CheckAuthor?type=author&token=" + localStorage.getItem('yinglian_up_token') + "&url=" + sdata?.url
                    window.location.href = url
                }
            } else Toast.fail(res?.data?.message)


        })

    }
    const goHome = () => {
        history.replace('/')
    }

    return (
        <div className={codeCss.bindcode}>
            <div className={codeCss.tip}>
                <QCImage src={AvLogo} className={codeCss.img} />
                <Flex direction="column" align="start">
                    <Text color="#333" size={32} margin={[0, 0, 30, 0]}>
                        应公司合规的要求，邀请您绑定企业码，便于后续餐饮的预定及支付。
                    </Text>
                    <Text color="#333" size={30} margin={[0, 0, 30, 0]}>
                        第一步、跳转商业通绑定企业码；
                    </Text>
                    <Text color="#333" size={30} margin={[0, 0, 30, 0]}>
                        第二步、授权绑定商业通企业码；
                    </Text>
                    <Text color="#333" size={30} margin={[0, 0, 60, 0]}>
                        第二步、进入商宴通进行预订；
                    </Text>
                </Flex>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} id='form'>
                <Text color="#333" size={36} margin={[0, 0, 10, 0]} style={{ display: 'inline-block' }}>请输入您支付宝账号：</Text>
                <Flex
                    direction="row"
                >
                    <Controller
                        control={control}
                        name="identity"
                        rules={{ required: { message: '支付宝账号必填', value: true } }}
                        defaultValue=""
                        render={(props) => (
                            <InputItem
                                className={codeCss.setCon}
                                placeholder="支付宝 | 手机号| 邮箱"
                                error={!!errors.identity}
                                onErrorClick={() => {
                                    Toast.show(errors.identity?.message)
                                }}
                                {...props}
                                id="bindform"
                            >
                            </InputItem>
                        )}
                    />
                    <Button type="submit" className={codeCss.authoriz} disabled={isbound}>
                        确认授权
                    </Button>
                </Flex>

                <Flex
                    alignContent="center"
                    align="center"
                    justify="center"
                    direction="row"
                >
                    <Button className={codeCss.gohome} onClick={goHome} disabled={!isbound}>
                        进入首页
                    </Button>
                </Flex>
            </form>
        </div>

    )
}

BindEntyCode.config = {
    title: '绑定商宴通企业码'
}

export default BindEntyCode