import { observable, action } from 'mobx'
import { persist } from 'mobx-persist'

const MAX_USED_CITY_LENGTH = 4

export class CityInfo {
  @persist
  @observable
  name = ''

  @persist
  @observable
  id = ''
}

export class CityStore {
  @persist('object', CityInfo)
  @observable
  info = new CityInfo()

  @persist('list', CityInfo)
  @observable
  used: CityInfo[] = []

  @action
  addToUsed(city: CityInfo) {
    if (!city.name || !city.id) return
    if (this.used.findIndex((c) => c.id === city.id) >= 0) {
      return
    }
    if (this.used.length >= MAX_USED_CITY_LENGTH) {
      this.used = this.used.slice(1, MAX_USED_CITY_LENGTH)
    }
    this.used.push(city)
  }
}
