import React, { FC } from 'react'
import { XCenterView } from '../QCFlex'
import Text from '../Text'

const PullRefreshHeaderView: FC<{
  status?: 'activate' | 'deactivate' | 'release' | 'finish'
}> = ({ status }) => (
  <XCenterView>
    {status === 'activate' && <Text>松开下拉刷新...</Text>}
    {status === 'release' && <Text>刷新中</Text>}
  </XCenterView>
)
export default PullRefreshHeaderView
