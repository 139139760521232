import React, { FC, memo, useEffect, useState } from 'react'
import { RowView } from '../../components/QCFlex'
import Icon from '../../components/Icon'
import FormRow, { FormRowProps } from '../../components/FormItems/FormRow'
import {
  ConnectForm,
  ConnectFormProps,
  ConnectFormChildrenProps
} from '../../components/QCForm'
import { usePagePickerRouter } from '../../hooks/pagePickerRouter'
import { addressText } from './index.less'
interface BaseAddressPickerProps extends FormRowProps {
  // 最外层form表单传入此属性保证在跳出表单时保存表单值
  onPush?: () => void
  state?: IAddressStateProps
}
interface IAddressStateProps {
  rid?: string
}
export interface FormAddressPickerProps
  extends BaseAddressPickerProps,
    ConnectFormProps {}

export interface AddressPickerProps
  extends BaseAddressPickerProps,
    Omit<ConnectFormChildrenProps, 'onBlur'>,
    ConnectFormProps {}
export const AddressPicker: FC<AddressPickerProps> = ({
  label,
  sync,
  placeholder,
  disabled,
  onPush,
  state,
  field,
  rules,
  value
}) => {
  const onPop = (item: Definitions.Europe.DeliveryAddress) => {
    sync && sync(item)
  }
  const pagePickerRouter = usePagePickerRouter<
    Definitions.Europe.DeliveryAddress,
    IAddressStateProps
  >({ field, onPop })

  const handleClick = () => {
    if (!disabled) {
      onPush && onPush()
      pagePickerRouter.push('/shippingAddressList', state)
    }
  }
  const isPlaceholder = !value
  const textClassName = `${addressText} ${
    isPlaceholder ? 'qc-input-placeholder' : ''
  } ellipsis`
  return (
    <FormRow label={label} required={!!rules?.required}>
      <RowView vAlign="center" hAlign="end">
        <div className={textClassName} onClick={handleClick}>
          {isPlaceholder
            ? placeholder
            : `${value.landmark?.title || ''}${value.address || ''}`}
        </div>
        <Icon name="iconyoujiantou" size={18} color="#888888FF" />
      </RowView>
    </FormRow>
  )
}
const FormAddressPicker: FC<FormAddressPickerProps> = ({
  field,
  ...otherProps
}) => (
  <ConnectForm field={field} rules={otherProps.rules}>
    <AddressPicker {...otherProps} />
  </ConnectForm>
)

export default FormAddressPicker
