import React, { FC, memo, useCallback, useContext, useMemo } from 'react'
import Icon from '../../Icon'
import FormRow from '../FormRow'
import { ConnectForm } from '../../QCForm'
import './index.global.less'
import { useClassNames } from '../../../hooks/classname'
import {
  RadioItemProps,
  FormRadioItemProps,
  MRadioItemProps
} from './interface'
import { RadioGroupContext } from './context'
export const RadioItem: FC<RadioItemProps> = ({
  label,
  className,
  sync,
  register,
  value,
  rules,
  ...restProps
}) => {
  const context = useContext(RadioGroupContext)

  const handleSync = useMemo(() => (context ? context.sync : sync), [
    context,
    sync
  ])
  const handleChange = useCallback((event) => {
    handleSync && handleSync(event.target.value)
  }, [])
  const radioProps = { ...restProps }
  if (context) {
    radioProps.onChange = handleChange
    radioProps.field = context.field
    radioProps.checked = context.value === value
    radioProps.disabled = context.disabled
  }
  const iconName = radioProps.checked
    ? 'iconxuanzhong'
    : 'iconquanquan-weixuanzhong'
  const iconColor = radioProps.checked ? '#FF9847FF' : '#eaeaea'
  const mergedClassName = useClassNames(['qc-radio-inner', className])
  return (
    <label className={className}>
      <FormRow label={label} required={!!rules?.required}>
        <div className={mergedClassName}>
          <input
            name={radioProps.field}
            {...(context && { ref: rules ? register?.(rules) : register })}
            type="radio"
            className="qc-radio"
            value={value}
            {...radioProps}
          />
          <Icon name={iconName} color={iconColor} size={30} />
        </div>
      </FormRow>
    </label>
  )
}
// 针对 FormProvider 的 带优化的Radioitem
const MRadioItem: FC<MRadioItemProps> = memo(
  ({ formState, watch, ...otherProps }) => <RadioItem {...otherProps} />,
  (prevProps, nextProps) =>
    prevProps.formState?.isDirty === nextProps.formState?.isDirty
)
const FormRadioItem: FC<FormRadioItemProps> = ({ field, ...otherProps }) => (
  <ConnectForm field={field} rules={otherProps.rules}>
    <MRadioItem {...otherProps} />
  </ConnectForm>
)

export default FormRadioItem
