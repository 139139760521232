import { AxiosRequestConfig } from 'axios'
import makeUseApi from '../utils/makeUseApi'
// 餐厅列表

export const RestaurantSearch: AxiosRequestConfig = {
  url: '/europe/search/takeaway',
  method: 'POST'
}
export const useRestaurantSearch = makeUseApi<
  Definitions.Europe.搜索外卖商户列表入参,
  Definitions.Europe.SearchResponseOf搜索商户返回值
>(RestaurantSearch)
